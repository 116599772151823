import createApi from "../utils/axiosInstance";

export const getGeneratedAccessToken = async () => {
  try {
    const api = createApi(false);
    const response = await api.get(`/fat-secret/generate-access-token`, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response.data;
  } catch (error) {
    console.error(
      "Generating access token failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getSearchResults = async (searchTerm, token, barcode) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/fat-secret/search-meal-items?${barcode ? `barcode=${barcode}` : ""}${
        searchTerm ? `search_expression=${searchTerm}` : ""
      }&format=json`,
      {
        headers: {
          "Content-Type": "application/json",
          "fatsecret-token": token
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting search data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getAllCustomFoodOptions = async (searchTerm, token, barcode) => {
  try {
    const api = createApi(true);
    const response = await api.get(`/custom-food/get-custom-food-item`);
    return response.data;
  } catch (error) {
    console.error(
      "Getting search data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const gettingCurrentMeal = async (mealId) => {
  try {
    const api = createApi(true);
    const response = await api.get(`/fat-secret/get-advance-meal/${mealId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Getting current meal failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const gettingCurrentRecipe = async (recipeId) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/fat-secret/get-advance-recipe/${recipeId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting current recipe failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const gettingAllUserMealOrRecipe = async (type) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/fat-secret/get-all-meals-recipe?type=${type}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting all meal or recipe failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const addMeal = async (mealName) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/fat-secret/add-advance-meal`, {
      mealName
    });
    return response.data;
  } catch (error) {
    console.error(
      "Adding meal failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const addRecipe = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/fat-secret/add-advance-recipe`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Adding recipe failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const addMealItems = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/fat-secret/add-advance-meal-items`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Adding meal items failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const saveMeal = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/fat-secret/edit-advance-meal`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Saving meal failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const saveRecipe = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/fat-secret/edit-advance-recipe`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Saving recipe failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const editMealItem = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(
      `/fat-secret/edit-advance-meal-items`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(
      "Editing meal items failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const deleteMealItem = async (id, itemId, type) => {
  try {
    const api = createApi(true);
    const response = await api.delete(
      `/fat-secret/delete-advance-meal-items/${type}/${id}/${itemId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "deleting meal items failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const deleteMealRecipe = async (id, type) => {
  try {
    const api = createApi(true);
    const response = await api.delete(
      `/fat-secret/delete-advance-meal-recipe/${type}/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "deleting meal failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const addCustomFoodItems = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(
      `/custom-food/add-edit-custom-food-item`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(
      "Adding custom food items failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const deleteCustomFoodItem = async (id) => {
  try {
    const api = createApi(true);
    const response = await api.delete(
      `/custom-food/delete-custom-food-item/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "deleting delete custom food item  failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
