import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Backdrop,
  Fade,
  Stack
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PaymentIcon from "@mui/icons-material/Payment";

export const FailedPaymentModal = ({ open, onClose }) => {
  const handleRetry = () => {
    onClose(); // Close the modal after handling retry
  };

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      sx={{ zIndex: 10000 }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 4,
            textAlign: "center"
          }}
        >
          <ErrorOutlineIcon
            sx={{
              fontSize: 60,
              color: "error.main",
              mb: 2
            }}
          />
          <Typography
            variant="h5"
            component="h2"
            sx={{
              fontWeight: "bold",
              color: "text.primary",
              mb: 1
            }}
          >
            Payment Issue Detected
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 3,
              color: "text.secondary"
            }}
          >
            It seems your recent payment didn't go through. Please try again
            with different card to continue accessing our services without
            interruptions.
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleRetry}
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                textTransform: "capitalize",
                px: 4
              }}
            >
              <PaymentIcon sx={{ mr: 1 }} />
              Retry Payment
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
              sx={{
                fontWeight: "bold",
                textTransform: "capitalize",
                px: 4
              }}
            >
              Dismiss
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};
