import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUserCards } from "../../features/auth/authSlice";
import {
  selectAuthLoading,
  selectCurrentUser,
  selectCurrentUserCards
} from "../../features/auth/authSelectors";
import { ReactComponent as CardIcon } from "../../assets/card.svg";
import { Add, ChevronLeft } from "@mui/icons-material";
import {
  TextField,
  Button,
  Typography,
  Snackbar,
  Box,
  styled,
  Alert,
  useMediaQuery,
  IconButton,
  Grid2
} from "@mui/material";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js";

import useThemeColor from "../../utils/useTheme";
import { createNewCard, removeCard, updateCard } from "../../api/authApi";
import DeleteUserModal from "./DeleteUserModal";
import { useTheme } from "@emotion/react";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(4, 0)
}));

const StyledInputLabel = styled("label")(({ styledColors }) => ({
  position: "absolute",
  top: "-18px",
  color: styledColors.inputLabelColor,
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ styledColors }) => ({
  input: {
    color: styledColors.color,
    ":disabled": {
      backgroundColor: "#A7C8E9",
      color: "#333333",
      borderRadius: "4px"
    }
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: styledColors.lightGreyColor
    },
    "&:hover fieldset": {
      borderColor: styledColors.color
    },
    "&.Mui-focused fieldset": {
      color: "#9CA3AF",
      borderColor: styledColors.color
    },
    "&.Mui-disabled": {
      "& input": {
        color: "red",
        cursor: "not-allowed"
      },
      "& fieldset": {
        borderColor: styledColors.lightGreyColor
      }
    }
  }
}));
const cardStyle = (styles) => ({
  base: {
    color: styles.color,
    fontSize: "16px",
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    letterSpacing: "0.025em",
    "::placeholder": {
      color: "#838283"
    },
    padding: "10px"
  },
  invalid: {
    color: "red",
    iconColor: "red"
  },
  complete: {
    color: "green"
  }
});
const CardLists = ({ setOpenSubscriptionModal }) => {
  const userData = useSelector(selectCurrentUser);
  const allCards = useSelector(selectCurrentUserCards);
  const styles = useThemeColor();
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [deleteCardModal, setDeleteCardModal] = useState(null);
  // const [editCardData, setEditCardData] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    cvv: "",
    expiryDate: "",
    cardNumber: ""
  });
  const [formErrors, setFormErrors] = useState({
    expiryDate: false
  });
  const [selectedCardId, setSelectedCardId] = useState(null);

  const matches = useMediaQuery("(max-width:590px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const theme = useTheme();
  const dispatch = useDispatch();
  const loading = useSelector(selectAuthLoading);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });

    if (name === "bio") {
      setFormErrors({
        ...formErrors,
        bio: !(value.length < 50)
      });
    } else {
      setFormErrors({ ...formErrors, [name]: value.trim() === "" });
    }
  };

  useEffect(() => {
    if (
      !(
        userData?.isAdmin === 1 ||
        ["Coach", "Company"].includes(userData?.userType)
      ) &&
      !userData?.isSubscriptionActive &&
      (userData?.isTrainingFreeTrialExpired ||
        userData?.isDiaryFreeTrialExpired)
    ) {
      setOpenSubscriptionModal(true);
    }
  }, [userData]);

  useEffect(() => {
    dispatch(fetchUserCards());
  }, []);

  useEffect(() => {
    if (allCards?.length) {
      const selectedCard = allCards?.find(
        (card) => card.isDefaultCard === true
      )?.id;
      setSelectedCardId(selectedCard);
    }
  }, [allCards]);
  useEffect(() => {
    if (formData.isJoiningThroughSource) {
      setFormData((prevState) => ({
        ...prevState,
        referenceId: ""
      }));
    }
  }, [formData.userType, formData.isJoiningThroughSource]);
  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        return;
      }

      const cardNumberElement = elements?.getElement(CardNumberElement);
      const cardExpiryElement = elements?.getElement(CardExpiryElement);
      const cardCvcElement = elements?.getElement(CardCvcElement);

      // Check if card number, expiry date, or CVC is invalid
      const cardNumberError =
        cardNumberElement?._empty || cardNumberElement?._invalid;
      const cardExpiryError =
        cardExpiryElement?._empty || cardExpiryElement?._invalid;
      const cardCvcError = cardCvcElement?._empty || cardCvcElement?._invalid;

      if (cardNumberError || cardExpiryError || cardCvcError) {
        setSnackbarMessage("Please correct card details first.");
        setOpenSnackbar(true);
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      // if (!editCardData) {
      const { token, error } = await stripe.createToken(cardElement, {
        name: formData.firstName + "-" + formData.lastName // Include the billing details name
      });

      if (error) {
        console.error("Error creating token:", error);
      } else {
        const id = token.id;
        const data = await createNewCard({ token: id });
        setIsAddingCard(false);
        setFormData({});
        dispatch(fetchUserCards());
      }
    } catch (error) {
      setSnackbarMessage(error?.message || "Adding card got failed.");
      setOpenSnackbar(true);
    }
  };

  return !(
    userData?.isAdmin === 1 || ["Coach", "Company"].includes(userData?.userType)
  ) &&
    !userData?.isSubscriptionActive &&
    (userData?.isTrainingFreeTrialExpired ||
      userData?.isDiaryFreeTrialExpired) ? (
    <></>
  ) : (
    <Box
      pl={3}
      color={styles.color}
      backgroundColor={styles.background}
      display={"flex"}
      width="100%"
      overflow="auto"
      flexDirection="column"
    >
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarMessage.includes("success") ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {isAddingCard ? (
        <Box
          flexGrow={1}
          paddingY={3}
          paddingX={3}
          sx={{
            width: { xs: "100%", md: "60%" },
            paddingTop: { xs: 2, md: 2 },
            paddingRight: { xs: 2, md: 13, lg: 20 },
            paddingBottom: { xs: 2, md: 2 },
            overflowY: { md: "auto" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start"
          }}
        >
          <Box
            component={"form"}
            sx={
              {
                // ...(matches && { marginBottom: { xs: 9 } })
              }
            }
            onSubmit={handleUpdate}
          >
            <StyledTextFieldContainer>
              <StyledInputLabel styledColors={styles} htmlFor="cardNumber">
                Card Number
              </StyledInputLabel>

              {/* {!editCardData?.cardNumber ? ( */}
              <Box p={2}>
                <CardNumberElement
                  options={{
                    style: {
                      padding: "20px !important",
                      base: cardStyle(styles).base,
                      invalid: cardStyle(styles).invalid
                    }
                  }}
                />
              </Box>
              {/* ) : (
                <StyledTextField
                  styledColors={styles}
                  fullWidth
                  variant="outlined"
                  name="cardNumber"
                  value={formData.cardNumber}
                  disabled
                  margin="normal"
                />
              )} */}
            </StyledTextFieldContainer>
            <Box display="flex" gap="10px" width={"100%"}>
              <StyledTextFieldContainer sx={{ margin: 0, width: "50%" }}>
                <StyledInputLabel styledColors={styles} htmlFor="expiryDate">
                  Expiry Date
                </StyledInputLabel>
                <Box p={2}>
                  <CardExpiryElement
                    options={{
                      style: {
                        base: cardStyle(styles).base,
                        invalid: cardStyle(styles).invalid
                      }
                    }}
                  />
                </Box>
              </StyledTextFieldContainer>
              <StyledTextFieldContainer sx={{ margin: 0, width: "50%" }}>
                <StyledInputLabel styledColors={styles} htmlFor="cvv">
                  CVV
                </StyledInputLabel>
                {/* {!editCardData?.cvv ? ( */}
                <Box p={2}>
                  <CardCvcElement
                    options={{
                      // ...(editCardData && { disabled: true }),
                      placeholder: "CVV",
                      style: {
                        base: cardStyle(styles).base,
                        invalid: cardStyle(styles).invalid
                      }
                    }}
                  />
                </Box>
                {/* ) : (
                  <StyledTextField
                    styledColors={styles}
                    fullWidth
                    variant="outlined"
                    name="cvv"
                    value={formData.cvv}
                    disabled
                    margin="normal"
                  />
                )} */}
              </StyledTextFieldContainer>
            </Box>
            <StyledTextFieldContainer>
              <StyledInputLabel styledColors={styles} htmlFor="firstName">
                Legal First Name
              </StyledInputLabel>
              <StyledTextField
                styledColors={styles}
                fullWidth
                variant="outlined"
                name="firstName"
                sx={{ marginTop: "20px" }}
                placeholder="Enter your first name"
                value={formData.firstName}
                onChange={handleInputChange}
                margin="normal"
              />
            </StyledTextFieldContainer>
            <StyledTextFieldContainer>
              <StyledInputLabel styledColors={styles} htmlFor="lastName">
                Legal Last Name
              </StyledInputLabel>
              <StyledTextField
                styledColors={styles}
                fullWidth
                variant="outlined"
                name="lastName"
                sx={{ marginTop: "20px" }}
                placeholder="Enter your last name"
                value={formData.lastName}
                onChange={handleInputChange}
                margin="normal"
              />
            </StyledTextFieldContainer>
            <Box display={"flex"} gap="10px">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!formData.firstName || !formData.lastName}
                sx={{
                  backgroundColor: "#368AD0",
                  cursor: "pointer",
                  color: "#fff",
                  height: "42px",
                  fontWeight: "bold",
                  textTransform: "none",
                  ":disabled": {
                    backgroundColor: "#A7C8E9",
                    color: "#64AEDD"
                  }
                }}
              >
                {/* {editCardData ? "Update Card" : */}
                Add Card
                {/* } */}
              </Button>
              <Button
                onClick={() => setIsAddingCard(false)}
                variant="outlined"
                sx={{
                  color: "#368AD0",
                  cursor: "pointer",
                  height: "42px",
                  textTransform: "none"
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      {!isAddingCard &&
        (allCards?.length ? (
          <Box
            flexGrow={1}
            paddingY={3}
            sx={{
              width: { xs: "100%" },
              paddingTop: { xs: 2, md: 4 },
              paddingRight: { xs: 2, md: 2, lg: 2 },
              paddingBottom: { xs: 8, md: 2 },
              // paddingLeft: { xs: 2, md: 10 },
              overflowY: { md: "auto" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start"
            }}
          >
            <Box>
              <Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography ml={2} fontSize={"20px"}>
                    Payment Methods
                  </Typography>
                  <Button
                    variant="no-outline"
                    onClick={() => setIsAddingCard(true)}
                    sx={{
                      textTransform: "none",
                      color: "#368AD0",
                      borderColor: "#368AD0",
                      gap: "8px",
                      borderRadius: "4px"
                    }}
                  >
                    Add New Card{" "}
                    <Add
                      sx={{
                        path: {
                          fill: "#368AD0"
                        }
                      }}
                    />
                  </Button>
                </Box>
                <Box p={3} sx={{ justifyContent: "center" }}>
                  <Grid2 container spacing={4}>
                    {allCards?.map((item, index) => (
                      <Grid2 item xs={12} sm={4} md={4} key={index}>
                        <Box
                          onClick={(e) => {
                            setSelectedCardId(item.id);
                          }}
                          sx={{
                            position: "relative",
                            width: { xs: "100%", md: "350px" },
                            height: { xs: "100%", md: "230px" },
                            borderRadius: "20px",
                            padding: {
                              xs: "24px 80px 10px 24px",
                              md: "24px 24px 10px 24px"
                            },
                            overflow: "hidden",
                            bgcolor: "transparent",
                            boxShadow: 3,
                            ...(item.id === selectedCardId && {
                              border: "2px solid #64AEDD"
                            }),
                            background:
                              "linear-gradient(to bottom right, #333333, #64AEDD)", // Emerald gradient
                            ":hover": {
                              cursor: "pointer"
                            }
                          }}
                        >
                          {/* Radial Pattern Overlay */}
                          <Box
                            sx={{
                              position: "absolute",
                              inset: 0,
                              opacity: 0.2
                            }}
                          />

                          {/* Card Content */}
                          <Box
                            sx={{
                              position: "relative",
                              zIndex: 1,
                              gap: 1.5,
                              display: "flex",
                              flexDirection: "column"
                            }}
                          >
                            <Box>
                              {/* Card Number */}
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "white",
                                  fontWeight: 700,
                                  letterSpacing: 1.5,
                                  mb: 2
                                }}
                              >
                                {item.cardNumber}
                              </Typography>

                              {/* Cardholder Name */}
                              <Typography
                                sx={{
                                  color: "white",
                                  mb: 1,
                                  textTransform: "capitalize"
                                }}
                              >
                                {item.firstName + " " + item.lastName || ""}
                              </Typography>

                              {/* Expiry and CVV */}
                              <Grid2 container spacing={4}>
                                <Grid2 item xs={6}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "rgba(162, 162, 167, 1)",
                                      mb: 1
                                    }}
                                  >
                                    Expiry Date
                                  </Typography>
                                  <Typography
                                    sx={{ color: "white", fontWeight: 500 }}
                                  >
                                    {item.expiryDate}
                                  </Typography>
                                </Grid2>
                                <Grid2 item xs={6}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "rgba(162, 162, 167, 1)",
                                      mb: 1
                                    }}
                                  >
                                    CVV
                                  </Typography>
                                  <Typography
                                    sx={{ color: "white", fontWeight: 500 }}
                                  >
                                    {item.cvv}
                                  </Typography>
                                </Grid2>
                              </Grid2>
                            </Box>

                            {/* Bottom Actions */}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline"
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1
                                }}
                              >
                                {/* <Button
                              fullWidth
                              variant="outlined"
                              onClick={() => {
                                setIsAddingCard(true);
                                setEditCardData(item);
                              }}
                              sx={{
                                width: "100px",
                                textTransform: "none",
                                color: "#368AD0",
                                borderColor: "#368AD0",
                                marginTop: "12px",
                                gap: "8px",
                                borderRadius: "4px"
                              }}
                            >
                              Edit Card
                            </Button> */}
                                {item.isDefaultCard ? (
                                  <></>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    // variant="no-outline"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDeleteCardModal(item.id);
                                    }}
                                    sx={{
                                      textTransform: "none",
                                      color: "#fff",
                                      borderColor: "#fff",
                                      marginTop: "12px",
                                      gap: "8px",
                                      borderRadius: "4px"
                                    }}
                                  >
                                    Remove
                                  </Button>
                                )}
                              </Box>
                              {item.id === selectedCardId && (
                                <Box
                                  sx={{
                                    bgcolor: "#64AEDD",
                                    color: "white",
                                    paddingX: 1,
                                    // paddingY: 0.5,
                                    fontSize: 13,
                                    borderRadius: "40px"
                                  }}
                                >
                                  Selected
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Grid2>
                    ))}
                  </Grid2>
                </Box>
              </Box>

              <Box
                mt={"1em"}
                width={"100%"}
                display="flex"
                justifyContent="flex-end"
                mr={2}
              >
                <Button
                  disabled={
                    allCards?.find((card) => card.isDefaultCard === true)
                      ?.id === selectedCardId
                  }
                  onClick={async () => {
                    await updateCard(selectedCardId);
                    await dispatch(fetchUserCards());
                  }}
                  sx={{
                    "&:disabled": {
                      backgroundColor: "#A7C8E9",
                      color: "#64AEDD",
                      pointerEvents: "none"
                    },
                    mx: 3,
                    width: { xs: "100%", md: "150px" },
                    textTransform: "none",
                    background: "#368AD0",
                    color: "#fcfcfc",
                    height: "40px",
                    padding: "12px 24px",
                    gap: "8px",
                    borderRadius: "4px",
                    mt: 1 // Make sure it has some margin on top
                  }}
                >
                  Save Payment
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "center", // Horizontally center
              alignItems: "center", // Vertically center
              // height: "70vh", // Full height of the viewport
              flexDirection: "column" // Stack items vertically
            }}
          >
            <CardIcon />
            <Box
              justifyContent="center"
              display="flex"
              flexDirection="column"
              alignItems={"center"}
            >
              <Typography color="#6B7280" fontWeight={500} fontSize={"24px"}>
                No Cards
              </Typography>
              <Typography color="#6B7280" fontSize={"14px"}>
                There is no cards to show
              </Typography>
            </Box>
            <Button
              onClick={() => setIsAddingCard(!isAddingCard)}
              sx={{
                textTransform: "none",
                background: "#368AD0",
                color: "#fcfcfc",
                height: "40px",
                padding: "12px 24px",
                gap: "8px",
                borderRadius: "4px"
              }}
            >
              Add New Card
              <Add />
            </Button>
          </Box>
        ))}
      <DeleteUserModal
        open={deleteCardModal}
        handleDelete={async () => {
          await removeCard(deleteCardModal);
          setDeleteCardModal(null);
          dispatch(fetchUserCards());
        }}
        handleClose={() => setDeleteCardModal(null)}
      />
    </Box>
  );
};

export default CardLists;
