import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as TPillars } from "../assets/mainlogo.svg";
import { ReactComponent as DiaryIcon } from "../assets/diary.svg";
import { ReactComponent as ModIcon } from "../assets/mod.svg";
import { ReactComponent as MealsIcon } from "../assets/meals.svg";
import { ReactComponent as ProfileIcon } from "../assets/profile.svg";
import { ReactComponent as ProgramIcon } from "../assets/book.svg";
import { ReactComponent as PremiumIcon } from "../assets/premium.svg";
import {
  Button,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
  Badge
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import SubscriptionModal from "../components/Modals/SubscriptionModal";
import { useCurrentUser } from "../components/Extra/UserContext";
import FreeTrialModal from "../components/Modals/FreeTrialModal";
import dayjs from "dayjs";

const drawerWidth = 265;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 10px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 24px)`
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  height: "92px",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(4, 1),
  ...theme.mixins.toolbar,
  position: "relative" // Add relative position for absolute positioning of the toggle button
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth - 20,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflow: "visible",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      overflow: "visible"
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      overflow: "visible"
    }
  })
}));

const AdminDashboard = ({
  activeLevelForMobile,
  open,
  setOpen,
  openSubscriptionModal,
  setOpenSubscriptionModal,
  openFreeTrialModal,
  setOpenFreeTrialModal
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const scrollableElement = document.getElementById("dashboard-main"); // or document.body
    scrollableElement.scrollTo(0, 0);
  }, []);
  const menuItems = [
    { text: "MOD", icon: <ModIcon />, path: "/" },
    { text: "Diary", icon: <DiaryIcon />, path: "/diary" },
    { text: "Programs", icon: <ProgramIcon />, path: "/programs" },
    { text: "Meal Library", icon: <MealsIcon />, path: "/meals" },
    { text: "Profile", icon: <ProfileIcon />, path: "/profile" }
  ];
  const currentUser = useCurrentUser();

  const { is_verified = false } = location.state || {
    is_verified: false
  };

  React.useEffect(() => {
    if (Boolean(is_verified)) {
      setOpenSubscriptionModal(false);
    } else if (
      !(
        currentUser?.isAdmin === 1 ||
        ["Coach", "Company"].includes(currentUser?.userType)
      ) &&
      !currentUser?.isSubscriptionActive &&
      currentUser?.isSubscriptionNeeded &&
      (currentUser?.isTrainingFreeTrialExpired ||
        currentUser?.isDiaryFreeTrialExpired)
    ) {
      setOpenSubscriptionModal(true);
    }
  }, [currentUser]);
  const freeTrialExpired =
    dayjs().diff(dayjs(currentUser?.createdAt), "days") + 1;

  const { startFreeTrial = false } = location.state || {
    startFreeTrial: false
  };

  React.useEffect(() => {
    if (
      !(
        currentUser?.isAdmin === 1 ||
        ["Coach", "Company"].includes(currentUser?.userType)
      ) &&
      startFreeTrial
    )
      setOpenFreeTrialModal(true);
  }, [startFreeTrial]);

  const lastDiaryVisitedToday = localStorage.getItem("diaryLastVisited")
    ? dayjs(localStorage.getItem("diaryLastVisited")).isSame(dayjs(), "day")
      ? true
      : false
    : null;
  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          ".MuiDrawer-paper": {
            display: "flex",
            justifyContent: "space-between",
            background: "#1C1C1C",
            color: "#9CA3AF"
          },
          display: { xs: "none", sm: "block" }
        }}
      >
        <Box>
          <DrawerHeader
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box
              display="flex"
              sx={{
                width: "85%",
                height: "auto"
              }}
              gap="11px"
            >
              <Box height={55} component="img" src="/images/threepillars.png" />
              <TPillars
                style={{
                  width: "100%",
                  height: "auto"
                }}
              />
            </Box>
            {/* Toggle Button for Drawer */}
            <IconButton
              onClick={() => setOpen(!open)}
              sx={{
                position: "absolute",
                padding: 0,
                top: "50%",
                right: -12, // Position based on drawer state
                transform: "translateY(-50%)",
                backgroundColor: "#1F2937",
                color: "#9CA3AF",
                "&:hover": {
                  backgroundColor: "#333333"
                }
              }}
            >
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List style={{ marginTop: "20px" }}>
            {menuItems.map(({ text, icon, path }) => (
              <ListItem key={text} sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => navigate(path)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    ...(location.pathname === path && {
                      background: "#1F2937",
                      borderRadius: "4px"
                    })
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      position: "relative", // Make icon positioning relative
                      ...(location.pathname === path
                        ? !["Meal Library", "Profile"].includes(text)
                          ? {
                              "& svg path": {
                                fill: "#368AD0"
                              }
                            }
                          : {
                              "& svg path": {
                                stroke: "#368AD0",
                                fill: "none"
                              }
                            }
                        : {})
                    }}
                  >
                    {text === "Programs" &&
                    !open &&
                    currentUser?.activeLevel !== "level3" &&
                    (!currentUser?.isSubscriptionNeeded ||
                      !currentUser?.isTrainingFreeTrialExpired) &&
                    currentUser?.pendingTrainingDays ? (
                      <Badge
                        sx={{
                          "& .MuiBadge-badge": {
                            color: "#FFF",
                            backgroundColor: "#F6541C",
                            position: "absolute", // Set badge position as absolute
                            top: open ? 0 : 2, // Adjust based on drawer state
                            left: open ? "60px" : "10px" // Adjust badge positioning for closed state
                          }
                        }}
                        badgeContent={
                          !currentUser?.isSubscriptionNeeded
                            ? currentUser?.pendingTrainingDays
                            : !currentUser?.isTrainingFreeTrialExpired
                            ? Math.min(5, currentUser?.pendingTrainingDays)
                            : currentUser?.pendingTrainingDays
                        }
                      >
                        {icon}
                      </Badge>
                    ) : text === "Diary" &&
                      !window.location.pathname.includes("diary") &&
                      !open &&
                      (!currentUser?.isDiarySubscriptionNeeded ||
                        !currentUser?.isDiaryFreeTrialExpired) &&
                      !lastDiaryVisitedToday ? (
                      <Badge
                        variant="dot"
                        sx={{
                          "& .MuiBadge-badge": {
                            color: "#FFF",
                            backgroundColor: "#F6541C",
                            left: "12px",
                            minWidth: "12px",
                            height: "12px",
                            borderRadius: "50%"
                          }
                        }}
                      >
                        {icon}
                      </Badge>
                    ) : (
                      icon
                    )}
                  </ListItemIcon>
                  {text === "Programs" &&
                  open &&
                  currentUser?.activeLevel !== "level3" &&
                  (!currentUser?.isSubscriptionNeeded ||
                    !currentUser?.isTrainingFreeTrialExpired) &&
                  currentUser?.pendingTrainingDays ? (
                    <Badge
                      sx={{
                        "& .MuiBadge-badge": {
                          color: "#FFF",
                          backgroundColor: "#F6541C",
                          left: "60px"
                        }
                      }}
                      badgeContent={
                        !currentUser?.isSubscriptionNeeded
                          ? currentUser?.pendingTrainingDays
                          : !currentUser?.isTrainingFreeTrialExpired
                          ? Math.min(5, currentUser?.pendingTrainingDays)
                          : currentUser?.pendingTrainingDays
                      }
                    >
                      <ListItemText
                        primary={text}
                        sx={{
                          opacity: open ? 1 : 0,
                          ...(location.pathname === path && {
                            color: "#368AD0"
                          })
                        }}
                      />
                    </Badge>
                  ) : text === "Diary" &&
                    !window.location.pathname.includes("diary") &&
                    open &&
                    (!currentUser?.isDiarySubscriptionNeeded ||
                      !currentUser?.isDiaryFreeTrialExpired) &&
                    !lastDiaryVisitedToday ? (
                    <Badge
                      variant="dot"
                      sx={{
                        "& .MuiBadge-badge": {
                          color: "#FFF",
                          backgroundColor: "#F6541C",
                          left: "30px",
                          minWidth: "15px",
                          height: "15px",
                          borderRadius: "50%"
                        }
                      }}
                    >
                      <ListItemText
                        primary={text}
                        sx={{
                          opacity: open ? 1 : 0,
                          ...(location.pathname === path && {
                            color: "#368AD0"
                          })
                        }}
                      />
                    </Badge>
                  ) : (
                    <ListItemText
                      primary={text}
                      sx={{
                        opacity: open ? 1 : 0,
                        ...(location.pathname === path && {
                          color: "#368AD0"
                        })
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        {open && !currentUser?.isSubscriptionActive && (
          <Box
            margin="0 16px 40px 16px"
            alignItems={"center"}
            justifyContent={"center"}
            display="flex"
            flexDirection="column"
            sx={{
              background: "#333333",
              padding: "20px",
              color: "#9CA3AF",
              borderRadius: "4px",
              display: { xs: "none", sm: "flex" }
            }}
          >
            <PremiumIcon />
            <Typography mt={2} fontSize="16px" fontWeight={600} color="#FCFCFC">
              3 Pillar full Access
            </Typography>
            <Typography fontSize="12px" fontWeight={400} color="#FCFCFC">
              Get all benefits
            </Typography>
            <Button
              fullWidth
              variant="outlined"
              style={{
                textTransform: "none",
                background: "#368AD0",
                color: "#FCFCFC",
                border: "none",
                marginTop: "10px"
              }}
              onClick={() => setOpenFreeTrialModal(true)}
            >
              Free Trial (
              {freeTrialExpired > 5
                ? "Expired"
                : `${5 - freeTrialExpired} days left`}
              )
            </Button>
            <Button
              fullWidth
              variant="outlined"
              style={{
                textTransform: "none",
                color: "#368AD0",
                borderColor: "#368AD0",
                marginTop: "10px"
              }}
              onClick={() => setOpenSubscriptionModal(true)}
            >
              View Plans
            </Button>
          </Box>
        )}
      </Drawer>
      <SubscriptionModal
        open={openSubscriptionModal}
        onClose={() => setOpenSubscriptionModal(false)}
        isMobile={isMobile}
      />
      <FreeTrialModal
        open={openFreeTrialModal}
        onClose={() => setOpenFreeTrialModal(false)}
        isMobile={isMobile}
        disabledFreeTrial={freeTrialExpired > 5}
        openSubscriptionModal={() => setOpenSubscriptionModal(true)}
      />
      {
        // (location.pathname === "/programs"
        //   ? isMobile && !activeLevelForMobile
        //   :
        isMobile && (
          // )
          <BottomNavigation
            showLabels
            sx={{
              height: "60px",
              display: { xs: "flex", sm: "none" },
              position: "fixed",
              bottom: 0,
              width: "100%",
              zIndex: 1000,
              backgroundColor: "#333333",
              svg: { path: { fill: "#9CA3AF" } }
            }}
            value={location.pathname}
            onChange={(event, newValue) => {
              navigate(newValue);
            }}
          >
            {menuItems.map(({ text, icon, path }) => (
              <BottomNavigationAction
                key={text}
                label={text}
                icon={
                  text === "Programs" &&
                  currentUser?.activeLevel !== "level3" &&
                  (!currentUser?.isSubscriptionNeeded ||
                    !currentUser?.isTrainingFreeTrialExpired) &&
                  currentUser?.pendingTrainingDays ? (
                    <Badge
                      badgeContent={currentUser?.pendingTrainingDays}
                      sx={{
                        "& .MuiBadge-badge": {
                          color: "#FFF",
                          backgroundColor: "#F6541C",
                          right: -10, // Adjusts badge position
                          top: -5 // Adjusts badge position
                        }
                      }}
                    >
                      {icon}
                    </Badge>
                  ) : (
                    icon
                  )
                }
                value={path}
                sx={{
                  "&.MuiButtonBase-root ": {
                    gap: "4px",
                    ...(location.pathname === path
                      ? !["Meal Library", "Profile"].includes(text)
                        ? {
                            "& svg path": {
                              fill: "#368AD0"
                            }
                          }
                        : {
                            "& svg path": {
                              stroke: "#368AD0",
                              fill: "none" // Optional: Set fill to none for these two
                            }
                          }
                      : {})
                  },
                  color: "#9CA3AF",
                  ...(location.pathname === path && {
                    color: "#368AD0 !important"
                  })
                }}
              />
            ))}
          </BottomNavigation>
        )
      }
    </>
  );
};

export default AdminDashboard;
