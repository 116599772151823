import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import React, { useEffect, useState } from "react";
import getInitials from "../../utils/getInitials";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectCurrentUserProfile
} from "../../features/auth/authSelectors";
import {
  DarkMode,
  DeleteOutline,
  Info,
  LightMode,
  LinkOutlined
} from "@mui/icons-material";
import { ReactComponent as TrophyIcon } from "../../assets/trophy.svg";
import { ReactComponent as StreaksIcon } from "../../assets/streak.svg";
import { ReactComponent as ChefIcon } from "../../assets/chef.svg";
import { ReactComponent as CoachIcon } from "../../assets/coach.svg";
import { ReactComponent as ConstantIcon } from "../../assets/constant.svg";
import { ReactComponent as BicepIcon } from "../../assets/bicep.svg";
import { ReactComponent as Lvl1Icon } from "../../assets/lvl1.svg";
import { ReactComponent as Lvl2Icon } from "../../assets/lvl2.svg";
import { ReactComponent as Lvl3Icon } from "../../assets/lvl3.svg";
import { ReactComponent as UserIcon } from "../../assets/profile.svg";
import { ReactComponent as LockIcon } from "../../assets/lock.svg";
import { ReactComponent as BillingIcon } from "../../assets/billing.svg";
import { ReactComponent as GroupIcon } from "../../assets/user.svg";
import { ReactComponent as StarIcon } from "../../assets/star.svg";
import { ReactComponent as LogoutIcon } from "../../assets/power.svg";
import { ReactComponent as PremiumIcon } from "../../assets/premiumicon.svg";
import {
  fetchCurrentUser,
  fetchCurrentUserProfileData,
  logoutUser
} from "../../features/auth/authSlice";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import useSignedUrl from "../../utils/useSignedUrl";
import { toggleTheme } from "../../features/theme/themeSlice";
import useThemeColor from "../../utils/useTheme";
import { selectCurrentTheme } from "../../features/theme/themeSelectors";
import styled from "@emotion/styled";
import { deleteUser } from "../../api/userApi";
import DeleteUserModal from "../Modals/DeleteUserModal";
import SubscriptionModal from "../Modals/SubscriptionModal";
import dayjs from "dayjs";
import AchievementModal from "../Modals/InfoModal";
import formatNumber from "../../utils/formatNumber";
import ReferralModal from "../Modals/ReferFriendModal";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
        ...theme.applyStyles("dark", {
          backgroundColor: "#8796A5"
        })
      }
    }
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#001e3c",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#003892"
    })
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#aab4be",
    borderRadius: 20 / 2,
    ...theme.applyStyles("dark", {
      backgroundColor: "#8796A5"
    })
  }
}));

const getLeaderPosition = (index) => {
  switch (index) {
    case 0:
      return "1st 🥇";
    case 1:
      return "2nd 🥈";
    case 2:
      return "3rd 🥉";
    default:
      return `${index + 1}`;
  }
};

function Profile({
  openSubscriptionModal,
  setOpenSubscriptionModal,
  setOpenFreeTrialModal
}) {
  const userData = useSelector(selectCurrentUser);
  const userProfileData = useSelector(selectCurrentUserProfile);
  const currentTheme = useSelector(selectCurrentTheme);
  const dispatch = useDispatch();
  const styles = useThemeColor();
  const theme = useTheme(); // Use theme hook to get the theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Correctly access breakpoints
  const isTablet = useMediaQuery("(min-width: 600px) and (max-width: 900px)");
  const navigate = useNavigate();
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [openReferModal, setOpenReferModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const scrollableElement = document.getElementById("dashboard-main"); // or document.body
    scrollableElement.scrollTo(0, 0);
  }, []);
  const lastDiaryVisitedLevel = localStorage.getItem("lastDiaryVisitedLevel");
  useEffect(() => {
    dispatch(fetchCurrentUser(dayjs().format("YYYY-MM-DD")));
    dispatch(
      fetchCurrentUserProfileData({
        diaryLevel: JSON.parse(lastDiaryVisitedLevel || "{}")?.level || "",
        date: dayjs().format("YYYY-MM-DD")
      })
    );
  }, [dispatch]);

  React.useEffect(() => {
    if (
      !(
        userData?.isAdmin === 1 ||
        ["Coach", "Company"].includes(userData?.userType)
      ) &&
      !userData?.isSubscriptionActive &&
      (userData?.isTrainingFreeTrialExpired ||
        userData?.isDiaryFreeTrialExpired)
    ) {
      setOpenSubscriptionModal(true);
    }
  }, [userData]);
  const freeTrialExpired = dayjs().diff(dayjs(userData.createdAt), "days") + 1;
  const imgSrc = useSignedUrl(
    userData?.profilePic,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );

  return !(
    userData?.isAdmin === 1 || ["Coach", "Company"].includes(userData?.userType)
  ) &&
    !userData?.isSubscriptionActive &&
    (userData?.isTrainingFreeTrialExpired ||
      userData?.isDiaryFreeTrialExpired) ? (
    <></>
  ) : (
    <Box
      sx={{
        padding: { xs: "0 0 100px 0" }
      }}
    >
      <Box width="100%" component="img" src="/images/frame.png" alt="frame" />
      <Box p="0 24px" display="flex" flexDirection="column" gap={6}>
        <Box
          position="relative"
          sx={{
            display: {
              sm: "block",
              md: "flex"
            },
            gap: {
              sm: "140px",
              md: "200px"
            }
          }}
        >
          <Box
            display="flex"
            gap="10px"
            mt={isTablet ? 2.5 : 0}
            alignItems="center"
            sx={{
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                cursor: "pointer",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                transform: "scale(1.05)"
              }
            }}
          >
            {userData?.profilePic ? (
              <Box
                component="img"
                height={170}
                borderRadius="50%"
                border={`3px solid ${styles.background}`}
                width={170}
                style={{
                  ...(isMobile && { top: "-45px" }),
                  "user-drag": "none",
                  "-webkit-user-drag": "none",
                  "-moz-user-select": "none"
                }}
                position={"absolute"}
                src={imgSrc}
                alt="avatar"
                bottom="-10px"
              />
            ) : (
              <Avatar
                sx={{
                  ...(isMobile && { top: "-45px" }),
                  width: 170,
                  height: 170,
                  position: "absolute",
                  bottom: "-10px",
                  border: `3px solid ${styles.background}`
                }}
              >
                <Typography
                  color={styles.color}
                  fontSize="30px"
                  fontWeight={400}
                >
                  {getInitials(userData?.fullName)}
                </Typography>
              </Avatar>
            )}{" "}
          </Box>
          <Box
            pl={isTablet ? 25 : 2}
            sx={{
              ...(isMobile && { marginTop: "140px" })
            }}
          >
            <Typography color={styles.color} fontSize="30px" fontWeight={500}>
              {userData?.displayName}
            </Typography>
            <Typography color={styles.color} fontSize="16px" fontWeight={600}>
              {userData?.fullName}
            </Typography>
            <Typography
              color={styles.lightBlack}
              fontSize="16px"
              fontWeight={400}
            >
              {userData?.bio}
            </Typography>
            {userData?.url && (
              <Box
                display="flex"
                alignItems="center"
                gap="5px"
                sx={{
                  ":hover": {
                    cursor: "pointer"
                  }
                }}
                onClick={() => {
                  const fullUrl =
                    userData?.url.startsWith("http://") ||
                    userData?.url.startsWith("https://")
                      ? userData?.url
                      : `https://${userData?.url}`; // You can choose http or https based on your requirements

                  window.open(fullUrl, "_blank");
                }}
              >
                <LinkOutlined
                  color="#368AD0"
                  sx={{ path: { color: "#368AD0" } }}
                />
                <Typography color="#368AD0" fontSize="13px" fontWeight={500}>
                  {userData?.url}
                </Typography>
              </Box>
              // </a>
            )}
          </Box>
        </Box>
        <Box
          padding={3}
          // height={isMobile ? 300 : 250}
          border="1px solid #8E8E8E"
          borderRadius={1}
          sx={{ background: styles.lightGreyColor }}
        >
          <Box
            display="flex"
            gap={3}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              gap={2}
              width={isMobile ? "100%" : "unset"}
              alignItems="center"
              justifyContent="space-between"
            >
              <TrophyIcon />
              <Box width={"100%"}>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/point-stats")}
                    color={styles.color}
                    fontSize={isMobile ? "16px" : "24px"}
                    fontWeight={500}
                  >
                    {formatNumber(userProfileData?.userPoints?.toFixed(2)) || 0}{" "}
                    pts
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    height={30}
                    color="#14142B"
                    sx={{ background: "#FFE1CA" }}
                    padding={"0 10px"}
                    borderRadius="100px"
                  >
                    <StreaksIcon height={14} width={14} />
                    <Typography fontWeight={700} fontSize="12px">
                      {" "}
                      {userProfileData?.streaks}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  color={styles.lightBlack}
                  fontSize={isMobile ? "11px" : "14px"}
                  fontWeight={400}
                >
                  Total points earned
                </Typography>
              </Box>
            </Box>
            {isMobile ? (
              <></>
            ) : (
              userProfileData?.challenge?._id && (
                <Box display={"flex"} gap={8}>
                  <Box
                    display="flex"
                    gap={2}
                    width={isMobile ? "100%" : "unset"}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box width={"100%"}>
                      <Typography
                        // color={styles.lightBlack}
                        fontSize={isMobile ? "13px" : "16px"}
                        fontWeight={600}
                      >
                        {userProfileData?.challenge?.title}
                      </Typography>
                      <Typography
                        color={styles.lightBlack}
                        fontSize={isMobile ? "11px" : "14px"}
                        fontWeight={400}
                      >
                        Challenge is Live!
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    gap={2}
                    width={isMobile ? "100%" : "unset"}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box width={"100%"}>
                      <Typography
                        // color={styles.lightBlack}
                        fontSize={isMobile ? "13px" : "16px"}
                        fontWeight={600}
                      >
                        Challenge Points
                      </Typography>
                      <Typography
                        textAlign={"center"}
                        color={styles.lightBlack}
                        fontSize={isMobile ? "11px" : "14px"}
                        fontWeight={400}
                      >
                        {+userProfileData?.userChallengePoints}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    gap={2}
                    width={isMobile ? "100%" : "unset"}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box width={"100%"}>
                      <Typography
                        // color={styles.lightBlack}
                        fontSize={isMobile ? "13px" : "16px"}
                        fontWeight={600}
                      >
                        Current Standing
                      </Typography>
                      <Typography
                        textAlign={"center"}
                        color={styles.lightBlack}
                        fontSize={isMobile ? "12px" : "15px"}
                        fontWeight={500}
                      >
                        {getLeaderPosition(userProfileData?.position)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )
            )}{" "}
            <Box>
              <Button
                variant="outlined"
                onClick={() => navigate("/point-stats")}
                sx={{
                  textTransform: "none",
                  color: "#368AD0",
                  borderColor: "#368AD0",
                  gap: "8px",
                  borderRadius: "4px",
                  alignSelf: "flex-end" // Align button to the end horizontally
                }}
              >
                See Stats
              </Button>
            </Box>
          </Box>
          {!isMobile ? (
            <></>
          ) : (
            userProfileData?.challenge?._id && (
              <Box display={"flex"} gap={2} mt={2}>
                <Box
                  display="flex"
                  gap={2}
                  width={isMobile ? "100%" : "unset"}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box width={"100%"}>
                    <Typography
                      // color={styles.lightBlack}
                      fontSize={isMobile ? "13px" : "16px"}
                      fontWeight={600}
                    >
                      {userProfileData?.challenge?.title}
                    </Typography>
                    <Typography
                      color={styles.lightBlack}
                      fontSize={isMobile ? "11px" : "14px"}
                      fontWeight={400}
                    >
                      Challenge is Live!
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  gap={2}
                  width={isMobile ? "100%" : "unset"}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box width={"100%"}>
                    <Typography
                      // color={styles.lightBlack}
                      fontSize={isMobile ? "13px" : "16px"}
                      fontWeight={600}
                    >
                      Challenge Points
                    </Typography>
                    <Typography
                      textAlign={"center"}
                      color={styles.lightBlack}
                      fontSize={isMobile ? "11px" : "14px"}
                      fontWeight={400}
                    >
                      {userProfileData?.userChallengePoints}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  gap={2}
                  width={isMobile ? "100%" : "unset"}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box width={"100%"}>
                    <Typography
                      // color={styles.lightBlack}
                      fontSize={isMobile ? "13px" : "16px"}
                      fontWeight={600}
                    >
                      Current Standing
                    </Typography>
                    <Typography
                      textAlign={"center"}
                      color={styles.lightBlack}
                      fontSize={isMobile ? "12px" : "15px"}
                      fontWeight={500}
                    >
                      {getLeaderPosition(userProfileData?.position)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )
          )}{" "}
          {userProfileData?.approvedMealsCount ||
          userData?.completedLevel?.length ||
          (userData?.isAdmin && userData?.userType === "Coach") ||
          userProfileData?.isConsistent ||
          userProfileData?.maxStreak ? (
            <>
              {" "}
              <Divider
                sx={{
                  background: "#8E8E8E",
                  margin: "11px 0"
                }}
              />{" "}
              <Box>
                <Box display={"flex"} gap="10px">
                  <Typography
                    color={styles.inputLabelColor}
                    fontSize="16px"
                    fontWeight={500}
                  >
                    Achievements{" "}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    sx={{ padding: 0 }}
                  >
                    <Info
                      sx={{
                        color: styles.color
                      }}
                    />
                  </IconButton>
                </Box>
                <Box
                  display="flex"
                  overflow="auto"
                  sx={{
                    "::-webkit-scrollbar": {
                      display: "none"
                    }
                  }}
                  mt={2}
                  gap={2}
                  alignItems="center"
                >
                  {userProfileData?.approvedMealsCount ? (
                    <Tooltip
                      slotProps={{
                        tooltip: {
                          sx: {
                            padding: 0
                          }
                        }
                      }}
                      title={
                        <Box
                          sx={{
                            margin: 0,
                            padding: 2,
                            background: styles.color,
                            color: styles.background,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            transition: "transform 0.3s, box-shadow 0.3s",
                            "&:hover": {
                              cursor: "pointer",
                              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                              transform: "scale(1.05)"
                            }
                          }}
                        >
                          <Typography fontWeight="bold" fontSize={16}>
                            Chef
                          </Typography>
                          <Typography fontSize={13}>
                            Reflects the number of submitted Meal of the Days
                            (MOD) posted publicly in the app.
                          </Typography>
                        </Box>
                      }
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ cursor: "pointer" }}
                        gap="10px"
                        position="relative" // Make the Box a positioning context
                      >
                        <ChefIcon height={80} width={80} />
                        {/* <Typography fontSize="0.7rem" variant="bold">
                        Chef
                      </Typography> */}
                        <Typography
                          color="#F6541C"
                          borderRadius="50%"
                          fontSize="smaller"
                          sx={{
                            padding:
                              String(userProfileData?.approvedMealsCount)
                                ?.length === 1
                                ? "1px 7px"
                                : "1px 4px",
                            background: styles.color,
                            position: "absolute", // Position the count absolutely
                            top: "45px", // Adjust as needed
                            right: "10px", // Adjust as needed
                            transform: "translate(50%, 50%)" // Center the count on the border
                          }}
                        >
                          {userProfileData?.approvedMealsCount}
                        </Typography>
                      </Box>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                  {userData?.completedLevel?.at(-1) === "level1" ? (
                    <Tooltip
                      slotProps={{
                        tooltip: {
                          sx: {
                            padding: 0
                          }
                        }
                      }}
                      title={
                        <Box
                          sx={{
                            margin: 0,
                            padding: 2,
                            background: styles.color,
                            color: styles.background,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center"
                          }}
                        >
                          <Typography fontWeight="bold" fontSize={16}>
                            Level 1
                          </Typography>
                          <Typography fontSize={13}>
                            One hash mark each for the completion of
                            #800gChallenge®, Lazy Macros®, and Three Pillars
                            Method® programs.
                          </Typography>
                        </Box>
                      }
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        gap="10px"
                        position="relative" // Make the Box a positioning context
                      >
                        <Lvl1Icon height={80} width={80} />
                        {/* <Typography fontSize="0.7rem" variant="bold">
                        Training Lvl 1
                      </Typography> */}
                      </Box>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                  {userData?.completedLevel?.at(-1) === "level2" ? (
                    <Tooltip
                      slotProps={{
                        tooltip: {
                          sx: {
                            padding: 0
                          }
                        }
                      }}
                      title={
                        <Box
                          sx={{
                            margin: 0,
                            padding: 2,
                            background: styles.color,
                            color: styles.background,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center"
                          }}
                        >
                          <Typography fontWeight="bold" fontSize={16}>
                            Level 2
                          </Typography>
                          <Typography fontSize={13}>
                            One hash mark each for the completion of
                            #800gChallenge®, Lazy Macros®, and Three Pillars
                            Method® programs.
                          </Typography>
                        </Box>
                      }
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ cursor: "pointer" }}
                        gap="10px"
                        position="relative" // Make the Box a positioning context
                      >
                        <Lvl2Icon height={80} width={80} />
                      </Box>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                  {userData?.completedLevel?.at(-1) === "level3" ? (
                    <Tooltip
                      slotProps={{
                        tooltip: {
                          sx: {
                            padding: 0
                          }
                        }
                      }}
                      title={
                        <Box
                          sx={{
                            margin: 0,
                            padding: 2,
                            background: styles.color,
                            color: styles.background,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center"
                          }}
                        >
                          <Typography fontWeight="bold" fontSize={16}>
                            Level 3
                          </Typography>
                          <Typography fontSize={13}>
                            One hash mark each for the completion of
                            #800gChallenge®, Lazy Macros®, and Three Pillars
                            Method® programs.
                          </Typography>
                        </Box>
                      }
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ cursor: "pointer" }}
                        gap="10px"
                        position="relative" // Make the Box a positioning context
                      >
                        <Lvl3Icon height={80} width={80} />
                        {/* <Typography fontSize="0.7rem" variant="bold">
                        Training Lvl 3
                      </Typography> */}
                      </Box>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                  {userData?.isAdmin && userData?.userType === "Coach" ? (
                    <Tooltip
                      slotProps={{
                        tooltip: {
                          sx: {
                            padding: 0
                          }
                        }
                      }}
                      title={
                        <Box
                          sx={{
                            margin: 0,
                            padding: 2,
                            background: styles.color,
                            color: styles.background,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center"
                          }}
                        >
                          <Typography fontWeight="bold" fontSize={16}>
                            Coach
                          </Typography>
                          <Typography fontSize={13}>
                            Three Pillars Method® Coach. Fulfilled the
                            requirements of the Coaches
                            Masterclass and affiliation.
                          </Typography>
                        </Box>
                      }
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{ cursor: "pointer" }}
                        justifyContent="center"
                        gap="10px"
                        position="relative" // Make the Box a positioning context
                      >
                        <CoachIcon height={80} width={80} />
                        {/* <Typography fontSize="0.7rem" variant="bold">
                        Training Lvl 3
                      </Typography> */}
                      </Box>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                  {userProfileData?.isConsistent ? (
                    <Tooltip
                      slotProps={{
                        tooltip: {
                          sx: {
                            padding: 0
                          }
                        }
                      }}
                      title={
                        <Box
                          sx={{
                            margin: 0,
                            padding: 2,
                            background: styles.color,
                            color: styles.background,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center"
                          }}
                        >
                          <Typography fontWeight="bold" fontSize={16}>
                            Consistency
                          </Typography>
                          <Typography fontSize={13}>
                            Appears when you've logged for at least 11 of the
                            last 14 days.
                          </Typography>
                        </Box>
                      }
                    >
                      {" "}
                      <Box
                        display="flex"
                        flexDirection="column"
                        sx={{ cursor: "pointer" }}
                        alignItems="center"
                        justifyContent="center"
                        gap="10px"
                        position="relative" // Make the Box a positioning context
                      >
                        <BicepIcon height={80} width={80} />
                        {/* <Typography
                          color="#F6541C"
                          padding="0px 7px"
                          borderRadius="50%"
                          fontSize="smaller"
                          sx={{
                            background: styles.color,
                            padding:
                              String(userProfileData?.isConsistent)?.length ===
                              1
                                ? "1px 7px"
                                : "1px 4px",
                            position: "absolute", // Position the count absolutely
                            top: "45px", // Adjust as needed
                            right: "10px", // Adjust as needed
                            transform: "translate(50%, 50%)" // Center the count on the border
                          }}
                        >
                          {userProfileData?.isConsistent}
                        </Typography> */}
                      </Box>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                  {userProfileData?.maxStreak ? (
                    <Tooltip
                      slotProps={{
                        tooltip: {
                          sx: {
                            padding: 0
                          }
                        }
                      }}
                      title={
                        <Box
                          sx={{
                            margin: 0,
                            padding: 2,
                            background: styles.color,
                            color: styles.background,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center"
                          }}
                        >
                          <Typography fontWeight="bold" fontSize={16}>
                            Streaking
                          </Typography>
                          <Typography fontSize={13}>
                            Represents your greatest streak of diary logging
                            days in a row.
                          </Typography>
                        </Box>
                      }
                    >
                      {" "}
                      <Box
                        display="flex"
                        flexDirection="column"
                        sx={{ cursor: "pointer" }}
                        alignItems="center"
                        justifyContent="center"
                        gap="10px"
                        position="relative" // Make the Box a positioning context
                      >
                        <ConstantIcon height={80} width={80} />
                        <Typography
                          color="#F6541C"
                          padding="0px 7px"
                          borderRadius="50%"
                          fontSize="smaller"
                          sx={{
                            background: styles.color,
                            padding:
                              String(userProfileData?.maxStreak)?.length === 1
                                ? "1px 7px"
                                : "1px 4px",
                            position: "absolute", // Position the count absolutely
                            top: "45px", // Adjust as needed
                            right: "10px", // Adjust as needed
                            transform: "translate(50%, 50%)" // Center the count on the border
                          }}
                        >
                          {userProfileData?.maxStreak}
                        </Typography>
                      </Box>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
        <AchievementModal
          isMobile={isMobile}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        {isMobile && !userData?.isSubscriptionActive && (
          <Box
            display="flex"
            borderRadius="12px"
            padding={"42px 24px"}
            alignItems={"center"}
            gap="70px"
            sx={{
              background:
                "linear-gradient(123.32deg, #368AD0 1.35%, #1A3A6D 83.05%)"
            }}
            onClick={() => setOpenSubscriptionModal(true)}
          >
            <Box>
              <Typography
                color="#368AD0"
                sx={{
                  background: "#FCFCFC",
                  width: "58px",
                  fontSize: "10px",
                  padding: "0 7px",
                  borderRadius: "100px",
                  height: "16px",
                  border: "1px solid #368AD0"
                }}
                mb={2}
                fontWeight={500}
              >
                Level Up
              </Typography>
              <Typography color="#FCFCFC" fontWeight={600} fontSize={"16px"}>
                Get 3 Pillars Full Access
              </Typography>
              <Button
                sx={{
                  padding: "10px 10px 10px 0",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "12px"
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenFreeTrialModal(true);
                }}
                variant="none"
              >
                Free Trial (
                {freeTrialExpired > 5
                  ? "Expired"
                  : `${5 - freeTrialExpired} days left`}
                )
              </Button>
            </Box>
            <PremiumIcon />
            <SubscriptionModal
              open={openSubscriptionModal}
              onClose={() => setOpenSubscriptionModal(false)}
              isMobile={true}
            />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          alignItems={"flex-start"}
        >
          <Button
            style={{
              width: "100%",
              justifyContent: "flex-start",
              textTransform: "none",
              gap: 20,
              alignItems: "center"
            }}
            onClick={() => navigate("/edit-profile")}
          >
            <Box>
              <UserIcon
                height={24}
                width={24}
                style={{ display: "flex", alignItems: "center" }}
              />
            </Box>
            <Box>
              <Typography
                color={styles.lightBlack}
                fontSize="14px"
                fontWeight={400}
              >
                Edit Profile
              </Typography>
            </Box>
          </Button>
          <Button
            style={{
              width: "100%",
              justifyContent: "flex-start",
              textTransform: "none",
              gap: 20,
              alignItems: "center"
            }}
            onClick={() =>
              navigate("/update-password", {
                state: { email: userData?.email }
              })
            }
          >
            <Box>
              <LockIcon
                height={24}
                width={24}
                style={{ display: "flex", alignItems: "center" }}
              />
            </Box>
            <Box>
              <Typography
                color={styles.lightBlack}
                fontSize="14px"
                fontWeight={400}
              >
                Edit Password
              </Typography>
            </Box>
          </Button>
          <Button
            style={{
              width: "100%",
              justifyContent: "flex-start",
              textTransform: "none",
              gap: 20,
              alignItems: "center"
            }}
            onClick={() => navigate("/manage-billing")}
          >
            <Box>
              <BillingIcon
                height={24}
                width={24}
                style={{ display: "flex", alignItems: "center" }}
              />
            </Box>
            <Box>
              <Typography
                color={styles.lightBlack}
                fontSize="14px"
                fontWeight={400}
              >
                Manage Billing
              </Typography>
            </Box>
          </Button>
          <Button
            style={{
              width: "100%",
              justifyContent: "flex-start",
              textTransform: "none",
              gap: 20,
              alignItems: "center"
            }}
            onClick={() => setOpenReferModal(true)}
          >
            <Box>
              <GroupIcon
                height={24}
                width={24}
                style={{ display: "flex", alignItems: "center" }}
              />
            </Box>
            <Box>
              <Typography
                color={styles.lightBlack}
                fontSize="14px"
                fontWeight={400}
              >
                Refer a Friend
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={async () => dispatch(toggleTheme())}
            style={{
              textTransform: "none",
              width: "100%",
              justifyContent: "space-between",
              height: "36px",
              gap: 20,
              alignItems: "center"
            }}
          >
            <Box display="flex" gap={2.5}>
              <Box>
                {currentTheme === "dark" ? (
                  <DarkMode
                    height={24}
                    width={24}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fill: "#9CA3AF"
                    }}
                  />
                ) : (
                  <LightMode
                    height={24}
                    width={24}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fill: "#9CA3AF"
                    }}
                  />
                )}
              </Box>
              <Box>
                <Typography
                  color={styles.lightBlack}
                  fontSize="14px"
                  fontWeight={400}
                >
                  Dark mode
                </Typography>
              </Box>
            </Box>
            <FormControlLabel
              control={
                <>
                  <Typography color={styles.lightBlack}>
                    {currentTheme === "dark" ? "ON" : "OFF"}
                  </Typography>
                  <MaterialUISwitch
                    sx={{ m: 1 }}
                    checked={currentTheme === "dark"}
                  />
                </>
              }
            />
          </Button>
          <Button
            style={{
              width: "100%",
              justifyContent: "flex-start",
              textTransform: "none",
              gap: 20,
              alignItems: "center"
            }}
          >
            <Box>
              <StarIcon
                height={24}
                width={24}
                style={{ display: "flex", alignItems: "center" }}
              />
            </Box>
            <Box>
              <Typography
                color={styles.lightBlack}
                fontSize="14px"
                fontWeight={400}
              >
                Rate Us
              </Typography>
            </Box>
          </Button>
          <Button
            style={{
              width: "100%",
              justifyContent: "flex-start",
              textTransform: "none",
              gap: 20,
              alignItems: "center"
            }}
            onClick={() => {
              dispatch(logoutUser());
            }}
          >
            <Box>
              <LogoutIcon
                height={24}
                width={24}
                style={{ display: "flex", alignItems: "center" }}
              />
            </Box>
            <Box>
              <Typography
                color={styles.lightBlack}
                fontSize="14px"
                fontWeight={400}
              >
                Log out
              </Typography>
            </Box>
          </Button>
          <Button
            style={{
              justifyContent: "flex-start",
              width: "100%",
              textTransform: "none",
              gap: 20,
              textAlign: "center",
              alignItems: "center"
            }}
            onClick={() => setDeleteUserModal(true)}
          >
            <Box>
              <DeleteOutline
                style={{
                  ":hover": {
                    background: "#FF4000"
                  },
                  display: "flex",
                  height: 24,
                  width: 24,
                  color: "#FF4000" // Use color here to fill the icon
                }}
              />
            </Box>
            <Box>
              <Typography color="#FF4000" fontSize="14px" fontWeight={400}>
                Delete Account
              </Typography>
            </Box>
          </Button>
          <ReferralModal
            open={openReferModal}
            onClose={() => setOpenReferModal(false)}
          />
          <DeleteUserModal
            open={deleteUserModal}
            handleDelete={async () => {
              await deleteUser(userData?.userId);
              dispatch(logoutUser());
              navigate("/");
            }}
            handleClose={() => setDeleteUserModal(false)}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Profile;
