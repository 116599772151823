import React, { useEffect, useMemo, useRef, useState } from "react";
import { ChevronLeft } from "@mui/icons-material";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Box,
  styled,
  Alert,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  List,
  ListItem,
  Grid
} from "@mui/material";
import CustomRadio from "../Extra/CustomRadio";
import { ReactComponent as AppleIcon } from "../../assets/apple.svg";
import { ReactComponent as ProteinIcon } from "../../assets/protein.svg";
import { ReactComponent as CaolriesIcon } from "../../assets/calories.svg";
import { addIngredients, updateIngredients } from "../../api/diaryApi"; // Update to your actual API import
import dayjs from "dayjs";
// import { options800gms, optionsProteins } from "../../utils/mockWeightData";
import {
  getCurrentUserDiaries,
  getCurrentUserDiaryLevels
} from "../../features/diary/diarySlice";
import { useDispatch, useSelector } from "react-redux";
import useThemeColor from "../../utils/useTheme";
import {
  selectAllOptionsProtein,
  selectAllOptions800g,
  selectAllOptionsOtherFood,
  selectAllOptions800gLevel3,
  selectAllOptionsProteinLevel3
} from "../../features/diary/diarySelectors";
import { selectCurrentUser } from "../../features/auth/authSelectors";
import { fetchCurrentUser } from "../../features/auth/authSlice";
import FoodSearch from "../Extra/FoodSearch";
import { convertItemQuantityToGrams } from "../../utils/weightConvert";
import { selectCurrentTheme } from "../../features/theme/themeSelectors";
import formatNumber from "../../utils/formatNumber";
import { dailyValues } from "../../utils/calculateDVPercent";
import { selectCustomFoodOptions } from "../../features/level3/level3Selectors";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(4, 0)
}));

const StyledInputLabel = styled("label")(({ styles }) => ({
  position: "absolute",
  top: "-18px",
  color: styles.inputLabelColor,
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ styles }) => ({
  input: {
    color: styles.color,
    "-webkit-text-fill-color": `${styles.color} !important`
  },
  "& .MuiOutlinedInput-root": {
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& fieldset": {
      borderColor: styles.lightGreyColor
    },
    "&:hover fieldset": {
      borderColor: styles.color
    },
    "&.Mui-focused fieldset": {
      borderColor: styles.color
    },
    "&.Mui-disabled fieldset": {
      borderColor: `${styles.lightGreyColor} !important`
    },
    "&.Mui-disabled": {
      background: styles.lightGreyColor,
      color: styles.color
    }
  }
}));

const excludedFields = [
  "serving_id",
  "serving_description",
  "serving_url",
  "metric_serving_amount",
  "metric_serving_unit",
  "calories",
  "protein"
];

const AddWeightForm = ({
  selectedLevel,
  diaryId,
  activeDay,
  setIsAddingWeight,
  isAddingWeight,
  levelId,
  editData,
  setIsRefreshNeeded
}) => {
  const [isDiaryRefreshNeeded, setIsDiaryRefreshNeeded] = useState(false);
  const dispatch = useDispatch();
  const amountRef = useRef(null);
  const options800gms = useSelector(selectAllOptions800g);
  const optionsProteins = useSelector(selectAllOptionsProtein);
  const optionsOtherFood = useSelector(selectAllOptionsOtherFood);
  const options800gLevel3 = useSelector(selectAllOptions800gLevel3);
  const optionsProteinLevel3 = useSelector(selectAllOptionsProteinLevel3);
  const allCustomFoodOptions = useSelector(selectCustomFoodOptions);
  const userData = useSelector(selectCurrentUser);
  const [selectedOptionData, setSelectedOptionData] = useState(null);
  const [selectedCustomFood, setSelectedCustomFood] = useState(null);
  const options = isAddingWeight?.toLowerCase()?.includes("800")
    ? options800gms
    : optionsProteins;
  const isLevel3 = selectedLevel?.levelName === "level3";
  // useMemo(() => {
  //   const baseOptions = isAddingWeight?.toLowerCase()?.includes("800")
  //     ? options800gms
  //     : optionsProteins;

  //   // if (
  //   //   editData?.name &&
  //   //   !baseOptions.some((opt) => opt.value === editData.name)
  //   // ) {
  //   //   return [...baseOptions, { value: editData.name, label: editData.name }];
  //   // }

  //   return baseOptions;
  // }, [isAddingWeight, isDiaryRefreshNeeded, editData]);

  useEffect(() => {
    if (selectedOptionData) {
      if (amountRef.current) {
        amountRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
        amountRef.current.focus();
      }
    }
  }, [selectedOptionData]);
  useEffect(() => {
    if (isDiaryRefreshNeeded) {
      dispatch(getCurrentUserDiaryLevels(dayjs().format("YYYY-MM-DD")));
      setIsDiaryRefreshNeeded(false);
    }
  }, [isDiaryRefreshNeeded]);
  const [formData, setFormData] = useState({
    quantity: editData?.quantity || "",
    noOfQty: editData?.noOfQty || "",
    type: isAddingWeight?.toLowerCase()?.includes("800")
      ? "800g"
      : isAddingWeight?.toLowerCase()?.includes("protein")
      ? "protein"
      : "other",
    unit: editData?.unit || "grams",
    [isAddingWeight]: editData?.name
      ? options?.map((item) => item.value)?.includes(editData?.name)
        ? editData?.name
        : "others"
      : "",
    customFoodType: editData?.name
      ? options?.map((item) => item.value)?.includes(editData?.name)
        ? ""
        : editData?.name
      : ""
  });
  const [favoriteFood, setFavoriteFood] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const loading = false; // Replace with actual loading state if needed
  const styles = useThemeColor();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const [disabledFields, setDisabledFields] = useState({
    quantity: false,
    noOfQty: false,
    unit: false
  });

  useEffect(() => {
    setDisabledFields({
      quantity: editData?.noOfQty ? true : false,
      noOfQty: editData?.quantity ? true : false,
      unit: editData?.noOfQty ? true : false
    });
  }, [editData]);
  const handleQuantityChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values and ensure it's at least 0
    if (Number(value) >= 0) {
      handleInputChange(e);
      setDisabledFields({
        quantity: false,
        noOfQty: value === "" ? false : true
        // unit: value === "" ? false : true
      });
    }
  };

  // Handle noOfQty change
  const handleNoOfQtyChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values and ensure it's at least 0
    if (Number(value) >= 0) {
      handleInputChange(e);
      setDisabledFields({
        quantity: value === "" ? false : true,
        noOfQty: false,
        unit: value === "" ? false : true
      });
    }
  };
  const currentTheme = useSelector(selectCurrentTheme);

  const scalingFactor =
    convertItemQuantityToGrams(
      formData,
      (editData ? editData?.extraDetails : selectedOptionData)?.serving
        ?.metric_serving_amount
    ).quantity /
    (editData ? editData?.extraDetails : selectedOptionData)?.serving
      ?.metric_serving_amount;

  // Exclude non-nutrient fields and specific fields like `protein` and `calories`
  const nutrientFields =
    (editData ? editData?.extraDetails : selectedOptionData) &&
    (editData?.extraDetails || selectedOptionData?.serving)
      ? Object.entries(
          (editData ? editData?.extraDetails : selectedOptionData)?.serving
        ).filter(([key, value]) => {
          return !excludedFields.includes(key) && !isNaN(Number(value));
        })
      : [];

  const formattedNutrients = nutrientFields?.map(([key, value]) => {
    const numericValue = (parseFloat(value) * scalingFactor).toFixed(2) || 0;
    return {
      name: key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase()),
      value: numericValue,
      unit: "g",
      percent: Math.round((numericValue / dailyValues[key]) * 100)
    };
  });

  const formatNumber = (value) => {
    return Number(value).toLocaleString();
  };

  const getNutrientValue = (name, unit = "") => {
    const nutrient = formattedNutrients.find((n) => n.name === name);
    return nutrient
      ? `${formatNumber(nutrient.value)}${unit || nutrient.unit} ${
          unit ? `(${nutrient.percent}%)` : ""
        }`
      : `0${unit || nutrient.unit} (0%)`;
  };

  const NutrientRow = ({ name, unit }) => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={0.5}
    >
      <Typography fontSize="13px">{name}</Typography>
      <Typography fontSize="13px">{getNutrientValue(name, unit)}</Typography>
      {/* <Typography fontSize="13px">{calculatePercentDV(name, unit)}</Typography> */}
    </Box>
  );
  useEffect(() => {
    if (isLevel3 && (selectedOptionData?.food_id || selectedCustomFood?.name)) {
      setFormData((prev) => ({
        ...prev,
        [isAddingWeight]:
          selectedOptionData?.food_name || selectedCustomFood?.name
      }));
    }
  }, [selectedOptionData, selectedCustomFood]);
  const favoriteOptionsData = useMemo(() => {
    if (selectedLevel?.levelName === "level3") {
      return formData?.type?.toLowerCase()?.includes("800")
        ? options800gLevel3
        : formData?.type?.toLowerCase()?.includes("protein")
        ? optionsProteinLevel3
        : optionsOtherFood;
    }
  }, [isAddingWeight, formData?.type]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData?.[isAddingWeight] || isLevel3
        ? !formData.quantity && !formData.noOfQty
        : !formData.quantity
    ) {
      const errors = [];
      if (!formData?.[isAddingWeight])
        errors.push("Please select a food type.");
      if (
        isLevel3 ? !formData.quantity && !formData.noOfQty : !formData.quantity
      )
        errors.push("Please enter a quantity.");
      setSnackbarMessage(errors.join(" "));
      setOpenSnackbar(true);
      return;
    }

    try {
      const currentTime = dayjs().format("hh:mm A");
      let key = isAddingWeight;
      if (isLevel3) {
        if (formData?.type === "800g") {
          key = "entry800g";
        } else if (formData?.type === "protein") {
          key = "protein";
        } else {
          key = "otherFood";
        }
      }
      if (editData?._id) {
        await updateIngredients({
          diaryId,
          levelId,
          ingredientId: editData?._id,
          currentDayDate: activeDay,
          [key]: {
            name: isLevel3
              ? selectedOptionData?.name || selectedOptionData?.food_name
              : formData?.[key] === "others"
              ? formData.customFoodType
                ? formData.customFoodType
                : "Other"
              : formData?.[key],
            noOfQty: formData?.noOfQty,
            quantity: formData?.quantity,
            time: currentTime,
            unit: formData?.unit
          }
        });
      } else {
        await addIngredients({
          levelId,
          currentDayDate: activeDay,
          [key]: {
            name: isLevel3
              ? selectedOptionData?.name || selectedOptionData?.food_name
              : formData.customFoodType && formData?.[key] === "others"
              ? formData.customFoodType
              : formData?.[key],
            quantity: formData?.quantity,
            time: currentTime,
            unit: formData?.unit,
            ...(isLevel3 &&
              (selectedOptionData?.id || selectedOptionData?._id) && {
                extraDetails: selectedOptionData,
                ...(formData?.noOfQty && { noOfQty: formData?.noOfQty })
              })
          }
        });
      }
      setSnackbarMessage("Entry logged successfully.");
      setOpenSnackbar(true);
      setFormData({ quantity: "", [isAddingWeight]: "", customFoodType: "" });
      setIsAddingWeight("");
      setIsRefreshNeeded(true);
      dispatch(getCurrentUserDiaries({ levelId }));
      dispatch(getCurrentUserDiaryLevels(dayjs().format("YYYY-MM-DD")));
      if (!userData?.isSubscriptionActive) {
        // dispatch(getCurrentUserDiaryLevels(dayjs().format("YYYY-MM-DD")));
        dispatch(fetchCurrentUser(dayjs().format("YYYY-MM-DD")));
      }
      localStorage.setItem(
        "lastDiaryVisitedLevel",
        JSON.stringify({ level: selectedLevel?.levelName, activeDay })
      );
    } catch (error) {
      console.error(error);
      setSnackbarMessage("Failed to log entry.");
      setOpenSnackbar(true);
    }
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const scrollableElement = document.getElementById("dashboard-main"); // or document.body
    scrollableElement.scrollTo(0, 0);
  }, []);

  return (
    <Box
      color={styles.color}
      backgroundColor={styles.background}
      display="flex"
      width="100%"
      overflow="auto"
      flexDirection="column"
    >
      <Box display="flex" alignItems="center" gap={3} p="20px 0 0 10px">
        <IconButton onClick={() => setIsAddingWeight(false)}>
          <ChevronLeft
            sx={{ height: "35px", width: "35px", fill: styles.color }}
          />
        </IconButton>
        <Typography textTransform="capitalize" fontSize="24px">
          {isAddingWeight?.toLowerCase()?.includes("800g")
            ? "#800gChallenge"
            : isAddingWeight?.toLowerCase()?.includes("other")
            ? "Other food"
            : isAddingWeight}{" "}
          Log
        </Typography>
      </Box>
      <Box
        flexGrow={1}
        py={3}
        px={{ xs: 2, md: 10 }}
        sx={{
          width: { xs: "100%", md: "100%", lg: "65%" },
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Box component="form" onSubmit={handleSubmit}>
          {isLevel3 ? (
            <Box
              sx={
                {
                  // width: { xs: "100%", md: "70%" }
                }
              }
              // component={"form"}
            >
              <StyledTextFieldContainer
                style={{ display: "flex", gap: "20px" }}
              >
                <StyledInputLabel
                  styles={styles}
                  htmlFor="quantity"
                  sx={
                    (isAddingWeight.includes("protein") && {
                      fontSize: "16px"
                    },
                    { mb: 2 })
                  }
                >
                  Enter food
                </StyledInputLabel>
                {editData ? (
                  <Box
                    mt={2}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={2}
                    borderRadius="8px"
                    padding={"12px"}
                    width={"100%"}
                    sx={{ background: styles.lightGreyColor }}
                  >
                    <Typography>{editData?.name}</Typography>
                  </Box>
                ) : (
                  <Box mt={2} width={"100%"}>
                    <FoodSearch
                      setSelectedOptionData={setSelectedOptionData}
                      isBarcodeNeeded={false}
                      favoriteFood={favoriteFood}
                      setFavoriteFood={setFavoriteFood}
                      selectedCustomFood={selectedCustomFood}
                      setSelectedCustomFood={setSelectedCustomFood}
                      type={formData.type}
                    />
                  </Box>
                )}
              </StyledTextFieldContainer>
              {console.log({ selectedOptionData })}
              {favoriteOptionsData?.length && !editData ? (
                <Box>
                  <Typography mt={-2} mb={1} fontSize={"14px"}>
                    Favorite food
                  </Typography>
                  <Grid container spacing={2}>
                    {favoriteOptionsData?.map((item) => (
                      <Grid item xs={12} sm={6}>
                        <Button
                          sx={{
                            background: `${
                              favoriteFood === item?.name
                                ? styles.primaryColor
                                : `#333333`
                            } !important`,
                            display: "flex",
                            gap: "10px",
                            justifyContent: "center",
                            padding: "10px",
                            textTransform: "none",
                            cursor: "pointer",
                            transition: "all 0.2s ease-in-out",
                            "&:hover": {
                              elevation: 3,
                              borderColor: "#358ad0"
                            }
                          }}
                          variant="outline"
                          fullWidth
                          onClick={() => {
                            console.log("item-=-------->", item);
                            if (favoriteFood === item?.name) {
                              setFavoriteFood(null);
                            } else {
                              if (
                                allCustomFoodOptions
                                  ?.map((data) => data.name?.trim())
                                  .includes(item?.name?.trim())
                              ) {
                                setSelectedCustomFood(
                                  allCustomFoodOptions?.find(
                                    (data) =>
                                      data.name?.trim() === item?.name?.trim()
                                  )
                                );
                                setSelectedOptionData(
                                  item?.extraDetails?.details
                                );
                                setFavoriteFood(null);
                              } else {
                                setFavoriteFood(item?.name);
                                setSelectedOptionData(item?.extraDetails);
                                setSelectedCustomFood(null);
                              }

                              // Scroll to and focus the amount input field
                              if (amountRef.current) {
                                amountRef.current.scrollIntoView({
                                  behavior: "smooth",
                                  block: "center"
                                });
                                amountRef.current.focus();
                              }
                            }
                          }}
                        >
                          <Typography fontSize="13px" textAlign="center">
                            {item?.name}
                          </Typography>
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : (
                <></>
              )}
              <StyledTextFieldContainer
                style={{
                  display: "flex",
                  gap: "20px",
                  marginTop: "30px",
                  marginBottom: "20px"
                }}
              >
                <StyledInputLabel styles={styles} htmlFor="quantity">
                  Amount
                </StyledInputLabel>
                <StyledTextField
                  styles={styles}
                  ref={amountRef}
                  type="number"
                  // min={0}
                  name="quantity"
                  onWheel={(e) => e.target.blur()}
                  margin="normal"
                  slotProps={{
                    htmlInput: {
                      maxLength: 13,
                      step: "1"
                    }
                  }}
                  placeholder="Enter the amount"
                  value={formData.quantity}
                  onChange={handleQuantityChange}
                  disabled={disabledFields.quantity}
                  sx={{ width: { xs: "100%", md: "60%" } }}
                />
                <FormControl
                  sx={{
                    width: "40%",
                    marginTop: "15px",
                    svg: {
                      fill: styles.color
                    },
                    ".MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: styles.lightGreyColor
                      },
                      "&:hover fieldset": {
                        borderColor: styles.lightGreyColor
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: styles.disabledColor
                      }
                    }
                  }}
                >
                  <Select
                    labelId="unit-label"
                    name="unit"
                    value={formData.unit}
                    MenuProps={{
                      MenuListProps: {
                        sx: {
                          padding: 0,
                          ".Mui-selected": {
                            color: `${styles.background} !important`,
                            background: `${styles.color} !important`
                          }
                        }
                      }
                    }}
                    sx={{
                      color: styles.color,
                      marginTop: 0, // Remove any margin
                      paddingTop: 0, // Remove any padding
                      "& .Mui-disabled": {
                        background: styles.lightGreyColor
                        // color: styles.color
                      }
                    }}
                    onChange={handleInputChange}
                    disabled={disabledFields.unit}
                  >
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value="grams"
                    >
                      Grams
                    </MenuItem>
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value="ounces"
                    >
                      Ounces
                    </MenuItem>
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value="cups"
                    >
                      Cups / Fists
                    </MenuItem>
                    {[
                      { value: "palms", label: "Palms" },
                      { value: "pounds", label: "Pounds" }
                      // { value: "mls", label: " MLs (millimeters)" }
                    ]?.map(({ value, label }) => (
                      <MenuItem
                        sx={{
                          backgroundColor: styles.background,
                          color: styles.color,
                          paddingY: 1,
                          "&:hover": {
                            backgroundColor: styles.color,
                            color: styles.background
                          }
                        }}
                        value={value}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </StyledTextFieldContainer>
              <Typography
                display="flex"
                fontWeight="bold"
                justifyContent="center"
              >
                OR
              </Typography>
              <StyledTextFieldContainer
                style={{ display: "flex", gap: "20px", marginTop: "15px" }}
              >
                <StyledInputLabel styles={styles} htmlFor="noOfQty">
                  No. of Quantity
                </StyledInputLabel>
                <StyledTextField
                  styles={styles}
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  // min={0}
                  name="noOfQty"
                  // InputProps={{ inputProps: { min: 36, max: 40, step: "any" } }}
                  slotProps={{
                    htmlInput: {
                      maxLength: 13,
                      step: "1"
                    }
                  }}
                  margin="normal"
                  // htmlInput={{ min: 0 }}
                  placeholder="Enter the quantity"
                  value={formData.noOfQty}
                  onChange={handleNoOfQtyChange}
                  disabled={disabledFields.noOfQty}
                  sx={{ width: { xs: "100%" } }}
                />
              </StyledTextFieldContainer>
              <StyledTextFieldContainer
                style={{ display: "flex", gap: "20px", marginTop: "40px" }}
              >
                <StyledInputLabel styles={styles} htmlFor="type">
                  Pillar Label
                </StyledInputLabel>
                <FormControl
                  sx={{
                    width: "100%",
                    marginTop: "15px",
                    svg: {
                      fill: styles.color
                    },
                    ".MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: styles.lightGreyColor
                      },
                      "&:hover fieldset": {
                        borderColor: styles.lightGreyColor
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: styles.disabledColor
                      }
                    }
                  }}
                >
                  <Select
                    labelId="unit-label"
                    name="type"
                    value={formData.type}
                    disabled={editData?.name}
                    MenuProps={{
                      MenuListProps: {
                        sx: {
                          padding: 0,
                          ".Mui-selected": {
                            color: `${styles.background} !important`,
                            background: `${styles.color} !important`
                          }
                        }
                      }
                    }}
                    sx={{
                      color: styles.color,
                      marginTop: 0, // Remove any margin
                      paddingTop: 0, // Remove any padding
                      "& .Mui-disabled": {
                        background: styles.lightGreyColor,
                        color: `${styles.color} !important`
                      },
                      "& .MuiSelect-select": {
                        color: `${styles.color} !important`,
                        "-webkit-text-fill-color": `${styles.color} !important`
                      }
                    }}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  >
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value="800g"
                    >
                      800g
                    </MenuItem>
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value="protein"
                    >
                      Protein
                    </MenuItem>
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value="other"
                    >
                      Other
                    </MenuItem>
                  </Select>
                </FormControl>
              </StyledTextFieldContainer>
              {formattedNutrients?.length &&
              (formData?.noOfQty || formData?.quantity) ? (
                <>
                  <StyledTextFieldContainer
                    style={{ display: "flex", gap: "20px", marginTop: "40px" }}
                  >
                    <StyledInputLabel styles={styles} htmlFor="nutrient">
                      Three Pillars Method
                    </StyledInputLabel>
                    <Box
                      mt={2}
                      width="100%"
                      // height={64}
                      borderRadius={2}
                      display="flex"
                      gap="10px"
                    >
                      <Box
                        width={"33%"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                        display="flex"
                        gap={1}
                        sx={{
                          background:
                            currentTheme === "light"
                              ? "#FEECE6"
                              : styles.modBackgroundBlocks,
                          padding: "14px 50px"
                        }}
                        borderRadius="8px"
                      >
                        <AppleIcon />
                        <Typography
                          fontSize="12px"
                          display="flex"
                          justifyContent="center"
                          fontWeight={400}
                          color={styles.inputLabelColor}
                        >
                          800g
                        </Typography>
                        <Typography
                          fontSize="24px"
                          fontWeight={600}
                          color="#F6541C"
                        >
                          {formData.type === "800g"
                            ? formatNumber(
                                (
                                  (editData
                                    ? editData?.extraDetails
                                    : selectedOptionData
                                  )?.serving?.metric_serving_amount *
                                  scalingFactor
                                ).toFixed(2) || 0
                              )
                            : 0}
                        </Typography>
                      </Box>

                      <Box
                        width={"33%"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                        display="flex"
                        gap={1}
                        sx={{
                          background:
                            currentTheme === "light"
                              ? "#D4E9FC"
                              : styles.modBackgroundBlocks,
                          padding: "14px 50px"
                        }}
                        borderRadius="8px"
                      >
                        <ProteinIcon />
                        <Typography
                          fontSize="12px"
                          fontWeight={400}
                          color={styles.inputLabelColor}
                        >
                          Protein
                        </Typography>
                        <Typography
                          fontSize="24px"
                          fontWeight={600}
                          color="#2C75C5"
                        >
                          {formatNumber(
                            (
                              (editData
                                ? editData?.extraDetails
                                : selectedOptionData
                              )?.serving?.protein * scalingFactor
                            ).toFixed(2) || 0
                          )}
                        </Typography>
                      </Box>
                      <Box
                        width={"33%"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                        display="flex"
                        gap={1}
                        sx={{
                          background:
                            currentTheme === "light"
                              ? "#fff5e6"
                              : styles.modBackgroundBlocks,
                          padding: "14px 50px"
                        }}
                        borderRadius="8px"
                      >
                        <CaolriesIcon />
                        <Typography
                          fontSize="12px"
                          fontWeight={400}
                          color={styles.inputLabelColor}
                        >
                          Calories
                        </Typography>
                        <Typography
                          fontSize="24px"
                          fontWeight={600}
                          color="#FF9431"
                        >
                          {formatNumber(
                            (
                              (editData
                                ? editData?.extraDetails
                                : selectedOptionData
                              )?.serving?.calories * scalingFactor
                            ).toFixed(2) || 0
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </StyledTextFieldContainer>
                  {formattedNutrients?.length ? (
                    <StyledTextFieldContainer
                      style={{
                        display: "flex",
                        gap: "20px",
                        marginTop: "40px"
                      }}
                    >
                      <StyledInputLabel styles={styles} htmlFor="nutrient">
                        Additional Stats
                      </StyledInputLabel>
                      <Box
                        width="100%"
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection="column"
                        alignItems={"center"}
                        mt={2}
                        gap={1}
                        borderRadius="8px"
                        padding={"8px 12px"}
                        sx={{ background: styles.lightGreyColor }}
                      >
                        {/* {formattedNutrients?.map(({ name, value, unit }) => (
                          <Box
                            width="100%"
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Typography fontSize="13px">{name}</Typography>
                            <Typography fontSize="13px">
                              {formatNumber(value || 0) + unit}
                            </Typography>
                          </Box>
                        ))} */}
                        <Box width="100%" borderRadius={1}>
                          {/* Carbs Group */}
                          <Box mb={2}>
                            <NutrientRow name="Carbohydrate" unit="g" />
                            <NutrientRow name="Fiber" unit="g" />
                          </Box>

                          {/* Fats Group */}
                          <Box mb={2}>
                            <NutrientRow name="Fat" unit="g" />
                            <NutrientRow name="Saturated Fat" unit="g" />
                          </Box>

                          {/* Vitamins and Minerals Group */}
                          <Box>
                            <NutrientRow name="Calcium" unit="mg" />
                            <NutrientRow name="Iron" unit="mg" />
                            <NutrientRow name="Potassium" unit="mg" />
                            <NutrientRow name="Sodium" unit="mg" />
                            <NutrientRow name="Vitamin A" unit="mcg" />
                            <NutrientRow name="Vitamin C" unit="mg" />
                            <NutrientRow name="Vitamin D" unit="mcg" />
                          </Box>
                        </Box>
                      </Box>
                    </StyledTextFieldContainer>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </Box>
          ) : (
            <>
              <Typography
                variant="body"
                fontSize="13px"
                sx={{ color: styles.inputLabelColor }}
                fontWeight={500}
              >
                Select one or create a custom entry
              </Typography>
              <Box display="flex" flexWrap="wrap">
                {options.map((option) => (
                  <Box key={option.value} width="50%" p={0.5}>
                    <CustomRadio
                      styles={styles}
                      value={option.value}
                      setIsDiaryRefreshNeeded={setIsDiaryRefreshNeeded}
                      label={option.label}
                      type={option.type}
                      id={option.id}
                      checked={formData?.[isAddingWeight] === option.value}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          [isAddingWeight]: option.value
                        })
                      }
                    />
                  </Box>
                ))}
              </Box>
              {/* Show additional input field if "Others" is selected */}
              {formData?.[isAddingWeight] === "others" && (
                <StyledTextFieldContainer>
                  <StyledInputLabel styles={styles} htmlFor="customFoodType">
                    Custom (e.g., mango)
                  </StyledInputLabel>
                  <StyledTextField
                    type="text"
                    styles={styles}
                    name="customFoodType"
                    placeholder="Enter custom food"
                    value={formData.customFoodType}
                    onChange={handleInputChange}
                    sx={{ width: "100%", mt: 2 }}
                  />
                </StyledTextFieldContainer>
              )}{" "}
              <StyledTextFieldContainer
                style={{ display: "flex", gap: "20px", marginTop: "40px" }}
              >
                <StyledInputLabel
                  styles={styles}
                  htmlFor="quantity"
                  sx={
                    isAddingWeight.includes("protein") && {
                      fontSize: "16px"
                    }
                  }
                >
                  {isAddingWeight.includes("protein")
                    ? "Enter protein grams consumed"
                    : "Enter quantity"}
                </StyledInputLabel>
                <StyledTextField
                  styles={styles}
                  type="number"
                  min={0}
                  name="quantity"
                  inputProps={{}}
                  margin="normal"
                  htmlInput={{ min: 0 }}
                  placeholder="No. (Optional)"
                  value={formData.quantity}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only numeric values and ensure it's at least 0
                    if (/^\d*$/.test(value) && Number(value) >= 0) {
                      handleInputChange(e);
                    }
                  }}
                  sx={{ width: { xs: "100%", md: "60%" } }}
                />
                {/* <StyledTextField
              styles={styles}
              margin="normal"
              placeholder="Weight Grams"
              value="Grams"
              disabled
              sx={{ width: "30%", background: styles.lightGreyColor }}
            /> */}
                {isAddingWeight?.toLowerCase()?.includes("800g") ? (
                  <FormControl
                    sx={{
                      width: "40%",
                      marginTop: "15px",
                      svg: {
                        fill: styles.color
                      },
                      ".MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: styles.lightGreyColor
                        },
                        "&:hover fieldset": {
                          borderColor: styles.color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: styles.color
                        }
                      }
                    }}
                  >
                    <Select
                      labelId="unit-label"
                      name="unit"
                      value={formData.unit}
                      MenuProps={{
                        MenuListProps: {
                          sx: {
                            padding: 0,
                            ".Mui-selected": {
                              color: `${styles.background} !important`,
                              background: `${styles.color} !important`
                            }
                          }
                        }
                      }}
                      sx={{
                        color: styles.color,
                        marginTop: 0, // Remove any margin
                        paddingTop: 0 // Remove any padding
                      }}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    >
                      <MenuItem
                        sx={{
                          backgroundColor: styles.background,
                          color: styles.color,
                          paddingY: 1,
                          "&:hover": {
                            backgroundColor: styles.color,
                            color: styles.background
                          }
                        }}
                        value="grams"
                      >
                        Grams
                      </MenuItem>
                      <MenuItem
                        sx={{
                          backgroundColor: styles.background,
                          color: styles.color,
                          paddingY: 1,
                          "&:hover": {
                            backgroundColor: styles.color,
                            color: styles.background
                          }
                        }}
                        value="ounces"
                      >
                        Ounces
                      </MenuItem>
                      <MenuItem
                        sx={{
                          backgroundColor: styles.background,
                          color: styles.color,
                          paddingY: 1,
                          "&:hover": {
                            backgroundColor: styles.color,
                            color: styles.background
                          }
                        }}
                        value="cups"
                      >
                        Cups / Fists
                      </MenuItem>
                      {!isAddingWeight?.toLowerCase()?.includes("800g") &&
                        [
                          { value: "palms", label: "Palms" },
                          { value: "pounds", label: "Pounds" },
                          { value: "mls", label: " MLs (millimeters)" }
                        ]?.map(({ value, label }) => (
                          <MenuItem
                            sx={{
                              backgroundColor: styles.background,
                              color: styles.color,
                              paddingY: 1,
                              "&:hover": {
                                backgroundColor: styles.color,
                                color: styles.background
                              }
                            }}
                            value={value}
                          >
                            {label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}
              </StyledTextFieldContainer>
              {isAddingWeight?.toLowerCase()?.includes("protein") ? (
                <StyledTextFieldContainer>
                  <Typography
                    variant="h6"
                    sx={{
                      color: styles.inputLabelColor,
                      fontSize: "18px",
                      pointerEvents: "none"
                    }}
                    gutterBottom
                  >
                    Estimating Notes:
                  </Typography>
                  <List sx={{ listStyle: "inside" }}>
                    <ListItem sx={{ display: "list-item", paddingY: 0.2 }}>
                      <Typography
                        variant="body3"
                        sx={{
                          color: styles.inputLabelColor,
                          fontSize: "15px",
                          pointerEvents: "none"
                        }}
                      >
                        Protein servings (e.g., chicken, salmon) at restaurants
                        are often about the size of your palm or a deck of
                        cards. This contains about 25g of protein.
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: "list-item", paddingY: 0.2 }}>
                      <Typography
                        variant="body3"
                        sx={{
                          color: styles.inputLabelColor,
                          fontSize: "15px",
                          pointerEvents: "none"
                        }}
                      >
                        1 oz (28g) of cooked meat contains ~7g of protein.
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: "list-item", paddingY: 0.2 }}>
                      <Typography
                        variant="body3"
                        sx={{
                          color: styles.inputLabelColor,
                          fontSize: "15px",
                          pointerEvents: "none"
                        }}
                      >
                        Weights on menus are raw portions; weight reduces by
                        ~25% when cooked (8oz burger is 6oz cooked or 42g
                        protein).
                      </Typography>
                    </ListItem>
                  </List>
                </StyledTextFieldContainer>
              ) : (
                <></>
              )}
            </>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              isLevel3
                ? !(
                    (formData.quantity && !formData.noOfQty) ||
                    (!formData.quantity && formData.noOfQty)
                  ) || !formData?.[isAddingWeight]
                : !formData.quantity || !formData?.[isAddingWeight]
            }
            sx={{
              marginTop: 3,
              backgroundColor: "#368AD0",
              color: "#fff",
              width: "100%",
              height: "42px",
              fontWeight: "bold",
              textTransform: "none",
              ":disabled": { backgroundColor: "#A7C8E9", color: "#64AEDD" }
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarMessage.includes("success") ? "success" : "error"}
            variant="filled"
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default AddWeightForm;
