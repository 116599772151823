import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Slide,
  TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { forwardRef, useEffect, useState } from "react";
import { updateIngredients } from "../../api/diaryApi";
import { getCurrentUserDiaries } from "../../features/diary/diarySlice";
import { useDispatch } from "react-redux";
import useThemeColor from "../../utils/useTheme";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "relative",
  margin: theme.spacing(2, 0)
}));

const StyledTextField = styled(TextField)(({ styles }) => ({
  input: {
    color: styles.color,
    ":disabled": {
      backgroundColor: "#A7C8E9",
      color: styles.color,
      borderRadius: "4px"
    }
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: styles.lightBlack,
      borderColor: styles.lightGreyColor
    },
    "&:hover fieldset": {
      borderColor: styles.color
    },
    "&.Mui-focused fieldset": {
      color: styles.lightBlack,
      borderColor: styles.color
    }
  }
}));
const TotalWeightUpdate = ({
  title,
  openWeightModal,
  diaryId,
  maxValue,
  levelId,
  setOpenWeightModal,
  setIsRefreshNeeded,
  dayName
}) => {
  const styles = useThemeColor();
  const dispatch = useDispatch();
  const [totalWeight, setTotalWeight] = useState(maxValue);
  useEffect(() => {
    // Update the state if maxValue changes
    setTotalWeight(maxValue);
  }, [maxValue]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateIngredients({
        diaryId,
        levelId,
        currentDayDate: dayName,
        [title?.toLowerCase()?.includes("800g")
          ? "outOf800g"
          : title?.toLowerCase()?.includes("protein")
          ? "outOfProtein"
          : "outOfCalories"]: +totalWeight
      });
      setOpenWeightModal("");
      dispatch(getCurrentUserDiaries({ levelId }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsRefreshNeeded(true);
    }
  };
  return (
    <Dialog
      TransitionComponent={Transition}
      TransitionProps={{
        timeout: {
          enter: 500,
          exit: 300
        }
      }}
      sx={{
        ".MuiDialog-paper": {
          background: styles.background,
          width: "400px",
          color: styles.color,
          position: "relative",
          padding: 2
        }
      }}
      open={openWeightModal === title}
      onClose={() => {
        setOpenWeightModal("");
      }}
      aria-labelledby="weight-modal-title"
      aria-describedby="weight-modal-description"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Box display="flex" width="100%" justifyContent="flex-end">
          <IconButton
            onClick={() => {
              setOpenWeightModal("");
            }}
            sx={{ padding: 0 }}
          >
            <CloseIcon
              sx={{ height: "28px", color: styles.color, width: "28px" }}
            />
          </IconButton>
        </Box>
        <DialogTitle id="weight-modal-title" mb={0} fontWeight="600">
          Enter your target grams for the {title}
        </DialogTitle>
        <StyledTextFieldContainer>
          <StyledTextField
            fullWidth
            styles={styles}
            variant="outlined"
            name="totalWeight"
            type="number"
            placeholder="Enter goal"
            value={totalWeight}
            onChange={(e) => {
              const value = e.target.value;
              // Allow only numeric values and ensure it's at least 0
              if (/^\d*$/.test(value) && Number(value) >= 0) {
                setTotalWeight(e.target.value);
              }
            }}
            required
            margin="normal"
          />
        </StyledTextFieldContainer>
        <Button
          fullWidth
          onClick={handleSubmit}
          sx={{
            fontSize: "small",
            // p: "2px 10px",
            backgroundColor: "#368AD0",
            cursor: "pointer",
            color: "#fff",
            textTransform: "none",
            ":disabled": {
              backgroundColor: "#A7C8E9",
              color: "#64AEDD",
              pointerEvents: "none"
            }
          }}
        >
          Update
        </Button>
      </Box>
    </Dialog>
  );
};

export default TotalWeightUpdate;
