import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllCustomFoodOptions,
  getGeneratedAccessToken,
  getSearchResults,
  gettingAllUserMealOrRecipe,
  gettingCurrentMeal,
  gettingCurrentRecipe
} from "../../api/level3Api";

export const generateAccessToken = createAsyncThunk(
  "diary/generateAccessToken",
  async (_, thunkAPI) => {
    try {
      const response = await getGeneratedAccessToken();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSearchResultsForMealItems = createAsyncThunk(
  "diary/getSearchResultsForMealItems",
  async ({ searchTerm = "", token, barcode = "" }, thunkAPI) => {
    try {
      const response = await getSearchResults(searchTerm, token, barcode);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const gettingAllCustomFoodOptions = createAsyncThunk(
  "diary/gettingAllCustomFoodOptions",
  async (_, thunkAPI) => {
    try {
      const response = await getAllCustomFoodOptions();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const gettingMealData = createAsyncThunk(
  "diary/gettingMealData",
  async (mealId, thunkAPI) => {
    try {
      const response = await gettingCurrentMeal(mealId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const gettingRecipeData = createAsyncThunk(
  "diary/gettingRecipeData",
  async (recipeId, thunkAPI) => {
    try {
      const response = await gettingCurrentRecipe(recipeId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllMealOrRecipeData = createAsyncThunk(
  "diary/getAllMealOrRecipeData",
  async (type, thunkAPI) => {
    try {
      const response = await gettingAllUserMealOrRecipe(type);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Initial state
const initialState = {
  accessToken: "",
  searchResults: [],
  currentMealData: {},
  currentRecipeData: {},
  allMealOrRecipeData: [],
  customFoodOptions: [],
  searchResultsLoading: false,
  loading: false, // loading state for getCurrentUserLevels
  error: null // error state
};

const level3slice = createSlice({
  name: "level3",
  initialState,
  reducers: {
    updateAddingMeal: (state, action) => {
      state.currentMealData = action.payload.meal;
    },
    updateAddingRecipe: (state, action) => {
      state.currentRecipeData = action.payload.recipe;
    },
    updateSearchResults: (state, action) => {
      state.searchResults = action.payload.results;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateAccessToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(generateAccessToken.fulfilled, (state, action) => {
        state.loading = false;
        state.accessToken = action.payload.access_token;
      })
      .addCase(generateAccessToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(gettingMealData.fulfilled, (state, action) => {
        state.currentMealData = action.payload.meal;
      })
      .addCase(gettingMealData.rejected, (state, action) => {
        state.currentMealData = {};
        state.error = action.payload;
      })
      .addCase(gettingRecipeData.fulfilled, (state, action) => {
        state.currentRecipeData = action.payload.recipe;
      })
      .addCase(gettingRecipeData.rejected, (state, action) => {
        state.currentRecipeData = {};
        state.error = action.payload;
      })
      .addCase(gettingAllCustomFoodOptions.fulfilled, (state, action) => {
        state.customFoodOptions = action.payload.data;
      })
      .addCase(gettingAllCustomFoodOptions.rejected, (state, action) => {
        state.customFoodOptions = [];
        state.error = action.payload;
      })
      .addCase(getAllMealOrRecipeData.fulfilled, (state, action) => {
        state.allMealOrRecipeData = action.payload.data;
      })
      .addCase(getAllMealOrRecipeData.rejected, (state, action) => {
        state.allMealOrRecipeData = [];
        state.error = action.payload;
      })
      .addCase(getSearchResultsForMealItems.pending, (state) => {
        state.searchResultsLoading = true;
      })
      .addCase(getSearchResultsForMealItems.fulfilled, (state, action) => {
        state.searchResultsLoading = false;
        state.searchResults = action.payload.results;
      })
      .addCase(getSearchResultsForMealItems.rejected, (state, action) => {
        state.searchResultsLoading = false;
        state.error = action.payload;
      });
  }
});
export const { updateAddingMeal, updateAddingRecipe, updateSearchResults } =
  level3slice.actions;

export default level3slice.reducer;
