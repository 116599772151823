import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  Stack,
  styled
} from "@mui/material";
import useThemeColor from "../../utils/useTheme";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(2, 0)
}));

const StyledInputLabel = styled("label")(({ styledColors }) => ({
  position: "absolute",
  top: "-18px",
  color: styledColors.inputLabelColor,
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ styledColors }) => ({
  input: {
    color: styledColors.color
  },
  "& .MuiOutlinedInput-root": {
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& fieldset": {
      color: styledColors.lightBlack,
      borderColor: styledColors.color
    },
    "&:hover fieldset": {
      borderColor: styledColors?.color
    },
    "&.Mui-focused fieldset": {
      color: styledColors.lightBlack,
      borderColor: styledColors?.color
    },
    "&.Mui-disabled": {
      background: styledColors.lightGreyColor,
      color: styledColors.color
    }
  }
}));
const FoodEditModal = ({ open, onClose, foodItem, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: "",
    quantity: "",
    noOfQty: "",
    type: "",
    unit: ""
  });

  useEffect(() => {
    if (foodItem) {
      setFormData({
        name: foodItem.name || "",
        quantity: foodItem.quantity || "",
        noOfQty: foodItem.noOfQty || "",
        type: foodItem.type || "",
        unit: foodItem.unit || ""
      });
      setDisabledFields(
        foodItem.noOfQty
          ? { quantity: true, noOfQty: false, unit: true }
          : { quantity: false, noOfQty: true, unit: false }
      );
    }
  }, [foodItem]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const [disabledFields, setDisabledFields] = useState({
    quantity: false,
    noOfQty: false,
    unit: false
  });

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values and ensure it's at least 0
    if (Number(value) >= 0) {
      handleInputChange(e);
      setDisabledFields({
        quantity: false,
        noOfQty: value === "" ? false : true
        // unit: value === "" ? false : true
      });
    }
  };

  // Handle noOfQty change
  const handleNoOfQtyChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values and ensure it's at least 0
    if (Number(value) >= 0) {
      handleInputChange(e);
      setDisabledFields({
        quantity: value === "" ? false : true,
        noOfQty: false,
        unit: value === "" ? false : true
      });
    }
  };

  const handleSubmit = () => {
    onSubmit(formData);
    onClose();
  };
  const styles = useThemeColor();

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={open}
      onClose={onClose}
      aria-labelledby="edit-food-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "500px" },
          color: styles.color,
          bgcolor: styles.background,
          borderRadius: 2,
          boxShadow: 24,
          p: 4
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Edit item
        </Typography>

        <Stack spacing={3} mt={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={2}
            borderRadius="8px"
            padding={"12px"}
            sx={{ background: styles.lightGreyColor }}
          >
            <Typography>{foodItem?.name}</Typography>
          </Box>

          <StyledTextFieldContainer
            style={{ display: "flex", gap: "20px", marginTop: "40px" }}
          >
            <StyledInputLabel styledColors={styles} htmlFor="quantity">
              Amount
            </StyledInputLabel>
            <StyledTextField
              styledColors={styles}
              type="number"
              // min={0}
              onWheel={(e) => e.target.blur()}
              name="quantity"
              slotProps={{
                htmlInput: {
                  maxLength: 13,
                  step: "1"
                }
              }}
              margin="normal"
              // htmlInput={{ min: 0 }}
              placeholder="Enter the amount"
              value={formData.quantity}
              onChange={handleQuantityChange}
              disabled={disabledFields.quantity}
              sx={{ width: { xs: "100%", md: "60%" } }}
            />
            <FormControl
              sx={{
                width: "40%",
                marginTop: "15px",
                svg: {
                  fill: styles.color
                },
                ".MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: styles.color
                  },
                  "&:hover fieldset": {
                    borderColor: styles.color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: styles.disabledColor
                  }
                }
              }}
            >
              <Select
                labelId="unit-label"
                name="unit"
                value={formData.unit}
                MenuProps={{
                  MenuListProps: {
                    sx: {
                      padding: 0,
                      ".Mui-selected": {
                        color: `${styles.background} !important`,
                        background: `${styles.color} !important`
                      }
                    }
                  }
                }}
                sx={{
                  color: styles.color,
                  marginTop: 0, // Remove any margin
                  paddingTop: 0, // Remove any padding
                  "& .Mui-disabled": {
                    background: styles.lightGreyColor
                    // color: styles.color
                  }
                }}
                onChange={handleInputChange}
                disabled={disabledFields.unit}
              >
                <MenuItem
                  sx={{
                    backgroundColor: styles.background,
                    color: styles.color,
                    paddingY: 1,
                    "&:hover": {
                      backgroundColor: styles.color,
                      color: styles.background
                    }
                  }}
                  value="grams"
                >
                  Grams
                </MenuItem>
                <MenuItem
                  sx={{
                    backgroundColor: styles.background,
                    color: styles.color,
                    paddingY: 1,
                    "&:hover": {
                      backgroundColor: styles.color,
                      color: styles.background
                    }
                  }}
                  value="ounces"
                >
                  Ounces
                </MenuItem>
                <MenuItem
                  sx={{
                    backgroundColor: styles.background,
                    color: styles.color,
                    paddingY: 1,
                    "&:hover": {
                      backgroundColor: styles.color,
                      color: styles.background
                    }
                  }}
                  value="cups"
                >
                  Cups / Fists
                </MenuItem>
                {[
                  { value: "palms", label: "Palms" },
                  { value: "pounds", label: "Pounds" }
                  // { value: "mls", label: " MLs (millimeters)" }
                ]?.map(({ value, label }) => (
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value={value}
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledTextFieldContainer>
          <Typography display="flex" fontWeight="bold" justifyContent="center">
            OR
          </Typography>
          <StyledTextFieldContainer
            style={{ display: "flex", gap: "20px", marginTop: "20px" }}
          >
            <StyledInputLabel styledColors={styles} htmlFor="noOfQty">
              No. of Quantity
            </StyledInputLabel>
            <StyledTextField
              styledColors={styles}
              type="number"
              // min={0}
              name="noOfQty"
              slotProps={{
                htmlInput: {
                  maxLength: 13,
                  step: "1"
                }
              }}
              margin="normal"
              // htmlInput={{ min: 0 }}
              placeholder="Enter the quantity"
              value={formData.noOfQty}
              onWheel={(e) => e.target.blur()}
              onChange={handleNoOfQtyChange}
              disabled={disabledFields.noOfQty}
              sx={{ width: { xs: "100%" } }}
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            style={{ display: "flex", gap: "20px", marginTop: "40px" }}
          >
            <StyledInputLabel styledColors={styles} htmlFor="type">
              Type of food
            </StyledInputLabel>
            <FormControl
              sx={{
                width: "100%",
                marginTop: "15px",
                svg: {
                  fill: styles.color
                },
                ".MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: styles.disabledColor
                  },
                  "&:hover fieldset": {
                    borderColor: styles.color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: styles.color
                  }
                }
              }}
            >
              <Select
                labelId="unit-label"
                name="type"
                value={formData.type}
                MenuProps={{
                  MenuListProps: {
                    sx: {
                      padding: 0,
                      ".Mui-selected": {
                        color: `${styles.background} !important`,
                        background: `${styles.color} !important`
                      }
                    }
                  }
                }}
                sx={{
                  color: styles.color,
                  marginTop: 0, // Remove any margin
                  paddingTop: 0 // Remove any padding
                }}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              >
                <MenuItem
                  sx={{
                    backgroundColor: styles.background,
                    color: styles.color,
                    paddingY: 1,
                    "&:hover": {
                      backgroundColor: styles.color,
                      color: styles.background
                    }
                  }}
                  value="800g"
                >
                  800g
                </MenuItem>
                <MenuItem
                  sx={{
                    backgroundColor: styles.background,
                    color: styles.color,
                    paddingY: 1,
                    "&:hover": {
                      backgroundColor: styles.color,
                      color: styles.background
                    }
                  }}
                  value="protein"
                >
                  Protein
                </MenuItem>
                <MenuItem
                  sx={{
                    backgroundColor: styles.background,
                    color: styles.color,
                    paddingY: 1,
                    "&:hover": {
                      backgroundColor: styles.color,
                      color: styles.background
                    }
                  }}
                  value="other"
                >
                  Other
                </MenuItem>
              </Select>
            </FormControl>
          </StyledTextFieldContainer>

          <Box
            sx={{
              display: "flex",
              cursor: "pointer",
              gap: 2,
              justifyContent: "flex-end",
              mt: 2
            }}
          >
            <Button
              variant="outlined"
              sx={{ textTransform: "none" }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: "#368AD0",
                cursor: "pointer",
                color: "#fff",
                ":disabled": {
                  backgroundColor: "#A7C8E9",
                  // color: "#FCFCFC",
                  borderRadius: "4px"
                }
              }}
              onClick={handleSubmit}
            >
              Save Changes
            </Button>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

export default FoodEditModal;
