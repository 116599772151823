import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useThemeColor from "../../utils/useTheme";

export default function LongMenu({ onClickHandler }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const styles = useThemeColor();

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon
          sx={{
            width: 20,
            height: 20,
            color: "#8E8E8E"
          }}
        />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              background: styles.background,
              color: styles.color
            }
          }
        }}
      >
        <MenuItem
          sx={{ fontSize: "14px" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClickHandler("edit");
            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          sx={{ fontSize: "14px" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClickHandler("delete");
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}
