import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from "@mui/material";
import { ReactComponent as TrashIcon } from "../../assets/delete.svg"; // Trash icon for visual appeal
import useThemeColor from "../../utils/useTheme";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DeleteUserModal = ({ open, handleClose, handleDelete }) => {
  const styles = useThemeColor();
  return (
    <Dialog
      TransitionComponent={Transition}
      TransitionProps={{
        timeout: {
          enter: 500,
          exit: 300
        }
      }}
      sx={{
        ".MuiDialog-paper": {
          background: styles.background,
          padding: 3,
          width: "500px",
          color: styles.color,
          position: "relative",
          borderRadius: "8px", // Rounded corners for a modern feel
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)" // Subtle shadow
        }
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-user-modal-title"
      aria-describedby="delete-user-modal-description"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        p={3}
        pb={0}
      >
        <TrashIcon width={50} height={50} color={styles.color} />
        <DialogTitle
          id="delete-user-modal-title"
          fontSize="20px"
          fontWeight="600"
          mt={2}
        >
          Are you sure you want to delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            color={styles.color}
            fontSize="13px"
            id="delete-user-modal-description"
          >
            This action is permanent and cannot be undone.
          </DialogContentText>
        </DialogContent>
      </Box>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 24px"
        }}
      >
        <Button
          variant="outlined"
          onClick={handleClose}
          sx={{
            textTransform: "none",
            color: "#888",
            borderColor: "#888",
            borderRadius: "4px",
            fontWeight: "500",
            width: "48%",
            padding: "10px 0"
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleDelete}
          sx={{
            textTransform: "none",
            backgroundColor: "#FF4000", // Red color for delete action
            color: "#fff",
            borderRadius: "4px",
            fontWeight: "600",
            width: "48%",
            padding: "10px 0",
            "&:hover": {
              backgroundColor: "#FF4000" // Darker red on hover
            }
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserModal;
