import {
  Avatar,
  Box,
  IconButton,
  Modal,
  Typography,
  useMediaQuery
} from "@mui/material";
import {
  Close,
  Favorite,
  FavoriteBorder,
  TurnedIn,
  TurnedInNot
} from "@mui/icons-material";
import getInitials from "../../utils/getInitials";
import { convertTextToLink } from "../../utils/getUrlFromText";
import useSignedUrl from "../../utils/useSignedUrl";
import { useTheme } from "@emotion/react";
import { favoriteMeal, likeMeal } from "../../api/modApi";
import { useEffect, useState } from "react";
import useThemeColor from "../../utils/useTheme";
import { selectCurrentTheme } from "../../features/theme/themeSelectors";
import { useSelector } from "react-redux";

const PreviewModal = ({
  onClose,
  setIsViewingUserMeals,
  isViewingUserMeals,
  currentMeal
}) => {
  const imgSrc = useSignedUrl(
    currentMeal?.createdByUser?.profilePic ||
      currentMeal?.createdByAdminUser?.profilePic,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );
  const styles = useThemeColor();
  const mealImageSrc = useSignedUrl(
    currentMeal?.mealImage,
    process.env.REACT_APP_BACKEND_MEAL_IMAGE_LOCATION
  );
  const [isLiked, setIsLiked] = useState(currentMeal?.likedByUser || false);
  const [isSaved, setIsSaved] = useState(currentMeal?.favouriteByUser || false);
  const theme = useTheme(); // Use theme hook to get the theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const currentTheme = useSelector(selectCurrentTheme);

  useEffect(() => {
    setIsLiked(currentMeal?.likedByUser);
    setIsSaved(currentMeal?.favouriteByUser);
  }, [currentMeal]);
  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={!!currentMeal}
      onClose={onClose}
      aria-labelledby="meal-modal-title"
      aria-describedby="meal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: { xs: "50%", md: "45%" },
          width: { xs: "90%", md: "70%", lg: "unset" },
          transform: "translate(-50%, -50%)",
          bgcolor: styles.background,
          boxShadow: 24,
          p: 1,
          borderRadius: 2,
          maxHeight: "90dvh", // Limits height for mobile
          overflowY: "auto" // Enables scrolling if content overflows
        }}
      >
        <Box display="flex" justifyContent="end">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <Close sx={{ color: styles.color }} />
          </IconButton>
        </Box>
        <Box
          gap={2}
          p="10px 20px"
          display="flex"
          sx={{
            flexDirection: isMobile ? "column" : "row"
          }}
        >
          <Box width={"100%"}>
            <Box
              component="img"
              alt="meal"
              src={mealImageSrc}
              // height={310}
              width={"100%"}
              borderRadius={10}
            />
          </Box>

          <Box width="100%">
            <Box
              display={"flex"}
              sx={{
                flexDirection: { xs: "column" },
                alignItems: { xs: "center" },
                justifyContent: { xs: "center" }
              }}
            >
              <Typography
                color={styles.color}
                fontSize={isMobile ? "28px" : "36px"}
                fontWeight={isMobile ? 600 : 500}
              >
                {currentMeal?.title}
              </Typography>
              <Box display="flex" margin={1} gap="20px">
                <Box
                  textAlign="center"
                  alignItems={"center"}
                  justifyContent={"left"}
                  display="flex"
                  gap={2}
                  sx={{
                    background:
                      currentTheme === "light"
                        ? "#FEECE6"
                        : styles.modBackgroundBlocks,
                    padding: "8px 16px"
                  }}
                  borderRadius="8px"
                >
                  <Box>
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color={styles.inputLabelColor}
                    >
                      800g
                    </Typography>
                    <Typography
                      fontSize="24px"
                      fontWeight={600}
                      color="#F6541C"
                    >
                      {currentMeal?.total800Gms || 0}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  alignItems={"center"}
                  justifyContent={"left"}
                  display="flex"
                  gap={2}
                  textAlign="center"
                  sx={{
                    background:
                      currentTheme === "light"
                        ? "#D4E9FC"
                        : styles.modBackgroundBlocks,
                    padding: "8px 16px"
                  }}
                  borderRadius="8px"
                >
                  <Box>
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color={styles.inputLabelColor}
                    >
                      Protein g
                    </Typography>
                    <Typography
                      fontSize="24px"
                      fontWeight={600}
                      color="#2C75C5"
                    >
                      {currentMeal?.totalProteinGms || 0}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <Box
                  my={2}
                  display="flex"
                  gap="10px"
                  alignItems="center"
                  onClick={() => {
                    if (!isViewingUserMeals) {
                      setTimeout(() => {
                        setIsViewingUserMeals(currentMeal);
                      }, 1000);
                    }
                  }}
                >
                  {currentMeal?.createdByUser?.profilePic ||
                  currentMeal?.createdByAdminUser?.profilePic ? (
                    <Box
                      component="img"
                      height={40}
                      borderRadius="50%"
                      width={40}
                      src={imgSrc}
                      alt="avatar"
                    />
                  ) : (
                    <Avatar sx={{ width: 40, height: 40 }}>
                      {getInitials(
                        currentMeal?.createdByUser?.profilePic ||
                          currentMeal?.createdByAdminUser?.profilePic
                      )}
                    </Avatar>
                  )}{" "}
                  <Typography
                    color={styles.color}
                    fontSize="14px"
                    fontWeight={400}
                  >
                    {currentMeal?.createdByUser?.fullName ||
                      currentMeal?.createdByAdminUser?.name}
                  </Typography>
                </Box>
                <Box sx={{ my: 2 }} display="flex" gap="10px">
                  <IconButton
                    onClick={async () => {
                      await likeMeal(currentMeal?._id);
                      setIsLiked(!isLiked);
                    }}
                    style={{
                      background: isLiked ? "#FF4000" : "transparent",
                      border: isLiked ? "none" : `1px solid ${styles.color}`
                    }}
                    sx={{
                      padding: 2,
                      height: "32px",
                      borderRadius: "4px"
                    }}
                  >
                    {" "}
                    {isLiked ? (
                      <Favorite
                        sx={{
                          height: "20px",
                          width: "20px",
                          fill: "#FCFCFC"
                        }}
                      />
                    ) : (
                      <FavoriteBorder
                        sx={{
                          height: "20px",
                          width: "20px",
                          fill: styles.color
                        }}
                      />
                    )}
                    <Typography
                      color={isLiked ? "#FCFCFC" : styles.color}
                      ml={1}
                    >
                      {isLiked ? 1 : 0}
                    </Typography>
                  </IconButton>
                  <IconButton
                    onClick={async () => {
                      await favoriteMeal(currentMeal?._id);
                      setIsSaved(!isSaved);
                    }}
                    style={{
                      background: isSaved ? "#03B55C" : "transparent",
                      border: isSaved ? "none" : `1px solid ${styles.color}`
                    }}
                    sx={{
                      height: "32px",
                      borderRadius: "4px"
                    }}
                  >
                    {isSaved ? (
                      <TurnedIn
                        sx={{
                          height: "20px",
                          width: "20px",
                          fill: "#FCFCFC"
                        }}
                      />
                    ) : (
                      <TurnedInNot
                        sx={{
                          height: "20px",
                          width: "20px",
                          fill: styles.color
                        }}
                      />
                    )}
                  </IconButton>
                </Box>
              </Box>
            </Box>

            {/* <Box height={64} borderRadius={2} display="flex" gap="10px">
              <Box
                width={"100%"}
                alignItems={"center"}
                justifyContent={"left"}
                display="flex"
                gap={2}
                sx={{
                  background:
                    currentTheme === "light"
                      ? "#FEECE6"
                      : styles.modBackgroundBlocks,
                  padding: "8px 16px",
                  color: "#9CA3AF"
                }}
                borderRadius="8px"
              >
                <AppleIcon />
                <Box>
                  <Typography
                    fontSize="12px"
                    fontWeight={400}
                    color={styles.inputLabelColor}
                  >
                    800g
                  </Typography>
                  <Typography fontSize="24px" fontWeight={500} color="#F6541C">
                    {currentMeal?.total800Gms || 0}
                  </Typography>
                </Box>
              </Box>

              <Box
                width={"100%"}
                alignItems={"center"}
                justifyContent={"left"}
                display="flex"
                gap={2}
                sx={{
                  background:
                    currentTheme === "light"
                      ? "#D4E9FC"
                      : styles.modBackgroundBlocks,
                  padding: "8px 16px",
                  color: "#9CA3AF"
                }}
                borderRadius="8px"
              >
                <ProteinIcon />
                <Box>
                  <Typography
                    fontSize="12px"
                    fontWeight={400}
                    color={styles.inputLabelColor}
                  >
                    Protein
                  </Typography>
                  <Typography fontSize="24px" fontWeight={500} color="#2C75C5">
                    {currentMeal?.totalProteinGms}g
                  </Typography>
                </Box>
              </Box>
            </Box> */}

            <Box
              width={"100%"}
              sx={{
                background: styles.modBackgroundBlocks,
                padding: "12px 16px",
                color: "#9CA3AF",
                mt: 3,
                borderRadius: "8px"
              }}
            >
              <Typography color={styles.color} fontSize="16px" fontWeight={600}>
                Ingredients
              </Typography>
              <ul
                style={{
                  paddingLeft: "18px",
                  margin: 0,
                  marginTop: 8,
                  color: styles.inputLabelColor
                }}
              >
                {currentMeal?.fruitsVeggies?.map((item) => (
                  <li key={item._id}>
                    <Typography variant="body1">
                      {item.quantity
                        ? `${item.quantity} ${item.unit} ${item.name}`
                        : item.name}
                    </Typography>
                  </li>
                ))}
                {currentMeal?.otherIngredients?.map((item) => (
                  <li key={item._id}>
                    <Typography variant="body1">
                      {item.quantity
                        ? `${item.quantity} ${item.unit} ${item.name}`
                        : item.name}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>

            {currentMeal?.generalText && (
              <Box
                width={"100%"}
                sx={{
                  background: styles.modBackgroundBlocks,
                  padding: "12px 16px",
                  mt: 3,
                  borderRadius: "8px"
                }}
              >
                <Typography
                  color={styles.color}
                  fontSize="16px"
                  fontWeight={600}
                >
                  Details
                </Typography>
                <Typography
                  mt={1}
                  variant="body1"
                  color={styles.inputLabelColor}
                >
                  {convertTextToLink(currentMeal?.generalText || "")}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PreviewModal;
