import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Fade,
  IconButton,
  Modal
} from "@mui/material";
import { Html5QrcodeScanner, Html5QrcodeSupportedFormats } from "html5-qrcode";
import {
  QrCode2Rounded,
  CloseRounded,
  RefreshRounded,
  LinkRounded,
  CameraEnhanceRounded,
  CheckRounded
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  generateAccessToken,
  getSearchResultsForMealItems
} from "../../features/level3/level3Slice";
import { selectAccessToken } from "../../features/level3/level3Selectors";

const QrScannerModal = ({ open, setOpen, setFavoriteFood, setOpenMenu }) => {
  const [result, setResult] = useState(null);
  const [scannerInstance, setScannerInstance] = useState(null);
  const [isReaderReady, setIsReaderReady] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const existingToken = useSelector(selectAccessToken);
  const handleClose = async () => {
    const { payload } = await dispatch(
      getSearchResultsForMealItems({
        barcode: result,
        token: accessToken
      })
    );
    setOpen(false);
    setResult(null);
    if (scannerInstance) {
      scannerInstance.clear();
    }
    setIsReaderReady(false);
    if (payload?.results?.length) {
      setOpenMenu();
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setFavoriteFood("");
    if (!existingToken && open) {
      dispatch(generateAccessToken()).then(({ payload }) => {
        setAccessToken(payload.access_token);
      });
    } else {
      setAccessToken(existingToken);
    }
  }, [open]);

  const initializeScanner = () => {
    if (scannerInstance) {
      scannerInstance.clear();
    }

    const newScanner = new Html5QrcodeScanner("reader", {
      fps: 20,
      qrbox: {
        width: 250,
        height: 250
      },
      rememberLastUsedCamera: true,
      formatsToSupport: [
        Html5QrcodeSupportedFormats.EAN_13,
        Html5QrcodeSupportedFormats.EAN_8,
        Html5QrcodeSupportedFormats.UPC_A,
        Html5QrcodeSupportedFormats.UPC_E
      ]
    });

    const success = (qrCodeResult) => {
      setResult(qrCodeResult);
      newScanner.clear();
    };

    const error = (err) => {
      console.error(err);
    };

    newScanner.render(success, error);
    setScannerInstance(newScanner);
  };

  useEffect(() => {
    if (open && isReaderReady) {
      initializeScanner();
    }
    return () => {
      if (scannerInstance) {
        scannerInstance.clear();
      }
    };
  }, [open, isReaderReady]);

  useEffect(() => {
    if (open) {
      setIsReaderReady(true);
    }
  }, [open]);

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Fade in={open}>
        <Paper
          elevation={24}
          sx={{
            width: "90%",
            maxWidth: "600px",
            maxHeight: "90vh",
            overflow: "hidden",
            borderRadius: 4,
            position: "relative",
            bgcolor: "#333333",
            color: "white",
            border: "1px solid rgba(255, 255, 255, 0.1)"
          }}
        >
          {/* Decorative Background Element */}
          <Box
            sx={{
              position: "absolute",
              top: -100,
              right: -100,
              width: 200,
              height: 200,
              borderRadius: "50%",
              background: "linear-gradient(45deg, #007AFF, #00F2FE)",
              opacity: 0.1,
              filter: "blur(40px)"
            }}
          />

          {/* Header */}
          <Box
            sx={{
              p: 2.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              background: "rgba(0, 0, 0, 0.2)"
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
              <QrCode2Rounded sx={{ color: "#007AFF" }} />
              <Typography
                variant="h6"
                component="h2"
                sx={{ fontWeight: 600, letterSpacing: 0.5 }}
              >
                Scan Barcode
              </Typography>
            </Box>
            <IconButton
              onClick={handleClose}
              size="small"
              sx={{
                color: "white",
                "&:hover": {
                  background: "rgba(255, 255, 255, 0.1)"
                }
              }}
            >
              <CloseRounded />
            </IconButton>
          </Box>

          {/* Content */}
          <Box sx={{ p: 3, position: "relative" }}>
            <Box
              id="reader"
              sx={{
                "& video": {
                  borderRadius: 2,
                  maxWidth: "100%",
                  border: "2px solid rgba(0, 122, 255, 0.3)",
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)"
                },
                "& img": {
                  display: "none"
                }
              }}
            />

            {result ? (
              <Fade in>
                <Box
                  sx={{
                    mt: 3,
                    p: 2.5,
                    borderRadius: 2,
                    bgcolor: "rgba(255, 255, 255, 0.05)",
                    backdropFilter: "blur(10px)",
                    border: "1px solid rgba(255, 255, 255, 0.1)"
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      mb: 1.5,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      color: "rgba(255, 255, 255, 0.7)"
                    }}
                  >
                    <LinkRounded fontSize="small" sx={{ color: "#007AFF" }} />
                    Scanned code:
                  </Typography>
                  <Typography
                    component="a"
                    href={result}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      wordBreak: "break-all",
                      color: "#007AFF",
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                        color: "#0056b3"
                      }
                    }}
                  >
                    {result}
                  </Typography>
                </Box>
              </Fade>
            ) : (
              <Box sx={{ position: "relative", mt: 3 }}>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    color: "rgba(255, 255, 255, 0.7)"
                  }}
                >
                  <CameraEnhanceRounded
                    fontSize="small"
                    sx={{ color: "#007AFF" }}
                  />
                  Position the Barcode within the frame
                </Typography>
              </Box>
            )}
          </Box>

          {/* Actions */}
          <Box
            sx={{
              p: 2.5,
              display: "flex",
              gap: 2,
              borderTop: "1px solid rgba(255, 255, 255, 0.1)",
              background: "rgba(0, 0, 0, 0.2)"
            }}
          >
            <Button
              fullWidth
              variant="outlined"
              onClick={handleClose}
              startIcon={result ? <CheckRounded /> : <CloseRounded />}
              sx={{
                borderColor: "rgba(255, 255, 255, 0.2)",
                color: "white",
                "&:hover": {
                  borderColor: "rgba(255, 255, 255, 0.3)",
                  background: "rgba(255, 255, 255, 0.05)"
                }
              }}
            >
              {result ? "Use code" : "Close"}
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setResult(null);
                setIsReaderReady(false);
                setTimeout(() => setIsReaderReady(true), 0);
              }}
              startIcon={<RefreshRounded />}
              sx={{
                background: "linear-gradient(45deg, #007AFF, #00F2FE)",
                boxShadow: "0 4px 12px rgba(0, 122, 255, 0.3)",
                "&:hover": {
                  background: "linear-gradient(45deg, #0056b3, #00d4e0)"
                }
              }}
            >
              Scan Again
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default QrScannerModal;
