import createApi from "../utils/axiosInstance";

export const getAllUserDiaries = async (levelId, firstLoad = false) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/user/diary/get-diary-day/${levelId}?firstLoad=${firstLoad}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Get user diaries failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getAllDiaryUserLevels = async (date) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/user/diary/get-user-diary-level?date=${date}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Get user diary level failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getAllDiaryUserStats = async ({
  timeFrame,
  levelId,
  includeToday,
  date,
  startDate = "",
  endDate = ""
}) => {
  try {
    const startDateAvailable = startDate ? `&customStartDate=${startDate}` : "";
    const endDateAvailable = endDate ? `&customEndDate=${endDate}` : "";
    const api = createApi(true);
    const response = await api.get(
      `/user/diary/get-stats/${levelId}?includeToday=${includeToday}&date=${date}&timeFrame=${
        timeFrame + startDateAvailable + endDateAvailable
      }`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Get user stats failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const completeDiary = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/user/diary/complete-diary-day`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Complete diary failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const deleteIngredients = async (
  levelId,
  diaryId,
  ingredientId,
  date
) => {
  try {
    const api = createApi(true);
    const response = await api.delete(
      `/user/diary/delete-ingredient/${levelId}/${diaryId}/${ingredientId}?date=${date}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Delete failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const deleteIngredientsCustomOption = async (optionId) => {
  try {
    const api = createApi(true);
    const response = await api.delete(
      `/user/diary/delete-ingredient-custom-options/${optionId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Delete failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const addIngredients = async (data) => {
  try {
    const api = createApi(true);

    const response = await api.post(`/user/diary/add-ingredient`, {
      ...data
    });
    return response.data;
  } catch (error) {
    console.error(
      "Add Ingredients failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const updateIngredients = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/user/diary/edit-ingredient`, {
      ...data
    });
    return response.data;
  } catch (error) {
    console.error(
      "Update total failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const copyYesterdayData = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/user/diary/copy-ingredient`, {
      ...data
    });
    return response.data;
  } catch (error) {
    console.error(
      "Copy yesterday failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const addMealOrRecipeToDiary = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/user/diary/add-meal-or-recipe`, {
      ...data
    });
    return response.data;
  } catch (error) {
    console.error(
      "Add meal or recipe to diary failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getLeaderBoardData = async (levelId, data) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/user/diary/get-leaderboard?levelId=${levelId}&month=${
        data?.month || ""
      }&year=${data?.year || ""}&challengeId=${data?.challengeId || ""}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting leaderboard data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getActiveChallengeData = async (levelId, date) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/user/diary/get-active-challenge?levelId=${levelId}&date=${date}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting challenge data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
