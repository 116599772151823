// modSelectors.js

// export const selectLevelData = (state) => state.diary.availableLevels;
export const selectAllDiariesData = (state) => state.diary.allDiaries;
export const selectAllOptions800g = (state) => state.diary.options800gms;
export const selectAllOptionsProtein = (state) => state.diary.optionsProteins;
export const selectAllOptionsOtherFood = (state) =>
  state.diary.optionsOtherFood;
export const selectAllOptions800gLevel3 = (state) =>
  state.diary.options800gmFood;
export const selectAllOptionsProteinLevel3 = (state) =>
  state.diary.optionsProteinFood;
export const selectAllDiaryLevels = (state) => state.diary.availableDiaryLevels;
export const selectActiveChallenge = (state) => state.diary.activeChallege;
export const selectAllDiaryStats = (state) => state.diary.stats;
export const selectAllDiaryExtraData = (state) => state.diary.extraData;
export const selectAllDiaryOutOf800g = (state) => state.diary.outOf800g;
export const selectLeaderboardData = (state) => state.diary.leaderboard;
export const selectAllDiaryOutOfProtein = (state) => state.diary.outOfProtein;
export const selectAllDiaryOutOfCalories = (state) => state.diary.outOfCalories;
