import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  completeDiary,
  getAllUserDiaries,
  getAllDiaryUserLevels,
  getAllDiaryUserStats,
  getLeaderBoardData,
  getActiveChallengeData
} from "../../api/diaryApi";
import cloneDeep from "lodash/cloneDeep";

export const getCurrentUserDiaries = createAsyncThunk(
  "diary/getCurrentUserDiaries",
  async ({ levelId, firstLoad }, thunkAPI) => {
    try {
      const response = await getAllUserDiaries(levelId, firstLoad);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const completeDiaryDay = createAsyncThunk(
  "diary/completeDiaryDay",
  async (data, thunkAPI) => {
    try {
      const response = await completeDiary(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCurrentUserDiaryLevels = createAsyncThunk(
  "diary/getCurrentUserDiaryLevels",
  async (date, thunkAPI) => {
    try {
      const response = await getAllDiaryUserLevels(date);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCurrentUserDiaryStats = createAsyncThunk(
  "diary/getCurrentUserDiaryStats",
  async (data, thunkAPI) => {
    try {
      const response = await getAllDiaryUserStats(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCurrentLevelLeaderboard = createAsyncThunk(
  "diary/getCurrentLevelLeaderboard",
  async ({ levelId, data }, thunkAPI) => {
    try {
      const response = await getLeaderBoardData(levelId, data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getActiveChallenge = createAsyncThunk(
  "diary/getActiveChallenge",
  async ({ levelId = "", date }, thunkAPI) => {
    try {
      const response = await getActiveChallengeData(levelId, date);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Initial state
const initialState = {
  allDiaries: [],
  availableDiaryLevels: [],
  options800gms: [],
  optionsProteins: [],
  optionsOtherFood: [],
  options800gmFood: [],
  optionsProteinFood: [],
  stats: [],
  extraData: {},
  outOf800g: "800",
  outOfProtein: "100",
  outOfCalories: "5000",
  leaderboard: [],
  activeChallege: {},
  loading: false, // loading state for getCurrentUserLevels
  error: null // error state
};

const diarySlice = createSlice({
  name: "diary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUserDiaryLevels.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUserDiaryLevels.fulfilled, (state, action) => {
        state.loading = false;
        state.options800gms = action.payload.static800gmOptions; // assuming response has meals data
        state.optionsProteins = action.payload.staticProteinOptions; // assuming response has meals data
        state.availableDiaryLevels = action.payload.diaryLevels; // assuming response has meals data
        // state.optionsOtherFood = Array.from(
        //   new Map(
        //     action.payload.otherFoodOptionData
        //       ?.flatMap((item) => JSON.parse(JSON.stringify(item.otherFood)))
        //       ?.map((food) => [food.name.trim(), food]) // Use the name as the key
        //   ).values() // Extract unique objects based on name
        // ).slice(0, 10);
        state.optionsOtherFood = Array.from(
          new Map(
            action.payload.otherFoodOptionData
              ?.flatMap((item) => cloneDeep(item.otherFood)) // Deep clone the objects
              ?.map((food) => [food.name.trim(), food])
          ).values()
        ).slice(0, 10);

        // state.options800gmFood = Array.from(
        //   new Map(
        //     action.payload.entry800gOptionData
        //       ?.flatMap((item) => JSON.parse(JSON.stringify(item.entry800g)))
        //       ?.map((food) => [food.name.trim(), food]) // Use the name as the key
        //   ).values() // Extract unique objects based on name
        // ).slice(0, 10);
        state.options800gmFood = Array.from(
          new Map(
            action.payload.entry800gOptionData
              ?.flatMap((item) => cloneDeep(item.entry800g)) // Deep clone the objects
              ?.map((food) => [food.name.trim(), food])
          ).values()
        ).slice(0, 10);
        // state.optionsProteinFood = Array.from(
        //   new Map(
        //     action.payload.proteinOptionData
        //       ?.flatMap((item) => JSON.parse(JSON.stringify(item.protein)))
        //       ?.map((food) => [food.name.trim(), food]) // Use the name as the key
        //   ).values() // Extract unique objects based on name
        // ).slice(0, 10);
        state.optionsProteinFood = Array.from(
          new Map(
            action.payload.proteinOptionData
              ?.flatMap((item) => cloneDeep(item.protein)) // Deep clone the objects
              ?.map((food) => [food.name.trim(), food])
          ).values()
        ).slice(0, 10);
      })
      .addCase(getCurrentUserDiaryLevels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCurrentUserDiaries.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUserDiaries.fulfilled, (state, action) => {
        state.loading = false;
        state.allDiaries = action.payload.diary; // assuming response has meals data
      })
      .addCase(getCurrentUserDiaries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCurrentUserDiaryStats.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUserDiaryStats.fulfilled, (state, action) => {
        state.loading = false;
        state.stats = action.payload.diaries; // assuming response has meals data
        state.extraData = {
          averagedSummaries: action.payload?.averagedSummaries,
          totalSummaries: action.payload?.totalSummaries
        }; // assuming response has meals data
        state.outOf800g = action.payload.outOf800g;
        state.outOfProtein = action.payload.outOfProtein;
        state.outOfCalories = action.payload.outOfCalories;
      })
      .addCase(getCurrentUserDiaryStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCurrentLevelLeaderboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentLevelLeaderboard.fulfilled, (state, action) => {
        state.loading = false;
        state.leaderboard = action.payload.leaderboard; // assuming response has meals data
      })
      .addCase(getCurrentLevelLeaderboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getActiveChallenge.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActiveChallenge.fulfilled, (state, action) => {
        state.loading = false;
        state.activeChallege = action.payload.challenge; // assuming response has meals data
      })
      .addCase(getActiveChallenge.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

// export const { updateTrainingById, updatePercentage } = diarySlice.actions;

export default diarySlice.reducer;
