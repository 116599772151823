import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { forwardRef, useEffect, useState } from "react";
import { updateIngredients } from "../../api/diaryApi";
import { getCurrentUserDiaries } from "../../features/diary/diarySlice";
import { useDispatch } from "react-redux";
import useThemeColor from "../../utils/useTheme";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "relative",
  margin: theme.spacing(2, 0)
}));
const StyledInputLabel = styled("label")(({ styledColors }) => ({
  position: "absolute",
  top: "-18px",
  color: styledColors.inputLabelColor,
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));
const StyledTextField = styled(TextField)(({ styledColors }) => ({
  input: {
    color: styledColors.color,
    ":disabled": {
      backgroundColor: "#A7C8E9",
      color: styledColors.color,
      borderRadius: "4px"
    }
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: styledColors.lightBlack,
      borderColor: styledColors.lightGreyColor
    },
    "&:hover fieldset": {
      borderColor: styledColors.color
    },
    "&.Mui-focused fieldset": {
      color: styledColors.lightBlack,
      borderColor: styledColors.color
    }
  }
}));
const ServicingRecipeModal = ({
  open,
  setOpen,
  title,
  openWeightModal,
  diaryId,
  handleSubmit,
  maxValue,
  levelId,
  setIsRefreshNeeded,
  dayName
}) => {
  const styles = useThemeColor();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    type: open?.recipeType,
    servicePerRecipe: 0
  });
  useEffect(() => {
    setFormData({
      type: open?.recipeType,
      servicePerRecipe: ""
    });
  }, [open]);

  const [totalWeight, setTotalWeight] = useState(maxValue);
  useEffect(() => {
    // Update the state if maxValue changes
    setTotalWeight(maxValue);
  }, [maxValue]);

  return (
    <Dialog
      TransitionComponent={Transition}
      TransitionProps={{
        timeout: {
          enter: 500,
          exit: 300
        }
      }}
      sx={{
        ".MuiDialog-paper": {
          background: styles.background,
          width: "100%",
          color: styles.color,
          position: "relative",
          padding: "0 40px 40px 40px",
          borderRadius: "8px"
        }
      }}
      open={open}
      onClose={() => {
        setOpen(null);
      }}
      aria-labelledby="weight-modal-title"
      aria-describedby="weight-modal-description"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Box display="flex" width="100%" justifyContent="flex-end">
          <IconButton
            onClick={() => {
              setOpen(null);
            }}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              paddingLeft: 0
            }}
          >
            <CloseIcon
              sx={{ height: "28px", color: styles.color, width: "28px" }}
            />
          </IconButton>
        </Box>
        <DialogTitle
          id="weight-modal-title"
          sx={{ pb: 0, mt: 6 }}
          fontWeight="600"
        >
          Recipe based on
        </DialogTitle>
        <Typography fontSize="12px" color={styles.inputLabelColor}>
          Please enter the recipe based on service or weight
        </Typography>
        <ToggleButtonGroup
          disabled
          value={formData.type}
          exclusive
          onChange={(e, newAlignment) => {
            if (newAlignment) {
              setFormData({ ...formData, type: newAlignment });
            }
          }}
          aria-label="text alignment"
          sx={{
            backgroundColor: styles.lightGreyColor, // Black background
            borderRadius: "8px",
            overflow: "hidden",
            width: "100%",
            margin: "20px 0 16px 0"
          }}
        >
          <ToggleButton
            value="service"
            aria-label="service based"
            sx={{
              textTransform: "none",
              color: styles.color,
              flex: 1,
              "&.Mui-selected": {
                color: styles.color,
                backgroundColor: "#368AD0",
                ":hover": {
                  backgroundColor: "#368AD0"
                }
              }
            }}
          >
            Servings based
          </ToggleButton>
          <ToggleButton
            value="weight"
            aria-label="weight based"
            sx={{
              flex: 1,
              textTransform: "none",
              color: styles.color,
              "&.Mui-selected": {
                color: styles.color,
                backgroundColor: "#368AD0",
                ":hover": {
                  backgroundColor: "#368AD0"
                }
              }
            }}
          >
            Weight based
          </ToggleButton>
        </ToggleButtonGroup>
        <StyledTextFieldContainer style={{ marginTop: 22 }}>
          <StyledInputLabel styledColors={styles} htmlFor="servicingPerRecipe">
            {formData.type === "service" ? "Servings" : "Weights"} per recipe
          </StyledInputLabel>
          <StyledTextField
            styledColors={styles}
            type="number"
            min={0}
            name="servicingPerRecipe"
            margin="normal"
            htmlInput={{ min: 0 }}
            placeholder="Enter the amount of servings"
            value={formData.servicePerRecipe}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value) && Number(value) >= 0) {
                setFormData({
                  ...formData,
                  servicePerRecipe: value
                });
              }
            }}
            sx={{ width: { xs: "100%" } }}
          />
        </StyledTextFieldContainer>
        <Button
          fullWidth
          disabled={!formData.servicePerRecipe}
          onClick={(e) => handleSubmit(e, formData.servicePerRecipe)}
          sx={{
            fontSize: "small",
            // p: "2px 10px",
            backgroundColor: "#368AD0",
            cursor: "pointer",
            color: "#fff",
            textTransform: "none",
            ":disabled": {
              backgroundColor: "#A7C8E9",
              color: "#64AEDD",
              pointerEvents: "none"
            }
          }}
        >
          Submit
        </Button>
      </Box>
    </Dialog>
  );
};

export default ServicingRecipeModal;
