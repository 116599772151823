import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import { ReactComponent as NoEntry } from "../../assets/no-entries.svg";
import { ReactComponent as AppleIcon } from "../../assets/apple.svg";
import { ReactComponent as ProteinIcon } from "../../assets/protein.svg";
import { ReactComponent as CaolriesIcon } from "../../assets/calories.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Box,
  styled,
  Alert,
  useMediaQuery,
  IconButton,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Paper,
  List,
  ListItem,
  Stack,
  ListItemText,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip
} from "@mui/material";
import useThemeColor from "../../utils/useTheme";
import { useTheme } from "@emotion/react";
import FoodSearch from "./FoodSearch";
import { selectCurrentTheme } from "../../features/theme/themeSelectors";
import {
  selectCurrentAddingMeal,
  selectCurrentAddingRecipe,
  selectLevel3Loading
} from "../../features/level3/level3Selectors";
import formatNumber from "../../utils/formatNumber";
import {
  addCustomFoodItems,
  addMeal,
  addMealItems,
  addRecipe,
  deleteMealItem,
  editMealItem,
  saveMeal,
  saveRecipe
} from "../../api/level3Api";
import {
  gettingMealData,
  gettingRecipeData,
  updateAddingMeal,
  updateAddingRecipe
} from "../../features/level3/level3Slice";
import { convertItemQuantityToGrams } from "../../utils/weightConvert";
import FoodEditModal from "../Modals/EditMealItem";
import LongMenu from "./ThreeDotMenu";
import { dailyValues } from "../../utils/calculateDVPercent";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(2, 0)
}));

const StyledInputLabel = styled("label")(({ styledColors }) => ({
  position: "absolute",
  top: "-18px",
  color: styledColors.inputLabelColor,
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ styledColors }) => ({
  input: {
    color: styledColors.color
  },
  "& .MuiOutlinedInput-root": {
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& fieldset": {
      color: styledColors.lightBlack,
      borderColor: styledColors.modLabel
    },
    "&:hover fieldset": {
      borderColor: styledColors.modLabel
    },
    "&.Mui-focused fieldset": {
      color: styledColors.lightBlack,
      borderColor: styledColors.modLabel
    },
    "&.Mui-disabled": {
      background: styledColors.lightGreyColor,
      color: styledColors.color
    }
  }
}));
const excludedFields = [
  "serving_id",
  "serving_description",
  "serving_url",
  "metric_serving_amount",
  "metric_serving_unit",
  "calories",
  "protein"
];
const AddMealForLevel3 = ({
  type,
  isAddingMealItem,
  setIsAddingMealItem,
  selectedOptionData,
  setIsShowingCreateMeal,
  setIsShowingCreateRecipe,
  setIsShowingCreateCustomFood,
  customFoodFormData,
  setCustomFoodFormData,
  setSelectedOptionData
}) => {
  console.log("customFoodFormData=========>", customFoodFormData);
  const [selectedCustomFood, setSelectedCustomFood] = useState(null);
  const currentMeal = useSelector(selectCurrentAddingMeal);
  const currentRecipe = useSelector(selectCurrentAddingRecipe);
  const [formData, setFormData] = useState({
    mealName: currentMeal?.mealName || "",
    recipeName: currentRecipe?.recipeName || "",
    quantity: "",
    noOfQty: "",
    unit: "grams",
    type: "800g",
    servicingPerRecipe: currentRecipe?.servicing_per_recipe || ""
  });

  const [disabledFields, setDisabledFields] = useState({
    quantity: false,
    noOfQty: false,
    unit: false
  });

  const [customDisabledFields, setCustomDisabledFields] = useState({
    quantity: false,
    noOfQty: false,
    unit: false
  });

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values and ensure it's at least 0
    if (Number(value) >= 0) {
      handleInputChange(e);
      setDisabledFields({
        quantity: false,
        noOfQty: value === "" ? false : true
        // unit: value === "" ? false : true
      });
    }
  };
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name, value);
    setCustomFoodFormData({
      ...customFoodFormData,
      [name]: value,
      ...(name === "quantity" && { noOfQty: "" }),
      ...(name === "noOfQty" && { quantity: "" })
    });
  };

  const handleCustomQuantityChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values and ensure it's at least 0
    if (Number(value) >= 0) {
      handleCustomInputChange(e);

      setCustomDisabledFields({
        quantity: false,
        noOfQty: value === "" ? false : true
        // unit: value === "" ? false : true
      });
    }
  };

  // Handle noOfQty change
  const handleNoOfQtyChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values and ensure it's at least 0
    if (Number(value) >= 0) {
      handleInputChange(e);
      setDisabledFields({
        quantity: value === "" ? false : true,
        noOfQty: false,
        unit: value === "" ? false : true
      });
    }
  };
  const handleCustomNoOfQtyChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values and ensure it's at least 0
    if (Number(value) >= 0) {
      handleCustomInputChange(e);

      setCustomDisabledFields({
        quantity: value === "" ? false : true,
        noOfQty: false,
        unit: value === "" ? false : true
      });
    }
  };

  const currentTheme = useSelector(selectCurrentTheme);
  const [openEditMealItemModal, setOpenEditMealItemModal] = useState(null);
  const [favoriteFood, setFavoriteFood] = useState("");
  const matches = useMediaQuery("(max-width:590px)");
  const styles = useThemeColor();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alignment, setAlignment] = useState(
    currentRecipe?.recipeType || "service"
  );

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCustomFoodInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the name includes nested keys (e.g., "details.serving.calories")
    const keys = name.split(".");

    setCustomFoodFormData((prevState) => {
      let updatedFormData = { ...prevState };

      // Navigate to the correct nested key
      let currentLevel = updatedFormData;
      for (let i = 0; i < keys.length - 1; i++) {
        currentLevel[keys[i]] = { ...currentLevel[keys[i]] }; // Ensure immutability
        currentLevel = currentLevel[keys[i]];
      }

      // Update the value at the final key
      currentLevel[keys[keys.length - 1]] = value;

      return updatedFormData;
    });
  };

  const updatedMealAccordingQuantity = useMemo(() => {
    return (type === "meal" ? currentMeal : currentRecipe)?.items?.map(
      (item) => {
        const details = item.details;
        const metricServingAmount = parseFloat(
          details.serving.metric_serving_amount
        );
        const scalingFactor =
          convertItemQuantityToGrams(item, metricServingAmount).quantity /
          metricServingAmount;
        const excludedFields = [
          "serving_id",
          "serving_description",
          "serving_url",
          "metric_serving_unit"
        ];
        const updatedDetails = Object.entries(details.serving).reduce(
          (acc, [key, value]) => {
            if (!excludedFields.includes(key) && !isNaN(Number(value))) {
              acc[key] = (value * scalingFactor).toFixed(2);
            }
            return acc;
          },
          {}
        );
        return {
          ...item,
          details: {
            ...item.details,
            serving: { ...item.details.serving, ...updatedDetails }
          }
        };
      }
    );
  }, [currentMeal, currentRecipe]);
  // excludedFields.details.serving summation of each common field in each object and exclude the excludedFields
  const totalExtraNutrients = useMemo(() => {
    // include all other fields from details.serving except excludedFields
    const nutrientFields = updatedMealAccordingQuantity?.reduce((acc, item) => {
      const details = item.details;
      Object.entries(details.serving).forEach(([key, value]) => {
        if (!excludedFields.includes(key) && !isNaN(Number(value))) {
          acc[key] = (acc[key] || 0) + parseFloat(value);
        }
      });
      return acc;
    }, {});
    if (nutrientFields) {
      const formattedNutrients = Object.entries(nutrientFields)?.map(
        ([key, value]) => {
          const numericValue = parseFloat(value);
          return {
            name: key
              .replace(/_/g, " ")
              .replace(/\b\w/g, (char) => char.toUpperCase()),
            value: numericValue,
            unit: "g",
            percent: Math.round((numericValue / dailyValues[key]) * 100)
          };
        }
      );
      return formattedNutrients;
    } else {
      return {};
    }
  }, [updatedMealAccordingQuantity]);
  const totals = updatedMealAccordingQuantity?.length
    ? updatedMealAccordingQuantity?.reduce(
        (totals, item) => {
          const serving = item.details.serving;
          const noOfQty = item.noOfQty || 1; // Default to 1 if noOfQty is missing

          if (item.type === "800g") {
            // Add to totals for 800g type
            totals.metric_serving_amount +=
              parseFloat(serving.metric_serving_amount) * noOfQty;
          }

          // Add to overall totals for protein and calories
          totals.protein += parseFloat(serving.protein) * noOfQty;
          totals.calories += parseFloat(serving.calories) * noOfQty;

          return totals;
        },
        { metric_serving_amount: 0, protein: 0, calories: 0 }
      )
    : { metric_serving_amount: 0, protein: 0, calories: 0 };

  const dispatch = useDispatch();
  const loading = useSelector(selectLevel3Loading);
  // Disable the button if there are errors or any required fields are empty
  const isFormValid =
    type === "meal"
      ? formData?.mealName?.trim() && currentMeal?.items?.length
      : formData?.recipeName?.trim() &&
        formData?.servicingPerRecipe &&
        alignment;

  const scalingFactor =
    convertItemQuantityToGrams(
      formData,
      selectedOptionData?.serving?.metric_serving_amount
    ).quantity / selectedOptionData?.serving?.metric_serving_amount;

  // Exclude non-nutrient fields and specific fields like `protein` and `calories`
  const nutrientFields =
    selectedOptionData &&
    Object.entries(selectedOptionData?.serving).filter(([key, value]) => {
      return !excludedFields.includes(key) && !isNaN(Number(value));
    });

  const formattedNutrients = nutrientFields?.map(([key, value]) => {
    const numericValue = (parseFloat(value) * scalingFactor).toFixed(2) || 0;
    return {
      name: key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase()),
      value: numericValue,
      unit: "g",
      percent: Math.round((numericValue / dailyValues[key]) * 100)
    };
  });

  const formatNumber = (value) => {
    return Number(value).toLocaleString();
  };

  const getNutrientValue = (name, unit = "", isTotal) => {
    const nutrient = (isTotal ? totalExtraNutrients : formattedNutrients)?.find(
      (n) => n.name === name
    );
    return nutrient
      ? `${formatNumber(nutrient.value)}${unit || nutrient.unit} ${
          unit ? `(${nutrient.percent}%)` : ""
        }`
      : `0${unit || nutrient.unit} (0%)`;
  };

  const NutrientRow = ({ name, unit, isTotal = false }) => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={0.5}
    >
      <Typography fontSize="13px">{name}</Typography>
      <Typography fontSize="13px">
        {getNutrientValue(name, unit, isTotal)}
      </Typography>
    </Box>
  );
  return (
    <Box
      color={styles.color}
      backgroundColor={styles.background}
      display={"flex"}
      width="100%"
      overflow="auto"
      flexDirection="column"
    >
      <Box
        flexGrow={1}
        paddingY={3}
        paddingLeft={10}
        sx={{
          width: { xs: "100%", lg: "70%" },
          paddingTop: { xs: 2, md: 2 },
          paddingRight: { xs: 2, md: 13, lg: 20 },
          paddingBottom: { xs: 8, md: 2 },
          paddingLeft: { xs: 2, md: 7 },
          overflowY: { md: "auto" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        }}
      >
        {type === "customFood" ? (
          <Box
            sx={{
              width: { xs: "100%", lg: "70%" },
              ...(matches && { marginBottom: { xs: 9 } })
            }}
            // component={"form"}
          >
            <StyledTextFieldContainer>
              <StyledInputLabel styledColors={styles} htmlFor="name">
                Food name
              </StyledInputLabel>
              <StyledTextField
                styledColors={styles}
                fullWidth
                variant="outlined"
                name="name"
                placeholder="Enter food name"
                value={customFoodFormData.name}
                onChange={handleCustomFoodInputChange}
                required
                margin="normal"
              />
            </StyledTextFieldContainer>

            <StyledTextFieldContainer
              style={{
                display: "flex",
                gap: "20px",
                marginTop: "30px",
                marginBottom: "20px"
              }}
            >
              <StyledInputLabel styledColors={styles} htmlFor="quantity">
                Amount
              </StyledInputLabel>
              <StyledTextField
                styledColors={styles}
                // ref={amountRef}
                type="number"
                // min={0}
                name="quantity"
                onWheel={(e) => e.target.blur()}
                margin="normal"
                slotProps={{
                  htmlInput: {
                    maxLength: 13,
                    step: "1"
                  }
                }}
                placeholder="Enter the amount"
                value={customFoodFormData.quantity}
                onChange={handleCustomQuantityChange}
                disabled={customDisabledFields.quantity}
                sx={{ width: { xs: "100%", md: "60%" } }}
              />
              <FormControl
                sx={{
                  width: "40%",
                  marginTop: "15px",
                  svg: {
                    fill: styles.color
                  },
                  ".MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: styles.modLabel
                    },
                    "&:hover fieldset": {
                      borderColor: styles.modLabel
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: styles.disabledColor
                    }
                  }
                }}
              >
                <Select
                  labelId="unit-label"
                  name="unit"
                  value={customFoodFormData.unit}
                  MenuProps={{
                    MenuListProps: {
                      sx: {
                        padding: 0,
                        ".Mui-selected": {
                          color: `${styles.background} !important`,
                          background: `${styles.color} !important`
                        }
                      }
                    }
                  }}
                  sx={{
                    color: styles.color,
                    marginTop: 0, // Remove any margin
                    paddingTop: 0, // Remove any padding
                    "& .Mui-disabled": {
                      background: styles.lightGreyColor
                      // color: styles.color
                    }
                  }}
                  onChange={handleCustomInputChange}
                  disabled={customDisabledFields.unit}
                >
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="grams"
                  >
                    Grams
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="ounces"
                  >
                    Ounces
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="cups"
                  >
                    Cups / Fists
                  </MenuItem>
                  {[
                    { value: "palms", label: "Palms" },
                    { value: "pounds", label: "Pounds" }
                    // { value: "mls", label: " MLs (millimeters)" }
                  ]?.map(({ value, label }) => (
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value={value}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StyledTextFieldContainer>
            <Typography
              display="flex"
              fontWeight="bold"
              justifyContent="center"
            >
              OR
            </Typography>
            <StyledTextFieldContainer
              style={{ display: "flex", gap: "20px", marginTop: "15px" }}
            >
              <StyledInputLabel styledColors={styles} htmlFor="noOfQty">
                No. of Quantity
              </StyledInputLabel>
              <StyledTextField
                styledColors={styles}
                onWheel={(e) => e.target.blur()}
                type="number"
                // min={0}
                name="noOfQty"
                // InputProps={{ inputProps: { min: 36, max: 40, step: "any" } }}
                slotProps={{
                  htmlInput: {
                    maxLength: 13,
                    step: "1"
                  }
                }}
                margin="normal"
                // htmlInput={{ min: 0 }}
                placeholder="Enter the quantity"
                value={customFoodFormData.noOfQty}
                onChange={handleCustomNoOfQtyChange}
                disabled={customDisabledFields.noOfQty}
                sx={{ width: { xs: "100%" } }}
              />
            </StyledTextFieldContainer>
            <StyledTextFieldContainer
              style={{ display: "flex", gap: "20px", marginTop: "40px" }}
            >
              <StyledInputLabel styledColors={styles} htmlFor="type">
                Pillar Label
              </StyledInputLabel>
              <FormControl
                sx={{
                  width: "100%",
                  marginTop: "15px",
                  svg: {
                    fill: styles.color
                  },
                  ".MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: styles.modLabel
                    },
                    "&:hover fieldset": {
                      borderColor: styles.modLabel
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: styles.disabledColor
                    }
                  }
                }}
              >
                <Select
                  labelId="unit-label"
                  name="type"
                  value={customFoodFormData.type}
                  // disabled={editData?.name}
                  MenuProps={{
                    MenuListProps: {
                      sx: {
                        padding: 0,
                        ".Mui-selected": {
                          color: `${styles.background} !important`,
                          background: `${styles.color} !important`
                        }
                      }
                    }
                  }}
                  sx={{
                    color: styles.color,
                    marginTop: 0, // Remove any margin
                    paddingTop: 0, // Remove any padding
                    "& .Mui-disabled": {
                      background: styles.lightGreyColor,
                      color: `${styles.color} !important`
                    },
                    "& .MuiSelect-select": {
                      color: `${styles.color} !important`,
                      "-webkit-text-fill-color": `${styles.color} !important`
                    }
                  }}
                  onChange={(e) => {
                    handleCustomInputChange(e);
                  }}
                >
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="800g"
                  >
                    800g
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="protein"
                  >
                    Protein
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="other"
                  >
                    Other
                  </MenuItem>
                </Select>
              </FormControl>
            </StyledTextFieldContainer>
            <StyledTextFieldContainer
              style={{ display: "flex", gap: "20px", marginTop: "40px" }}
            >
              <StyledInputLabel styledColors={styles} htmlFor="nutrient">
                Three Pillars Method
              </StyledInputLabel>
              <Box
                mt={2}
                width="100%"
                // height={64}
                borderRadius={2}
                display="flex"
                gap={isMobile ? "10px" : "28px"}
              >
                <Box
                  width={"33%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  display="flex"
                  gap={1}
                  sx={{
                    background:
                      currentTheme === "light"
                        ? "#FEECE6"
                        : styles.modBackgroundBlocks,
                    padding: "14px 50px"
                  }}
                  borderRadius="8px"
                >
                  <AppleIcon />
                  <Typography
                    fontSize="12px"
                    display="flex"
                    justifyContent="center"
                    fontWeight={400}
                    color={styles.inputLabelColor}
                  >
                    800g
                  </Typography>
                  <StyledTextFieldContainer
                    style={{
                      display: "flex",
                      gap: "20px",
                      margin: 0.5
                    }}
                  >
                    <StyledTextField
                      styledColors={styles}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      // disabled={customFoodFormData.type !== "800g"}
                      name="details.serving.metric_serving_amount"
                      slotProps={{
                        htmlInput: {
                          maxLength: 13,
                          step: "1"
                        }
                      }}
                      margin="normal"
                      value={
                        customFoodFormData?.details?.serving
                          ?.metric_serving_amount
                      }
                      onChange={handleCustomFoodInputChange}
                      sx={{
                        input: {
                          color: "#F6541C",
                          textAlign: "center !important"
                        },
                        m: 0,
                        width: { xs: "100px" },
                        padding: 1.2
                      }}
                    />
                  </StyledTextFieldContainer>
                  {/* <Typography fontSize="24px" fontWeight={600} color="#F6541C">
                    {formData.type === "800g"
                      ? formatNumber(
                          (
                            selectedOptionData?.serving?.metric_serving_amount *
                            scalingFactor
                          ).toFixed(2) || 0
                        )
                      : 0}
                  </Typography> */}
                </Box>

                <Box
                  width={"33%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  display="flex"
                  gap={1}
                  sx={{
                    background:
                      currentTheme === "light"
                        ? "#D4E9FC"
                        : styles.modBackgroundBlocks,
                    padding: "14px 50px"
                  }}
                  borderRadius="8px"
                >
                  <ProteinIcon />
                  <Typography
                    fontSize="12px"
                    fontWeight={400}
                    color={styles.inputLabelColor}
                  >
                    Protein
                  </Typography>
                  {/* <Typography fontSize="24px" fontWeight={600} color="#2C75C5">
                    {formatNumber(
                      (
                        selectedOptionData?.serving?.protein * scalingFactor
                      ).toFixed(2) || 0
                    )}
                  </Typography> */}
                  <StyledTextFieldContainer
                    style={{
                      display: "flex",
                      gap: "20px",
                      margin: 0.5
                    }}
                  >
                    <StyledTextField
                      styledColors={styles}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      name="details.serving.protein"
                      slotProps={{
                        htmlInput: {
                          maxLength: 13,
                          step: "1"
                        }
                      }}
                      margin="normal"
                      value={customFoodFormData.details.serving.protein}
                      onChange={handleCustomFoodInputChange}
                      sx={{
                        input: {
                          color: "#2C75C5",
                          textAlign: "center !important"
                        },
                        m: 0,
                        width: { xs: "100px" },
                        padding: 1.2
                      }}
                    />
                  </StyledTextFieldContainer>
                </Box>
                <Box
                  width={"33%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  display="flex"
                  gap={1}
                  sx={{
                    background:
                      currentTheme === "light"
                        ? "#fff5e6"
                        : styles.modBackgroundBlocks,
                    padding: "14px 50px"
                  }}
                  borderRadius="8px"
                >
                  <CaolriesIcon />
                  <Typography
                    fontSize="12px"
                    fontWeight={400}
                    color={styles.inputLabelColor}
                  >
                    Calories
                  </Typography>
                  {/* <Typography fontSize="24px" fontWeight={600} color="#FF9431">
                    {formatNumber(
                      (
                        selectedOptionData?.serving?.calories * scalingFactor
                      ).toFixed(2) || 0
                    )}
                  </Typography> */}
                  <StyledTextFieldContainer
                    style={{
                      display: "flex",
                      gap: "20px",
                      margin: 0.5
                    }}
                  >
                    <StyledTextField
                      styledColors={styles}
                      type="number"
                      name="details.serving.calories"
                      slotProps={{
                        htmlInput: {
                          maxLength: 13,
                          step: "1"
                        }
                      }}
                      onWheel={(e) => e.target.blur()}
                      margin="normal"
                      value={customFoodFormData.details.serving.calories}
                      onChange={handleCustomFoodInputChange}
                      sx={{
                        input: {
                          color: "#FF9431",
                          textAlign: "center !important"
                        },
                        m: 0,
                        width: { xs: "100px" },
                        padding: 1.2
                      }}
                    />
                  </StyledTextFieldContainer>
                </Box>
              </Box>
            </StyledTextFieldContainer>
            <StyledTextFieldContainer
              style={{ display: "flex", gap: "20px", marginTop: "40px" }}
            >
              <StyledInputLabel styledColors={styles} htmlFor="nutrient">
                Additional Stats
              </StyledInputLabel>
              <Box width="100%" pt={2} borderRadius={1}>
                {/* Carbs Group */}
                <Box mb={2}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={0.5}
                  >
                    <Typography fontSize="13px">{"Carbohydrate"}</Typography>
                    <Box display="flex" gap="38px" alignItems={"center"}>
                      <Typography fontSize="13px">
                        <StyledTextField
                          styledColors={styles}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          name="details.serving.carbohydrate" // Dynamic name for state mapping
                          slotProps={{
                            htmlInput: {
                              maxLength: 13,
                              step: "1"
                            }
                          }}
                          margin="normal"
                          value={
                            customFoodFormData.details.serving.carbohydrate
                          }
                          onChange={handleCustomFoodInputChange} // Unified handler
                          sx={{
                            input: {
                              textAlign: "center !important",
                              padding: "2px"
                            },
                            m: 0,
                            width: { xs: "80px" }
                          }}
                        />
                      </Typography>
                      <Typography>g</Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={0.5}
                  >
                    <Typography fontSize="13px">{"Fiber"}</Typography>
                    <Box display="flex" gap="38px" alignItems={"center"}>
                      <Typography fontSize="13px">
                        <StyledTextField
                          styledColors={styles}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          name="details.serving.fiber" // Dynamic name for state mapping
                          slotProps={{
                            htmlInput: {
                              maxLength: 13,
                              step: "1"
                            }
                          }}
                          margin="normal"
                          value={customFoodFormData.details.serving.fiber}
                          onChange={handleCustomFoodInputChange} // Unified handler
                          sx={{
                            input: {
                              textAlign: "center !important",
                              padding: "2px"
                            },
                            m: 0,
                            width: { xs: "80px" }
                          }}
                        />
                      </Typography>
                      <Typography>g</Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Fats Group */}
                <Box mb={2}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={0.5}
                  >
                    <Typography fontSize="13px">{"Fat"}</Typography>
                    <Box display="flex" gap="38px" alignItems={"center"}>
                      <Typography fontSize="13px">
                        <StyledTextField
                          styledColors={styles}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          name="details.serving.fat" // Dynamic name for state mapping
                          slotProps={{
                            htmlInput: {
                              maxLength: 13,
                              step: "1"
                            }
                          }}
                          margin="normal"
                          value={customFoodFormData.details.serving.fat}
                          onChange={handleCustomFoodInputChange} // Unified handler
                          sx={{
                            input: {
                              textAlign: "center !important",
                              padding: "2px"
                            },
                            m: 0,
                            width: { xs: "80px" }
                          }}
                        />
                      </Typography>
                      <Typography>g</Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={0.5}
                  >
                    <Typography fontSize="13px">{"Saturated Fat"}</Typography>
                    <Box display="flex" gap="38px" alignItems={"center"}>
                      <Typography fontSize="13px">
                        <StyledTextField
                          styledColors={styles}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          name="details.serving.saturated_fat" // Dynamic name for state mapping
                          slotProps={{
                            htmlInput: {
                              maxLength: 13,
                              step: "1"
                            }
                          }}
                          margin="normal"
                          value={
                            customFoodFormData.details.serving.saturated_fat
                          }
                          onChange={handleCustomFoodInputChange} // Unified handler
                          sx={{
                            input: {
                              textAlign: "center !important",
                              padding: "2px"
                            },
                            m: 0,
                            width: { xs: "80px" }
                          }}
                        />
                      </Typography>
                      <Typography>g</Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Vitamins and Minerals Group */}
                <Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={0.5}
                  >
                    <Typography fontSize="13px">{"Calcium"}</Typography>
                    <Box display="flex" gap="38px" alignItems={"center"}>
                      <Typography fontSize="13px">
                        <StyledTextField
                          styledColors={styles}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          name="details.serving.calcium" // Dynamic name for state mapping
                          slotProps={{
                            htmlInput: {
                              maxLength: 13,
                              step: "1"
                            }
                          }}
                          margin="normal"
                          value={customFoodFormData.details.serving.calcium}
                          onChange={handleCustomFoodInputChange} // Unified handler
                          sx={{
                            input: {
                              textAlign: "center !important",
                              padding: "2px"
                            },
                            m: 0,
                            width: { xs: "80px" }
                          }}
                        />
                      </Typography>
                      <Typography>g</Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={0.5}
                  >
                    <Typography fontSize="13px">{"Iron"}</Typography>
                    <Box display="flex" gap="38px" alignItems={"center"}>
                      <Typography fontSize="13px">
                        <StyledTextField
                          styledColors={styles}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          name="details.serving.iron" // Dynamic name for state mapping
                          slotProps={{
                            htmlInput: {
                              maxLength: 13,
                              step: "1"
                            }
                          }}
                          margin="normal"
                          value={customFoodFormData.details.serving.iron}
                          onChange={handleCustomFoodInputChange} // Unified handler
                          sx={{
                            input: {
                              textAlign: "center !important",
                              padding: "2px"
                            },
                            m: 0,
                            width: { xs: "80px" }
                          }}
                        />
                      </Typography>
                      <Typography>g</Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={0.5}
                  >
                    <Typography fontSize="13px">{"Potassium"}</Typography>
                    <Box display="flex" gap="38px" alignItems={"center"}>
                      <Typography fontSize="13px">
                        <StyledTextField
                          styledColors={styles}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          name="details.serving.potassium" // Dynamic name for state mapping
                          slotProps={{
                            htmlInput: {
                              maxLength: 13,
                              step: "1"
                            }
                          }}
                          margin="normal"
                          value={customFoodFormData.details.serving.potassium}
                          onChange={handleCustomFoodInputChange} // Unified handler
                          sx={{
                            input: {
                              textAlign: "center !important",
                              padding: "2px"
                            },
                            m: 0,
                            width: { xs: "80px" }
                          }}
                        />
                      </Typography>
                      <Typography>g</Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={0.5}
                  >
                    <Typography fontSize="13px">{"Sodium"}</Typography>
                    <Box display="flex" gap="24.5px" alignItems={"center"}>
                      <Typography fontSize="13px">
                        <StyledTextField
                          styledColors={styles}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          name="details.serving.sodium" // Dynamic name for state mapping
                          slotProps={{
                            htmlInput: {
                              maxLength: 13,
                              step: "1"
                            }
                          }}
                          margin="normal"
                          value={customFoodFormData.details.serving.sodium}
                          onChange={handleCustomFoodInputChange} // Unified handler
                          sx={{
                            input: {
                              textAlign: "center !important",
                              padding: "2px"
                            },
                            m: 0,
                            width: { xs: "80px" }
                          }}
                        />
                      </Typography>
                      <Typography>mg</Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={0.5}
                  >
                    <Typography fontSize="13px">{"Vitamin A"}</Typography>
                    <Box display="flex" gap="16.35px" alignItems={"center"}>
                      <Typography fontSize="13px">
                        <StyledTextField
                          styledColors={styles}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          name="details.serving.vitamin_a" // Dynamic name for state mapping
                          slotProps={{
                            htmlInput: {
                              maxLength: 13,
                              step: "1"
                            }
                          }}
                          margin="normal"
                          value={customFoodFormData.details.serving.vitamin_a}
                          onChange={handleCustomFoodInputChange} // Unified handler
                          sx={{
                            input: {
                              textAlign: "center !important",
                              padding: "2px"
                            },
                            m: 0,
                            width: { xs: "80px" }
                          }}
                        />
                      </Typography>
                      <Typography>mcg</Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={0.5}
                  >
                    <Typography fontSize="13px">{"Vitamin C"}</Typography>
                    <Box display="flex" gap="26px" alignItems={"center"}>
                      <Typography fontSize="13px">
                        <StyledTextField
                          styledColors={styles}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          name="details.serving.vitamin_c" // Dynamic name for state mapping
                          slotProps={{
                            htmlInput: {
                              maxLength: 13,
                              step: "1"
                            }
                          }}
                          margin="normal"
                          value={customFoodFormData.details.serving.vitamin_c}
                          onChange={handleCustomFoodInputChange} // Unified handler
                          sx={{
                            input: {
                              textAlign: "center !important",
                              padding: "2px"
                            },
                            m: 0,
                            width: { xs: "80px" }
                          }}
                        />
                      </Typography>
                      <Typography>mg</Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={0.5}
                  >
                    <Typography fontSize="13px">{"Vitamin D"}</Typography>
                    <Box display="flex" gap="18px" alignItems={"center"}>
                      <Typography fontSize="13px">
                        <StyledTextField
                          styledColors={styles}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          name="details.serving.vitamin_d" // Dynamic name for state mapping
                          slotProps={{
                            htmlInput: {
                              maxLength: 13,
                              step: "1"
                            }
                          }}
                          margin="normal"
                          value={customFoodFormData.details.serving.vitamin_d}
                          onChange={handleCustomFoodInputChange} // Unified handler
                          sx={{
                            input: {
                              textAlign: "center !important",
                              padding: "2px"
                            },
                            m: 0,
                            width: { xs: "80px" }
                          }}
                        />
                      </Typography>
                      <Typography>mcg</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </StyledTextFieldContainer>

            <Button
              onClick={async (e) => {
                const data = {
                  isFromSuperAdmin: false,
                  name: customFoodFormData?.name,
                  quantity: customFoodFormData?.quantity,
                  noOfQty: customFoodFormData?.noOfQty,
                  unit: customFoodFormData?.unit,
                  type: customFoodFormData?.type,
                  details: {
                    ...customFoodFormData?.details,
                    serving: {
                      ...Object.fromEntries(
                        Object.entries(
                          customFoodFormData?.details?.serving || {}
                        ).map(([key, value]) => [key, value === "" ? 0 : value])
                      ),
                      metric_serving_amount: customFoodFormData?.quantity
                        ? Number(customFoodFormData.quantity)
                        : customFoodFormData?.details?.serving
                            ?.metric_serving_amount || 0
                    }
                  },
                  id: customFoodFormData?.id
                };
                await addCustomFoodItems(data);
                setIsShowingCreateCustomFood(false);
                setCustomFoodFormData({
                  name: "",
                  quantity: "",
                  noOfQty: "",
                  unit: "grams",
                  type: "800g",
                  details: {
                    serving: {
                      serving_id: String(
                        Math.floor(Math.random() * 90000) + 10000
                      ),
                      metric_serving_amount: "",
                      metric_serving_unit: "g",
                      calories: "",
                      carbohydrate: "",
                      protein: "",
                      fat: "",
                      saturated_fat: "",
                      sodium: "",
                      potassium: "",
                      fiber: "",
                      vitamin_a: "",
                      vitamin_c: "",
                      vitamin_d: "",
                      calcium: "",
                      iron: ""
                    }
                  }
                });
              }}
              fullWidth
              disabled={
                !customFoodFormData.name ||
                !customFoodFormData.details.serving.calcium ||
                !customFoodFormData.details.serving.calories ||
                (!customFoodFormData.quantity &&
                  !(
                    customFoodFormData.noOfQty &&
                    customFoodFormData.details.serving.metric_serving_amount
                  )) ||
                !customFoodFormData.details.serving.fat ||
                !customFoodFormData.details.serving.fiber ||
                !customFoodFormData.type ||
                !customFoodFormData.details.serving.protein
              }
              variant="contained"
              sx={{
                mt: 2,
                textTransform: "none",
                ":disabled": {
                  backgroundColor: "#A7C8E9",
                  borderRadius: "4px",
                  color: styles.color
                }
              }}
            >
              Submit
            </Button>
          </Box>
        ) : isAddingMealItem ? (
          !selectedOptionData ? (
            <Box
              sx={{
                width: { xs: "100%", lg: "70%" },
                ...(matches && { marginBottom: { xs: 9 } })
              }}
            >
              <FoodSearch
                selectedCustomFood={selectedCustomFood}
                setSelectedCustomFood={setSelectedCustomFood}
                setSelectedOptionData={setSelectedOptionData}
                favoriteFood={favoriteFood}
                setFavoriteFood={setFavoriteFood}
              />
              {/* <Box>
                <Typography my={3} fontWeight="bold" fontSize={"15px"}>
                  Favorite food
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      sx={{
                        border: "1px solid #333333",
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-start",
                        padding: "10px",
                        textTransform: "none"
                      }}
                      variant="outline"
                      fullWidth
                      onClick={() => setFavoriteFood("bread")}
                    >
                      <Box
                        component="img"
                        src="/images/bread.png"
                        alt="base"
                        sx={{
                          width: "38px",
                          height: "38px",
                          objectFit: "cover"
                        }}
                      />
                      <Typography fontSize="12px" fontWeight={"bold"}>
                        Bread
                      </Typography>
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Button
                      sx={{
                        border: "1px solid #333333",
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-start",
                        padding: "10px",
                        textTransform: "none"
                      }}
                      variant="outline"
                      fullWidth
                      onClick={() => setFavoriteFood("sausage")}
                    >
                      <Box
                        component="img"
                        src="/images/sausage.png"
                        alt="base"
                        sx={{
                          width: "38px",
                          height: "38px",
                          objectFit: "cover"
                        }}
                      />
                      <Typography fontSize="12px" fontWeight={"bold"}>
                        Sausage
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      sx={{
                        border: "1px solid #333333",
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-start",
                        padding: "10px",
                        textTransform: "none"
                      }}
                      onClick={() => setFavoriteFood("eggs")}
                      variant="outline"
                      fullWidth
                    >
                      <Box
                        component="img"
                        src="/images/eggs.png"
                        alt="base"
                        sx={{
                          width: "38px",
                          height: "38px",
                          objectFit: "cover"
                        }}
                      />
                      <Typography fontSize="12px" fontWeight={"bold"}>
                        Egg
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      sx={{
                        border: "1px solid #333333",
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-start",
                        padding: "10px",
                        textTransform: "none"
                      }}
                      variant="outline"
                      fullWidth
                      onClick={() => setFavoriteFood("chicken")}
                    >
                      <Box
                        component="img"
                        src="/images/chicken.png"
                        alt="base"
                        sx={{
                          width: "38px",
                          height: "38px",
                          objectFit: "cover"
                        }}
                      />
                      <Typography fontSize="12px" fontWeight={"bold"}>
                        Chicken
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box> */}
            </Box>
          ) : (
            <Box
              sx={{
                width: { xs: "100%", lg: "70%" },
                ...(matches && { marginBottom: { xs: 9 } })
              }}
              // component={"form"}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={2}
                borderRadius="8px"
                padding={"4px 12px"}
                sx={{ background: styles.lightGreyColor }}
              >
                <Typography>{selectedOptionData?.name}</Typography>
                <IconButton
                  sx={{ color: styles.modLabel }}
                  onClick={() => {
                    setSelectedOptionData(null);
                    setFavoriteFood("");
                  }}
                >
                  <EditIcon height={22} width={22} />
                </IconButton>
              </Box>

              <StyledTextFieldContainer
                style={{ display: "flex", gap: "20px", marginTop: "40px" }}
              >
                <StyledInputLabel styledColors={styles} htmlFor="quantity">
                  Amount
                </StyledInputLabel>
                <StyledTextField
                  styledColors={styles}
                  type="number"
                  // min={0}
                  name="quantity"
                  slotProps={{
                    htmlInput: {
                      maxLength: 13,
                      step: "1"
                    }
                  }}
                  onWheel={(e) => e.target.blur()}
                  margin="normal"
                  // htmlInput={{ min: 0 }}
                  placeholder="Enter the amount"
                  value={formData.quantity}
                  onChange={handleQuantityChange}
                  disabled={disabledFields.quantity}
                  sx={{ width: { xs: "100%", md: "60%" } }}
                />
                <FormControl
                  sx={{
                    width: "40%",
                    marginTop: "15px",
                    svg: {
                      fill: styles.color
                    },
                    ".MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: styles.color
                      },
                      "&:hover fieldset": {
                        borderColor: styles.color
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: styles.disabledColor
                      }
                    }
                  }}
                >
                  <Select
                    labelId="unit-label"
                    name="unit"
                    value={formData.unit}
                    MenuProps={{
                      MenuListProps: {
                        sx: {
                          padding: 0,
                          ".Mui-selected": {
                            color: `${styles.background} !important`,
                            background: `${styles.color} !important`
                          }
                        }
                      }
                    }}
                    sx={{
                      color: styles.color,
                      marginTop: 0, // Remove any margin
                      paddingTop: 0, // Remove any padding
                      "& .Mui-disabled": {
                        background: styles.lightGreyColor
                        // color: styles.color
                      }
                    }}
                    onChange={handleInputChange}
                    disabled={disabledFields.unit}
                  >
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value="grams"
                    >
                      Grams
                    </MenuItem>
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value="ounces"
                    >
                      Ounces
                    </MenuItem>
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value="cups"
                    >
                      Cups / Fists
                    </MenuItem>
                    {[
                      { value: "palms", label: "Palms" },
                      { value: "pounds", label: "Pounds" }
                      // { value: "mls", label: " MLs (millimeters)" }
                    ]?.map(({ value, label }) => (
                      <MenuItem
                        sx={{
                          backgroundColor: styles.background,
                          color: styles.color,
                          paddingY: 1,
                          "&:hover": {
                            backgroundColor: styles.color,
                            color: styles.background
                          }
                        }}
                        value={value}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </StyledTextFieldContainer>
              <Typography
                display="flex"
                fontWeight="bold"
                justifyContent="center"
              >
                OR
              </Typography>
              <StyledTextFieldContainer
                style={{ display: "flex", gap: "20px", marginTop: "15px" }}
              >
                <StyledInputLabel styledColors={styles} htmlFor="noOfQty">
                  No. of Quantity
                </StyledInputLabel>
                <StyledTextField
                  styledColors={styles}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  // min={0}
                  name="noOfQty"
                  slotProps={{
                    htmlInput: {
                      maxLength: 13,
                      step: "1"
                    }
                  }}
                  margin="normal"
                  // htmlInput={{ min: 0 }}
                  placeholder="Enter the quantity"
                  value={formData.noOfQty}
                  onChange={handleNoOfQtyChange}
                  disabled={disabledFields.noOfQty}
                  sx={{ width: { xs: "100%" } }}
                />
              </StyledTextFieldContainer>
              <StyledTextFieldContainer
                style={{ display: "flex", gap: "20px", marginTop: "40px" }}
              >
                <StyledInputLabel styledColors={styles} htmlFor="type">
                  Pillar Label
                </StyledInputLabel>
                <FormControl
                  sx={{
                    width: "100%",
                    marginTop: "15px",
                    svg: {
                      fill: styles.color
                    },
                    ".MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: styles.disabledColor
                      },
                      "&:hover fieldset": {
                        borderColor: styles.color
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: styles.color
                      }
                    }
                  }}
                >
                  <Select
                    labelId="unit-label"
                    name="type"
                    value={formData.type}
                    MenuProps={{
                      MenuListProps: {
                        sx: {
                          padding: 0,
                          ".Mui-selected": {
                            color: `${styles.background} !important`,
                            background: `${styles.color} !important`
                          }
                        }
                      }
                    }}
                    sx={{
                      color: styles.color,
                      marginTop: 0, // Remove any margin
                      paddingTop: 0 // Remove any padding
                    }}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  >
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value="800g"
                    >
                      800g
                    </MenuItem>
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value="protein"
                    >
                      Protein
                    </MenuItem>
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value="other"
                    >
                      Other
                    </MenuItem>
                  </Select>
                </FormControl>
              </StyledTextFieldContainer>
              <StyledTextFieldContainer
                style={{ display: "flex", gap: "20px", marginTop: "40px" }}
              >
                <StyledInputLabel styledColors={styles} htmlFor="nutrient">
                  Three Pillars Method
                </StyledInputLabel>
                <Box
                  mt={2}
                  width="100%"
                  // height={64}
                  borderRadius={2}
                  display="flex"
                  gap="28px"
                >
                  <Box
                    width={"33%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    display="flex"
                    gap={1}
                    sx={{
                      background:
                        currentTheme === "light"
                          ? "#FEECE6"
                          : styles.modBackgroundBlocks,
                      padding: "14px 50px"
                    }}
                    borderRadius="8px"
                  >
                    <AppleIcon />
                    <Typography
                      fontSize="12px"
                      display="flex"
                      justifyContent="center"
                      fontWeight={400}
                      color={styles.inputLabelColor}
                    >
                      800g
                    </Typography>
                    <Typography
                      fontSize="24px"
                      fontWeight={600}
                      color="#F6541C"
                    >
                      {formData.type === "800g"
                        ? formatNumber(
                            (
                              selectedOptionData?.serving
                                ?.metric_serving_amount * scalingFactor
                            ).toFixed(2) || 0
                          )
                        : 0}
                    </Typography>
                  </Box>

                  <Box
                    width={"33%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    display="flex"
                    gap={1}
                    sx={{
                      background:
                        currentTheme === "light"
                          ? "#D4E9FC"
                          : styles.modBackgroundBlocks,
                      padding: "14px 50px"
                    }}
                    borderRadius="8px"
                  >
                    <ProteinIcon />
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color={styles.inputLabelColor}
                    >
                      Protein
                    </Typography>
                    <Typography
                      fontSize="24px"
                      fontWeight={600}
                      color="#2C75C5"
                    >
                      {formatNumber(
                        (
                          selectedOptionData?.serving?.protein * scalingFactor
                        ).toFixed(2) || 0
                      )}
                    </Typography>
                  </Box>
                  <Box
                    width={"33%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    display="flex"
                    gap={1}
                    sx={{
                      background:
                        currentTheme === "light"
                          ? "#fff5e6"
                          : styles.modBackgroundBlocks,
                      padding: "14px 50px"
                    }}
                    borderRadius="8px"
                  >
                    <CaolriesIcon />
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color={styles.inputLabelColor}
                    >
                      Calories
                    </Typography>
                    <Typography
                      fontSize="24px"
                      fontWeight={600}
                      color="#FF9431"
                    >
                      {formatNumber(
                        (
                          selectedOptionData?.serving?.calories * scalingFactor
                        ).toFixed(2) || 0
                      )}
                    </Typography>
                  </Box>
                </Box>
              </StyledTextFieldContainer>
              {formattedNutrients?.length ? (
                <StyledTextFieldContainer
                  style={{ display: "flex", gap: "20px", marginTop: "40px" }}
                >
                  <StyledInputLabel styledColors={styles} htmlFor="nutrient">
                    Additional Stats
                  </StyledInputLabel>
                  {/* <Box
                    width="100%"
                    display={"flex"}
                    justifyContent={"space-between"}
                    flexDirection="column"
                    alignItems={"center"}
                    mt={2}
                    gap={1}
                    borderRadius="8px"
                    padding={"8px 12px"}
                    sx={{ background: styles.lightGreyColor }}
                  >
                    {formattedNutrients?.map(({ name, value, unit }) => (
                      <Box
                        width="100%"
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography fontSize="13px">{name}</Typography>
                        <Typography fontSize="13px">
                          {formatNumber(value || 0) + unit}
                        </Typography>
                      </Box>
                    ))}
                  </Box> */}
                  <Box width="100%" pt={2} borderRadius={1}>
                    {/* Carbs Group */}
                    <Box mb={2}>
                      <NutrientRow name="Carbohydrate" unit="g" />
                      <NutrientRow name="Fiber" unit="g" />
                    </Box>

                    {/* Fats Group */}
                    <Box mb={2}>
                      <NutrientRow name="Fat" unit="g" />
                      <NutrientRow name="Saturated Fat" unit="g" />
                    </Box>

                    {/* Vitamins and Minerals Group */}
                    <Box>
                      <NutrientRow name="Calcium" unit="mg" />
                      <NutrientRow name="Iron" unit="mg" />
                      <NutrientRow name="Potassium" unit="mg" />
                      <NutrientRow name="Sodium" unit="mg" />
                      <NutrientRow name="Vitamin A" unit="mcg" />
                      <NutrientRow name="Vitamin C" unit="mg" />
                      <NutrientRow name="Vitamin D" unit="mcg" />
                    </Box>
                  </Box>
                </StyledTextFieldContainer>
              ) : (
                <></>
              )}
              <Button
                onClick={async (e) => {
                  const data = {
                    id: type === "meal" ? currentMeal?._id : currentRecipe?._id,
                    type,
                    items: {
                      quantity: formData.quantity,
                      unit: formData.unit,
                      noOfQty: formData.noOfQty,
                      type: formData.type,
                      name: selectedOptionData?.name,
                      details: selectedOptionData
                    }
                  };
                  await addMealItems(data);
                  setIsAddingMealItem(false);
                  setSelectedOptionData(null);
                  setFormData({
                    ...formData,
                    quantity: "",
                    unit: "grams",
                    type: "800g",
                    noOfQty: ""
                  });
                  setDisabledFields({
                    quantity: false,
                    noOfQty: false,
                    unit: false
                  });
                  type === "meal"
                    ? await dispatch(gettingMealData(currentMeal?._id))
                    : await dispatch(gettingRecipeData(currentRecipe?._id));
                }}
                fullWidth
                disabled={
                  !(
                    ((formData.quantity && !formData.noOfQty) ||
                      (!formData.quantity && formData.noOfQty)) &&
                    formData.unit &&
                    formData.type
                  )
                }
                variant="contained"
                sx={{
                  mt: 2,
                  textTransform: "none",
                  ":disabled": {
                    backgroundColor: "#A7C8E9",
                    borderRadius: "4px",
                    color: styles.color
                  }
                }}
              >
                Submit
              </Button>
            </Box>
          )
        ) : (
          <Box
            sx={{
              width: { xs: "100%" },
              ...(matches && { marginBottom: { xs: 9 } })
            }}
          >
            {type === "meal" ? (
              <StyledTextFieldContainer>
                <StyledInputLabel styledColors={styles} htmlFor="mealName">
                  Meal Name
                </StyledInputLabel>
                <StyledTextField
                  styledColors={styles}
                  fullWidth
                  variant="outlined"
                  type={"text"}
                  name="mealName"
                  placeholder="Enter meal name"
                  value={formData.mealName}
                  onChange={handleInputChange}
                  margin="normal"
                />
              </StyledTextFieldContainer>
            ) : (
              <>
                <StyledTextFieldContainer>
                  <StyledInputLabel styledColors={styles} htmlFor="recipeName">
                    Recipe name
                  </StyledInputLabel>
                  <StyledTextField
                    styledColors={styles}
                    fullWidth
                    variant="outlined"
                    type={"text"}
                    name="recipeName"
                    placeholder="Enter recipe name"
                    value={formData.recipeName}
                    onChange={handleInputChange}
                    margin="normal"
                  />
                </StyledTextFieldContainer>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                  sx={{
                    backgroundColor: styles.lightGreyColor, // Black background
                    borderRadius: "8px",
                    overflow: "hidden",
                    width: "100%",
                    margin: "0 0 25px 0"
                  }}
                >
                  <ToggleButton
                    value="service"
                    aria-label="service based"
                    sx={{
                      textTransform: "none",
                      color: styles.color,
                      flex: 1,
                      "&.Mui-selected": {
                        color: styles.color,
                        backgroundColor: "#368AD0",
                        ":hover": {
                          backgroundColor: "#368AD0"
                        }
                      }
                    }}
                  >
                    Servings based
                  </ToggleButton>
                  <ToggleButton
                    value="weight"
                    aria-label="weight based"
                    sx={{
                      flex: 1,
                      textTransform: "none",
                      color: styles.color,
                      "&.Mui-selected": {
                        color: styles.color,
                        backgroundColor: "#368AD0",
                        ":hover": {
                          backgroundColor: "#368AD0"
                        }
                      }
                    }}
                  >
                    Weight based
                  </ToggleButton>
                </ToggleButtonGroup>
                <StyledTextFieldContainer style={{ marginTop: 22 }}>
                  <StyledInputLabel
                    styledColors={styles}
                    htmlFor="servicingPerRecipe"
                  >
                    {alignment === "service" ? "Servings" : "Weights"} per
                    recipe
                  </StyledInputLabel>
                  <StyledTextField
                    styledColors={styles}
                    type="number"
                    min={0}
                    name="servicingPerRecipe"
                    margin="normal"
                    htmlInput={{ min: 0 }}
                    placeholder="Enter the amount of servings"
                    value={formData.servicingPerRecipe}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value) && Number(value) >= 0) {
                        handleInputChange(e);
                      }
                    }}
                    sx={{ width: { xs: "100%" } }}
                  />
                </StyledTextFieldContainer>
              </>
            )}
            <Snackbar
              open={openSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={2000}
              onClose={() => setOpenSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenSnackbar(false)}
                severity={
                  snackbarMessage?.includes("Success") ||
                  snackbarMessage?.includes("success")
                    ? "success"
                    : "error"
                }
                variant="filled"
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
            <Button
              variant="outlined"
              onClick={async () => {
                try {
                  if (type === "meal") {
                    if (!formData?.mealName) {
                      setSnackbarMessage("Meal name is required");
                      setOpenSnackbar(true);
                      return;
                    }
                    if (!currentMeal?._id) {
                      const data = await addMeal(formData?.mealName);
                      await dispatch(updateAddingMeal({ meal: data.meal }));
                    }
                  } else {
                    if (!formData?.recipeName) {
                      setSnackbarMessage("Recipe name is required");
                      setOpenSnackbar(true);
                      return;
                    }
                    if (!currentRecipe?._id) {
                      const data = await addRecipe({
                        recipeName: formData?.recipeName,
                        servicing_per_recipe: +formData?.servicingPerRecipe,
                        recipeType: alignment
                      });
                      await dispatch(
                        updateAddingRecipe({ recipe: data.recipe })
                      );
                    }
                  }
                  setIsAddingMealItem(true);
                } catch (error) {
                  console.log("Error adding meal", error);
                  setSnackbarMessage(error.message);
                  setOpenSnackbar(true);
                }
              }}
              sx={{
                textTransform: "none",
                color: "#368AD0",
                width: "100%",
                borderColor: "#368AD0",
                "&:hover": { backgroundColor: "#368AD0", color: "#fff" },
                borderRadius: "8px",
                padding: "8px 16px"
              }}
            >
              <Add /> Add Meal Item
            </Button>
            {updatedMealAccordingQuantity?.length ? (
              <>
                <Box
                  gap="28px"
                  borderRadius="16px"
                  mt={3}
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  sx={{ background: styles.lightDiaryTracker }}
                  justifyContent="center"
                >
                  <Paper
                    sx={{
                      boxShadow: "none",
                      width: "100%",
                      background: "transparent",
                      color: styles.color
                    }}
                  >
                    <List sx={{ width: "100%" }}>
                      {updatedMealAccordingQuantity?.map((item, index) => (
                        <React.Fragment key={item.food_id}>
                          <ListItem
                            sx={{ py: 0, border: 0 }}
                            secondaryAction={
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                {item?.type === "800g" ? (
                                  <Typography
                                    justifyContent="center"
                                    alignItems="center"
                                    gap="4px"
                                    display="flex"
                                    variant="body2"
                                    fontWeight="bold"
                                    color={styles.color}
                                  >
                                    <Box
                                      height="8px"
                                      width="8px"
                                      sx={{ background: "#F6541C" }}
                                      borderRadius="50%"
                                    />
                                    {formatNumber(
                                      item.details?.serving
                                        ?.metric_serving_amount || 0
                                    )}
                                    g
                                  </Typography>
                                ) : (
                                  <></>
                                )}
                                <Typography
                                  justifyContent="center"
                                  alignItems="center"
                                  gap="4px"
                                  display="flex"
                                  variant="body2"
                                  fontWeight="bold"
                                  color={styles.color}
                                >
                                  <Box
                                    height="8px"
                                    width="8px"
                                    sx={{ background: "#368AD0" }}
                                    borderRadius="50%"
                                  />
                                  {formatNumber(
                                    item.details?.serving?.protein || 0
                                  )}
                                  g
                                </Typography>
                                <Typography
                                  justifyContent="center"
                                  alignItems="center"
                                  gap="4px"
                                  display="flex"
                                  variant="body2"
                                  fontWeight="bold"
                                  color={styles.color}
                                >
                                  <Box
                                    height="8px"
                                    width="8px"
                                    sx={{ background: "#FF9431" }}
                                    borderRadius="50%"
                                  />
                                  {formatNumber(
                                    item.details?.serving?.calories || 0
                                  )}
                                </Typography>
                                <LongMenu
                                  onClickHandler={async (actionType) => {
                                    // setEditIngredientData(item);
                                    if (actionType === "edit") {
                                      setOpenEditMealItemModal(item);
                                    } else {
                                      await deleteMealItem(
                                        type === "meal"
                                          ? currentMeal?._id
                                          : currentRecipe?._id,
                                        item?._id,
                                        type
                                      );
                                      type === "meal"
                                        ? await dispatch(
                                            gettingMealData(currentMeal?._id)
                                          )
                                        : await dispatch(
                                            gettingRecipeData(
                                              currentRecipe?._id
                                            )
                                          );
                                    }
                                  }}
                                />
                              </Stack>
                            }
                          >
                            <ListItemText
                              primary={
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Tooltip title={item?.name?.trim()}>
                                    <Typography
                                      sx={{
                                        cursor: "pointer",
                                        display: "block", // Ensures the container behaves as a block element
                                        maxWidth: isMobile ? "90px" : "180px", // Set your fixed width (adjust as needed)
                                        overflow: "hidden", // Hide overflow content
                                        textOverflow: "ellipsis", // Show ellipsis when text overflows
                                        whiteSpace: "nowrap" // Prevent text from wrapping
                                      }}
                                      variant="caption"
                                    >
                                      {item?.name?.trim()}
                                    </Typography>
                                  </Tooltip>
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "gray" }}
                                  >
                                    (
                                    {item.noOfQty
                                      ? `${item.noOfQty}pcs`
                                      : `${item.quantity}${item.unit}`}
                                    )
                                  </Typography>
                                </Stack>
                              }
                              secondary={
                                <Typography
                                  variant="caption"
                                  textTransform={"capitalize"}
                                  sx={{
                                    bgcolor:
                                      item.type === "protein"
                                        ? "#2196f3"
                                        : item.type === "800g"
                                        ? "#F54103"
                                        : "#FF9431",
                                    px: 1,
                                    py: 0.4,
                                    borderRadius: 100
                                  }}
                                >
                                  {item.type}
                                  {/* === "other"
                                    ? "Calories"
                                    : item.type} */}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </React.Fragment>
                      ))}
                    </List>
                  </Paper>
                  <Box sx={{ width: "90%", m: 2 }}>
                    {" "}
                    {/* Add margin here */}
                    <Button
                      fullWidth
                      onClick={async () => {
                        await editMealItem({
                          addType: type,
                          id:
                            type === "meal"
                              ? currentMeal?._id
                              : currentRecipe?._id,
                          items: []
                        });
                        type === "meal"
                          ? await dispatch(gettingMealData(currentMeal?._id))
                          : await dispatch(
                              gettingRecipeData(currentRecipe?._id)
                            );
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        p: "2px 40px",
                        color: styles.color,
                        border: `1px solid ${styles.color}`,
                        cursor: "pointer",
                        textTransform: "none"
                      }}
                    >
                      <IconButton
                        sx={{
                          color: styles.textColorDiary,
                          height: "17px",
                          width: "17px",
                          padding: 0,
                          ":disabled": {
                            color: styles.textColorDiary
                          }
                        }}
                        disabled
                      >
                        <Add color={styles.textColorDiary} fontSize="12px" />
                      </IconButton>
                      <Typography color={styles.textColorDiary} fontSize="13px">
                        Clear all
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                <Typography fontSize="13px" variant="h6" sx={{ mt: 2 }}>
                  Totals
                </Typography>
                <Box
                  gap="28px"
                  borderRadius="16px"
                  p="12px 60px"
                  mt={0.5}
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  sx={{ background: styles.lightDiaryTracker }}
                  justifyContent="center"
                >
                  {/* Other nutrients summary */}

                  <Paper
                    sx={{
                      boxShadow: "none",
                      width: "100%",
                      background: "transparent",
                      color: styles.color
                    }}
                  >
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        justifyContent="center"
                        alignItems="center"
                        gap="4px"
                        display="flex"
                        variant="body2"
                        fontWeight="bold"
                        color={styles.color}
                      >
                        <Box
                          height="8px"
                          width="8px"
                          sx={{ background: "#F6541C" }}
                          borderRadius="50%"
                        />
                        {formatNumber(totals?.metric_serving_amount || 0)}g
                      </Typography>

                      <Typography
                        justifyContent="center"
                        alignItems="center"
                        gap="4px"
                        display="flex"
                        variant="body2"
                        fontWeight="bold"
                        color={styles.color}
                      >
                        <Box
                          height="8px"
                          width="8px"
                          sx={{ background: "#368AD0" }}
                          borderRadius="50%"
                        />
                        {formatNumber(totals?.protein || 0)}g
                      </Typography>
                      <Typography
                        justifyContent="center"
                        alignItems="center"
                        gap="4px"
                        display="flex"
                        variant="body2"
                        fontWeight="bold"
                        color={styles.color}
                      >
                        <Box
                          height="8px"
                          width="8px"
                          sx={{ background: "#FF9431" }}
                          borderRadius="50%"
                        />
                        {formatNumber(totals?.calories || 0)}
                      </Typography>
                    </Stack>
                  </Paper>
                </Box>
                <Typography fontSize="13px" variant="h6" sx={{ mt: 2 }}>
                  Other Nutrients
                </Typography>
                <Box
                  gap="28px"
                  borderRadius="16px"
                  p="12px"
                  mt={0.5}
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  sx={{ background: styles.lightDiaryTracker }}
                  justifyContent="center"
                >
                  {/* Other nutrients summary */}

                  <Paper
                    sx={{
                      boxShadow: "none",
                      width: "100%",
                      background: "transparent",
                      color: styles.color
                    }}
                  >
                    <Box width="100%" borderRadius={1}>
                      {/* Carbs Group */}
                      <Box mb={2}>
                        <NutrientRow name="Carbohydrate" unit="g" isTotal />
                        <NutrientRow name="Fiber" unit="g" isTotal />
                      </Box>

                      {/* Fats Group */}
                      <Box mb={2}>
                        <NutrientRow name="Fat" unit="g" isTotal />
                        <NutrientRow name="Saturated Fat" unit="g" isTotal />
                      </Box>

                      {/* Vitamins and Minerals Group */}
                      <Box>
                        <NutrientRow name="Calcium" unit="mg" isTotal />
                        <NutrientRow name="Iron" unit="mg" isTotal />
                        <NutrientRow name="Potassium" unit="mg" isTotal />
                        <NutrientRow name="Sodium" unit="mg" isTotal />
                        <NutrientRow name="Vitamin A" unit="mcg" isTotal />
                        <NutrientRow name="Vitamin C" unit="mg" isTotal />
                        <NutrientRow name="Vitamin D" unit="mcg" isTotal />
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </>
            ) : (
              <Box
                height={"250px"}
                gap="28px"
                borderRadius="16px"
                p="20px"
                mt={3}
                alignItems="center"
                display="flex"
                flexDirection="column"
                sx={{ background: styles.lightDiaryTracker }}
                justifyContent="center"
              >
                <NoEntry />
                <Typography color="#6B7280">No entry</Typography>
              </Box>
            )}
            <Button
              type="submit"
              variant="outline"
              disabled={!isFormValid}
              color="primary"
              onClick={async (e) => {
                if (type === "meal") {
                  await saveMeal({
                    mealId: currentMeal?._id,
                    mealName: formData?.mealName
                  });
                  dispatch(updateAddingMeal({ meal: {} }));
                  setIsShowingCreateMeal(false);
                } else {
                  await saveRecipe({
                    recipeId: currentRecipe?._id,
                    recipeName: formData?.recipeName,
                    recipeType: alignment,
                    servicing_per_recipe: formData?.servicingPerRecipe
                  });
                  dispatch(updateAddingRecipe({ recipe: {} }));
                  setIsShowingCreateRecipe(false);
                }
                setFormData({
                  mealName: "",
                  recipeName: "",
                  servicingPerRecipe: "",
                  quantity: "",
                  noOfQty: "",
                  unit: "grams",
                  type: "800g"
                });
                setDisabledFields({
                  quantity: false,
                  noOfQty: false,
                  unit: false
                });
              }}
              sx={{
                marginTop: 3.1,
                backgroundColor: "#368AD0",
                cursor: "pointer",
                color: "#fff",
                width: "100%",
                height: "42px",
                fontWeight: "bold",
                textTransform: "none",
                ":disabled": {
                  backgroundColor: "#A7C8E9",
                  color: "#64AEDD"
                }
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        )}
        <FoodEditModal
          onClose={() => setOpenEditMealItemModal(null)}
          open={openEditMealItemModal?._id}
          foodItem={openEditMealItemModal}
          onSubmit={async (formData) => {
            await editMealItem({
              ...formData,
              addType: type,
              id: type === "meal" ? currentMeal?._id : currentRecipe?._id,
              itemId: openEditMealItemModal?._id
            });
            type === "meal"
              ? await dispatch(gettingMealData(currentMeal?._id))
              : await dispatch(gettingRecipeData(currentRecipe?._id));
          }}
        />
      </Box>
    </Box>
  );
};

export default AddMealForLevel3;
