import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ReactComponent as TrophyIcon } from "../../assets/trophy.svg";
import { ReactComponent as StatsIcon } from "../../assets/stats.svg";
import { ReactComponent as MealIcon } from "../../assets/meal.svg";
import { ReactComponent as RecipeIcon } from "../../assets/recipe.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Typography } from "@mui/material";
import useThemeColor from "../../utils/useTheme";

export default function ThreeDotMenu({ onClickHandler, isLevel3 = false }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const styles = useThemeColor();

  return (
    <Box>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon
          sx={{
            width: 20,
            height: 20,
            color: "#8E8E8E"
          }}
        />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              background: styles.background,
              color: styles.color
            }
          }
        }}
      >
        <MenuItem
          sx={{ fontSize: "14px" }}
          onClick={() => {
            onClickHandler("stats");
            handleClose();
          }}
        >
          <Box display="flex" alignItems={"center"} gap="10px">
            <StatsIcon
              style={{
                width: 16,
                height: 16
              }}
            />
            <Typography fontSize="14px">View Stats</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ fontSize: "14px" }}
          onClick={() => {
            onClickHandler("leaderboard");
            handleClose();
          }}
        >
          <Box display="flex" alignItems={"center"} gap="10px">
            <TrophyIcon
              style={{
                width: 16,
                height: 16
              }}
            />
            <Typography fontSize="14px">Leaderboard</Typography>
          </Box>
        </MenuItem>
        {isLevel3 ? (
          <>
            <MenuItem
              sx={{ fontSize: "14px" }}
              onClick={() => {
                onClickHandler("createMeal");
                handleClose();
              }}
            >
              <Box display="flex" alignItems={"center"} gap="10px">
                <MealIcon
                  style={{
                    width: 16,
                    height: 16
                  }}
                />
                <Typography fontSize="14px">Create Meal</Typography>
              </Box>
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "14px" }}
              onClick={() => {
                onClickHandler("createRecipe");
                handleClose();
              }}
            >
              <Box display="flex" alignItems={"center"} gap="10px">
                <RecipeIcon
                  style={{
                    width: 16,
                    height: 16
                  }}
                />
                <Typography fontSize="14px">Create Recipe</Typography>
              </Box>
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "14px" }}
              onClick={() => {
                onClickHandler("addCustomFoods");
                handleClose();
              }}
            >
              <Box display="flex" alignItems={"center"} gap="10px">
                <Box
                  component="img"
                  src="/images/custom.png"
                  alt="base"
                  sx={{
                    height: 16,
                    width: 16,
                    objectFit: "cover"
                  }}
                />
                <Typography fontSize="14px">Custom Foods</Typography>
              </Box>
            </MenuItem>
          </>
        ) : (
          <></>
        )}
      </Menu>
    </Box>
  );
}
