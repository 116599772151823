import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import {
  TextField,
  Typography,
  Box,
  useMediaQuery,
  Paper,
  InputAdornment,
  Divider,
  Button
} from "@mui/material";
import useThemeColor from "../../utils/useTheme";
import { useTheme } from "@emotion/react";
import {
  selectAllMealOrRecipeData,
  selectCurrentAddingMeal,
  selectCurrentAddingRecipe
} from "../../features/level3/level3Selectors";
import formatNumber from "../../utils/formatNumber";
import { deleteCustomFoodItem, deleteMealRecipe } from "../../api/level3Api";
import {
  getAllMealOrRecipeData,
  gettingMealData,
  gettingRecipeData,
  updateAddingMeal,
  updateAddingRecipe
} from "../../features/level3/level3Slice";
import { convertItemQuantityToGrams } from "../../utils/weightConvert";
import LongMenu from "./ThreeDotMenu";
import DeleteUserModal from "../Modals/DeleteUserModal";
import ServicingRecipeModal from "../Modals/ServicingRecipeModal";
import { addMealOrRecipeToDiary } from "../../api/diaryApi";
import dayjs from "dayjs";
import { getCurrentUserDiaries } from "../../features/diary/diarySlice";

const excludedFields = [
  "serving_id",
  "serving_description",
  "serving_url",
  "metric_serving_unit"
];

const processMeals = (meals, searchValue, type) => {
  return meals
    .map((meal) => {
      const total = meal.items.reduce(
        (acc, item) => {
          if (item.type === "800g" || type === "custom food")
            acc.metric_serving_amount += parseFloat(
              item.details.serving.metric_serving_amount
            );
          acc.calories += parseFloat(item.details.serving.calories);
          acc.protein += parseFloat(item.details.serving.protein);
          return acc;
        },
        { metric_serving_amount: 0, calories: 0, protein: 0 }
      );

      return {
        _id: meal._id,
        name: meal.name,
        servicing_per_recipe: meal.servicing_per_recipe,
        recipeType: meal.recipeType,
        metric_serving_amount: total.metric_serving_amount.toFixed(2),
        metric_serving_unit: "g",
        calories: total.calories.toFixed(2),
        protein: total.protein.toFixed(2),
        ...meal
      };
    })
    .filter((item) =>
      searchValue
        ? item.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
        : Boolean(item.name)
    );
};
const AddMealOrRecipeToDiary = ({
  levelId,
  activeDay,
  currentLevel,
  type,
  setEditMealToDiary,
  setIsShowingCreateMeal,
  setIsRefreshNeeded,
  setIsShowingCreateRecipe,
  setEditRecipeToDiary,
  customFoodFormData,
  setCustomFoodFormData,
  setIsShowingCreateCustomFood,
  setEditCustomFoodToDiary
}) => {
  const [searchValue, setSearchValue] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteCustomFoodItemId, setDeleteCustomFoodItemId] = useState("");
  const styles = useThemeColor();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const allMealOrRecipeData = useSelector(selectAllMealOrRecipeData);

  useEffect(() => {
    dispatch(
      getAllMealOrRecipeData(type === "custom food" ? "customFood" : type)
    );
  }, [type]);

  const [openServicingRecipeModal, setOpenServicingRecipeModal] =
    useState(false);

  const updatedMealAccordingQuantity = useMemo(() => {
    if (type === "custom food") return allMealOrRecipeData;
    return allMealOrRecipeData?.map((singleItem) => {
      const arr = singleItem?.items?.map((item) => {
        const details = item.details;
        const metricServingAmount = parseFloat(
          details.serving.metric_serving_amount
        );
        const scalingFactor =
          convertItemQuantityToGrams(item).quantity / metricServingAmount;
        const updatedDetails = Object.entries(details.serving).reduce(
          (acc, [key, value]) => {
            if (!excludedFields.includes(key) && !isNaN(Number(value))) {
              acc[key] = (value * scalingFactor).toFixed(2);
            }
            return acc;
          },
          {}
        );
        return {
          ...item,
          details: {
            ...item.details,
            serving: { ...item.details.serving, ...updatedDetails }
          }
        };
      });
      return {
        ...singleItem,
        items: arr
      };
    });
  }, [allMealOrRecipeData]);

  const dispatch = useDispatch();
  const currentTime = dayjs().format("hh:mm A");

  return (
    <Box
      color={styles.color}
      backgroundColor={styles.background}
      display={"flex"}
      width="100%"
      overflow="auto"
      flexDirection="column"
    >
      <Box
        flexGrow={1}
        paddingY={3}
        paddingLeft={10}
        sx={{
          paddingTop: { xs: 2, md: 2 },
          paddingRight: { xs: 2 },
          paddingBottom: { xs: 8, md: 2 },
          paddingLeft: { xs: 2, md: 7 },
          overflowY: { md: "auto" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        }}
      >
        <Box
          gap="20px"
          justifyContent="space-between"
          sx={{
            // width: isMobile ? "100%" : "70%",
            alignItems: isMobile ? "start" : "center",
            display: isMobile ? "block" : "flex",
            flexDirection: isMobile ? "column" : "row"
          }}
        >
          <TextField
            variant="outlined" // You can change this to "filled" or "standard" as needed
            placeholder={`Search ${type}...`}
            size="small" // Adjust size if needed
            fullWidth // Makes the search bar take full width
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ height: "17px", color: "#4B5563" }} />
                  </InputAdornment>
                )
              }
            }}
            sx={{
              width: { xs: "100%", md: "70%" },
              "& .MuiInputBase-root": {
                padding: "14px 11px",
                height: "100%",
                //   width: "387px",
                borderRadius: 2,
                background: styles.trackerBackground,
                color: "#6B7280",
                "& fieldset": {
                  borderColor: "#358ad0 !important"
                }
              },
              "& .Mui-focused": {
                "& fieldset": {
                  borderColor: "#358ad0 !important"
                }
              },
              height: "48px",
              // width: !isMobile ? "387px" : "unset",
              fontSize: "14px",
              background: styles.background, // Optional: set background color
              borderRadius: 2 // Optional: round corners
            }}
          />
          <Button
            onClick={() => {
              if (type === "recipe") {
                setIsShowingCreateRecipe(true);
                setEditRecipeToDiary(false);
                dispatch(updateAddingRecipe({ recipe: {} }));
              } else if (type === "meal") {
                setIsShowingCreateMeal(true);
                setEditMealToDiary(false);
                dispatch(updateAddingMeal({ meal: {} }));
              } else {
                setIsShowingCreateCustomFood(true);
                setEditCustomFoodToDiary(false);
                setCustomFoodFormData({
                  name: "",
                  quantity: "",
                  noOfQty: "",
                  unit: "grams",
                  type: "800g",
                  details: {
                    serving: {
                      serving_id: String(
                        Math.floor(Math.random() * 90000) + 10000
                      ),
                      metric_serving_amount: "",
                      metric_serving_unit: "g",
                      calories: "",
                      carbohydrate: "",
                      protein: "",
                      fat: "",
                      saturated_fat: "",
                      sodium: "",
                      potassium: "",
                      fiber: "",
                      vitamin_a: "",
                      vitamin_c: "",
                      vitamin_d: "",
                      calcium: "",
                      iron: ""
                    }
                  }
                });
              }
            }}
            sx={{
              mt: isMobile ? 2 : 0,
              textTransform: "none",
              background: "#368AD0",
              color: "#fcfcfc",
              height: "40px",
              fontWeight: 600,
              width: { md: "190px", xs: "100%" },
              padding: "12px 0",
              gap: "8px",
              borderRadius: "4px"
            }}
          >
            Create new {type}
          </Button>
        </Box>
        <Box mt={3}>
          {processMeals(updatedMealAccordingQuantity, searchValue, type)
            ?.length ? (
            processMeals(updatedMealAccordingQuantity, searchValue, type)?.map(
              (item) => (
                <>
                  <Paper
                    onClick={async () => {
                      // if (type === "custom food") return;
                      if (type === "recipe") setOpenServicingRecipeModal(item);
                      else {
                        await addMealOrRecipeToDiary({
                          levelId,
                          type: type === "custom food" ? "customFood" : type,
                          mealOrRecipeId: item._id,
                          currentDayDate: activeDay,
                          time: currentTime
                        });
                        type === "custom food"
                          ? setEditCustomFoodToDiary(false)
                          : setEditMealToDiary(false);
                        setIsRefreshNeeded(true);
                        setTimeout(() => {
                          dispatch(
                            getCurrentUserDiaries({
                              levelId
                            })
                          );
                        }, 2000);
                        localStorage.setItem(
                          "lastDiaryVisitedLevel",
                          JSON.stringify({
                            level: currentLevel?.levelName,
                            activeDay
                          })
                        );
                      }
                    }}
                    key={item.id}
                    sx={{
                      boxShadow: "none",
                      background: "transparent",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 2,
                      color: styles.color,
                      cursor: "pointer"
                    }}
                  >
                    <Box>
                      <Typography fontSize={"15px"}>{item.name}</Typography>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1.1}
                        width="140px"
                      >
                        <Typography
                          justifyContent="center"
                          alignItems="center"
                          gap="4px"
                          display="flex"
                          fontSize="13px"
                          fontWeight="bold"
                          color={styles.color}
                        >
                          <Box
                            height="8px"
                            width="8px"
                            sx={{ background: "#F6541C" }}
                            borderRadius="50%"
                          />
                          {formatNumber(item.metric_serving_amount || 0)}g
                        </Typography>
                        <Typography
                          justifyContent="center"
                          alignItems="center"
                          gap="4px"
                          display="flex"
                          fontSize="13px"
                          fontWeight="bold"
                          color={styles.color}
                        >
                          <Box
                            height="8px"
                            width="8px"
                            sx={{ background: "#368AD0" }}
                            borderRadius="50%"
                          />
                          {formatNumber(item.protein || 0)}g
                        </Typography>
                        <Typography
                          justifyContent="center"
                          alignItems="center"
                          gap="4px"
                          display="flex"
                          fontSize="13px"
                          fontWeight="bold"
                          color={styles.color}
                        >
                          <Box
                            height="8px"
                            width="8px"
                            sx={{ background: "#FF9431" }}
                            borderRadius="50%"
                          />
                          {Math.round(formatNumber(item.calories || 0))}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <LongMenu
                        onClickHandler={async (actionType) => {
                          // setEditIngredientData(item);
                          if (actionType === "edit") {
                            // setOpenEditMealItemModal(item);
                            if (type === "meal") {
                              setEditMealToDiary(false);
                              await dispatch(gettingMealData(item._id));
                              setIsShowingCreateMeal(true);
                            } else if (type === "recipe") {
                              setEditRecipeToDiary(false);
                              await dispatch(gettingRecipeData(item._id));
                              setIsShowingCreateRecipe(true);
                            } else {
                              setEditCustomFoodToDiary(false);
                              setCustomFoodFormData({
                                id: item?._id,
                                ...item?.items[0]
                              });
                              setIsShowingCreateCustomFood(true);
                            }
                          } else {
                            setDeleteCustomFoodItemId(item?._id);
                            setOpenDeleteModal(true);
                          }
                        }}
                      />
                    </Box>
                  </Paper>
                  <Divider sx={{ margin: "0 16px", background: "#333333" }} />
                  <DeleteUserModal
                    open={openDeleteModal}
                    handleClose={() => setOpenDeleteModal(false)}
                    handleDelete={async () => {
                      if (type !== "custom food") {
                        await deleteMealRecipe(
                          type === "meal" ? item?._id : item?._id,
                          type
                        );
                      } else {
                        await deleteCustomFoodItem(deleteCustomFoodItemId);
                      }
                      await dispatch(
                        getAllMealOrRecipeData(
                          type === "custom food" ? "customFood" : type
                        )
                      );
                      setOpenDeleteModal(false);
                    }}
                  />
                </>
              )
            )
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <ServicingRecipeModal
        open={openServicingRecipeModal}
        setOpen={setOpenServicingRecipeModal}
        handleSubmit={async (e, amount) => {
          e.preventDefault();
          e.stopPropagation();
          await addMealOrRecipeToDiary({
            levelId,
            type,
            mealOrRecipeId: openServicingRecipeModal._id,
            currentDayDate: activeDay,
            time: currentTime,
            totalConsumed: amount
          });
          setEditRecipeToDiary(false);
          setIsRefreshNeeded(true);
          setTimeout(() => {
            dispatch(
              getCurrentUserDiaries({
                levelId
              })
            );
          }, 2000);
          localStorage.setItem(
            "lastDiaryVisitedLevel",
            JSON.stringify({
              level: currentLevel?.levelName,
              activeDay
            })
          );
        }}
      />
    </Box>
  );
};

export default AddMealOrRecipeToDiary;
