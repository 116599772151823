import React, { useState, forwardRef } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  IconButton,
  Typography,
  styled,
  useMediaQuery,
  Snackbar,
  Alert
} from "@mui/material";
import { Email, Close, Send } from "@mui/icons-material";
import ReactConfetti from "react-confetti";
import { useTheme } from "@emotion/react";
import { selectCurrentUser } from "../../features/auth/authSelectors";
import { useSelector } from "react-redux";
import { referFriend } from "../../api/userApi";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(2.5, 0)
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-18px",
  color: "#D1D5DB",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: "#FCFCFC"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#333333"
    },
    "&:hover fieldset": {
      borderColor: "#FCFCFC"
    },
    "&.Mui-focused fieldset": {
      color: "#9CA3AF",
      borderColor: "#FCFCFC"
    }
  }
}));
const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

const ReferralModal = ({ open, onClose }) => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const currentUser = useSelector(selectCurrentUser);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      // Simulate API call
      await referFriend({
        senderName: currentUser?.fullName,
        recipientEmail: email
      });

      setShowSuccess(true);
      setIsSubmitting(false);

      // Close modal first
      onClose();

      // Show confetti after modal closes
      setShowConfetti(true);

      // Remove confetti after 5 seconds
      setTimeout(() => {
        setShowConfetti(false);
        setEmail("");
        setShowSuccess(false);
      }, 5000);
    } catch (err) {
      setSnackbarMessage(err.error);
      setOpenSnackbar(true);
      setIsSubmitting(false);
      setEmail("");

      // Close modal first
      onClose();
    }
  };
  // PUT API CALL HERE
  return (
    <>
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={isMobile ? 600 : 1200}
          recycle={false}
          //   colors={["#368AD0", "#2a6ba4", "#1d4d7a", "#62a6e0", "#90c1e8"]}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      )}
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={"error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        sx={{}}
        PaperProps={{
          style: {
            borderRadius: 16,
            padding: "16px",
            backgroundColor: "#1C1C1C",
            color: "#FCFCFC",
            maxWidth: "400px"
          }
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey.500"
          }}
        >
          <Close />
        </IconButton>

        <DialogTitle>
          <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
            <Email sx={{ fontSize: 48, color: "#368AD0", mb: 2 }} />
            <Typography variant="h5" fontWeight="600" textAlign="center">
              Refer a Friend
            </Typography>
            <Typography
              variant="body1"
              color="#FCFCFC"
              textAlign="center"
              mt={1}
            >
              Share the joy! Invite your friend to join us
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box display="flex" flexDirection="column" gap={1.5} mt={2}>
            <StyledTextFieldContainer>
              <StyledInputLabel htmlFor="email">
                Email Address *
              </StyledInputLabel>
              <StyledTextField
                fullWidth
                variant="outlined"
                name="email"
                type="email"
                placeholder="Enter your friend's email"
                value={email}
                InputProps={{
                  startAdornment: <Email sx={{ color: "grey.500", mr: 1 }} />
                }}
                disabled={isSubmitting || showSuccess}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
            </StyledTextFieldContainer>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={
                !email || isSubmitting || showSuccess || !validateEmail(email)
              }
              sx={{
                bgcolor: "#368AD0",
                color: "white",
                py: 1.5,
                textTransform: "none",
                borderRadius: "8px",
                "&:hover": {
                  bgcolor: "#2a6ba4"
                },
                ":disabled": {
                  backgroundColor: "#A7C8E9",
                  color: "#64AEDD",
                  pointerEvents: "none"
                }
              }}
              endIcon={<Send />}
            >
              {isSubmitting
                ? "Sending..."
                : showSuccess
                ? "Referred!"
                : "Send Invitation"}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReferralModal;
