import React from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTheme } from "@emotion/react";
import useThemeColor from "../../utils/useTheme";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const WeekView = ({
  activeDay,
  setActiveDay,
  startDay,
  setStartDay,
  allCompletedData,
  handleDayClick,
  handleNext,
  handlePrevious,
  visibleDays,
  visibleDaysCount,
  days
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery("(min-width: 600px) and (max-width: 900px)");
  const isLaptop = useMediaQuery("(min-width: 900px) and (max-width: 1300px)");
  const styles = useThemeColor();
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={isMobile ? "column" : "row"}
      justifyContent="center"
      borderBottom={`1px solid ${styles.lightGreyColor}`}
    >
      <IconButton
        sx={{ color: styles.color, p: { md: 0, lg: 1 } }}
        aria-label="previous"
        // onClick={enhancedHandlePrevious}
        onClick={handlePrevious}
        disabled={startDay === 1}
      >
        {isMobile ? (
          <ArrowDropUp sx={{ height: 38, width: 38 }} />
        ) : (
          <ArrowBackIosIcon />
        )}
      </IconButton>
      <Box
        display={"flex"}
        sx={{
          backgroundColor: styles.background,
          padding: { md: "0 0px 10px 0", lg: "0 10px 20px 10px" },
          gap: isLaptop ? "25px" : { md: "2px", lg: "30px" },
          ...(isMobile && { gap: "8px" })
        }}
      >
        {visibleDays?.map(({ title, value, day }) => {
          return (
            <Box
              display="flex"
              onClick={() => handleDayClick(value)} // Only handle click if day is enabled
              alignItems="center"
              flexDirection="column"
              gap={isMobile ? 0.4 : 1}
              borderRadius={100}
              // height={80}
              sx={{
                cursor: "pointer",
                padding: { sm: "3.5px", lg: "8px", md: "8px 3px" },
                ...((isMobile || isTablet) && { padding: "5px" }),
                color: activeDay === value ? "white" : "#9e9e9e",
                backgroundColor:
                  activeDay === value ? styles.dayHighlighter : "transparent"
              }}
              key={value}
            >
              <Typography
                p={"3px 5px"}
                borderRadius={"20px"}
                sx={{ background: "#a7c8e6", color: "black" }}
                color={styles.color}
                fontSize="10px"
                mb={1}
              >
                {day}
              </Typography>
              <Typography color={styles.color} fontSize="14px" fontWeight={700}>
                {title?.split(" ")?.[0]}
              </Typography>
              <Button
                sx={{
                  ":disabled": {
                    color: `${styles.lightBlack} !important`
                  },
                  ...(allCompletedData?.includes(value) && {
                    border: "2px solid #368AD0"
                  }),
                  color: activeDay === value ? "#FCFCFC" : styles.disabledColor,
                  backgroundColor:
                    activeDay === value ? "#368AD0" : "transparent",
                  minWidth: "40px",
                  borderRadius: "50%",
                  padding: "6px"
                }}
              >
                {title?.split(" ")?.[1]}
              </Button>
            </Box>
          );
        })}
      </Box>
      <IconButton
        sx={{ color: styles.color, p: { md: 0, lg: 1 } }}
        aria-label="next"
        // onClick={enhancedHandleNext}
        onClick={handleNext}
        disabled={startDay + visibleDaysCount - 1 >= days?.length}
      >
        {isMobile ? (
          <ArrowDropDown sx={{ height: 38, width: 38 }} />
        ) : (
          <ArrowForwardIosIcon />
        )}
      </IconButton>
    </Box>
  );
};

export default WeekView;
