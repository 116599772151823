import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRecentMealsFromPast } from "../../api/mealsApi";

// Async thunk for fetching all meals with pagination
export const getRecentMeals = createAsyncThunk(
  "meals/getRecentMeals",
  async ({ currentDate, type, userId, searchTerm = "" }, thunkAPI) => {
    try {
      const response = await getRecentMealsFromPast(
        currentDate,
        userId,
        type,
        searchTerm
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Initial state
const initialState = {
  allMeals: [], // holds the meals data
  userMeals: [], // holds the user meals data
  loading: false, // loading state for getCurrentDateMeal
  error: null // error state
};

const mealsSlice = createSlice({
  name: "meals",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handling getRecentMeals actions
      .addCase(getRecentMeals.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRecentMeals.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.userId) {
          state.userMeals = action.payload.meals; // assuming response has meals data
        } else state.allMeals = action.payload.meals; // assuming response has meals data
      })
      .addCase(getRecentMeals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default mealsSlice.reducer;
