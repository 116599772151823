import React from "react";
import {
  Card,
  Box,
  Typography,
  useMediaQuery,
  IconButton
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { deleteIngredientsCustomOption } from "../../api/diaryApi";

const CustomRadio = ({
  label,
  setIsDiaryRefreshNeeded,
  type,
  checked,
  onChange,
  id,
  value,
  styles
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box onClick={() => onChange(value)} sx={{ cursor: "pointer" }}>
      <Card
        sx={{
          backgroundColor: styles.lightGreyColor,
          "&:hover": {
            backgroundColor: styles.disabledColor,
            color: styles.background
          },
          transition: "background-color 0.3s",
          padding: type === "CUSTOM" ? "9px 13px" : 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px"
          }}
        >
          <Box
            sx={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: checked ? "#368AD0" : "transparent",
              border: checked ? "none" : "2px solid grey"
            }}
          >
            {checked && (
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  backgroundColor: "white",
                  borderRadius: "50%"
                }}
              />
            )}
          </Box>
          <Typography
            fontSize={isMobile ? "13px" : "15px"}
            color={styles.color}
            variant="body1"
          >
            {label}
          </Typography>
        </Box>
        {type === "CUSTOM" && (
          <IconButton
            onClick={async (e) => {
              e.stopPropagation();
              await deleteIngredientsCustomOption(id);
              setIsDiaryRefreshNeeded(true);
            }}
            sx={{
              fontSize: "16px",
              padding: "11px",
              borderRadius: "4px",
              background: "#FF4000",
              svg: {
                path: {
                  fill: "#FCFCFC"
                }
              },
              "&:hover": {
                background: "#FF4000"
              }
            }}
          >
            {/* {currentTheme === "light" ? (
              <BlackDelete />
            ) : ( */}
            <Delete />
            {/* )} */}
          </IconButton>
        )}
      </Card>
    </Box>
  );
};

export default CustomRadio;
