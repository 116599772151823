import { useSelector } from "react-redux";
import { selectCurrentTheme } from "../features/theme/themeSelectors";

const useThemeColor = () => {
  const themeMode = useSelector(selectCurrentTheme);
  const darkTheme = themeMode === "dark";
  const styles = {
    background: darkTheme ? "#1C1C1C" : "#FCFCFC",
    color: darkTheme ? "#FCFCFC" : "#1C1C1C",
    lightGreyColor: darkTheme ? "#333333" : "#F5F5F5",
    inputLabelColor: darkTheme ? "#D1D5DB" : "#374151",
    disabledColor: darkTheme ? "#9e9e9e" : "#616161",
    lightBlack: darkTheme ? "#9CA3AF" : "#505050",
    lightBlue: darkTheme ? "#1F2937" : "#D1D5DB",
    primaryColor: darkTheme ? "#368AD0" : "#1F5D8A",
    premiumIcon: darkTheme ? "#333333" : "#C9C9C9",
    drawerBackground: darkTheme ? "#1C1C1C" : "#E8E8E8",
    modBackgroundBlocks: darkTheme ? "#333333" : "#F3F4F6",
    modLabel: darkTheme ? "#9CA3AF" : "#6B7280",
    levelBackgrounds: darkTheme ? "#333333" : "#1C1C1C",
    dayHighlighter: darkTheme ? "#333333" : "#64AEDD",
    dayViewColor: darkTheme ? "#FCFCFC" : "#8E8E8E",
    diaryTracker: darkTheme ? "#1C1C1C" : "#E5E7EB",
    lightDiaryTracker: darkTheme ? "#333333" : "#E5E7EB",
    trackerBackground: darkTheme ? "#333333" : "#D1D5DB",
    textColorDiary: darkTheme ? "#FCFCFC" : "#6B7280"
  };
  return styles;
};

export default useThemeColor;
