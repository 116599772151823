import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import modReducer from "../features/mod/modSlice";
import mealsReducer from "../features/meals/mealsSlice";
import trainingReducer from "../features/training/trainingSlice";
import themeReducer from "../features/theme/themeSlice";
import diaryReducer from "../features/diary/diarySlice";
import level3reducer from "../features/level3/level3Slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    mod: modReducer,
    meals: mealsReducer,
    training: trainingReducer,
    theme: themeReducer,
    diary: diaryReducer,
    level3: level3reducer
  }
});
