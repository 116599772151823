import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectAuthLoading } from "../../features/auth/authSelectors";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Avatar,
  Box,
  styled,
  Alert,
  useMediaQuery,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import dayjs from "dayjs";
import { ReactComponent as CameraIcon } from "../../assets/camera.svg";
import { fetchUser, getUserType } from "../../api/userApi";
import { completeProfile } from "../../features/auth/authSlice";
import { getUniqueSuffix } from "../../utils/getUniqueSuffix";
import { uploadFile } from "../../utils/uploadFile";
import useSignedUrl from "../../utils/useSignedUrl";
import { useTheme } from "@emotion/react";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(5, 0)
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-15px",
  color: "#D1D5DB",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: "#FCFCFC",
    ":disabled": {
      backgroundColor: "#A7C8E9",
      color: "#FCFCFC",
      borderRadius: "4px"
    }
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#333333"
    },
    "&:hover fieldset": {
      borderColor: "#FCFCFC"
    },
    "&.Mui-focused fieldset": {
      color: "#9CA3AF",
      borderColor: "#FCFCFC"
    }
  }
}));
const getUserTypes = async () => {
  try {
    const data = await getUserType();
    return data;
  } catch (error) {
    console.error(
      "Get user types failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
const Register = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    displayName: "",
    referralCode: "",
    email: "",
    password: "",
    profilePic: null,
    bio: "",
    url: "",
    userType: "",
    referenceName: "",
    referenceId: "",
    isVerified: false
  });

  const [formErrors, setFormErrors] = useState({
    fullName: false,
    displayName: false,
    email: false,
    password: false,
    bio: false,
    url: false,
    referralCode: false
  });
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const matches = useMediaQuery("(max-width:590px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isCompanyUser, setIsCompanyUser] = useState(false);
  const [availableOptions, setAvailableOptions] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();
  const loading = useSelector(selectAuthLoading);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const validateUrl = (url) =>
    /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/.test(
      url
    );
  const validatePassword = (password) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,}$/.test(
      password
    );

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]:
        type === "checkbox"
          ? checked
          : name === "bio"
          ? value.length < 51
            ? value
            : formData?.bio
          : value
    });

    if (name === "email") {
      setFormErrors({ ...formErrors, email: !validateEmail(value) });
    } else if (name === "url") {
      setFormErrors({ ...formErrors, url: !validateUrl(value) });
    } else if (name === "password") {
      setFormErrors({ ...formErrors, password: !validatePassword(value) });
    } else if (name !== "bio") {
      setFormErrors({ ...formErrors, [name]: value.trim() === "" });
    } else if (name === "referralCode") {
      setFormErrors({ ...formErrors, referralCode: value.trim() === "" });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 30 * 1024 * 1024) {
      setSnackbarMessage("File size should be less than 30MB.");
      setOpenSnackbar(true);
      return;
    }
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        profilePic: file
      }));
    }
  };
  // users/getUserById/:id
  useEffect(() => {
    getUserTypes()
      .then((data) => {
        if (data.usersTypeList) {
          setAvailableOptions(data.usersTypeList);
        }
      })
      .catch(() => setAvailableOptions([]));
  }, []);
  const handleRegister = async (e) => {
    // fullName: "",
    // displayName: "",
    // email: "",
    // password: "",
    // profilePic: null,
    // bio: "",
    // url: "",
    // userType: "",
    // referenceName: "",
    // isVerified: false
    delete formData.email;
    delete formData.password;
    // delete formData.userType;
    delete formData.referenceName;

    e.preventDefault();
    if (Object.values(formErrors).some((error) => error)) {
      setSnackbarMessage("Please correct the form errors.");
      setOpenSnackbar(true);
      return;
    }
    if (
      formData.userType !== "Individual" &&
      (formData.referralCode?.trim() === "" || !formData?.referralCode)
    ) {
      setSnackbarMessage("Please enter referral code");
      setOpenSnackbar(true);
      return;
    }
    try {
      const uniqueFileName = getUniqueSuffix(formData.profilePic?.name);
      const { profilePic, ...restFormData } = formData;
      const finalFormData = profilePic
        ? { ...restFormData, profilePic: uniqueFileName }
        : restFormData;

      const data = await dispatch(
        completeProfile({
          formData: { ...finalFormData, isVerified: false },
          userId
        })
      );
      if (formData.profilePic) {
        await uploadFile(
          formData.profilePic,
          uniqueFileName,
          process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
        );
      }

      if (data?.payload?.message) {
        setSnackbarMessage("Successfully user details updated!");
        setOpenSnackbar(true);
        setTimeout(() => navigate("/"), 2000);
      } else {
        setIsCompanyUser(isCompanyUser);
        setSnackbarMessage(data?.payload?.error || "Update user failed.");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error(
        "Update user failed:",
        error.response ? error.response.data : error.message
      );
      throw error.response ? error.response.data : error;
    }
  };
  const [exisitingImage, setExistingImage] = useState("");
  useEffect(() => {
    if (userId)
      fetchUser(userId, dayjs().format("YYYY-MM-DD")).then((data) => {
        if (data && data.user) {
          setIsCompanyUser(data.user.userType === "Company");
          setFormData({
            fullName: data.user.fullName,
            displayName: data.user.displayName,
            email: data.user.email,
            userType: data.user.userType,
            bio: data.user.bio,
            url: data.user.url,
            referenceId: data.user.referenceId,
            referenceName: data.user.referenceName,
            referralCode: data.user.code
          });
          setExistingImage(data.user.profilePic);
        }
      });
  }, [userId]);
  const imgSrc = useSignedUrl(
    exisitingImage,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );
  return (
    <Box
      color={"#FCFCFC"}
      backgroundColor={"#1C1C1C"}
      display={"flex"}
      width="100%"
      overflow="hidden"
      height="100dvh"
      sx={{
        flexDirection: { xs: "column", md: "row" }
      }}
    >
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { xs: 0, md: "50%" },
          display: { xs: "none", md: "block" },
          objectFit: "cover"
        }}
      >
        <Box
          component="img"
          src="/images/base.png"
          alt="base"
          sx={{
            width: "100%",
            height: "100dvh",
            objectFit: "cover"
          }}
        />
      </Box>
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { md: "0" },
          objectFit: "cover",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          marginTop: "20px"
        }}
      >
        <Box
          component="img"
          src="/images/threepillars.png"
          alt="base"
          sx={{
            width: { xs: "60px", md: "100px" },
            height: { xs: "60px", md: "100px" },
            objectFit: "cover"
          }}
        />
      </Box>

      <Box
        flexGrow={1}
        paddingY={3}
        paddingLeft={10}
        sx={{
          paddingTop: { xs: 5, md: 8, lg: 10 },
          paddingRight: { xs: 5, md: 13, lg: 20 },
          paddingBottom: { xs: 5, md: 8, lg: 10 },
          paddingLeft: { xs: 5, md: 10 },
          height: { xs: "93%", md: "80%" },
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          ...(!isMobile && { width: "30%" }),
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(255, 255, 255, 0.5)",
          "&::-webkit-scrollbar": {
            width: "4px"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            borderRadius: "6px"
          }
        }}
      >
        <Box
          sx={{
            display: {
              xs: "flex",
              md: "block"
            },
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography
            sx={{
              ...(matches && { display: "flex", justifyContent: "center" })
            }}
            fontSize="30px"
            fontWeight="bold"
            gutterBottom
          >
            Complete your profile
          </Typography>
          <Typography
            sx={{
              ...(matches && { display: "flex", justifyContent: "center" })
            }}
            color="#6B7280"
            fontSize="16px"
            gutterBottom
          >
            complete your remaining details here
          </Typography>
        </Box>
        <Snackbar
          open={openSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={
              snackbarMessage?.includes("Success") ||
              snackbarMessage?.includes("success")
                ? "success"
                : "error"
            }
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Box component={"form"} mt={2} onSubmit={handleRegister}>
          <input
            accept="image/*"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="profile-pic-upload"
          />
          <Box
            sx={{
              ...(matches && { justifyContent: "center" })
            }}
            display="flex"
            gap="50px"
            alignItems="center"
          >
            {formData.profilePic ? (
              <Avatar
                onClick={() =>
                  document.getElementById("profile-pic-upload").click()
                }
                alt="Profile Pic"
                src={URL.createObjectURL(formData.profilePic)}
                sx={{
                  width: 130,
                  height: 130,
                  marginTop: 2
                }}
              />
            ) : imgSrc ? (
              <Avatar
                onClick={() =>
                  document.getElementById("profile-pic-upload").click()
                }
                alt="Profile Pic"
                src={imgSrc}
                sx={{
                  width: 130,
                  height: 130,
                  marginTop: 2
                }}
              />
            ) : (
              <label htmlFor="profile-pic-upload">
                <Button
                  variant="contained"
                  component="span"
                  color="default"
                  style={{
                    borderRadius: "48%",
                    backgroundColor: "#ccc",
                    width: 130,
                    height: 130,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 16,
                    cursor: "pointer"
                  }}
                >
                  <CameraIcon style={{ width: 24, height: 24 }} />
                </Button>
              </label>
            )}
          </Box>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="fullName">Name</StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="fullName"
              placeholder="Enter your Full name"
              value={formData.fullName}
              onChange={handleInputChange}
              required
              error={formErrors.fullName}
              helperText={formErrors.fullName && "Name is required"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="displayName">
              Username (Displayed in app)
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="displayName"
              placeholder="Enter your Username"
              value={formData.displayName}
              onChange={handleInputChange}
              required
              error={formErrors.displayName}
              helperText={formErrors.displayName && "Username is required"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="bio">
              Short Bio (50 character max)
            </StyledInputLabel>
            <StyledTextField
              sx={{
                "& .MuiInputBase-root": {
                  color: "#FCFCFC"
                }
              }}
              fullWidth
              variant="outlined"
              name="bio"
              placeholder="Enter your bio"
              value={formData.bio}
              multiline
              rows={4}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onChange={handleInputChange}
              margin="normal"
              error={formErrors.bio}
              helperText={
                formErrors.bio && "Bio should be less than 50 characters"
              }
            />
            <Typography
              variant="caption"
              sx={{
                color: formData.bio?.length > 50 ? "red" : "#D1D5DB",
                position: "absolute",
                fontSize: "15px",
                right: 10,
                bottom: 10 // Adjust this value as necessary
              }}
            >
              {formData.bio?.length}/50
            </Typography>
          </StyledTextFieldContainer>

          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="email">Email Address</StyledInputLabel>
            <StyledTextField
              fullWidth
              disabled
              variant="outlined"
              name="email"
              type="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleInputChange}
              required
              error={formErrors.email}
              helperText={formErrors.email && "Enter a valid email address"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="password">Password</StyledInputLabel>
            <StyledTextField
              fullWidth
              disabled
              variant="outlined"
              name="password"
              type="password"
              placeholder="Enter Password"
              value={"Test1234"}
              onChange={handleInputChange}
              required
              error={formErrors.password}
              helperText={
                formErrors.password &&
                "Password must be 8 characters long: Contains 1 upper, 1 lower case, 1 symbol and 1 digit"
              }
              margin="normal"
            />
            <Typography fontSize={12}>
              (Change your password from profile page later)
            </Typography>
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="url">
              URL (link to their website, social media, etc.)
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="url"
              placeholder="Enter URL"
              value={formData.url}
              onChange={handleInputChange}
              error={formErrors.url}
              helperText={formErrors.url && "Enter a valid URL"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          {!isCompanyUser && formData.referenceName ? (
            <>
              <StyledTextFieldContainer>
                <StyledInputLabel htmlFor="source-of-joining-label">
                  {" "}
                  Source of joining
                </StyledInputLabel>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  name="source-of-joining-label"
                  value={formData.userType}
                  disabled
                  margin="normal"
                />
              </StyledTextFieldContainer>
              <StyledTextFieldContainer>
                <StyledInputLabel htmlFor="referenceName">
                  Name
                </StyledInputLabel>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  name="referenceName"
                  value={formData.referenceName}
                  disabled
                  margin="normal"
                />
              </StyledTextFieldContainer>
            </>
          ) : Object.values(availableOptions)?.length > 0 ? (
            <>
              {" "}
              <FormControl fullWidth variant="outlined">
                <InputLabel
                  id="source-of-joining-label"
                  sx={{ color: "#FCFCFC" }}
                >
                  Source of joining
                </InputLabel>
                <Select
                  labelId="source-of-joining-label"
                  id="source-of-joining"
                  name="userType"
                  value={formData.userType}
                  onChange={handleInputChange}
                  label="Source of joining"
                  sx={{
                    // width: "100%",
                    color: "#FCFCFC", // White font for selected value
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#333" // Border color
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FCFCFC" // Border color when focused
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FCFCFC" // Border color on hover
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#1C1C1C", // Black background for the dropdown
                        color: "#FCFCFC", // White font color for the menu items
                        "& .MuiMenuItem-root": {
                          padding: "10px" // Adjust padding inside menu items
                        },
                        "& .Mui-selected": {
                          backgroundColor: "#333" // Custom color for selected item
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "#444" // Darker hover background
                        }
                      }
                    }
                  }}
                >
                  <MenuItem value="Coach">Coach</MenuItem>
                  <MenuItem value="Gym">Gym</MenuItem>
                  <MenuItem value="Company">Company</MenuItem>
                  <MenuItem value="Affiliate">Affiliate</MenuItem>
                </Select>
              </FormControl>
              {formData.userType && formData.userType !== "Individual" && (
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ marginTop: 30 }}
                >
                  <InputLabel
                    id="source-of-joining-name"
                    sx={{ color: "#FCFCFC" }}
                  >
                    {formData.userType} name
                  </InputLabel>
                  <Select
                    labelId="source-of-joining-name"
                    id="source-of-joining"
                    name="referenceId"
                    value={formData.referenceId}
                    onChange={handleInputChange}
                    label="Source of joining"
                    sx={{
                      // width: "100%",
                      color: "#FCFCFC",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#333"
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FCFCFC"
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FCFCFC"
                      },
                      // Limit selected value width with ellipsis
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden"
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          backgroundColor: "#1C1C1C",
                          color: "#FCFCFC",
                          // width: "100%",
                          // maxWidth: "300px", // Set max width for menu items
                          "& .MuiMenuItem-root": {
                            padding: "10px"
                          },
                          "& .Mui-selected": {
                            backgroundColor: "#333"
                          },
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "#444"
                          }
                        }
                      }
                    }}
                  >
                    {availableOptions?.[formData?.userType.toLowerCase()]
                      ?.length ? (
                      availableOptions?.[formData?.userType.toLowerCase()]?.map(
                        (option) => {
                          return (
                            <MenuItem
                              value={option._id}
                              style={{
                                // maxWidth: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                              }}
                            >
                              {
                                option[
                                  formData?.userType?.toLowerCase() + "Name"
                                ]
                              }
                            </MenuItem>
                          );
                        }
                      )
                    ) : (
                      <MenuItem
                        disabled
                        style={{
                          // maxWidth: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap"
                        }}
                      >
                        No options available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            </>
          ) : (
            <></>
          )}
          {formData.userType !== "Individual" && (
            <StyledTextFieldContainer sx={{ mt: 5 }}>
              <StyledInputLabel htmlFor="referralCode">
                Referral Code*
              </StyledInputLabel>
              <StyledTextField
                fullWidth
                sx={{ marginTop: "20px" }}
                variant="outlined"
                name="referralCode"
                placeholder="Enter referral code"
                value={formData.referralCode}
                onChange={handleInputChange}
                // onBlur={handleBlurValidation}
                error={formErrors.referralCode}
                helperText={formErrors.referralCode && "Enter referral code"}
                margin="normal"
              />
            </StyledTextFieldContainer>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            style={{
              marginTop: 30,
              backgroundColor: "#368AD0",
              color: "#FCFCFC",
              width: "100%",
              height: "42px",
              textTransform: "none"
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Confirm"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
