import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  IconButton,
  Autocomplete,
  InputAdornment,
  CircularProgress,
  Grid,
  Typography,
  Paper
} from "@mui/material";
import { ReactComponent as BarcodeScannerIcon } from "../../assets/barcode.svg";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { debounce, result } from "lodash";
import BarcodeScannerOnButtonClick from "./BarcodeReader";
import useThemeColor from "../../utils/useTheme";
import {
  generateAccessToken,
  getSearchResultsForMealItems,
  gettingAllCustomFoodOptions,
  updateSearchResults
} from "../../features/level3/level3Slice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAccessToken,
  selectCustomFoodOptions,
  selectSearchResults,
  selectSearchResultsLoading
} from "../../features/level3/level3Selectors";

const FoodSearch = ({
  favoriteFood,
  setFavoriteFood,
  setSelectedOptionData,
  selectedCustomFood,
  setSelectedCustomFood,
  type = "",
  isBarcodeNeeded = true
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [options, setOptions] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [openScanner, setOpenScanner] = useState(false);
  const existingToken = useSelector(selectAccessToken);
  const searchedData = useSelector(selectSearchResults);
  const allCustomFoodOptions = useSelector(selectCustomFoodOptions);
  const searchLoading = useSelector(selectSearchResultsLoading);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  // Initialize authentication on component mount
  useEffect(() => {
    if (selectedCustomFood) {
      setSearchQuery("");
    }
  }, [selectedCustomFood]);
  useEffect(() => {
    if (existingToken) {
      setAccessToken(existingToken);
    } else {
      dispatch(generateAccessToken()).then(({ payload }) => {
        setAccessToken(payload.access_token);
      });
    }
    dispatch(updateSearchResults({ results: [] }));
    dispatch(gettingAllCustomFoodOptions());
  }, []);

  useEffect(() => {
    if (favoriteFood) {
      setSearchQuery(favoriteFood);
      searchFood(favoriteFood);
      // setOpen(true);
    }
  }, [favoriteFood]);

  useEffect(() => {
    const data = searchedData?.map((item) => {
      return {
        name: `${item.description}${
          item.brand_name ? ` (${item.brand_name})` : ""
        } `,
        id: item.serving.serving_id,
        ...item
      };
    });
    setOptions(data);
  }, [searchedData]);
  // Debounced search function
  const searchFood = useCallback(
    debounce(async (query) => {
      if (query.length < 4) {
        setOptions([]);
        return;
      }

      try {
        await dispatch(
          getSearchResultsForMealItems({
            searchTerm: query,
            token: accessToken
          })
        );
      } catch (err) {
        handleError("Failed to fetch food data");
      }
    }, 1000),
    [dispatch, accessToken]
  );

  const handleInputChange = (event, newValue) => {
    setSearchQuery(newValue);
    searchFood(newValue);
    if (selectedCustomFood) setSelectedCustomFood(null);
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const styles = useThemeColor();
  return (
    <Box>
      {/* Search Bar */}
      <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
        <Autocomplete
          fullWidth
          open={open}
          slotProps={{
            popper: {
              sx: {
                "& .MuiAutocomplete-paper": {
                  "& .MuiAutocomplete-listbox": {
                    padding: "0 !important", // Ensure no padding
                    ".Mui-focused": {
                      backgroundColor: `${styles.lightGreyColor} !important`,
                      color: `${styles.color} !important`
                    }
                  },
                  padding: "0 !important" // Remove padding from the dropdown container
                }
              }
            }
          }}
          sx={{
            "& .MuiAutocomplete-loading": {
              display: "flex",
              justifyContent: "center",
              padding: "16px" // Optional: adjust padding for better alignment
            }
          }}
          onOpen={handleOpen}
          onClose={handleClose}
          freeSolo
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                fontSize: "14px",
                backgroundColor: styles.background,
                color: styles.color,
                padding: 0, // Adjust padding as needed for options
                "&:hover": {
                  background: `${styles.lightGreyColor} !important`,
                  color: `${styles.color} !important`
                }
              }}
              {...props}
            >
              {option.name}
            </Box>
          )}
          options={options}
          onChange={(e, value) => {
            setSelectedOptionData(value);
          }}
          inputValue={searchQuery}
          onInputChange={handleInputChange}
          loading={searchLoading}
          loadingText={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CircularProgress size={20} />
            </Box>
          }
          filterOptions={(x) => x} // Disable built-in filtering as we're using server-side search
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name || ""
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              onFocus={() => setOpen(true)}
              placeholder="Search food..."
              onKeyDown={(e) => {
                // Check if the Enter key is pressed
                if (e.key === "Enter") {
                  setOpen(true); // Open the dropdown on Enter press
                }
              }}
              slotProps={{
                input: {
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ height: "17px", color: "#4B5563" }}
                        />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  )
                }
              }}
              sx={{
                "& .MuiInputBase-root": {
                  padding: "14px 11px",
                  height: "100%",
                  borderRadius: 2,
                  color: styles.color,
                  "& fieldset": {
                    borderColor: "#358ad0 !important"
                  }
                },
                "& .Mui-focused": {
                  "& fieldset": {
                    borderColor: "#358ad0 !important"
                  }
                },
                height: "48px",
                fontSize: "14px",
                background: styles.background,
                borderRadius: 2
              }}
            />
          )}
        />
        {isBarcodeNeeded ? (
          <>
            <IconButton
              onClick={() => setOpenScanner(true)}
              sx={{
                color: styles.color,
                border: `1px solid ${styles.color}`,
                borderRadius: "6px",
                padding: "10px",
                textTransform: "none",
                svg: {
                  path: {
                    fill: styles.color
                  }
                }
              }}
            >
              <BarcodeScannerIcon />
            </IconButton>
            <BarcodeScannerOnButtonClick
              open={openScanner}
              setOpen={setOpenScanner}
              setFavoriteFood={setFavoriteFood}
              setOpenMenu={handleOpen}
            />
          </>
        ) : (
          <></>
        )}
      </Box>

      {(type
        ? allCustomFoodOptions?.filter((item) => item?.type === type)
        : allCustomFoodOptions
      )?.length ? (
        <Box sx={{ py: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 2, fontSize: "13px" }}>
            Custom Foods
          </Typography>
          <Grid container spacing={2}>
            {(type
              ? allCustomFoodOptions?.filter((item) => item?.type === type)
              : allCustomFoodOptions
            )?.map((item) => (
              <Grid key={item?._id} item xs={12} sm={6}>
                <Paper
                  elevation={selectedCustomFood?._id === item?._id ? 3 : 1}
                  sx={{
                    background: "#333",
                    color: styles.color,
                    cursor: "pointer",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      elevation: 3,
                      borderColor: "#358ad0"
                    },
                    background: "#333333 !important",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                    textTransform: "none"
                  }}
                  onClick={() => {
                    if (!selectedCustomFood) {
                      setSelectedCustomFood(item);
                      const data = { ...item, serving: item.details.serving };
                      setSelectedOptionData(data);
                      setSearchQuery("");
                    } else {
                      setSelectedCustomFood(null);
                    }
                  }}
                >
                  {/* Content */}
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      px: 2,
                      py: 1,
                      color:
                        selectedCustomFood?._id === item?._id
                          ? "#358ad0"
                          : styles.color,
                      fontSize:
                        selectedCustomFood?._id === item?._id ? "16px" : "13px"
                    }}
                  >
                    {item.name}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default FoodSearch;
