import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Snackbar,
  Typography,
  useMediaQuery
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTheme } from "@emotion/react";
import useThemeColor from "../../utils/useTheme";

const DaySelector = ({
  activeDay,
  setActiveDay,
  startDay,
  setStartDay,
  trainingData,
  allCompletedData,
  handleDayClick,
  handleNext,
  handlePrevious,
  visibleDays,
  visibleDaysCount,
  days,
  enabledDays = []
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptop = useMediaQuery("(min-width: 900px) and (max-width: 1300px)");

  const styles = useThemeColor();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderBottom={`1px solid ${styles.lightGreyColor}`}
      sx={{
        backgroundColor: styles.background,
        padding: { md: "0 0px 10px 0", lg: "0 10px 20px 10px" },
        gap: isLaptop ? "22px" : { md: "4px", lg: "30px" },
        ...(isMobile && { gap: "8px", paddingBottom: "10px" })
      }}
    >
      <IconButton
        sx={{ color: styles.color, p: { md: 0, lg: 1 } }}
        aria-label="previous"
        onClick={handlePrevious}
        disabled={startDay === 1}
      >
        <ArrowBackIosIcon />
      </IconButton>

      {visibleDays.map((day) => {
        const isEnabled = enabledDays.includes(day); // Check if the day is in the enabledDays list

        return (
          <Box
            display="flex"
            onClick={() => {
              if (isEnabled) {
                handleDayClick(day);
              } else {
                setSnackbarMessage("1 day of training unlocks each day.");
                setOpenSnackbar(true);
              }
            }} // Only handle click if day is enabled
            alignItems="center"
            textAlign="center"
            flexDirection="column"
            gap={1}
            borderRadius={100}
            height={80}
            sx={{
              cursor: isEnabled ? "pointer" : "not-allowed", // Show "not-allowed" cursor for disabled days
              padding: isLaptop
                ? "8px 3px"
                : { sm: "5px", lg: "8px", md: "8px 3px" },
              ...(isMobile && { padding: "5px" }),
              color: activeDay === day ? "white" : "#9e9e9e",
              backgroundColor:
                activeDay === day ? styles.dayHighlighter : "transparent",
              opacity: isEnabled ? 1 : 0.5 // Lower opacity for disabled days
            }}
            key={day}
          >
            <Snackbar
              open={openSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={4000}
              onClose={() => setOpenSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenSnackbar(false)}
                severity={
                  snackbarMessage.includes("Success") ? "success" : "error"
                }
                variant="filled"
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
            <Typography
              color={activeDay === day ? styles.color : styles.dayViewColor}
              fontSize="13px"
            >
              Day
            </Typography>
            <Button
              onClick={() => isEnabled && handleDayClick(day)}
              variant={activeDay === day ? "contained" : "text"}
              sx={{
                ":disabled": {
                  color: styles.disabledColor
                },
                ...(allCompletedData?.includes(day) && {
                  border: "2px solid #368AD0"
                }),
                color: activeDay === day ? "#FCFCFC" : styles.disabledColor,
                backgroundColor: activeDay === day ? "#368AD0" : "transparent",
                minWidth: "40px",
                borderRadius: "50%",
                padding: "6px"
              }}
              disabled={!isEnabled} // Disable button for days not in enabledDays
            >
              {day}
            </Button>
          </Box>
        );
      })}

      <IconButton
        sx={{ color: styles.color, p: { md: 0, lg: 1 } }}
        aria-label="next"
        onClick={handleNext}
        disabled={startDay + visibleDaysCount - 1 >= days.length}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default DaySelector;
