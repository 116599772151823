import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  styled,
  Switch,
  Typography,
  useMediaQuery
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import useThemeColor from "../../utils/useTheme";
import DateRangePicker from "./DateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllDiaryExtraData,
  selectAllDiaryOutOf800g,
  selectAllDiaryOutOfCalories,
  selectAllDiaryOutOfProtein,
  selectAllDiaryStats
} from "../../features/diary/diarySelectors";
import { getCurrentUserDiaryStats } from "../../features/diary/diarySlice";
import DiaryTracker from "../Extra/DiaryTracker";
import dayjs from "dayjs";
import { ReactComponent as TrophyIcon } from "../../assets/goal-trophy.svg";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import { useTheme } from "@emotion/react";
import formatNumber from "../../utils/formatNumber";
import Graph from "./Graph";
import { dailyValues } from "../../utils/calculateDVPercent";

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, activeColor, background }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: background
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: activeColor
    }
  })
);
const includedMicroNutrientFields = [
  "calcium",
  "iron",
  "potassium",
  "sodium",
  "vitamin_a",
  "vitamin_c",
  "vitamin_d"
];

const nutrientFields = (data) => {
  if (data)
    return Object.entries(data).filter(([key, value]) => {
      return includedMicroNutrientFields.includes(key) && !isNaN(Number(value));
    });
  else return [];
};

//   return nutrientFields?.(data)?.map(([key, value]) => {
//     const numericValue = Math.round(parseFloat(value) || 0);
//     // const isSmallValue = numericValue && numericValue < 1;
//     return {
//       name: key
//         .replace(/_/g, " ")
//         .replace(/\b\w/g, (char) => char.toUpperCase()),
//       value: numericValue,
//       unit: "g",
//       percent: Math.round((numericValue / dailyValues[key]) * 100)
//     };
//   });
// };

const StatsList = ({ level: { levelName, levelId } }) => {
  const styles = useThemeColor();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [includeToday, setIncludeToday] = useState(true);
  const [value, setValue] = useState("7days");
  const dispatch = useDispatch();
  const allStats = useSelector(selectAllDiaryStats);
  const extraData = useSelector(selectAllDiaryExtraData);
  const total800g = useSelector(selectAllDiaryOutOf800g);
  const totalProteins = useSelector(selectAllDiaryOutOfProtein);
  const totalCalories = useSelector(selectAllDiaryOutOfCalories);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    if ((value === "custom" && startDate && endDate) || value !== "custom")
      dispatch(
        getCurrentUserDiaryStats({
          timeFrame: value,
          levelId,
          includeToday,
          date: dayjs().format("YYYY-MM-DD"),
          ...(value === "custom" && {
            startDate: dayjs(startDate).format("YYYY-MM-DD"),
            endDate: dayjs(endDate).format("YYYY-MM-DD")
          })
        })
      );
  }, [dispatch, includeToday, value, levelId, startDate, endDate]);

  const calculateAverage800gms = useMemo(() => {
    const sum = allStats?.reduce(
      (prev, curr) => prev + (curr.totalEntry800g || 0),
      0
    );
    return {
      average:
        Math.round(
          levelName === "level3"
            ? extraData?.averagedSummaries?.metric_serving_amount
            : sum / allStats?.length
        ) || 0,
      total: allStats[0]?.outOf800g || total800g
    };
  }, [allStats, total800g]);

  const calculateAverageProtein = useMemo(() => {
    const sum = allStats?.reduce(
      (prev, curr) => prev + (curr.totalProtein || 0),
      0
    );
    return {
      average:
        Math.round(
          levelName === "level3"
            ? extraData?.averagedSummaries?.protein
            : sum / allStats?.length
        ) || 0,
      total: allStats[0]?.outOfProtein || totalProteins
    };
  }, [allStats, totalProteins]);

  const calculateAverageCalories = useMemo(() => {
    const sum = allStats?.reduce(
      (prev, curr) => prev + (curr.totalProtein || 0),
      0
    );
    return {
      average:
        Math.round(
          levelName === "level3"
            ? extraData?.averagedSummaries?.calories || 0
            : sum / allStats?.length
        ) || 0,
      total: allStats[0]?.outOfCalories || totalCalories
    };
  }, [allStats, totalCalories]);

  const formattedNutrients = nutrientFields(extraData?.averagedSummaries)?.map(
    ([key, value]) => {
      const numericValue = Math.round(parseFloat(value) || 0);
      return {
        name: key
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase()),
        value: numericValue,
        unit: "g",
        percent: Math.round((numericValue / dailyValues[key]) * 100)
      };
    }
  );

  const formatNumberString = (value) => {
    return Number(value).toLocaleString();
  };

  const getNutrientValue = (name, unit = "") => {
    const nutrient = formattedNutrients.find((n) => n.name === name);
    return nutrient
      ? // ? `${formatNumberString(nutrient.value)}${unit || nutrient.unit} ${
        //     unit ? `(${nutrient.percent}%)` : ""
        //   }`
        // : `0${unit || nutrient.unit} (0%)`;
        `${formatNumberString(nutrient.value)}${unit || nutrient.unit}`
      : `0${unit || nutrient.unit}`;
  };

  const NutrientRow = ({ name, unit }) => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={0.5}
    >
      <Typography fontSize="13px">{name}</Typography>
      <Typography fontSize="13px">{getNutrientValue(name, unit)}</Typography>
      {/* <Typography fontSize="13px">{calculatePercentDV(name, unit)}</Typography> */}
    </Box>
  );
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap" // Allows wrapping on smaller screens
        gap={isMobile ? 2 : 0} // Adds spacing between items for better mobile layout
      >
        <Typography fontSize={"24px"} color={styles.color}>
          Diet Statistics
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexWrap="wrap"
          sx={{ width: { xs: "100%", md: "35%" }, gap: 1 }}
        >
          {value !== "today" && (
            <Box
              display="flex"
              alignItems="center"
              sx={{ marginRight: isMobile ? 0 : 2 }}
            >
              <Typography color={styles.color} fontSize={"13px"}>
                Include Today
              </Typography>
              <Switch
                checked={includeToday}
                onChange={(e) => setIncludeToday(e.target.checked)}
              />
            </Box>
          )}
          <FormControl
            sx={{
              minWidth: "140px", // Set minimum width for consistent appearance
              flexGrow: 1, // Adjusts width dynamically for proper alignment
              svg: { fill: styles.color },
              ".MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: styles.lightGreyColor
                },
                "&:hover fieldset": {
                  borderColor: styles.color
                },
                "&.Mui-focused fieldset": {
                  borderColor: styles.color
                }
              }
            }}
          >
            <Select
              labelId="range-label"
              name="range"
              value={value}
              MenuProps={{
                MenuListProps: {
                  sx: {
                    padding: 0,
                    ".Mui-selected": {
                      color: `${styles.background} !important`,
                      background: `${styles.color} !important`
                    }
                  }
                }
              }}
              sx={{
                color: styles.color,
                marginTop: 0,
                paddingTop: 0
              }}
              onChange={(e) => setValue(e.target.value)}
            >
              <MenuItem
                sx={{
                  backgroundColor: styles.background,
                  color: styles.color,
                  paddingY: 1,
                  "&:hover": {
                    backgroundColor: styles.color,
                    color: styles.background
                  }
                }}
                value="today"
              >
                Today
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor: styles.background,
                  color: styles.color,
                  paddingY: 1,
                  "&:hover": {
                    backgroundColor: styles.color,
                    color: styles.background
                  }
                }}
                value="7days"
              >
                7 Days
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor: styles.background,
                  color: styles.color,
                  paddingY: 1,
                  "&:hover": {
                    backgroundColor: styles.color,
                    color: styles.background
                  }
                }}
                value="30days"
              >
                30 Days
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor: styles.background,
                  color: styles.color,
                  paddingY: 1,
                  "&:hover": {
                    backgroundColor: styles.color,
                    color: styles.background
                  }
                }}
                value="custom"
              >
                Custom time
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      {value === "custom" && (
        <Box display="flex" justifyContent="flex-end">
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          borderRadius: "16px",
          backgroundColor: styles.diaryTracker
        }}
      >
        <DiaryTracker
          title={"#800gChallenge"}
          nonEditable
          levelName={levelName}
          strokeColor={"#F6541C"}
          value={calculateAverage800gms?.average}
          maxValue={calculateAverage800gms?.total}
          heightX={levelName === "level2" ? (isMobile ? 130 : 210) : 250}
          widthX={levelName === "level2" ? (isMobile ? 190 : 300) : 350}
        />
        {levelName !== "level1" && (
          <DiaryTracker
            title={"Protein"}
            nonEditable
            levelName={levelName}
            strokeColor={"#368AD0"}
            value={calculateAverageProtein?.average}
            maxValue={calculateAverageProtein?.total}
            heightX={levelName === "level2" ? (isMobile ? 130 : 210) : 250}
            widthX={levelName === "level2" ? (isMobile ? 190 : 300) : 350}
          />
        )}
        {levelName === "level3" && (
          <DiaryTracker
            isCalories
            nonEditable
            levelName={levelName}
            value={calculateAverageCalories?.average}
            maxValue={calculateAverageCalories?.total}
            strokeColor={"#FF9431"}
            title={"Calories"}
            heightX={levelName === "level2" ? 210 : 250}
            widthX={levelName === "level2" ? 300 : 350}
          />
        )}
      </Box>

      {levelName === "level3" ? (
        <>
          {" "}
          {/* <Box
            width="100%"
            display={"flex"}
            justifyContent={"space-between"}
            flexDirection="column"
            alignItems={"center"}
            gap={0.6}
            borderRadius="8px"
            padding={"18px"}
            sx={{ background: styles.lightGreyColor }}
          >
            <Box
              width="100%"
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize="13px">Fat</Typography>
              <Typography fontSize="13px">
                {formatNumber(Number(extraData?.averagedSummaries?.fat)) || 0}g
              </Typography>
            </Box>
            <Box
              width="100%"
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize="13px">Carbohydrate</Typography>
              <Typography fontSize="13px">
                {formatNumber(
                  Number(extraData?.averagedSummaries?.carbohydrate)?.toFixed(2)
                ) || 0}
                g
              </Typography>
            </Box>
            <Box
              width="100%"
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize="13px">Fiber</Typography>
              <Typography fontSize="13px">
                {formatNumber(
                  Number(extraData?.averagedSummaries?.fiber)?.toFixed(2)
                ) || 0}
                g
              </Typography>
            </Box>
          </Box> */}
          <Box>
            <Typography fontSize="15px" color={styles.inputLabelColor}>
              Three Pillars Method
            </Typography>
            <Box
              mt={1}
              width="100%"
              borderRadius="8px"
              padding={"18px"}
              sx={{ background: styles.lightGreyColor }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection="column"
                alignItems={"start"}
              >
                <Typography
                  justifyContent="center"
                  alignItems="center"
                  gap="10px"
                  display="flex"
                  variant="body2"
                  fontWeight="bold"
                  color={styles.color}
                >
                  <Box
                    height="8px"
                    width="8px"
                    sx={{ background: "#F6541C" }}
                    borderRadius="50%"
                  />
                  800g
                </Typography>
                <Box
                  width="100%"
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          extraData?.totalSummaries?.entry800gSummary?.calories
                        )?.toFixed(2)
                      ) || 0}{" "}
                      Cals
                    </Typography>
                    <Typography fontSize="12px">
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.entry800gSummary
                              ?.calories
                          ) || 0
                        ) /
                          (calculateAverageCalories?.average || 1)) *
                          100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          +extraData?.totalSummaries?.entry800gSummary?.protein
                        )?.toFixed(2)
                      ) || 0}
                      g Protein
                    </Typography>
                    <Typography fontSize="12px">
                      {" "}
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.entry800gSummary?.protein
                          ) || 0
                        ) / calculateAverageProtein?.average || 0) * 100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          +extraData?.totalSummaries?.entry800gSummary
                            ?.carbohydrate
                        )?.toFixed(2)
                      ) || 0}
                      g Carbs
                    </Typography>
                    <Typography fontSize="12px">
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.entry800gSummary
                              ?.carbohydrate
                          ) || 0
                        ) / extraData?.averagedSummaries?.carbohydrate || 0) *
                          100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          +extraData?.totalSummaries?.entry800gSummary?.fiber
                        )?.toFixed(2)
                      ) || 0}
                      g Fiber
                    </Typography>
                    <Typography fontSize="12px">
                      {" "}
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.entry800gSummary?.fiber
                          ) || 0
                        ) / extraData?.averagedSummaries?.fiber || 0) * 100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          +extraData?.totalSummaries?.entry800gSummary?.fat
                        )?.toFixed(2)
                      ) || 0}
                      g Fat
                    </Typography>
                    <Typography fontSize="12px">
                      {" "}
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.entry800gSummary?.fat
                          ) || 0
                        ) / extraData?.averagedSummaries?.fat || 0) * 100
                      )}
                      %
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider
                sx={{ background: "#8E8E8E", width: "100%", m: "10px 0" }}
              />
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection="column"
                alignItems={"start"}
              >
                <Typography
                  justifyContent="center"
                  alignItems="center"
                  gap="10px"
                  display="flex"
                  variant="body2"
                  fontWeight="bold"
                  color={styles.color}
                >
                  <Box
                    height="8px"
                    width="8px"
                    sx={{ background: "#368AD0" }}
                    borderRadius="50%"
                  />
                  Protein
                </Typography>
                <Box
                  width="100%"
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          extraData?.totalSummaries?.proteinSummary?.calories
                        )?.toFixed(2)
                      ) || 0}{" "}
                      Cals
                    </Typography>
                    <Typography fontSize="12px">
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.proteinSummary?.calories
                          ) || 0
                        ) / calculateAverageCalories?.average || 0) * 100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          +extraData?.totalSummaries?.proteinSummary?.protein
                        )?.toFixed(2)
                      ) || 0}
                      g Protein
                    </Typography>
                    <Typography fontSize="12px">
                      {" "}
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.proteinSummary?.protein
                          ) || 0
                        ) / calculateAverageProtein?.average || 0) * 100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          +extraData?.totalSummaries?.proteinSummary
                            ?.carbohydrate
                        )?.toFixed(2)
                      ) || 0}
                      g Carbs
                    </Typography>
                    <Typography fontSize="12px">
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.proteinSummary
                              ?.carbohydrate
                          ) || 0
                        ) / extraData?.averagedSummaries?.carbohydrate || 0) *
                          100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          +extraData?.totalSummaries?.proteinSummary?.fiber
                        )?.toFixed(2)
                      ) || 0}
                      g Fiber
                    </Typography>
                    <Typography fontSize="12px">
                      {" "}
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.proteinSummary?.fiber
                          ) || 0
                        ) / extraData?.averagedSummaries?.fiber || 0) * 100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          +extraData?.totalSummaries?.proteinSummary?.fat
                        )?.toFixed(2)
                      ) || 0}
                      g Fat
                    </Typography>
                    <Typography fontSize="12px">
                      {" "}
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.proteinSummary?.fat
                          ) || 0
                        ) / extraData?.averagedSummaries?.fat || 0) * 100
                      )}
                      %
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider
                sx={{ background: "#8E8E8E", width: "100%", m: "10px 0" }}
              />
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection="column"
                alignItems={"start"}
              >
                <Typography
                  justifyContent="center"
                  alignItems="center"
                  gap="10px"
                  display="flex"
                  variant="body2"
                  fontWeight="bold"
                  color={styles.color}
                >
                  <Box
                    height="8px"
                    width="8px"
                    sx={{ background: "#FF9431" }}
                    borderRadius="50%"
                  />
                  Other
                </Typography>
                <Box
                  width="100%"
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          extraData?.totalSummaries?.otherFoodSummary?.calories
                        )?.toFixed(2)
                      ) || 0}{" "}
                      Cals
                    </Typography>
                    <Typography fontSize="12px">
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.otherFoodSummary
                              ?.calories
                          ) || 0
                        ) / calculateAverageCalories?.average || 0) * 100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          +extraData?.totalSummaries?.otherFoodSummary?.protein
                        )?.toFixed(2)
                      ) || 0}
                      g Protein
                    </Typography>
                    <Typography fontSize="12px">
                      {" "}
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.otherFoodSummary?.protein
                          ) || 0
                        ) / calculateAverageProtein?.average || 0) * 100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          +extraData?.totalSummaries?.otherFoodSummary
                            ?.carbohydrate
                        )?.toFixed(2)
                      ) || 0}
                      g Carbs
                    </Typography>
                    <Typography fontSize="12px">
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.otherFoodSummary
                              ?.carbohydrate
                          ) || 0
                        ) / extraData?.averagedSummaries?.carbohydrate || 0) *
                          100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          +extraData?.totalSummaries?.otherFoodSummary?.fiber
                        )?.toFixed(2)
                      ) || 0}
                      g Fiber
                    </Typography>
                    <Typography fontSize="12px">
                      {" "}
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.otherFoodSummary?.fiber
                          ) || 0
                        ) / extraData?.averagedSummaries?.fiber || 0) * 100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          +extraData?.totalSummaries?.otherFoodSummary?.fat
                        )?.toFixed(2)
                      ) || 0}
                      g Fat
                    </Typography>
                    <Typography fontSize="12px">
                      {" "}
                      {Math.round(
                        (formatNumber(
                          Number(
                            extraData?.totalSummaries?.otherFoodSummary?.fat
                          ) || 0
                        ) / extraData?.averagedSummaries?.fat || 0) * 100
                      )}
                      %
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography fontSize="15px" color={styles.inputLabelColor}>
              Macronutrients
            </Typography>
            <Box
              mt={1}
              width="100%"
              borderRadius="8px"
              padding={"18px"}
              sx={{ background: styles.lightGreyColor }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection="column"
                alignItems={"start"}
                gap="10px"
              >
                <Box
                  width="100%"
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {calculateAverageCalories?.average} Cals
                    </Typography>
                    <Typography fontSize="12px">
                      {Math.round(
                        (formatNumber(
                          Number(calculateAverageCalories?.average) || 0
                        ) / calculateAverageCalories?.total || 0) * 100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {calculateAverageProtein?.average}g Protein
                    </Typography>
                    <Typography fontSize="12px">
                      {Math.round(
                        (formatNumber(
                          Number(calculateAverageProtein?.average) || 0
                        ) / calculateAverageProtein?.total || 0) * 100
                      )}
                      %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          extraData?.averagedSummaries?.carbohydrate
                        )?.toFixed(2)
                      ) || 0}
                      g Carbs
                    </Typography>
                    <Typography fontSize="12px">33%</Typography>
                  </Box>
                  <Box mr={2}>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(extraData?.averagedSummaries?.fat)?.toFixed(2)
                      ) || 0}
                      g Fat
                    </Typography>
                    <Typography fontSize="12px">12%</Typography>
                  </Box>
                </Box>
                <Box
                  width="100%"
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box width={"90px"}></Box>
                  <Box width={"90px"}></Box>
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(extraData?.averagedSummaries?.fiber)?.toFixed(2)
                      ) || 0}
                      g Fiber
                    </Typography>
                  </Box>{" "}
                  <Box>
                    <Typography fontSize="12px" color={styles.lightBlack}>
                      {formatNumber(
                        Number(
                          extraData?.averagedSummaries?.saturated_fat
                        )?.toFixed(2)
                      ) || 0}
                      g Saturated Fat
                    </Typography>
                    <Typography fontSize="12px" textAlign="center">
                      33%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {extraData?.averagedSummaries &&
          Object.keys(extraData?.averagedSummaries)?.length ? (
            <Box>
              <Typography fontSize="15px" color={styles.inputLabelColor}>
                Select Micronutrients
              </Typography>
              <Box
                width="100%"
                padding={"18px"}
                sx={{
                  background: styles.lightGreyColor,
                  borderRadius: 2,
                  mt: 1
                }}
                borderRadius={1}
              >
                {/* Vitamins and Minerals Group */}
                <NutrientRow name="Calcium" unit="mg" />
                <NutrientRow name="Iron" unit="mg" />
                <NutrientRow name="Potassium" unit="mg" />
                <NutrientRow name="Sodium" unit="mg" />
                <NutrientRow name="Vitamin A" unit="mcg" />
                <NutrientRow name="Vitamin C" unit="mg" />
                <NutrientRow name="Vitamin D" unit="mcg" />
              </Box>
              {/* <Box
                mt={1}
                width="100%"
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection="column"
                alignItems={"center"}
                gap={0.6}
                borderRadius="8px"
                padding={"18px"}
                sx={{ background: styles.lightGreyColor }}
              >
                {formattedNutrients(extraData?.averagedSummaries)?.map(
                  (item) => (
                    <Box
                      width="100%"
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography fontSize="13px">{item.name}</Typography>
                      <Typography fontSize="13px">
                        {item.value + item.unit}
                      </Typography>
                    </Box>
                  )
                )}
              </Box> */}
            </Box>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      {levelName !== "level3" ? (
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          mb={2}
          sx={{
            ...(!isMobile && {
              overflowY: "auto",
              maxHeight: "calc(100vh - 120px)"
            })
          }}
        >
          {allStats?.map((item) => (
            <>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                sx={{
                  background: styles.modBackgroundBlocks,
                  borderRadius: "16px",
                  p: 3
                }}
              >
                <Box display="flex" gap="10px" alignItems="center">
                  <Typography fontWeight={600} fontSize={"18px"}>
                    {dayjs(item?.currentDayDate).format("MMMM D")}
                  </Typography>
                  {+item.totalEntry800g >= +item.outOf800g &&
                    (levelName === "level2"
                      ? +item.totalProtein >= +item.outOfProtein
                      : true) && (
                      <Box
                        display="flex"
                        gap="4px"
                        alignItems="center"
                        sx={{
                          background: "#F1B44C",
                          px: 1,
                          border: `1px solid ${styles.color}`,
                          borderRadius: "100px"
                        }}
                      >
                        <TrophyIcon />
                        <Typography color="#14142B" fontSize={"12px"}>
                          Goal Achieved
                        </Typography>
                      </Box>
                    )}
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography fontWeight={600} color="#F6541C" fontSize="16px">
                    {" "}
                    800g
                  </Typography>
                  <Box>
                    <Typography fontWeight={600} fontSize="16px">
                      {levelName === "level3"
                        ? `${
                            item?.entry800gSummary?.metric_serving_amount || 0
                          }/${total800g}g`
                        : `${item.totalEntry800g || 0}/${item.outOf800g}g`}
                    </Typography>
                  </Box>
                </Box>
                <BorderLinearProgress
                  activeColor="#F6541C"
                  background={styles.lightBlack}
                  variant="determinate"
                  value={
                    levelName === "level3"
                      ? ((item?.entry800gSummary?.metric_serving_amount || 0) >=
                        total800g
                          ? 1
                          : Math.round(
                              item?.entry800gSummary?.metric_serving_amount || 0
                            ) / total800g) * 100
                      : ((item.totalEntry800g || 0) >= item.outOf800g
                          ? 1
                          : (item.totalEntry800g || 0) / item.outOf800g) * 100
                  }
                />
                {levelName !== "level1" && (
                  <>
                    {" "}
                    <Box display="flex" mt={1} justifyContent="space-between">
                      <Typography
                        fontWeight={600}
                        color="#368AD0"
                        fontSize="16px"
                      >
                        {" "}
                        Protein
                      </Typography>
                      <Box>
                        <Typography fontWeight={600} fontSize="16px">
                          {levelName === "level3"
                            ? `${Math.round(
                                item?.entry800gSummary?.protein +
                                  item?.proteinSummary?.protein +
                                  item?.otherFoodSummary?.protein || 0
                              )}/${totalProteins}g`
                            : `${item.totalProtein || 0}/${item.outOfProtein}g`}
                        </Typography>
                      </Box>
                    </Box>
                    <BorderLinearProgress
                      activeColor="#368AD0"
                      background={styles.lightBlack}
                      variant="determinate"
                      value={
                        levelName === "level3"
                          ? ((item?.entry800gSummary?.protein +
                              item?.proteinSummary?.protein +
                              item?.otherFoodSummary?.protein ||
                              0 ||
                              0) >= totalProteins
                              ? 1
                              : (item?.entry800gSummary?.protein +
                                  item?.proteinSummary?.protein +
                                  item?.otherFoodSummary?.protein ||
                                  0 ||
                                  0) / totalProteins) * 100
                          : ((item.totalProtein || 0) >= item.outOfProtein
                              ? 1
                              : (item.totalProtein || 0) / item.outOfProtein) *
                            100
                      }
                    />
                  </>
                )}
                {/* {levelName === "level3" && (
                <>
                  {" "}
                  <Box display="flex" mt={1} justifyContent="space-between">
                    <Typography
                      fontWeight={600}
                      color="#FF9431"
                      fontSize="16px"
                    >
                      {" "}
                      Calories
                    </Typography>
                    <Box>
                      <Typography fontWeight={600} fontSize="16px">
                        {Math.round(
                          item?.entry800gSummary?.calories +
                            item?.proteinSummary?.calories +
                            item?.otherFoodSummary?.calories ||
                            0 ||
                            0
                        )}
                        /{totalCalories}
                      </Typography>
                    </Box>
                  </Box>
                  <BorderLinearProgress
                    activeColor="#FF9431"
                    background={styles.lightBlack}
                    variant="determinate"
                    value={
                      ((item?.entry800gSummary?.calories +
                        item?.proteinSummary?.calories +
                        item?.otherFoodSummary?.calories ||
                        0 ||
                        0) >= totalCalories
                        ? 1
                        : (item?.entry800gSummary?.calories +
                            item?.proteinSummary?.calories +
                            item?.otherFoodSummary?.calories ||
                            0 ||
                            0) / totalCalories) * 100
                    }
                  />
                </>
              )} */}
              </Box>
            </>
          ))}
        </Box>
      ) : allStats?.length ? (
        <Box display="flex" flexDirection="column" gap={2} mb={2}>
          <Graph
            total800g={total800g}
            totalProteins={totalProteins}
            totalCalories={totalCalories}
            data={allStats}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default StatsList;
