import {
  Box,
  Button,
  Grid2,
  InputAdornment,
  Link,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import ViewUserMeals from "../Modals/ViewUserMeals";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import MealCard from "../Meals/MealCard";
import {
  selectLoading,
  selectRecentMealData
} from "../../features/meals/mealsSelectors";
import { getRecentMeals } from "../../features/meals/mealsSlice";
import { favoriteMeal, likeMeal } from "../../api/modApi";
import useThemeColor from "../../utils/useTheme";
import { selectCurrentUser } from "../../features/auth/authSelectors";

const Meals = ({ setOpenSubscriptionModal }) => {
  const [isViewingUserMeals, setIsViewingUserMeals] = React.useState({});
  const [isRefresh, setIsRefresh] = React.useState(false);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState("favorites");
  const theme = useTheme(); // Use theme hook to get the theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Correctly access breakpoints
  const loader = useSelector(selectLoading);
  const mealsData = useSelector(selectRecentMealData);
  const styles = useThemeColor();
  const [localMealsData, setLocalMealsData] = useState([]);
  const userData = useSelector(selectCurrentUser);
  useEffect(() => {
    setLocalMealsData(mealsData);
  }, [mealsData]);

  useEffect(() => {
    if (
      !(
        userData?.isAdmin === 1 ||
        ["Coach", "Company"].includes(userData?.userType)
      ) &&
      !userData?.isSubscriptionActive &&
      (userData?.isTrainingFreeTrialExpired ||
        userData?.isDiaryFreeTrialExpired)
    ) {
      setOpenSubscriptionModal(true);
    }
  }, [userData]);
  useEffect(() => {
    if (activeTab)
      if (!searchValue || searchValue.length > 3 || isRefresh) {
        dispatch(
          getRecentMeals({
            searchTerm: searchValue,
            currentDate: dayjs().format("YYYY-MM-DD"),
            type: activeTab
          })
        );
        setIsRefresh(false);
      }
  }, [dispatch, isViewingUserMeals, activeTab, isRefresh, searchValue]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const scrollableElement = document.getElementById("dashboard-main"); // or document.body
    scrollableElement.scrollTo(0, 0);
  }, []);

  const activeStyles = useCallback(
    (currentTab) => {
      return currentTab === activeTab
        ? {
            background: "#368Ad0",
            color: "#FCFCFC",
            border: "none",
            textTransform: "none"
          }
        : {
            border: "1px solid #6B7280",
            color: "#6B7280",
            textTransform: "none"
          };
    },
    [activeTab]
  );

  const handleLikeToggle = async (mealId) => {
    const updatedMeals = localMealsData.map((meal) =>
      meal._id === mealId
        ? {
            ...meal,
            likedByUser: meal.likedByUser ? 0 : 1,
            likeCount: meal.likedByUser
              ? meal.likeCount - 1
              : meal.likeCount + 1
          }
        : meal
    );

    setLocalMealsData([...updatedMeals]);
    await likeMeal(mealId);
  };

  const handleFavoriteToggle = async (mealId) => {
    const updatedMeals = localMealsData.map((meal) =>
      meal._id === mealId
        ? {
            ...meal,
            favouriteByUser: meal.favouriteByUser ? 0 : 1
          }
        : meal
    );

    setLocalMealsData([...updatedMeals]);
    await favoriteMeal(mealId);
  };

  return !(
    userData?.isAdmin === 1 || ["Coach", "Company"].includes(userData?.userType)
  ) &&
    !userData?.isSubscriptionActive &&
    (userData?.isTrainingFreeTrialExpired ||
      userData?.isDiaryFreeTrialExpired) ? (
    <></>
  ) : (
    <Box pb={10}>
      {Object.keys(isViewingUserMeals).length > 0 ? (
        <ViewUserMeals
          isViewingUserMeals={isViewingUserMeals}
          setIsViewingUserMeals={setIsViewingUserMeals}
        />
      ) : (
        <>
          <Box
            display="flex"
            padding={{
              lg: "20px 20px 40px 32px",
              sm: "40px 32px",
              xs: "20px 20px 0 20px"
            }}
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems={isMobile ? "flex-start" : "center"}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="24px"
              sx={{
                width:
                  activeTab === "favorites" && !localMealsData?.length
                    ? "100%"
                    : "unset"
              }}
            >
              <Typography
                fontSize={"30px"}
                fontWeight={500}
                color={styles.color}
              >
                Meal Library
              </Typography>
              <TextField
                variant="outlined" // You can change this to "filled" or "standard" as needed
                placeholder="Search..."
                size="small" // Adjust size if needed
                fullWidth // Makes the search bar take full width
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ height: "17px", color: "#4B5563" }}
                        />
                      </InputAdornment>
                    )
                  }
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    padding: "14px 11px",
                    height: "100%",
                    //   width: "387px",
                    borderRadius: 2,
                    color: "#6B7280",
                    "& fieldset": {
                      borderColor: "#358ad0 !important"
                    }
                  },
                  "& .Mui-focused": {
                    "& fieldset": {
                      borderColor: "#358ad0 !important"
                    }
                  },
                  height: "48px",
                  width: !isMobile ? "387px" : "unset",
                  fontSize: "14px",
                  background: styles.background, // Optional: set background color
                  borderRadius: 2 // Optional: round corners
                }}
              />
              <Box display="flex" gap="10px" textTransform="none">
                <Button
                  onClick={() => setActiveTab("favorites")}
                  sx={activeStyles("favorites")}
                >
                  My Favorites 
                </Button>
                <Button
                  onClick={() => setActiveTab("popular")}
                  sx={activeStyles("popular")}
                >
                  Most Popular
                </Button>
                <Button
                  onClick={() => setActiveTab("recent")}
                  sx={activeStyles("recent")}
                  variant="outlined"
                >
                  Most Recent
                </Button>
              </Box>
              <Box>
                {loader ? (
                  <Skeleton sx={{ background: "gray" }} />
                ) : activeTab === "favorites" && !localMealsData?.length ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={"50vh"}
                  >
                    <Typography color={styles.modLabel}>
                      You have not saved any meals. Head on over to the{" "}
                      <Link
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                          color: "#368AD0"
                        }}
                        onClick={() => setActiveTab("popular")}
                      >
                        Most Popular
                      </Link>{" "}
                      or{" "}
                      <Link
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                          color: "#368AD0"
                        }}
                        onClick={() => setActiveTab("recent")}
                      >
                        Most Recent
                      </Link>{" "}
                      tabs to see what the community is sharing.
                    </Typography>
                  </Box>
                ) : (
                  <Grid2 container spacing={2.5} rowSpacing={4}>
                    {localMealsData?.map((meal, index) => (
                      <Grid2 item xs={2} key={index}>
                        <MealCard
                          searchValue={searchValue}
                          meal={meal}
                          setIsViewingUserMeals={setIsViewingUserMeals}
                          currentTab={activeTab}
                          isFromViewingMeal={false}
                          onLikeToggle={(id) => handleLikeToggle(id)}
                          onFavourite={(id) => handleFavoriteToggle(id)}
                          setIsRefresh={setIsRefresh}
                        />
                      </Grid2>
                    ))}
                  </Grid2>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Meals;
