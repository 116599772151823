import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Snackbar,
  styled,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as CameraIcon } from "../../assets/camera.svg";
import { useEffect, useRef, useState } from "react";
import { Add, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { addNewMod } from "../../features/mod/modSlice";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import ThankyouModal from "./ThankYouModal";
import { useTheme } from "@emotion/react";
import { uploadFile } from "../../utils/uploadFile";
import { getUniqueSuffix } from "../../utils/getUniqueSuffix";
import useThemeColor from "../../utils/useTheme";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(4, 0, 0, 0)
  },
  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(1, 0, 3, 3)
  }
}));

const StyledInputLabel = styled("label")(({ theme, styledColors }) => ({
  position: "absolute",
  top: "-15px",
  color: styledColors.inputLabelColor,
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ styledColors }) => ({
  "& .MuiOutlinedInput-root": {
    // fontSize: "14px",
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    ".Mui-disabled": {
      "-webkit-text-fill-color": "#6A7178"
    },
    ".MuiInputBase-input": {
      color: styledColors?.color
    },
    "& fieldset": {
      borderColor: styledColors?.lightGreyColor
    },
    "&.Mui-focused fieldset": {
      borderColor: styledColors?.lightGreyColor
    }
  }
}));

const AddMod = ({ isAddingMeal, isLoading, setIsLoading, setIsAddingMeal }) => {
  const [formData, setFormData] = useState({
    title: "",
    generalText: "",
    profilePic: null,
    total800Gms: "",
    totalProteinGms: "",
    fruitsVeggies: [{ name: "", quantity: "", unit: "gram" }],
    proteins: [{ name: "", quantity: "", unit: "gram" }],
    otherFood: [{ name: "", quantity: "", unit: "gram" }],
    agree: false
  });
  const styles = useThemeColor();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openThanksModal, setOpenThanksModal] = useState(false);
  const dispatch = useDispatch();
  // Handle input change for title, generalText, and other inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  // Handle dynamic field changes for fruits, proteins, and otherFood
  const handleDynamicInputChange = (index, e, type) => {
    const { name, value } = e.target;
    const updatedArray = formData[type].map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormData((prev) => ({
      ...prev,
      [type]: updatedArray
    }));
  };

  // Handle adding new input for fruitsVeggies, proteins, or otherFood
  // const handleAddMore = (type) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     [type]: [...prev[type], { name: "", quantity: "", unit: "gram" }]
  //   }));
  // };

  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 10 * 1024 * 1024) {
      setSnackbarMessage("File size should be less than 10MB.");
      setOpenSnackbar(true);
      return;
    }
    setFormData((prev) => ({
      ...prev,
      profilePic: file
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (!formData.title || !formData.profilePic) {
        setSnackbarMessage(
          `Meal ${
            !formData.title ? "name" : !formData.profilePic ? "image" : ""
          } is required`
        );
        setOpenSnackbar(true);
        setLoading(false);
        return;
      }
      if (formData.fruitsVeggies.length && !formData.fruitsVeggies[0].name) {
        setSnackbarMessage("Provide atleast one fruit and veggies details.");
        setOpenSnackbar(true);
        setLoading(false);
        return;
      }

      // Prepare data object
      const data = {
        title: formData.title,
        total800Gms: formData.total800Gms,
        totalProteinGms: formData.totalProteinGms,
        fruitsVeggies: [],
        otherIngredients: [],
        protein: [],
        generalText: formData.generalText || "",
        status: "pending"
      };

      // Process fruitsVeggies array
      if (
        formData.fruitsVeggies.length > 0 &&
        formData.fruitsVeggies[0].name !== ""
      ) {
        formData.fruitsVeggies.forEach((item) => {
          if (item.name) {
            data.fruitsVeggies.push({
              name: item.name,
              quantity: item.quantity,
              unit: item.unit
            });
          }
        });
      }

      // Process other ingredients array
      if (formData.otherFood.length > 0 && formData.otherFood[0].name !== "") {
        formData.otherFood.forEach((item) => {
          if (item.name) {
            data.otherIngredients.push({
              name: item.name,
              quantity: item.quantity,
              unit: item.unit
            });
          }
        });
      }

      // Process proteins array
      if (formData.proteins.length > 0 && formData.proteins[0].name !== "") {
        formData.proteins.forEach((item) => {
          if (item.name) {
            data.protein.push({
              name: item.name,
              quantity: item.quantity,
              unit: item.unit
            });
          }
        });
      }

      // Convert data to JSON string
      const uniqueFileName = getUniqueSuffix(formData.profilePic?.name);
      const jsonString = JSON.stringify({ ...data, mealImage: uniqueFileName });

      // Encode JSON string to Base64 (btoa is used for encoding)
      const base64EncodedData = btoa(encodeURIComponent(jsonString));

      const { payload } = await dispatch(
        addNewMod({ data: base64EncodedData })
      );

      await uploadFile(
        formData.profilePic,
        uniqueFileName,
        process.env.REACT_APP_BACKEND_MEAL_IMAGE_LOCATION
      );

      setLoading(false);
      if (!payload.error) {
        setOpenThanksModal(true);
      } else {
        setSnackbarMessage(
          payload.error || "Failed to add meal. Please try again."
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      setLoading(false);
      setSnackbarMessage("Failed to add meal. Please try again.");
      setOpenSnackbar(true);
    }
  };
  const handleDelete = (index, type) => {
    const updatedArray = formData[type].filter((_, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      [type]: updatedArray
    }));
  };
  const theme = useTheme(); // Use theme hook to get the theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Correctly access breakpoints

  const fileInputRef = useRef(null);

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleKeyDown = (e, type, index) => {
    if (e.key === "Enter") {
      e.preventDefault();

      // Get all input elements
      const inputs = document.querySelectorAll(
        'input:not([type="file"]), textarea'
      );
      const inputArray = Array.from(inputs);

      // Find current input index
      const currentIndex = inputArray.findIndex((input) => input === e.target);

      // Focus next input if available
      if (currentIndex < inputArray.length - 1) {
        inputArray[currentIndex + 1].focus();
      }
    }
  };
  return !isLoading ? (
    <Box
      width="100%"
      padding={"20px 20px 80px 20px"}
      // overflow="auto"
      // height="100dvh"
    >
      {" "}
      <Box
        display={"flex"}
        alignItems="center"
        gap="20px"
        padding={isMobile ? 2 : 0}
      >
        <IconButton onClick={() => setIsAddingMeal(false)} sx={{ padding: 0 }}>
          <CloseIcon
            sx={{ height: "30px", color: styles.color, width: "30px" }}
          />
        </IconButton>
        <Typography
          color={styles.color}
          fontSize={isMobile ? "24px" : "30px"}
          fontWeight={isMobile ? 600 : 500}
        >
          Submit your MOD
        </Typography>
      </Box>
      <Box
        component="form"
        margin={isMobile ? "20px 0 0 0" : "24px 40px 0 0"}
        display={!isMobile ? "flex" : "block"}
      >
        <input
          ref={fileInputRef}
          id="upload-input"
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <Box>
          {formData.profilePic ? (
            <Box sx={{ position: "relative", display: "inline-block" }}>
              <label htmlFor="upload-input">
                <Box
                  // onClick={handleEditClick}
                  component="img"
                  alt="Meal Pic"
                  src={URL.createObjectURL(formData.profilePic)}
                  sx={{
                    width: isMobile ? "100%" : 500,
                    // height: isMobile ? 300 : 500,
                    marginTop: 2,
                    border: `1px dashed ${styles.inputLabelColor}`
                  }}
                />
              </label>
            </Box>
          ) : (
            <Box
              onClick={handleEditClick}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: isMobile ? "100%" : 500,
                height: isMobile ? 300 : 500,
                border: `1px dashed  ${styles.inputLabelColor}`,
                borderRadius: "10px",
                position: "relative",
                cursor: "pointer",
                bgcolor: styles.lightGreyColor
              }}
            >
              <CameraIcon width={67} height={60} color="#D1D5DB" />
              <Typography
                fontSize="12px"
                fontWeight="bold"
                color={styles.color}
                mt={1}
              >
                Tap to upload
              </Typography>
              <Typography fontSize="10px" color={styles.color}>
                (Supported file PNG, JPEG)
              </Typography>
            </Box>
          )}
        </Box>
        <Box width={"100%"}>
          <StyledTextFieldContainer>
            <StyledInputLabel styledColors={styles} htmlFor="title">
              Meal Name
            </StyledInputLabel>
            <StyledTextField
              styledColors={styles}
              fullWidth
              onKeyDown={(e) => handleKeyDown(e)}
              variant="outlined"
              name="title"
              placeholder="Enter a name for the meal"
              value={formData.title}
              onChange={handleInputChange}
              required
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            style={{
              marginBottom: 0,
              marginTop: "30px"
            }}
          >
            <StyledInputLabel styledColors={styles} htmlFor="fruitsvegies">
              #800gChallenge Foods
            </StyledInputLabel>
            {formData.fruitsVeggies.map((item, index) => (
              <Box display="flex" alignItems="center" gap="20px">
                <StyledTextField
                  styledColors={styles}
                  fullWidth
                  variant="outlined"
                  name="name"
                  placeholder="(e.g., 150g broccoli, 1 apple, 1 cup blueberries)"
                  value={item.name}
                  margin="normal"
                  onKeyDown={(e) => handleKeyDown(e, "fruitsVeggies", index)}
                  onChange={(e) =>
                    handleDynamicInputChange(index, e, "fruitsVeggies")
                  }
                  required
                />
                {formData.fruitsVeggies.length > 1 && (
                  <IconButton
                    onClick={() => handleDelete(index, "fruitsVeggies")}
                    sx={{
                      fontSize: "16px",
                      borderRadius: "4px",
                      background: "#FF4000",
                      svg: {
                        path: {
                          fill: "#FCFCFC"
                        }
                      },
                      "&:hover": {
                        background: "#FF4000"
                      }
                    }}
                  >
                    <Delete height={24} width={24} />
                  </IconButton>
                )}
              </Box>
            ))}
            {/* <Button
              fullWidth
              variant="outlined"
              onClick={() => handleAddMore("fruitsVeggies")}
              sx={{
                textTransform: "none",
                color: "#368AD0",
                borderColor: "#368AD0",
                marginTop: "12px",
                gap: "8px",
                borderRadius: "4px"
              }}
            >
              <Add
                sx={{
                  path: {
                    fill: "#368AD0"
                  }
                }}
              />
              Add more
            </Button> */}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            style={{
              marginBottom: 0,
              marginTop: "30px"
            }}
          >
            <StyledInputLabel styledColors={styles} htmlFor="protein">
              Protein Foods
            </StyledInputLabel>
            {formData.proteins.map((item, index) => (
              <Box display="flex" alignItems="center" gap="20px">
                <StyledTextField
                  styledColors={styles}
                  fullWidth
                  variant="outlined"
                  name="name"
                  placeholder="(e.g., 1 chicken breast, 1 cup cottage cheese, 4oz steak)"
                  value={item.name}
                  margin="normal"
                  onKeyDown={(e) => handleKeyDown(e, "fruitsVeggies", index)}
                  onChange={(e) =>
                    handleDynamicInputChange(index, e, "proteins")
                  }
                  required
                />
                {/* <StyledTextField styledColors={styles}
                  type="number"
                  name="quantity"
                  margin="normal"
                  slotProps={{ htmlInput: { min: 1 } }}
                  placeholder="No. (Optional)"
                  value={item.quantity}
                  onChange={(e) =>
                    handleDynamicInputChange(index, e, "proteins")
                  }
                  sx={{
                    marginTop: "16px",
                    width: { xs: "30%", md: "25%" }
                  }}
                />
                <StyledTextField styledColors={styles}
                  margin="normal"
                  placeholder="Protein Grams"
                  // value="Gram"
                  disabled
                  sx={{
                    marginTop: "16px",
                    borderRadius: "4px",
                    width: { xs: "30%", md: "25%" },
                    background: "#333333"
                  }}
                /> */}
                {formData.proteins.length > 1 && (
                  <IconButton
                    onClick={() => handleDelete(index, "proteins")}
                    sx={{
                      fontSize: "16px",
                      borderRadius: "4px",
                      background: "#FF4000",
                      svg: {
                        path: {
                          fill: "#FCFCFC"
                        }
                      },
                      "&:hover": {
                        background: "#FF4000"
                      }
                    }}
                  >
                    <Delete height={24} width={24} />
                  </IconButton>
                )}
              </Box>
            ))}
            {/* <Button
              fullWidth
              variant="outlined"
              onClick={() => handleAddMore("proteins")}
              sx={{
                textTransform: "none",
                color: "#368AD0",
                borderColor: "#368AD0",
                marginTop: "12px",
                gap: "8px",
                borderRadius: "4px"
              }}
            >
              <Add
                sx={{
                  path: {
                    fill: "#368AD0"
                  }
                }}
              />
              Add more
            </Button> */}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            style={{
              marginBottom: 0,
              marginTop: "30px"
            }}
          >
            <StyledInputLabel styledColors={styles} htmlFor="other">
              Other Foods
            </StyledInputLabel>
            {formData.otherFood.map((item, index) => (
              <Box display="flex" alignItems="center" gap="20px">
                <StyledTextField
                  styledColors={styles}
                  fullWidth
                  variant="outlined"
                  name="name"
                  placeholder="(e.g., 1 cup rice, 2 tbsp ranch dressing, 1 oz walnuts"
                  value={item.name}
                  margin="normal"
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={(e) =>
                    handleDynamicInputChange(index, e, "otherFood")
                  }
                  required
                />
                {/* <StyledTextField styledColors={styles}
                  type="number"
                  name="quantity"
                  margin="normal"
                  placeholder="No."
                  value={item.quantity}
                  slotProps={{ htmlInput: { min: 1 } }}
                  onChange={(e) => handleDynamicInputChange(index, e, "otherFood")}
                  sx={{ marginTop: "16px", width: { xs: "30%", md: "25%" } }}
                />
                <FormControl
                  sx={{
                    width: { xs: "40%", md: "25%" },
                    marginTop: "8px",
                    svg: {
                      fill: "#FCFCFC"
                    },
                    ".MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#333333"
                      },
                      "&:hover fieldset": {
                        borderColor: "#FCFCFC"
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#FCFCFC"
                      }
                    }
                  }}
                >
                  <Select
                    labelId="unit-label"
                    name="unit"
                    value={item.unit}
                    sx={{
                      color: "#FCFCFC",
                      marginTop: 0, // Remove any margin
                      paddingTop: 0 // Remove any padding
                    }}
                    displayEmpty
                    onChange={(e) =>
                      handleDynamicInputChange(index, e, "otherFood")
                    }
                  >
                    <MenuItem value="gram">Gram</MenuItem>
                    <MenuItem value="pounds">Pounds</MenuItem>
                    <MenuItem value="cup">Cup</MenuItem>
                    <MenuItem value="pint">Pint</MenuItem>
                    <MenuItem value="Tps">Tps</MenuItem>
                    <MenuItem value="tps">tps</MenuItem>
                  </Select>
                </FormControl> */}
                {formData.otherFood.length > 1 && (
                  <IconButton
                    onClick={() => handleDelete(index, "otherFood")}
                    sx={{
                      fontSize: "16px",
                      borderRadius: "4px",
                      background: "#FF4000",
                      svg: {
                        path: {
                          fill: "#FCFCFC"
                        }
                      },
                      "&:hover": {
                        background: "#FF4000"
                      }
                    }}
                  >
                    <Delete height={24} width={24} />
                  </IconButton>
                )}
              </Box>
            ))}
            {/* <Button
              fullWidth
              variant="outlined"
              onClick={() => handleAddMore("otherFood")}
              sx={{
                textTransform: "none",
                color: "#368AD0",
                borderColor: "#368AD0",
                marginTop: "12px",
                gap: "8px",
                borderRadius: "4px"
              }}
            >
              <Add
                sx={{
                  path: {
                    fill: "#368AD0"
                  }
                }}
              />
              Add more
            </Button> */}
          </StyledTextFieldContainer>
          <Box mt={2} display={"flex"} flexDirection={"column"} gap="10px">
            <Box
              display={"flex"}
              gap="10px"
              alignItems="center"
              sx={{ marginLeft: { md: 3 } }}
            >
              <Typography
                color={styles.inputLabelColor}
                fontWeight="bold"
                fontSize="13px"
                pointerEvents="none"
              >
                #800gChallenge Grams :
              </Typography>
              <StyledTextField
                styledColors={styles}
                type="number"
                name="total800Gms"
                margin="normal"
                placeholder="No. (Optional)"
                value={formData.total800Gms}
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only numeric values and ensure it's at least 0
                  if (/^\d*$/.test(value) && Number(value) >= 0) {
                    handleInputChange(e);
                  }
                }}
                slotProps={{ htmlInput: { min: 1 } }}
                sx={{
                  "& input[type=number]": {
                    padding: "10px"
                  },
                  width: { md: "25%" }
                }}
              />
            </Box>
            <Box
              display={"flex"}
              gap="10px"
              alignItems="center"
              sx={{ marginLeft: { md: 3 } }}
            >
              <Box>
                <Typography
                  color={styles.inputLabelColor}
                  fontWeight="bold"
                  fontSize="13px"
                  pointerEvents="none"
                >
                  Protein Grams :
                </Typography>
              </Box>
              <StyledTextField
                styledColors={styles}
                type="number"
                onKeyDown={(e) => handleKeyDown(e)}
                name="totalProteinGms"
                margin="normal"
                placeholder="No. (Optional)"
                value={formData.totalProteinGms}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only numeric values and ensure it's at least 0
                  if (/^\d*$/.test(value) && Number(value) >= 0) {
                    handleInputChange(e);
                  }
                }}
                slotProps={{ htmlInput: { min: 1 } }}
                sx={{
                  "& input[type=number]": {
                    padding: "10px"
                  },
                  width: { md: "25%" }
                }}
              />
            </Box>
          </Box>
          <StyledTextFieldContainer style={{ marginTop: "30px" }}>
            <StyledInputLabel styledColors={styles} htmlFor="generalText">
              Notes
            </StyledInputLabel>
            <StyledTextField
              styledColors={styles}
              fullWidth
              onKeyDown={(e) => handleKeyDown(e)}
              variant="outlined"
              name="generalText"
              placeholder="Add any helpful instructions or tips!"
              value={formData.generalText}
              onChange={handleInputChange}
              required
              multiline
              rows={5}
              margin="normal"
            />
          </StyledTextFieldContainer>{" "}
          <StyledTextFieldContainer>
            {" "}
            <FormGroup>
              {" "}
              <FormControlLabel
                sx={{
                  color: styles.inputLabelColor,
                  fontWeight: "bold",
                  fontSize: "14px"
                }}
                required
                control={
                  <Checkbox
                    name="agree"
                    checked={formData.agree}
                    onChange={() =>
                      setFormData({ ...formData, agree: !formData.agree })
                    }
                    icon={
                      <CheckBoxOutlineBlank
                        sx={{ color: styles.inputLabelColor }} // Gray color when unchecked
                      />
                    }
                    checkedIcon={
                      <CheckBox
                        sx={{
                          color: styles.color,
                          borderRadius: "4px"
                        }}
                      />
                    }
                    sx={{
                      "&.Mui-checked": {
                        border: "none",
                        color: "#368AD0" // Green border when checked
                      }
                    }}
                  />
                }
                label="The picture I uploaded is my own and release the rights to use in
            this app"
              />
            </FormGroup>
          </StyledTextFieldContainer>
          <Box
            ml={!isMobile ? 2.8 : 0}
            mt={isMobile ? 3 : 0}
            gap={2}
            display="flex"
          >
            <Button
              fullWidth
              onClick={handleSubmit}
              disabled={
                !formData.title || !formData.profilePic || !formData.agree
              }
              sx={{
                textTransform: "none",
                color: "#fcfcfc",
                padding: "12px 20.5px",
                background: "#368AD0",
                fontWeight: 700,
                "&:disabled": {
                  backgroundColor: "#A7C8E9",
                  color: "#64AEDD",
                  pointerEvents: "none"
                }
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={4000}
              onClose={() => setOpenSnackbar(false)}
            >
              <Alert onClose={() => setOpenSnackbar(false)} severity="error">
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Box>
        {openThanksModal && (
          <ThankyouModal
            openThanksModal={openThanksModal}
            handleCloseThanksModal={setOpenThanksModal}
            goBack={setIsAddingMeal}
          />
        )}
      </Box>
    </Box>
  ) : (
    <Box
      width={"100%"}
      justifyContent={"center"}
      display={"flex"}
      height={"80vh"}
      alignItems={"center"}
    >
      <CircularProgress />
    </Box>
  );
};
export default AddMod;
