import {
  Alert,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Add, ChevronLeft } from "@mui/icons-material";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { ReactComponent as Edit } from "../../assets/edit.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LockIcon } from "../../assets/lock1.svg";
import { ReactComponent as NoEntry } from "../../assets/no-entries.svg";
import dayjs from "dayjs";
import { ReactComponent as TrophyIcon } from "../../assets/trophy.svg";
import { ReactComponent as StatsIcon } from "../../assets/stats.svg";
import { ReactComponent as MealIcon } from "../../assets/meal.svg";
import { ReactComponent as RecipeIcon } from "../../assets/recipe.svg";
import { selectCurrentUser } from "../../features/auth/authSelectors";
import DiaryTracker from "../Extra/DiaryTracker";
import AddWeightForm from "../Modals/AddWeight";
import {
  copyYesterdayData,
  deleteIngredients,
  updateIngredients
} from "../../api/diaryApi";
import {
  completeDiaryDay,
  getCurrentUserDiaries,
  getCurrentUserDiaryLevels
} from "../../features/diary/diarySlice";
import {
  selectAllDiariesData,
  selectAllDiaryLevels
} from "../../features/diary/diarySelectors";
import PickerWithButtonField from "../Extra/DatePicker";
import {
  addMissingDates,
  generateDateArray
} from "../../utils/getAvailableDiaryDates";
import WeekView from "../Extra/WeekView";
import { options800gms, optionsProteins } from "../../utils/mockWeightData";
import LongMenu from "../Extra/ThreeDotMenu";
import useThemeColor from "../../utils/useTheme";
import {
  convertItemQuantityToGrams,
  getTotalWeight
} from "../../utils/weightConvert";
import StatsList from "../Extra/StatsList";
import ThreeDotMenu from "../Extra/ThreeDotMenuForMobile";
import Leaderboard from "../Extra/Leaderboard";
import { formatDate } from "../../utils/formatDate";
import AddMealForLevel3 from "../Extra/AddMealForLevel3";
import AddMealOrRecipeToDiary from "../Extra/AddMealOrRecipeToDiary";
import { useNavigate } from "react-router-dom";

const CopyYesterdayButton = ({
  diaryData,
  activeDay,
  selectedLevel,
  currentLevel,
  currentActiveData,
  setSnackbarMessage,
  setOpenSnackbar,
  setIsRefreshNeeded,
  dispatch,
  copyYesterdayData
}) => {
  // Check if conditions are met
  const isConditionMet = () => {
    const previousDayData = diaryData?.find(
      (item) =>
        item.currentDayDate ===
        dayjs(activeDay)?.subtract(1, "day")?.format("YYYY-MM-DD")
    );

    const is800gEmpty = previousDayData?.entry800g?.length === 0;
    const isProteinEmpty =
      selectedLevel !== "level1"
        ? previousDayData?.protein?.length === 0
        : true;
    const isOtherFoodEmpty =
      selectedLevel === "level3"
        ? previousDayData?.otherFood?.length === 0
        : true;

    return is800gEmpty && isProteinEmpty && isOtherFoodEmpty;
  };

  const handleClick = async () => {
    if (isConditionMet()) {
      setSnackbarMessage("No data for yesterday");
      setOpenSnackbar(true);
      return;
    }

    const previousDay = dayjs(activeDay)
      ?.subtract(1, "day")
      ?.format("YYYY-MM-DD");
    const previousDayData = diaryData?.find(
      (item) => item.currentDayDate === previousDay
    );

    await copyYesterdayData({
      levelId: currentLevel?.levelId,
      previousDiaryId: previousDayData?._id,
      currentDiaryId: currentActiveData?._id,
      currentDayDate: activeDay
    });

    localStorage.setItem(
      "lastDiaryVisitedLevel",
      JSON.stringify({
        level: currentLevel?.levelName,
        activeDay
      })
    );

    setIsRefreshNeeded(true);
    setTimeout(() => {
      dispatch(
        getCurrentUserDiaries({
          levelId: currentLevel?.levelId
        })
      );
    }, 2000);
  };

  return (
    <Button
      onClick={handleClick}
      sx={{
        fontSize: "small",
        p: "0px 10px",
        background: isConditionMet() ? "#A7C8E9" : "#368AD0",
        color: isConditionMet() ? "#64AEDD" : "#fcfcfc",
        cursor: "pointer",
        textTransform: "none",
        "&:hover": {
          background: isConditionMet() ? "#A7C8E9" : "#2D74B2"
        }
      }}
    >
      Copy yesterday
    </Button>
  );
};
const updatedItemAccordingQuantity = (item) => {
  const details = item.extraDetails;
  if (!details) return;
  const metricServingAmount = parseFloat(
    details?.serving?.metric_serving_amount
  );

  const scalingFactor =
    convertItemQuantityToGrams(item, metricServingAmount).quantity /
    metricServingAmount;
  const excludedFields = [
    "serving_id",
    "serving_description",
    "serving_url",
    "metric_serving_unit"
  ];
  const updatedDetails = Object.entries(details?.serving).reduce(
    (acc, [key, value]) => {
      if (!excludedFields.includes(key) && !isNaN(Number(value))) {
        acc[key] = (value * scalingFactor).toFixed(2);
      }
      return acc;
    },
    {}
  );
  return {
    ...item,
    extraDetails: {
      ...item.extraDetails,
      serving: { ...item.extraDetails.serving, ...updatedDetails }
    }
  };
};
const visibleDaysCount = 7; // Number of days to show at a time

const Diary = ({
  setOpenSubscriptionModal,
  activeLevelForMobile,
  setActiveLevelForMobile
}) => {
  const navigate = useNavigate();
  const [selectedLevel, setSelectedLevel] = React.useState("");
  const [isShowingAllStats, setIsShowingAllStats] = useState(false);
  const [isShowingLeaderBoard, setIsShowingLeaderBoard] = useState(false);
  const [isShowingCreateMeal, setIsShowingCreateMeal] = useState(false);
  const [isShowingCreateRecipe, setIsShowingCreateRecipe] = useState(false);
  const [isShowingCreateCustomFood, setIsShowingCreateCustomFood] =
    useState(false);
  const [isAddingMealItem, setIsAddingMealItem] = useState(false);
  const [editMealToDiary, setEditMealToDiary] = useState(false);
  const [editRecipeToDiary, setEditRecipeToDiary] = useState(false);
  const [editCustomFoodToDiary, setEditCustomFoodToDiary] = useState(false);
  const [selectedOptionData, setSelectedOptionData] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [customFoodFormData, setCustomFoodFormData] = useState({
    name: "",
    quantity: "",
    noOfQty: "",
    unit: "grams",
    type: "800g",
    details: {
      serving: {
        serving_id: String(Math.floor(Math.random() * 90000) + 10000),
        metric_serving_amount: "",
        metric_serving_unit: "g",
        calories: "",
        carbohydrate: "",
        protein: "",
        fat: "",
        saturated_fat: "",
        sodium: "",
        potassium: "",
        fiber: "",
        vitamin_a: "",
        vitamin_c: "",
        vitamin_d: "",
        calcium: "",
        iron: ""
      }
    }
  });
  const dispatch = useDispatch();
  const [openWeightModal, setOpenWeightModal] = useState("");
  const [date, setDate] = useState(dayjs());
  const allLevels = useSelector(selectAllDiaryLevels);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery("(min-width: 600px) and (max-width: 900px)");
  const isInsideTablet = useMediaQuery(
    "(min-width: 700px) and (max-width: 900px)"
  );
  const isLaptop = useMediaQuery("(min-width: 900px) and (max-width: 1400px)");
  const allDiaryData = useSelector(selectAllDiariesData);
  const userData = useSelector(selectCurrentUser);
  const [diaryData, setDiaryData] = useState([]);
  const [activeDay, setActiveDay] = useState(dayjs().format("YYYY-MM-DD"));
  const [isAddingWeight, setIsAddingWeight] = useState("");
  const [editIngredientData, setEditIngredientData] = useState();
  const [isRefreshNeeded, setIsRefreshNeeded] = useState(false);
  const styles = useThemeColor();
  const levelData = JSON.parse(
    localStorage.getItem("lastDiaryVisitedLevel") || "{}"
  );
  const handleLevelSelect = (level) => {
    setSelectedLevel(level);
    setActiveLevelForMobile(true);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const currentLevel = useMemo(() => {
    return allLevels?.find((item) => item?.levelName === selectedLevel);
  }, [allLevels, selectedLevel]);

  useEffect(() => {
    localStorage.setItem("diaryLastVisited", dayjs().format("YYYY-MM-DD"));
    dispatch(getCurrentUserDiaryLevels(dayjs().format("YYYY-MM-DD")));
  }, [dispatch]);

  const isContentLocked =
    // userData?.isDiaryFreeTrialExpired && userData?.isDiarySubscriptionNeeded
    //   ? true
    //   :
    // userData?.isDiaryFreeTrialExpired &&
    // userData?.isDiarySubscriptionNeeded &&
    !currentLevel?.levelActive;

  useEffect(() => {
    if (Object.values(levelData)?.length) {
      setSelectedLevel(levelData?.level);
      setActiveLevelForMobile(true);
    } else if (
      (userData?.activeDiaryLevel && !userData?.isDiarySubscriptionNeeded) ||
      (userData?.activeDiaryLevel &&
        userData?.isDiarySubscriptionNeeded &&
        !userData.isDiaryFreeTrialExpired)
    ) {
      setSelectedLevel(userData?.activeDiaryLevel);
      setActiveLevelForMobile(true);
    }
    // else {
    //   if (Object.values(levelData)?.length) {
    //     setSelectedLevel(levelData?.level);
    //   }
    // }
  }, [userData]);
  useEffect(() => {
    if (currentLevel?.levelId) {
      dispatch(
        getCurrentUserDiaries({
          levelId: currentLevel?.levelId,
          firstLoad: true
        })
      );
    }
  }, [dispatch, currentLevel?.levelId]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const scrollableElement = document.getElementById("dashboard-main");
    scrollableElement.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (
      !(
        userData?.isAdmin === 1 ||
        ["Coach", "Company"].includes(userData?.userType)
      ) &&
      !userData?.isSubscriptionActive &&
      (userData?.isTrainingFreeTrialExpired ||
        userData?.isDiaryFreeTrialExpired)
    ) {
      setOpenSubscriptionModal(true);
    }
  }, [userData]);

  useEffect(() => {
    if (allDiaryData) {
      if (!isRefreshNeeded) {
        const currentUserDay = dayjs().format("YYYY-MM-DD");
        const previousDiaryDayData = allDiaryData?.filter(
          (item) =>
            item.currentDayDate !== currentUserDay &&
            !item?.completionDiaryDayDate &&
            (item?.entry800g?.length ||
              item?.protein?.length ||
              item?.otherFood?.length)
        );

        if (previousDiaryDayData?.length) {
          dispatch(
            completeDiaryDay({
              levelId: currentLevel?.levelId,
              diaryIds: previousDiaryDayData?.map((item) => item?._id)
            })
          );
          setTimeout(() => {
            dispatch(getCurrentUserDiaries({ levelId: currentLevel?.levelId }));
          }, 1000);
        }
      }
      const updatedData = addMissingDates(
        allDiaryData,
        dayjs(userData?.["createdAt"]).startOf("month"),
        currentLevel
      );
      setDiaryData(updatedData);
      if (!isRefreshNeeded) {
        // if (Object.values(levelData)?.length) {
        //   setActiveDay(levelData?.activeDay);
        // } else {
        setActiveDay(dayjs().format("YYYY-MM-DD"));
      }
      // }
    }
  }, [allDiaryData, userData]);

  const [startDay, setStartDay] = useState(1); // Start displaying from day 1

  const days = useMemo(() => {
    return currentLevel?.levelName
      ? generateDateArray(
          dayjs(userData?.["createdAt"]).startOf("month").format("YYYY-MM-DD")
        )
      : [];
  }, [currentLevel]);

  const getVisibleDays = (days, startDay) => {
    // Get the first date's day of week (0 = Sunday, 6 = Saturday)
    const firstDate = dayjs(days[startDay - 1]?.value);
    const isFirstIteration = startDay === 1;

    // For first iteration, calculate remaining days in week
    if (isFirstIteration) {
      const dayOfWeek = firstDate.day(); // 0-6
      const daysLeftInWeek = 7 - dayOfWeek;
      return days.slice(0, daysLeftInWeek);
    }

    // For subsequent iterations, show 7 days (or 5 if mobile)
    return days.slice(startDay - 1, startDay - 1 + visibleDaysCount);
  };

  // Use it like this:
  const visibleDays = getVisibleDays(days, startDay);

  // const visibleDays = days.slice(startDay - 1, startDay - 1 + visibleDaysCount);

  // const [visibleDaysCount, setVisibleDaysCount] = useState(1);
  useEffect(() => {
    if (diaryData && !isRefreshNeeded) {
      const createdDate = dayjs(userData?.["createdAt"]).startOf("month");
      const currentDate = dayjs();

      // If we're not in the first week, find the current week's Sunday
      if (currentDate.diff(createdDate, "day") > 7) {
        // Get current week's Sunday
        const currentWeekSunday = currentDate.startOf("week");

        // Find index of this Sunday in our days array
        const sundayIndex = days.findIndex((day) =>
          dayjs(day.value).isSame(currentWeekSunday, "day")
        );

        if (sundayIndex !== -1) {
          setStartDay(sundayIndex + 1); // +1 because startDay is 1-based
        } else {
          // If Sunday not found (might be past the last date),
          // go to the last complete week
          const lastWeekStart = Math.max(1, days.length - 7);
          setStartDay(lastWeekStart);
        }
      } else {
        // We're in the first week, start from day 1
        setStartDay(1);
      }
    }
  }, [diaryData, days]);

  const handleDayClick = (day) => {
    setActiveDay(day);
  };

  const currentActiveData = useMemo(() => {
    return diaryData?.find((item) => item.currentDayDate === activeDay);
  }, [diaryData, activeDay]);

  const allCompletedData = useMemo(() => {
    return diaryData
      ?.filter((item) => item.completionDiaryDayDate)
      ?.map((item) => item.currentDayDate);
  }, [diaryData]);

  // const handleNext = () => {
  //   if (startDay + visibleDaysCount - 1 < days.length) {
  //     setStartDay((prev) => prev + visibleDaysCount);
  //   }
  // };

  // const handlePrevious = () => {
  //   if (startDay > 1) {
  //     setStartDay((prev) => prev - visibleDaysCount);
  //   }
  // };
  const handleNext = () => {
    const firstDate = dayjs(days[startDay - 1]?.value);
    const isFirstIteration = startDay === 1;

    if (isFirstIteration) {
      // After first iteration, jump to next Sunday
      const nextSunday = firstDate.endOf("week").add(1, "day");
      const nextStartIndex = days.findIndex((day) =>
        dayjs(day.value).isSame(nextSunday, "day")
      );
      if (nextStartIndex !== -1) {
        setStartDay(nextStartIndex + 1);
      }
    } else {
      // For subsequent clicks, move forward by 7 days
      setStartDay(startDay + 7);
    }
  };

  const handlePrevious = () => {
    // Move back by 7 days, but not before the start
    const newStartDay = Math.max(1, startDay - 7);
    setStartDay(newStartDay);
  };

  const summaryData = useCallback(
    (type) => {
      if (selectedLevel !== "level3") {
        return {};
      }
      return currentActiveData?.[type]?.reduce(
        (acc, item) => {
          const updatedItem = updatedItemAccordingQuantity(item);
          acc.metric_serving_amount += parseFloat(
            updatedItem?.extraDetails.serving.metric_serving_amount
          );
          acc.calories += parseFloat(
            updatedItem?.extraDetails.serving.calories
          );
          acc.protein += parseFloat(updatedItem?.extraDetails.serving.protein);
          acc.carbohydrate += parseFloat(
            updatedItem?.extraDetails.serving.carbohydrate
          );
          acc.fiber += parseFloat(updatedItem?.extraDetails.serving.fiber);
          acc.fat += parseFloat(updatedItem?.extraDetails.serving.fat);
          return acc;
        },
        {
          metric_serving_amount: 0,
          calories: 0,
          protein: 0,
          carbohydrate: 0,
          fiber: 0,
          fat: 0
        }
      );
    },
    [currentActiveData]
  );

  return isAddingWeight ? (
    <AddWeightForm
      diaryId={currentActiveData?._id || ""}
      activeDay={activeDay}
      selectedLevel={currentLevel}
      isAddingWeight={isAddingWeight}
      setIsAddingWeight={setIsAddingWeight}
      levelId={currentLevel?.levelId}
      editData={editIngredientData}
      setIsRefreshNeeded={setIsRefreshNeeded}
    />
  ) : (
    <Box
      pb={10}
      sx={{
        padding: isMobile ? "0" : "20px 0 40px 32px"
      }}
    >
      {((!activeLevelForMobile && isMobile) || !isMobile) && (
        <Box display="flex" flexDirection={"column"}>
          <Box
            sx={{
              ...(isMobile && {
                width: "100%",
                padding: "16px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                color: styles.color
              })
            }}
          >
            {isShowingAllStats ? (
              <Box display="flex" gap="20px" mb={2}>
                <IconButton onClick={() => setIsShowingAllStats(false)}>
                  <ChevronLeft
                    sx={{ height: "35px", width: "35px", fill: styles.color }}
                  />
                </IconButton>
                <Typography
                  fontSize={isMobile ? "28px" : "30px"}
                  fontWeight={500}
                  color={styles.color}
                >
                  Stats
                </Typography>
              </Box>
            ) : isShowingLeaderBoard ? (
              <Box display="flex" gap="20px" mb={2}>
                <IconButton onClick={() => setIsShowingLeaderBoard(false)}>
                  <ChevronLeft
                    sx={{ height: "35px", width: "35px", fill: styles.color }}
                  />
                </IconButton>
                <Typography
                  fontSize={isMobile ? "28px" : "30px"}
                  fontWeight={500}
                  color={styles.color}
                >
                  Monthly Leaderboard
                </Typography>
              </Box>
            ) : isShowingCreateMeal || editMealToDiary ? (
              <Box display="flex" gap="20px" mb={2}>
                <IconButton
                  onClick={() => {
                    if (selectedOptionData?.serving) {
                      setSelectedOptionData(null);
                    } else if (isAddingMealItem) {
                      setIsAddingMealItem(false);
                    } else if (editMealToDiary) {
                      setEditMealToDiary(false);
                    } else {
                      setIsShowingCreateMeal(false);
                    }
                  }}
                >
                  <ChevronLeft
                    sx={{ height: "35px", width: "35px", fill: styles.color }}
                  />
                </IconButton>
                <Typography
                  fontSize={isMobile ? "28px" : "30px"}
                  fontWeight={500}
                  color={styles.color}
                >
                  Add Meal
                </Typography>
              </Box>
            ) : isShowingCreateRecipe || editRecipeToDiary ? (
              <Box display="flex" gap="20px" mb={2}>
                <IconButton
                  onClick={() => {
                    if (selectedOptionData?.serving) {
                      setSelectedOptionData(null);
                    } else if (isAddingMealItem) {
                      setIsAddingMealItem(false);
                    } else if (editRecipeToDiary) {
                      setEditRecipeToDiary(false);
                    } else {
                      setIsShowingCreateRecipe(false);
                    }
                  }}
                >
                  <ChevronLeft
                    sx={{ height: "35px", width: "35px", fill: styles.color }}
                  />
                </IconButton>
                <Typography
                  fontSize={isMobile ? "28px" : "30px"}
                  fontWeight={500}
                  color={styles.color}
                >
                  Add Recipe
                </Typography>
              </Box>
            ) : isShowingCreateCustomFood || editCustomFoodToDiary ? (
              <Box display="flex" gap="20px" mb={2}>
                <IconButton
                  onClick={() => {
                    if (selectedOptionData?.serving) {
                      setSelectedOptionData(null);
                    } else if (editCustomFoodToDiary) {
                      setEditCustomFoodToDiary(false);
                    } else {
                      setIsShowingCreateCustomFood(false);
                    }
                  }}
                >
                  <ChevronLeft
                    sx={{ height: "35px", width: "35px", fill: styles.color }}
                  />
                </IconButton>
                <Typography
                  fontSize={isMobile ? "28px" : "30px"}
                  fontWeight={500}
                  color={styles.color}
                >
                  Add Custom Food
                </Typography>
              </Box>
            ) : (
              <>
                <Typography
                  fontSize={isMobile ? "28px" : "30px"}
                  fontWeight={500}
                  color={styles.color}
                >
                  Diary
                </Typography>
                <Typography
                  textAlign={isMobile ? "center" : "left"}
                  color="#6B7280"
                  fontSize={isMobile ? "15px" : "16px"}
                  fontWeight={isMobile ? 600 : 500}
                >
                  Where you can track your daily grams
                </Typography>
              </>
            )}
          </Box>
          {isShowingLeaderBoard ? (
            <Box>
              <Leaderboard levelId={currentLevel?.levelId} />
            </Box>
          ) : editMealToDiary ? (
            <AddMealOrRecipeToDiary
              currentLevel={currentLevel}
              levelId={currentLevel?.levelId}
              activeDay={activeDay}
              setIsShowingCreateMeal={setIsShowingCreateMeal}
              setIsShowingCreateRecipe={setIsShowingCreateRecipe}
              setEditMealToDiary={setEditMealToDiary}
              setIsRefreshNeeded={setIsRefreshNeeded}
              setEditRecipeToDiary={setEditRecipeToDiary}
              type="meal"
            />
          ) : editRecipeToDiary ? (
            <AddMealOrRecipeToDiary
              currentLevel={currentLevel}
              levelId={currentLevel?.levelId}
              setIsRefreshNeeded={setIsRefreshNeeded}
              activeDay={activeDay}
              setIsShowingCreateMeal={setIsShowingCreateMeal}
              setIsShowingCreateRecipe={setIsShowingCreateRecipe}
              setEditMealToDiary={setEditMealToDiary}
              setEditRecipeToDiary={setEditRecipeToDiary}
              type="recipe"
            />
          ) : editCustomFoodToDiary ? (
            <AddMealOrRecipeToDiary
              currentLevel={currentLevel}
              levelId={currentLevel?.levelId}
              setIsRefreshNeeded={setIsRefreshNeeded}
              activeDay={activeDay}
              setIsShowingCreateMeal={setIsShowingCreateMeal}
              setIsShowingCreateRecipe={setIsShowingCreateRecipe}
              setEditMealToDiary={setEditMealToDiary}
              setEditRecipeToDiary={setEditRecipeToDiary}
              setCustomFoodFormData={setCustomFoodFormData}
              setIsShowingCreateCustomFood={setIsShowingCreateCustomFood}
              setEditCustomFoodToDiary={setEditCustomFoodToDiary}
              type="custom food"
            />
          ) : isShowingCreateMeal ? (
            <Box>
              <AddMealForLevel3
                type={"meal"}
                setIsShowingCreateMeal={setIsShowingCreateMeal}
                setIsShowingCreateRecipe={setIsShowingCreateRecipe}
                levelId={currentLevel?.levelId}
                isAddingMealItem={isAddingMealItem}
                setIsAddingMealItem={setIsAddingMealItem}
                selectedOptionData={selectedOptionData}
                setSelectedOptionData={setSelectedOptionData}
              />
            </Box>
          ) : isShowingCreateRecipe ? (
            <Box>
              <AddMealForLevel3
                setIsShowingCreateRecipe={setIsShowingCreateRecipe}
                setIsShowingCreateMeal={setIsShowingCreateMeal}
                isAddingMealItem={isAddingMealItem}
                setIsAddingMealItem={setIsAddingMealItem}
                selectedOptionData={selectedOptionData}
                setSelectedOptionData={setSelectedOptionData}
                type={"recipe"}
                levelId={currentLevel?.levelId}
              />
            </Box>
          ) : isShowingCreateCustomFood ? (
            <Box>
              <AddMealForLevel3
                setIsShowingCreateCustomFood={setIsShowingCreateCustomFood}
                setIsShowingCreateRecipe={setIsShowingCreateRecipe}
                setIsShowingCreateMeal={setIsShowingCreateMeal}
                isAddingMealItem={isAddingMealItem}
                setIsAddingMealItem={setIsAddingMealItem}
                selectedOptionData={selectedOptionData}
                setSelectedOptionData={setSelectedOptionData}
                customFoodFormData={customFoodFormData}
                setCustomFoodFormData={setCustomFoodFormData}
                type={"customFood"}
                levelId={currentLevel?.levelId}
              />
            </Box>
          ) : (
            <Box
              mt={isMobile ? 0 : 3}
              display={"flex"}
              gap={2}
              flexDirection={isLaptop ? "column" : !isTablet ? "row" : "column"}
            >
              <Box
                sx={{
                  width: isInsideTablet
                    ? "70%"
                    : isTablet || isLaptop
                    ? "95%"
                    : !isMobile
                    ? { sm: "30%", md: "37%", lg: "32%" }
                    : "100%",
                  borderRadius: "24px",
                  ...(!isMobile && {
                    height: "calc(100vh - 40px)"
                  }),
                  margin: isInsideTablet ? "16px 100px" : 2,
                  background: styles.levelBackgrounds,
                  display: "flex",
                  flexDirection: isLaptop ? "row" : "column",
                  padding:
                    isTablet || isMobile
                      ? "20px"
                      : { md: "24px 11px", lg: "24px 16px" },
                  overflowY: "auto",
                  ...((isTablet || isLaptop) && { height: "100%" })
                }}
                gap={4}
              >
                {allLevels?.map(
                  ({ title, description, levelName, levelActive }) => (
                    <Button
                      fullWidth
                      onClick={() => {
                        if (levelActive) {
                          handleLevelSelect(levelName);
                        } else {
                          if (userData?.isDiarySubscriptionNeeded) {
                            setSnackbarMessage(
                              "Please purchase subscription to unlock"
                            );
                            setOpenSnackbar(true);
                            return;
                          } else {
                            setSnackbarMessage(
                              "Please purchase the corresponding Training Program to unlock"
                            );
                            setOpenSnackbar(true);
                            return;
                          }
                        }
                      }}
                      sx={{
                        padding: isTablet || isLaptop ? "35px 10px" : 6,
                        alignItems: "center",
                        border:
                          selectedLevel === levelName
                            ? "1px solid #64AEDD"
                            : "1px solid #D1D5DB",
                        borderRadius: "16px",
                        backgroundColor:
                          selectedLevel === levelName
                            ? "#368AD0"
                            : // : isMobile
                              // ? styles.lightGreyColor
                              "transparent"
                      }}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        gap="16px"
                        justifyContent="space-between"
                        textTransform="none"
                        alignItems="center"
                      >
                        <Box textAlign={"start"}>
                          <Box>
                            <Typography
                              color={"#FCFCFC"}
                              fontSize={isLaptop ? "0.95rem" : "1rem"}
                              fontWeight={600}
                            >
                              {title} <sup>&#174;</sup>
                            </Typography>
                            <Typography
                              fontSize="15px"
                              fontWeight={300}
                              color={
                                selectedLevel === levelName
                                  ? "#FCFCFC"
                                  : "#D1D5DB"
                              }
                            >
                              {description}
                            </Typography>
                            {/* {
                              // levelName !== "level1" &&
                              !levelActive && (
                                <Typography
                                  mt={1}
                                  fontSize="12px"
                                  fontWeight={500}
                                  color={
                                    selectedLevel === levelName
                                      ? "#FCFCFC"
                                      : "#D1D5DB"
                                  }
                                >
                                  {`Purchase the ${title} Program to unlock ${title}
                                Logging`}
                                </Typography>
                              )
                            } */}
                          </Box>
                        </Box>
                        <Box>{levelActive ? <></> : <LockIcon />}</Box>
                      </Box>
                    </Button>
                  )
                )}
              </Box>
              {!isMobile && selectedLevel ? (
                isShowingAllStats ? (
                  <Box
                    display="flex"
                    sx={{
                      width:
                        isTablet || isLaptop
                          ? "100%"
                          : !isMobile
                          ? { sm: "60%", md: "60%", lg: "75%" }
                          : "100%",
                      padding: "0 25px",
                      ...((!isMobile || !isTablet || !isLaptop) && {
                        maxHeight: "calc(100vh - 40px)"
                      })
                    }}
                    flexDirection="column"
                    gap={3}
                    ml={isMobile ? 0 : 2}
                  >
                    <StatsList level={currentLevel} />
                  </Box>
                ) : isContentLocked ? (
                  <Box
                    sx={{
                      background:
                        "radial-gradient(circle at 50% 50%, #368AD0, #2D5F87, #1A3A5C)",
                      width: isTablet || isLaptop ? "95%" : { lg: "80%" },
                      ...((isTablet || isLaptop) && {
                        height: "240px"
                      }),
                      margin: "24px 16px",
                      zIndex: 1,
                      borderRadius: "8px"
                    }}
                  >
                    <Box
                      display="flex"
                      borderRadius="8px"
                      justifyContent="center"
                      alignItems="center"
                      mr={1}
                      sx={{
                        width: "100%",
                        height: "100%",
                        backdropFilter: "blur(8px)" // blur effect
                      }}
                    >
                      <LockIcon
                        style={{ color: "#FCFCFC", height: 70, width: 70 }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    sx={{
                      width:
                        isTablet || isLaptop
                          ? "100%"
                          : !isMobile
                          ? { sm: "60%", md: "60%", lg: "75%" }
                          : "100%",
                      padding: "0 25px",
                      ...((!isMobile || !isTablet) && {
                        maxHeight: "calc(100vh - 40px)"
                      })
                    }}
                    flexDirection="column"
                    gap={3}
                    ml={isMobile ? 0 : 2}
                  >
                    <Box display="flex" justifyContent="flex-end">
                      <Box display="flex" gap="10px">
                        <IconButton
                          sx={{
                            padding: "12px",
                            borderRadius: "10px",
                            background: styles.lightDiaryTracker
                          }}
                          onClick={() => {
                            setIsShowingAllStats(true);
                          }}
                        >
                          <StatsIcon
                            style={{
                              width: 16,
                              height: 16
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setIsShowingLeaderBoard(true);
                          }}
                          sx={{
                            padding: "12px",
                            borderRadius: "10px",
                            background: styles.lightDiaryTracker
                          }}
                        >
                          <TrophyIcon
                            style={{
                              width: 16,
                              height: 16
                            }}
                          />
                        </IconButton>
                        {currentLevel?.levelName === "level3" && (
                          <>
                            {" "}
                            <IconButton
                              sx={{
                                padding: "12px",
                                borderRadius: "10px",
                                background: styles.lightDiaryTracker
                              }}
                              onClick={() => {
                                setEditMealToDiary(true);
                                // dispatch(updateAddingMeal({ meal: {} }));
                              }}
                            >
                              <MealIcon
                                style={{
                                  width: 16,
                                  height: 16
                                }}
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setEditRecipeToDiary(true);
                                // setIsShowingCreateRecipe(true);
                                // dispatch(updateAddingRecipe({ recipe: {} }));
                              }}
                              sx={{
                                padding: "12px",
                                borderRadius: "10px",
                                background: styles.lightDiaryTracker
                              }}
                            >
                              <RecipeIcon
                                style={{
                                  width: 16,
                                  height: 16
                                }}
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setEditCustomFoodToDiary(true);
                              }}
                              sx={{
                                padding: "12px",
                                borderRadius: "10px",
                                background: styles.lightDiaryTracker
                              }}
                            >
                              <Box
                                component="img"
                                src="/images/custom.png"
                                alt="base"
                                sx={{
                                  height: 16,
                                  width: 16,
                                  objectFit: "cover"
                                }}
                              />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      gap="10px"
                      flexDirection="column"
                      // justifyContent="space-between"
                      width="100%"
                    >
                      <Box flexGrow={1} display="flex" justifyContent="center">
                        <PickerWithButtonField
                          maxDate={dayjs(userData?.["createdAt"])
                            .startOf("month")
                            .format("YYYY-MM-DD")}
                          allCompletedDates={allCompletedData}
                          date={date}
                          visibleDaysCount={visibleDaysCount}
                          setStartDay={setStartDay}
                          setDate={setDate}
                          activeDay={activeDay}
                          setActiveDay={setActiveDay}
                        />
                      </Box>{" "}
                      <WeekView
                        allCompletedData={allCompletedData}
                        activeDay={activeDay}
                        setActiveDay={setActiveDay}
                        startDay={startDay}
                        visibleDays={visibleDays}
                        visibleDaysCount={visibleDaysCount}
                        days={days}
                        setStartDay={setStartDay}
                        diaryData={diaryData}
                        handleDayClick={handleDayClick}
                        handleNext={handleNext}
                        handlePrevious={handlePrevious}
                      />{" "}
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={"20px"}
                      sx={{
                        ...((!isMobile || !isTablet) && {
                          overflowY: "auto",
                          maxHeight: "calc(100vh - 120px)"
                        })
                      }}
                    >
                      <Box display="flex" justifyContent="space-between">
                        <Typography
                          color={styles.color}
                          fontWeight={600}
                          fontSize={"24px"}
                        >
                          {formatDate(activeDay, false)} (
                          {dayjs(activeDay).format(isMobile ? "ddd" : "dddd")})
                        </Typography>
                        <CopyYesterdayButton
                          diaryData={diaryData}
                          activeDay={activeDay}
                          selectedLevel={selectedLevel}
                          currentLevel={currentLevel}
                          currentActiveData={currentActiveData}
                          setSnackbarMessage={setSnackbarMessage}
                          setOpenSnackbar={setOpenSnackbar}
                          setIsRefreshNeeded={setIsRefreshNeeded}
                          dispatch={dispatch}
                          copyYesterdayData={copyYesterdayData}
                        />
                      </Box>
                      <Box
                        gap={
                          (isMobile || isTablet) &&
                          currentLevel?.levelName === "level3"
                            ? "20px"
                            : "40px"
                        }
                        display="flex"
                        borderRadius={"16px"}
                        sx={{ background: styles.diaryTracker }}
                        justifyContent="center"
                      >
                        <DiaryTracker
                          levelName={currentLevel?.levelName}
                          openWeightModal={openWeightModal}
                          setOpenWeightModal={setOpenWeightModal}
                          title={"#800gChallenge"}
                          levelId={currentLevel?.levelId}
                          diaryId={currentActiveData?._id || ""}
                          value={
                            selectedLevel === "level3"
                              ? Math.round(
                                  summaryData("entry800g")
                                    ?.metric_serving_amount || 0
                                )
                              : getTotalWeight(currentActiveData?.entry800g) ||
                                0
                          }
                          strokeColor={"#F6541C"}
                          maxValue={currentActiveData?.outOf800g}
                          setIsRefreshNeeded={setIsRefreshNeeded}
                          dayName={activeDay}
                          heightX={
                            currentLevel?.levelName === "level2" ? 210 : 250
                          }
                          widthX={
                            currentLevel?.levelName === "level2" ? 300 : 350
                          }
                        />
                        {currentLevel?.levelName !== "level1" && (
                          <DiaryTracker
                            levelName={currentLevel?.levelName}
                            openWeightModal={openWeightModal}
                            setOpenWeightModal={setOpenWeightModal}
                            title={"Protein"}
                            levelId={currentLevel?.levelId}
                            diaryId={currentActiveData?._id || ""}
                            value={
                              selectedLevel === "level3"
                                ? Math.round(
                                    summaryData("entry800g")?.protein +
                                      summaryData("protein")?.protein +
                                      summaryData("otherFood")?.protein
                                  ) || 0
                                : getTotalWeight(currentActiveData?.protein) ||
                                  0
                            }
                            strokeColor={"#368AD0"}
                            maxValue={currentActiveData?.outOfProtein}
                            setIsRefreshNeeded={setIsRefreshNeeded}
                            dayName={activeDay}
                            heightX={
                              currentLevel?.levelName === "level2" ? 210 : 250
                            }
                            widthX={
                              currentLevel?.levelName === "level2" ? 300 : 350
                            }
                          />
                        )}
                        {currentLevel?.levelName === "level3" && (
                          <DiaryTracker
                            isCalories
                            levelName={currentLevel?.levelName}
                            openWeightModal={openWeightModal}
                            setOpenWeightModal={setOpenWeightModal}
                            title={"Calories"}
                            levelId={currentLevel?.levelId}
                            diaryId={currentActiveData?._id || ""}
                            value={
                              Math.round(
                                summaryData("entry800g")?.calories +
                                  summaryData("protein")?.calories +
                                  summaryData("otherFood")?.calories
                              ) || 0
                            }
                            strokeColor={"#FF9431"}
                            maxValue={currentActiveData?.outOfCalories}
                            setIsRefreshNeeded={setIsRefreshNeeded}
                            dayName={activeDay}
                            heightX={
                              currentLevel?.levelName === "level2" ? 210 : 250
                            }
                            widthX={
                              currentLevel?.levelName === "level2" ? 300 : 350
                            }
                          />
                        )}
                      </Box>
                      {currentLevel?.levelName === "level3" && (
                        <Box display="flex" gap="40px" justifyContent="center">
                          <Button
                            onClick={handleClick}
                            sx={{
                              textTransform: "none",
                              background: "#368AD0",
                              color: "#fcfcfc",
                              height: "40px",
                              fontWeight: 600,
                              width: { lg: "25%", md: "40%", xs: "100%" },
                              padding: "12px 24px",
                              gap: "8px",
                              borderRadius: "4px"
                            }}
                          >
                            <Add /> Add
                          </Button>
                          <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            slotProps={{
                              paper: {
                                style: {
                                  background: styles.background,
                                  color: styles.color
                                }
                              }
                            }}
                            MenuListProps={{
                              "aria-labelledby": "basic-button"
                            }}
                            sx={{
                              "& .MuiPaper-root": {
                                width: anchorEl ? anchorEl.offsetWidth : "auto",
                                background: "#fff",
                                color: "#000"
                                // margin: "0 20px"
                              }
                            }}
                          >
                            <MenuItem
                              sx={{
                                // justifyContent: "center",
                                gap: "10px"
                                // alignItems: "center"
                              }}
                              onClick={() => {
                                handleClose();
                                setEditIngredientData();
                                setIsAddingWeight("entry800g");
                              }}
                            >
                              <Add
                                style={{ marginRight: "4px" }}
                                height={17}
                                width={17}
                              />{" "}
                              <Typography fontSize={14}>Add 800g </Typography>
                            </MenuItem>
                            <MenuItem
                              sx={{
                                gap: "10px"
                                // alignItems: "center",
                                // justifyContent: "center"
                              }}
                              onClick={() => {
                                handleClose();
                                setEditIngredientData();
                                setIsAddingWeight("protein");
                              }}
                            >
                              {" "}
                              <Add
                                style={{ marginRight: "4px" }}
                                height={17}
                                width={17}
                              />
                              <Typography fontSize={14}>Add Protein</Typography>
                            </MenuItem>
                            <MenuItem
                              sx={{
                                gap: "10px"
                                // alignItems: "center",
                                // justifyContent: "center"
                              }}
                              onClick={() => {
                                handleClose();
                                setEditIngredientData();
                                setIsAddingWeight("otherFood");
                              }}
                            >
                              <Add
                                style={{ marginRight: "4px" }}
                                height={17}
                                width={17}
                              />{" "}
                              <Typography fontSize={14}>Add Other</Typography>
                            </MenuItem>
                            <MenuItem
                              sx={{
                                gap: "10px"
                              }}
                              onClick={() => {
                                handleClose();
                                setIsShowingCreateCustomFood(false);
                                setEditCustomFoodToDiary(true);
                              }}
                            >
                              <Box
                                component="img"
                                src="/images/custom.png"
                                alt="base"
                                sx={{
                                  height: 20,
                                  width: 20,
                                  objectFit: "cover",
                                  marginRight: "8px"
                                }}
                              />
                              {/* <Add style={{ marginRight: "4px" }} />{" "} */}
                              <Typography fontSize={14}>
                                Add Custom Food
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              sx={{
                                gap: "10px",
                                alignItems: "center"
                              }}
                              onClick={() => {
                                handleClose();
                                // Action for Add Meal
                                setIsShowingCreateMeal(false);
                                setEditMealToDiary(true);
                              }}
                            >
                              <MealIcon
                                style={{ marginRight: "8px" }}
                                height={20}
                                width={20}
                              />
                              <Typography fontSize={14}>Add Meal</Typography>
                            </MenuItem>
                            <MenuItem
                              sx={{
                                gap: "10px",
                                alignItems: "center"
                              }}
                              onClick={() => {
                                handleClose();
                                // Action for Add Recipe
                                setIsShowingCreateRecipe(false);
                                setEditRecipeToDiary(true);
                              }}
                            >
                              <RecipeIcon
                                style={{ marginRight: "8px" }}
                                height={17}
                                width={17}
                              />
                              <Typography fontSize={14}>Add Recipe</Typography>
                            </MenuItem>
                          </Menu>
                        </Box>
                      )}
                      <Box
                        width={"100%"}
                        gap="40px"
                        display="flex"
                        justifyContent="center"
                      >
                        {selectedLevel !== "level3" ? (
                          <Button
                            onClick={() => {
                              setEditIngredientData();
                              setIsAddingWeight("entry800g");
                            }}
                            sx={{
                              mt: 3,
                              textTransform: "none",
                              background: "#368AD0",
                              color: "#fcfcfc",
                              height: "40px",
                              fontWeight: 600,
                              padding: "12px 24px",
                              gap: "8px",
                              borderRadius: "4px"
                            }}
                          >
                            <Add /> Add 800g entry
                          </Button>
                        ) : (
                          <Typography
                            // marginBottom={-4}
                            // marginTop={4}
                            fontWeight={600}
                            color="#f6521c"
                          >
                            800g{" "}
                          </Typography>
                        )}
                      </Box>
                      {currentActiveData?.entry800g?.length ? (
                        selectedLevel === "level3" ? (
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={2}
                          >
                            <Box
                              mt={-2}
                              display={"flex"}
                              gap={0.4}
                              flexDirection="column"
                              borderRadius="8px"
                              padding={"12px"}
                              width={"100%"}
                              sx={{ background: styles.lightGreyColor }}
                            >
                              <Typography fontWeight="bold" fontSize={14}>
                                {"800g"}
                              </Typography>
                              <Typography fontSize={13}>
                                {`${
                                  Math.round(
                                    +summaryData("entry800g")?.calories
                                  ) || 0
                                } Cals · ${
                                  Math.round(
                                    +summaryData("entry800g")?.protein
                                  ) || 0
                                }g Protein · ${
                                  Math.round(
                                    +summaryData("entry800g")?.carbohydrate
                                  ) || 0
                                }g Carbs · ${
                                  Math.round(
                                    +summaryData("entry800g")?.fiber
                                  ) || 0
                                }g Fiber · ${
                                  Math.round(+summaryData("entry800g")?.fat) ||
                                  0
                                }g Fat`}
                              </Typography>
                            </Box>
                            <Box
                              // height={"200px"}
                              gap="10px"
                              mb={2}
                              borderRadius="16px"
                              p="20px"
                              alignItems="center"
                              display="flex"
                              flexDirection="column"
                              sx={{ background: styles.lightDiaryTracker }}
                              justifyContent="center"
                            >
                              {currentActiveData?.entry800g?.map((item) => (
                                <Box
                                  width={"100%"}
                                  display="flex"
                                  justifyContent="space-between"
                                  key={item.key}
                                >
                                  <Box>
                                    <Box
                                      display={"flex"}
                                      alignItems="center"
                                      gap="10px"
                                    >
                                      <Tooltip
                                        title={
                                          options800gms?.find(
                                            (option) =>
                                              option.value === item.name
                                          )?.label ?? item.name
                                        }
                                      >
                                        <Typography
                                          sx={{
                                            cursor: "pointer",
                                            display: "block", // Ensures the container behaves as a block element
                                            maxWidth: isMobile
                                              ? "90px"
                                              : "500px", // Set your fixed width (adjust as needed)
                                            overflow: "hidden", // Hide overflow content
                                            textOverflow: "ellipsis", // Show ellipsis when text overflows
                                            whiteSpace: "nowrap" // Prevent text from wrapping
                                          }}
                                          fontSize={13}
                                          color={styles.color}
                                          textTransform={"capitalize"}
                                        >
                                          {options800gms?.find(
                                            (option) =>
                                              option.value === item.name
                                          )?.label ?? item.name}
                                        </Typography>
                                      </Tooltip>
                                      {item?.type === "meal" ? (
                                        <MealIcon
                                          style={{
                                            width: 16,
                                            height: 16
                                          }}
                                        />
                                      ) : item?.type === "recipe" ? (
                                        <RecipeIcon
                                          style={{
                                            width: 16,
                                            height: 16
                                          }}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                    <Typography
                                      fontSize={10}
                                      color={styles.disabledColor}
                                    >
                                      {item.time}
                                    </Typography>
                                  </Box>
                                  <Box display="flex" alignItems={"center"}>
                                    <Typography fontSize={13} fontWeight={600}>
                                      {
                                        convertItemQuantityToGrams(item)
                                          ?.quantity
                                      }
                                      g
                                    </Typography>
                                    <LongMenu
                                      onClickHandler={async (type) => {
                                        if (type === "edit") {
                                          setEditIngredientData(item);
                                          setIsAddingWeight("entry800g");
                                        } else {
                                          await deleteIngredients(
                                            currentLevel?.levelId,
                                            currentActiveData?._id,
                                            item?._id,
                                            activeDay
                                          );
                                          setIsRefreshNeeded(true);
                                          dispatch(
                                            getCurrentUserDiaries({
                                              levelId: currentLevel?.levelId
                                            })
                                          );
                                        }
                                      }}
                                    />
                                    {/* <Box display="flex" gap={1} ml={1}>
                                      <IconButton
                                        sx={{
                                          fontSize: "16px",
                                          borderRadius: "4px",
                                          background: "#368AD0",
                                          svg: {
                                            path: {
                                              fill: "#FCFCFC"
                                            }
                                          },
                                          "&:hover": {
                                            background: "#368AD0"
                                          }
                                        }}
                                        onClick={() => {
                                          setEditIngredientData(item);
                                          setIsAddingWeight("entry800g");
                                        }}
                                      >
                                        <Edit />
                                      </IconButton>
                                      <IconButton
                                        onClick={async () => {
                                          await deleteIngredients(
                                            currentLevel?.levelId,
                                            currentActiveData?._id,
                                            item?._id,
                                            activeDay
                                          );
                                          setIsRefreshNeeded(true);
                                          dispatch(
                                            getCurrentUserDiaries({
                                              levelId: currentLevel?.levelId
                                            })
                                          );
                                        }}
                                        sx={{
                                          fontSize: "16px",
                                          borderRadius: "4px",
                                          background: "#FF4000",
                                          svg: {
                                            path: {
                                              fill: "#FCFCFC"
                                            }
                                          },
                                          "&:hover": {
                                            background: "#FF4000"
                                          }
                                        }}
                                      >
                                        <Delete />
                                      </IconButton>
                                    </Box> */}
                                  </Box>
                                </Box>
                              ))}
                              <Button
                                onClick={async () => {
                                  await updateIngredients({
                                    diaryId: currentActiveData?._id,
                                    levelId: currentLevel?.levelId,
                                    entry800g: [],
                                    currentDayDate: activeDay
                                  });
                                  setIsRefreshNeeded(true);
                                  dispatch(
                                    getCurrentUserDiaries({
                                      levelId: currentLevel?.levelId
                                    })
                                  );
                                }}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  p: "2px 40px",
                                  color: styles.color,
                                  border: `1px solid ${styles.color}`,
                                  cursor: "pointer",
                                  textTransform: "none"
                                }}
                              >
                                <IconButton
                                  sx={{
                                    color: styles.textColorDiary,
                                    height: "17px",
                                    width: "17px",
                                    padding: 0,
                                    ":disabled": {
                                      color: styles.textColorDiary
                                    }
                                  }}
                                  disabled
                                >
                                  <Add
                                    color={styles.textColorDiary}
                                    fontSize="12px"
                                  />
                                </IconButton>
                                <Typography
                                  color={styles.textColorDiary}
                                  fontSize="13px"
                                >
                                  Clear all
                                </Typography>
                              </Button>
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            // height={"200px"}
                            gap="10px"
                            borderRadius="16px"
                            p="20px"
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            sx={{ background: styles.lightDiaryTracker }}
                            justifyContent="center"
                          >
                            {currentActiveData?.entry800g?.map((item) => (
                              <Box
                                width={"100%"}
                                display="flex"
                                justifyContent="space-between"
                                key={item.key}
                              >
                                <Box>
                                  <Typography
                                    fontSize={13}
                                    color={styles.color}
                                    textTransform={"capitalize"}
                                  >
                                    {options800gms?.find(
                                      (option) => option.value === item.name
                                    )?.label ?? item.name}
                                  </Typography>
                                  <Typography
                                    fontSize={10}
                                    color={styles.disabledColor}
                                  >
                                    {item.time}
                                  </Typography>
                                </Box>
                                <Box display="flex" alignItems={"center"}>
                                  <Typography fontSize={13} fontWeight={600}>
                                    {convertItemQuantityToGrams(item)?.quantity}
                                    g
                                  </Typography>
                                  <LongMenu
                                    onClickHandler={async (type) => {
                                      if (type === "edit") {
                                        setEditIngredientData(item);
                                        setIsAddingWeight("entry800g");
                                      } else {
                                        await deleteIngredients(
                                          currentLevel?.levelId,
                                          currentActiveData?._id,
                                          item?._id,
                                          activeDay
                                        );
                                        setIsRefreshNeeded(true);
                                        dispatch(
                                          getCurrentUserDiaries({
                                            levelId: currentLevel?.levelId
                                          })
                                        );
                                      }
                                    }}
                                  />
                                  {/* <LongMenu
                                  onClickHandler={async (type) => {
                                    setEditIngredientData(item);
                                    if (type === "edit") {
                                      setIsAddingWeight("entry800g");
                                    } else {
                                      await deleteIngredients(
                                        currentLevel?.levelId,
                                        currentActiveData?._id,
                                        item?._id
                                      );
                                      setIsRefreshNeeded(true);
                                      dispatch(
                                        getCurrentUserDiaries(
                                          currentLevel?.levelId
                                        )
                                      );
                                    }
                                  }}
                                /> */}
                                </Box>
                              </Box>
                            ))}
                            <Button
                              onClick={async () => {
                                await updateIngredients({
                                  diaryId: currentActiveData?._id,
                                  levelId: currentLevel?.levelId,
                                  entry800g: [],
                                  currentDayDate: activeDay
                                });
                                setIsRefreshNeeded(true);
                                dispatch(
                                  getCurrentUserDiaries({
                                    levelId: currentLevel?.levelId
                                  })
                                );
                              }}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                p: "2px 40px",
                                color: styles.color,
                                border: `1px solid ${styles.color}`,
                                cursor: "pointer",
                                textTransform: "none"
                              }}
                            >
                              <IconButton
                                sx={{
                                  color: styles.textColorDiary,
                                  height: "17px",
                                  width: "17px",
                                  padding: 0,
                                  ":disabled": {
                                    color: styles.textColorDiary
                                  }
                                }}
                                disabled
                              >
                                <Add
                                  color={styles.textColorDiary}
                                  fontSize="12px"
                                />
                              </IconButton>
                              <Typography
                                color={styles.textColorDiary}
                                fontSize="13px"
                              >
                                Clear all
                              </Typography>
                            </Button>
                          </Box>
                        )
                      ) : (
                        <Box
                          height={"200px"}
                          gap="10px"
                          borderRadius="16px"
                          p="20px"
                          alignItems="center"
                          display="flex"
                          flexDirection="column"
                          sx={{ background: styles.lightDiaryTracker }}
                          justifyContent="center"
                        >
                          <NoEntry />
                          <Typography color="#6B7280">No entry</Typography>
                        </Box>
                      )}
                      {currentLevel?.levelName !== "level1" && (
                        <>
                          <Box
                            width={"100%"}
                            gap="40px"
                            display="flex"
                            justifyContent="center"
                          >
                            {selectedLevel !== "level3" ? (
                              <Button
                                onClick={() => {
                                  setEditIngredientData();
                                  setIsAddingWeight("protein");
                                }}
                                sx={{
                                  textTransform: "none",
                                  background: "#368AD0",
                                  color: "#fcfcfc",
                                  height: "40px",
                                  fontWeight: 600,
                                  padding: "12px 24px",
                                  gap: "8px",
                                  borderRadius: "4px"
                                }}
                              >
                                <Add /> Add Protein entry
                              </Button>
                            ) : (
                              <Typography
                                // marginBottom={-4}
                                // marginTop={4}
                                fontWeight={600}
                                color="#368AD0"
                              >
                                Protein{" "}
                              </Typography>
                            )}
                          </Box>
                          {currentActiveData?.protein?.length ? (
                            selectedLevel === "level3" ? (
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                gap={2}
                              >
                                <Box
                                  display={"flex"}
                                  mt={-2}
                                  gap={0.4}
                                  flexDirection="column"
                                  borderRadius="8px"
                                  padding={"12px"}
                                  width={"100%"}
                                  sx={{ background: styles.lightGreyColor }}
                                >
                                  <Typography fontWeight="bold" fontSize={14}>
                                    {"Protein"}
                                  </Typography>
                                  <Typography fontSize={13}>
                                    {`${
                                      Math.round(
                                        +summaryData("protein")?.calories
                                      ) || 0
                                    } Cals · ${
                                      Math.round(
                                        +summaryData("protein")?.protein
                                      ) || 0
                                    }g Protein · ${
                                      Math.round(
                                        +summaryData("protein")?.carbohydrate
                                      ) || 0
                                    }g Carbs · ${
                                      Math.round(
                                        +summaryData("protein")?.fiber
                                      ) || 0
                                    }g Fiber · ${
                                      Math.round(
                                        +summaryData("protein")?.fat
                                      ) || 0
                                    }g Fat`}
                                  </Typography>
                                </Box>
                                <Box
                                  gap="10px"
                                  borderRadius="16px"
                                  p="20px"
                                  mb={2}
                                  alignItems="center"
                                  display="flex"
                                  flexDirection="column"
                                  sx={{ background: styles.lightDiaryTracker }}
                                  justifyContent="center"
                                >
                                  {currentActiveData?.protein?.map((item) => (
                                    <Box
                                      width={"100%"}
                                      display="flex"
                                      justifyContent="space-between"
                                      key={item.key}
                                    >
                                      <Box>
                                        <Box
                                          display={"flex"}
                                          alignItems="center"
                                          gap="10px"
                                        >
                                          <Tooltip
                                            title={
                                              optionsProteins?.find(
                                                (option) =>
                                                  option.value === item.name
                                              )?.label ?? item.name
                                            }
                                          >
                                            <Typography
                                              fontSize={13}
                                              color={styles.color}
                                              textTransform={"capitalize"}
                                              sx={{
                                                cursor: "pointer",
                                                display: "block", // Ensures the container behaves as a block element
                                                maxWidth: isMobile
                                                  ? "90px"
                                                  : "500px", // Set your fixed width (adjust as needed)
                                                overflow: "hidden", // Hide overflow content
                                                textOverflow: "ellipsis", // Show ellipsis when text overflows
                                                whiteSpace: "nowrap" // Prevent text from wrapping
                                              }}
                                            >
                                              {optionsProteins?.find(
                                                (option) =>
                                                  option.value === item.name
                                              )?.label ?? item.name}
                                            </Typography>
                                          </Tooltip>
                                          {item?.type === "meal" ? (
                                            <MealIcon
                                              style={{
                                                width: 16,
                                                height: 16
                                              }}
                                            />
                                          ) : item?.type === "recipe" ? (
                                            <RecipeIcon
                                              style={{
                                                width: 16,
                                                height: 16
                                              }}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                        <Typography
                                          fontSize={10}
                                          color={styles.disabledColor}
                                        >
                                          {item.time}
                                        </Typography>
                                      </Box>
                                      <Box display="flex" alignItems={"center"}>
                                        <Typography
                                          fontSize={13}
                                          fontWeight={600}
                                        >
                                          {Math.round(
                                            updatedItemAccordingQuantity(item)
                                              ?.extraDetails?.serving?.protein
                                          )}
                                          g
                                        </Typography>
                                        <LongMenu
                                          onClickHandler={async (type) => {
                                            if (type === "edit") {
                                              setEditIngredientData(item);
                                              setIsAddingWeight("protein");
                                            } else {
                                              await deleteIngredients(
                                                currentLevel?.levelId,
                                                currentActiveData?._id,
                                                item?._id,
                                                activeDay
                                              );
                                              setIsRefreshNeeded(true);
                                              dispatch(
                                                getCurrentUserDiaries({
                                                  levelId: currentLevel?.levelId
                                                })
                                              );
                                            }
                                          }}
                                        />
                                        {/* <Box display="flex" ml={1} gap={1}>
                                          <IconButton
                                            sx={{
                                              fontSize: "16px",
                                              borderRadius: "4px",
                                              background: "#368AD0",
                                              svg: {
                                                path: {
                                                  fill: "#FCFCFC"
                                                }
                                              },
                                              "&:hover": {
                                                background: "#368AD0"
                                              }
                                            }}
                                            onClick={() => {
                                              setEditIngredientData(item);
                                              setIsAddingWeight("protein");
                                            }}
                                          >
                                            <Edit />
                                          </IconButton>
                                          <IconButton
                                            onClick={async () => {
                                              await deleteIngredients(
                                                currentLevel?.levelId,
                                                currentActiveData?._id,
                                                item?._id,
                                                activeDay
                                              );
                                              setIsRefreshNeeded(true);
                                              dispatch(
                                                getCurrentUserDiaries({
                                                  levelId: currentLevel?.levelId
                                                })
                                              );
                                            }}
                                            sx={{
                                              fontSize: "16px",
                                              borderRadius: "4px",
                                              background: "#FF4000",
                                              svg: {
                                                path: {
                                                  fill: "#FCFCFC"
                                                }
                                              },
                                              "&:hover": {
                                                background: "#FF4000"
                                              }
                                            }}
                                          >
                                            <Delete />
                                          </IconButton>
                                        </Box> */}
                                      </Box>
                                    </Box>
                                  ))}
                                  <Button
                                    onClick={async () => {
                                      await updateIngredients({
                                        diaryId: currentActiveData?._id,
                                        levelId: currentLevel?.levelId,
                                        protein: [],
                                        currentDayDate: activeDay
                                      });
                                      setIsRefreshNeeded(true);
                                      dispatch(
                                        getCurrentUserDiaries({
                                          levelId: currentLevel?.levelId
                                        })
                                      );
                                    }}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                      p: "2px 40px",
                                      color: styles.color,
                                      border: `1px solid ${styles.color}`,
                                      cursor: "pointer",
                                      textTransform: "none"
                                    }}
                                  >
                                    <IconButton
                                      sx={{
                                        color: styles.textColorDiary,
                                        height: "17px",
                                        width: "17px",
                                        padding: 0,
                                        ":disabled": {
                                          color: styles.textColorDiary
                                        }
                                      }}
                                      disabled
                                    >
                                      <Add
                                        color={styles.textColorDiary}
                                        fontSize="12px"
                                      />
                                    </IconButton>
                                    <Typography
                                      color={styles.textColorDiary}
                                      fontSize="13px"
                                    >
                                      Clear all
                                    </Typography>
                                  </Button>
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                gap="10px"
                                borderRadius="16px"
                                p="20px"
                                alignItems="center"
                                display="flex"
                                flexDirection="column"
                                sx={{ background: styles.lightDiaryTracker }}
                                justifyContent="center"
                              >
                                {currentActiveData?.protein?.map((item) => (
                                  <Box
                                    width={"100%"}
                                    display="flex"
                                    justifyContent="space-between"
                                    key={item.key}
                                  >
                                    <Box>
                                      <Typography
                                        fontSize={13}
                                        color={styles.color}
                                        textTransform={"capitalize"}
                                      >
                                        {optionsProteins?.find(
                                          (option) => option.value === item.name
                                        )?.label ?? item.name}
                                      </Typography>
                                      <Typography
                                        fontSize={10}
                                        color={styles.disabledColor}
                                      >
                                        {item.time}
                                      </Typography>
                                    </Box>
                                    <Box display="flex" alignItems={"center"}>
                                      <Typography
                                        fontSize={13}
                                        fontWeight={600}
                                      >
                                        {
                                          convertItemQuantityToGrams(item)
                                            ?.quantity
                                        }
                                        g
                                      </Typography>
                                      <LongMenu
                                        onClickHandler={async (type) => {
                                          if (type === "edit") {
                                            setEditIngredientData(item);
                                            setIsAddingWeight("protein");
                                          } else {
                                            await deleteIngredients(
                                              currentLevel?.levelId,
                                              currentActiveData?._id,
                                              item?._id,
                                              activeDay
                                            );
                                            setIsRefreshNeeded(true);
                                            dispatch(
                                              getCurrentUserDiaries({
                                                levelId: currentLevel?.levelId
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                ))}
                                <Button
                                  onClick={async () => {
                                    await updateIngredients({
                                      diaryId: currentActiveData?._id,
                                      levelId: currentLevel?.levelId,
                                      protein: [],
                                      currentDayDate: activeDay
                                    });
                                    setIsRefreshNeeded(true);
                                    dispatch(
                                      getCurrentUserDiaries({
                                        levelId: currentLevel?.levelId
                                      })
                                    );
                                  }}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    p: "2px 40px",
                                    color: styles.color,
                                    border: `1px solid ${styles.color}`,
                                    cursor: "pointer",
                                    textTransform: "none"
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                      color: styles.textColorDiary,
                                      height: "17px",
                                      width: "17px",
                                      padding: 0,
                                      ":disabled": {
                                        color: styles.textColorDiary
                                      }
                                    }}
                                    disabled
                                  >
                                    <Add
                                      color={styles.textColorDiary}
                                      fontSize="12px"
                                    />
                                  </IconButton>
                                  <Typography
                                    color={styles.textColorDiary}
                                    fontSize="13px"
                                  >
                                    Clear all
                                  </Typography>
                                </Button>
                              </Box>
                            )
                          ) : (
                            <Box
                              height={"200px"}
                              gap="10px"
                              borderRadius="16px"
                              p="20px"
                              alignItems="center"
                              display="flex"
                              flexDirection="column"
                              sx={{ background: styles.lightDiaryTracker }}
                              justifyContent="center"
                            >
                              <NoEntry />
                              <Typography color="#6B7280">No entry</Typography>
                            </Box>
                          )}
                        </>
                      )}
                      {currentLevel?.levelName === "level3" && (
                        <>
                          <Box
                            width={"100%"}
                            gap="40px"
                            display="flex"
                            justifyContent="center"
                          >
                            <Typography
                              // marginBottom={-1.5}
                              // marginTop={4}
                              fontWeight={600}
                              color={"#ff9431"}
                            >
                              Other{" "}
                            </Typography>
                          </Box>
                          {currentActiveData?.otherFood?.length ? (
                            selectedLevel === "level3" ? (
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                gap={2}
                              >
                                <Box
                                  display={"flex"}
                                  gap={0.4}
                                  flexDirection="column"
                                  borderRadius="8px"
                                  mt={-2}
                                  padding={"12px"}
                                  width={"100%"}
                                  sx={{ background: styles.lightGreyColor }}
                                >
                                  <Typography fontWeight="bold" fontSize={14}>
                                    {"Other"}
                                  </Typography>
                                  <Typography fontSize={13}>
                                    {`${
                                      Math.round(
                                        +summaryData("otherFood")?.calories
                                      ) || 0
                                    } Cals · ${
                                      Math.round(
                                        +summaryData("otherFood")?.protein
                                      ) || 0
                                    }g Protein · ${
                                      Math.round(
                                        +summaryData("otherFood")?.carbohydrate
                                      ) || 0
                                    }g Carbs · ${
                                      Math.round(
                                        +summaryData("otherFood")?.fiber
                                      ) || 0
                                    }g Fiber · ${
                                      Math.round(
                                        +summaryData("otherFood")?.fat
                                      ) || 0
                                    }g Fat`}
                                  </Typography>
                                </Box>
                                <Box
                                  gap="10px"
                                  borderRadius="16px"
                                  p="20px"
                                  mb={2}
                                  alignItems="center"
                                  display="flex"
                                  flexDirection="column"
                                  sx={{ background: styles.lightDiaryTracker }}
                                  justifyContent="center"
                                >
                                  {currentActiveData?.otherFood?.map((item) => (
                                    <Box
                                      width={"100%"}
                                      display="flex"
                                      justifyContent="space-between"
                                      key={item.key}
                                    >
                                      <Box>
                                        <Box
                                          display={"flex"}
                                          alignItems="center"
                                          gap="10px"
                                        >
                                          <Tooltip title={item.name}>
                                            <Typography
                                              fontSize={13}
                                              color={styles.color}
                                              textTransform={"capitalize"}
                                              sx={{
                                                cursor: "pointer",
                                                display: "block", // Ensures the container behaves as a block element
                                                maxWidth: isMobile
                                                  ? "90px"
                                                  : "500px", // Set your fixed width (adjust as needed)
                                                overflow: "hidden", // Hide overflow content
                                                textOverflow: "ellipsis", // Show ellipsis when text overflows
                                                whiteSpace: "nowrap" // Prevent text from wrapping
                                              }}
                                            >
                                              {item.name}
                                            </Typography>
                                          </Tooltip>
                                          {item?.type === "meal" ? (
                                            <MealIcon
                                              style={{
                                                width: 16,
                                                height: 16
                                              }}
                                            />
                                          ) : item?.type === "recipe" ? (
                                            <RecipeIcon
                                              style={{
                                                width: 16,
                                                height: 16
                                              }}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                        <Typography
                                          fontSize={10}
                                          color={styles.disabledColor}
                                        >
                                          {item.time}
                                        </Typography>
                                      </Box>
                                      <Box display="flex" alignItems={"center"}>
                                        <Typography
                                          fontSize={13}
                                          fontWeight={600}
                                        >
                                          {Math.round(
                                            updatedItemAccordingQuantity(item)
                                              ?.extraDetails?.serving?.calories
                                          )}
                                        </Typography>
                                        <LongMenu
                                          onClickHandler={async (type) => {
                                            if (type === "edit") {
                                              setEditIngredientData(item);
                                              setIsAddingWeight("otherFood");
                                            } else {
                                              await deleteIngredients(
                                                currentLevel?.levelId,
                                                currentActiveData?._id,
                                                item?._id,
                                                activeDay
                                              );
                                              setIsRefreshNeeded(true);
                                              dispatch(
                                                getCurrentUserDiaries({
                                                  levelId: currentLevel?.levelId
                                                })
                                              );
                                            }
                                          }}
                                        />
                                        {/* <Box display="flex" ml={1} gap={1}>
                                          <IconButton
                                            sx={{
                                              fontSize: "16px",
                                              borderRadius: "4px",
                                              background: "#368AD0",
                                              svg: {
                                                path: {
                                                  fill: "#FCFCFC"
                                                }
                                              },
                                              "&:hover": {
                                                background: "#368AD0"
                                              }
                                            }}
                                            onClick={() => {
                                              setEditIngredientData(item);
                                              setIsAddingWeight("otherFood");
                                            }}
                                          >
                                            <Edit />
                                          </IconButton>
                                          <IconButton
                                            onClick={async () => {
                                              await deleteIngredients(
                                                currentLevel?.levelId,
                                                currentActiveData?._id,
                                                item?._id,
                                                activeDay
                                              );
                                              setIsRefreshNeeded(true);
                                              dispatch(
                                                getCurrentUserDiaries({
                                                  levelId: currentLevel?.levelId
                                                })
                                              );
                                            }}
                                            sx={{
                                              fontSize: "16px",
                                              borderRadius: "4px",
                                              background: "#FF4000",
                                              svg: {
                                                path: {
                                                  fill: "#FCFCFC"
                                                }
                                              },
                                              "&:hover": {
                                                background: "#FF4000"
                                              }
                                            }}
                                          >
                                            <Delete />
                                          </IconButton>
                                        </Box> */}
                                      </Box>
                                    </Box>
                                  ))}
                                  <Button
                                    onClick={async () => {
                                      await updateIngredients({
                                        diaryId: currentActiveData?._id,
                                        levelId: currentLevel?.levelId,
                                        otherFood: [],
                                        currentDayDate: activeDay
                                      });
                                      setIsRefreshNeeded(true);
                                      dispatch(
                                        getCurrentUserDiaries({
                                          levelId: currentLevel?.levelId
                                        })
                                      );
                                    }}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                      p: "2px 40px",
                                      color: styles.color,
                                      border: `1px solid ${styles.color}`,
                                      cursor: "pointer",
                                      textTransform: "none"
                                    }}
                                  >
                                    <IconButton
                                      sx={{
                                        color: styles.textColorDiary,
                                        height: "17px",
                                        width: "17px",
                                        padding: 0,
                                        ":disabled": {
                                          color: styles.textColorDiary
                                        }
                                      }}
                                      disabled
                                    >
                                      <Add
                                        color={styles.textColorDiary}
                                        fontSize="12px"
                                      />
                                    </IconButton>
                                    <Typography
                                      color={styles.textColorDiary}
                                      fontSize="13px"
                                    >
                                      Clear all
                                    </Typography>
                                  </Button>
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                gap="10px"
                                borderRadius="16px"
                                p="20px"
                                alignItems="center"
                                display="flex"
                                flexDirection="column"
                                sx={{ background: styles.lightDiaryTracker }}
                                justifyContent="center"
                              >
                                {currentActiveData?.otherFood?.map((item) => (
                                  <Box
                                    width={"100%"}
                                    display="flex"
                                    justifyContent="space-between"
                                    key={item.key}
                                  >
                                    <Box>
                                      <Typography
                                        fontSize={13}
                                        color={styles.color}
                                        textTransform={"capitalize"}
                                      >
                                        {optionsProteins?.find(
                                          (option) => option.value === item.name
                                        )?.label ?? item.name}
                                      </Typography>
                                      <Typography
                                        fontSize={10}
                                        color={styles.disabledColor}
                                      >
                                        {item.time}
                                      </Typography>
                                    </Box>
                                    <Box display="flex" alignItems={"center"}>
                                      <Typography
                                        fontSize={13}
                                        fontWeight={600}
                                      >
                                        {
                                          convertItemQuantityToGrams(item)
                                            ?.quantity
                                        }
                                        g
                                      </Typography>
                                      <LongMenu
                                        onClickHandler={async (type) => {
                                          if (type === "edit") {
                                            setEditIngredientData(item);
                                            setIsAddingWeight("otherFood");
                                          } else {
                                            await deleteIngredients(
                                              currentLevel?.levelId,
                                              currentActiveData?._id,
                                              item?._id,
                                              activeDay
                                            );
                                            setIsRefreshNeeded(true);
                                            dispatch(
                                              getCurrentUserDiaries({
                                                levelId: currentLevel?.levelId
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                ))}
                                <Button
                                  onClick={async () => {
                                    await updateIngredients({
                                      diaryId: currentActiveData?._id,
                                      levelId: currentLevel?.levelId,
                                      otherFood: [],
                                      currentDayDate: activeDay
                                    });
                                    setIsRefreshNeeded(true);
                                    dispatch(
                                      getCurrentUserDiaries({
                                        levelId: currentLevel?.levelId
                                      })
                                    );
                                  }}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    p: "2px 40px",
                                    color: styles.color,
                                    border: `1px solid ${styles.color}`,
                                    cursor: "pointer",
                                    textTransform: "none"
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                      color: styles.textColorDiary,
                                      height: "17px",
                                      width: "17px",
                                      padding: 0,
                                      ":disabled": {
                                        color: styles.textColorDiary
                                      }
                                    }}
                                    disabled
                                  >
                                    <Add
                                      color={styles.textColorDiary}
                                      fontSize="12px"
                                    />
                                  </IconButton>
                                  <Typography
                                    color={styles.textColorDiary}
                                    fontSize="13px"
                                  >
                                    Clear all
                                  </Typography>
                                </Button>
                              </Box>
                            )
                          ) : (
                            <Box
                              height={"200px"}
                              gap="10px"
                              borderRadius="16px"
                              p="20px"
                              alignItems="center"
                              display="flex"
                              flexDirection="column"
                              sx={{ background: styles.lightDiaryTracker }}
                              justifyContent="center"
                            >
                              <NoEntry />
                              <Typography color="#6B7280">No entry</Typography>
                            </Box>
                          )}
                        </>
                      )}
                      {/* <Box display="flex" justifyContent="center">
                        <Button
                          onClick={() => {
                            dispatch(
                              completeDiaryDay({
                                levelId: currentLevel?.levelId,
                                diaryId: currentActiveData?._id
                              })
                            );
                            setIsRefreshNeeded(true);
                            setTimeout(() => {
                              dispatch(
                                getCurrentUserDiaries({
                                  levelId: currentLevel?.levelId
                                })
                              );
                            }, 2000);
                          }}
                          disabled={
                            currentActiveData?.completionDiaryDayDate
                              ? true
                              : !currentActiveData?.entry800g?.length &&
                                !currentActiveData?.protein?.length
                          }
                          sx={{
                            fontSize: "small",
                            p: "10px 20px",
                            color: "#368AD0",
                            border: "1px solid #368AD0",
                            cursor: "pointer",
                            textTransform: "none",
                            ":disabled": {
                              backgroundColor: "#A7C8E9",
                              border: "none",
                              color: "#64AEDD",
                              pointerEvents: "none"
                            }
                          }}
                        >
                          Complete the day
                        </Button>
                      </Box> */}
                    </Box>
                  </Box>
                )
              ) : (
                <></>
              )}
            </Box>
          )}
        </Box>
      )}
      {activeLevelForMobile && isMobile && (
        <Box
          display="flex"
          sx={{
            width: "100%",
            padding: "10px 25px"
          }}
          flexDirection="column"
          gap={3}
        >
          {isShowingAllStats ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={"16%"}
              mr={isMobile ? 2 : 0}
            >
              <IconButton
                onClick={() => {
                  setIsShowingAllStats(false);
                }}
              >
                <ChevronLeft
                  sx={{
                    width: 30,
                    height: 30,
                    color: styles.color
                  }}
                  width={30}
                  height={30}
                  color={styles.color}
                />
              </IconButton>

              <Typography fontWeight={600} fontSize={"18px"}>
                Stats
              </Typography>
              <div />
            </Box>
          ) : isShowingLeaderBoard ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={"16%"}
              mr={isMobile ? 2 : 0}
            >
              <IconButton onClick={() => setIsShowingLeaderBoard(false)}>
                <ChevronLeft
                  sx={{
                    width: 30,
                    height: 30,
                    color: styles.color
                  }}
                />
              </IconButton>
              <Typography fontWeight={600} fontSize={"18px"}>
                Monthly Leaderboard
              </Typography>
              <div />
            </Box>
          ) : isShowingCreateMeal || editMealToDiary ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={"16%"}
              mr={isMobile ? 2 : 0}
            >
              <IconButton
                onClick={() => {
                  if (selectedOptionData?.serving) {
                    setSelectedOptionData(null);
                  } else if (isAddingMealItem) {
                    setIsAddingMealItem(false);
                  } else if (editMealToDiary) {
                    setEditMealToDiary(false);
                  } else {
                    setIsShowingCreateMeal(false);
                  }
                }}
              >
                <ChevronLeft
                  sx={{
                    width: 30,
                    height: 30,
                    color: styles.color
                  }}
                />
              </IconButton>
              <Typography fontWeight={600} fontSize={"18px"}>
                Add Meal
              </Typography>
              <div />
            </Box>
          ) : isShowingCreateRecipe || editRecipeToDiary ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={"16%"}
              mr={isMobile ? 2 : 0}
            >
              <IconButton
                onClick={() => {
                  if (selectedOptionData?.serving) {
                    setSelectedOptionData(null);
                  } else if (isAddingMealItem) {
                    setIsAddingMealItem(false);
                  } else if (editRecipeToDiary) {
                    setEditRecipeToDiary(false);
                  } else {
                    setIsShowingCreateRecipe(false);
                  }
                }}
              >
                <ChevronLeft
                  sx={{
                    width: 30,
                    height: 30,
                    color: styles.color
                  }}
                />
              </IconButton>
              <Typography fontWeight={600} fontSize={"18px"}>
                Add Recipe
              </Typography>
              <div />
            </Box>
          ) : isShowingCreateCustomFood || editCustomFoodToDiary ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={"16%"}
              mr={isMobile ? 2 : 0}
            >
              <IconButton
                onClick={() => {
                  if (selectedOptionData?.serving) {
                    setSelectedOptionData(null);
                  } else if (editCustomFoodToDiary) {
                    setEditCustomFoodToDiary(false);
                  } else {
                    setIsShowingCreateCustomFood(false);
                  }
                }}
              >
                <ChevronLeft
                  sx={{
                    width: 30,
                    height: 30,
                    color: styles.color
                  }}
                />
              </IconButton>
              <Typography fontWeight={600} fontSize={"18px"}>
                Add Custom Food
              </Typography>
              <div />
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={"16%"}
            >
              <IconButton
                onClick={() => {
                  setActiveLevelForMobile(false);
                }}
              >
                <ChevronLeft
                  sx={{
                    width: 30,
                    height: 30,
                    color: styles.color
                  }}
                  width={30}
                  height={30}
                  color={styles.color}
                />
              </IconButton>

              <PickerWithButtonField
                maxDate={dayjs(userData?.["createdAt"])
                  .startOf("month")
                  .format("YYYY-MM-DD")}
                allCompletedDates={allCompletedData}
                date={date}
                visibleDaysCount={visibleDaysCount}
                setStartDay={setStartDay}
                setDate={setDate}
                setActiveDay={setActiveDay}
              />
              <ThreeDotMenu
                isLevel3={currentLevel?.levelName === "level3"}
                onClickHandler={async (type) => {
                  if (type === "stats") setIsShowingAllStats(true);
                  else if (type === "leaderboard")
                    setIsShowingLeaderBoard(true);
                  else if (type === "createMeal") {
                    setEditMealToDiary(true);
                    // dispatch(updateAddingMeal({ meal: {} }));
                  } else if (type === "createRecipe") {
                    setEditRecipeToDiary(true);
                    // dispatch(updateAddingRecipe({ recipe: {} }));
                  } else if (type === "addCustomFoods") {
                    setEditCustomFoodToDiary(true);
                    // dispatch(updateAddingRecipe({ recipe: {} }));
                  }
                }}
              />
            </Box>
          )}
          {isShowingAllStats ? (
            <StatsList level={currentLevel} />
          ) : isShowingLeaderBoard ? (
            <Leaderboard levelId={currentLevel?.levelId} />
          ) : editMealToDiary ? (
            <AddMealOrRecipeToDiary
              currentLevel={currentLevel}
              levelId={currentLevel?.levelId}
              setIsRefreshNeeded={setIsRefreshNeeded}
              activeDay={activeDay}
              setIsShowingCreateMeal={setIsShowingCreateMeal}
              setIsShowingCreateRecipe={setIsShowingCreateRecipe}
              setEditMealToDiary={setEditMealToDiary}
              setEditRecipeToDiary={setEditRecipeToDiary}
              type="meal"
            />
          ) : editRecipeToDiary ? (
            <AddMealOrRecipeToDiary
              currentLevel={currentLevel}
              levelId={currentLevel?.levelId}
              setIsRefreshNeeded={setIsRefreshNeeded}
              activeDay={activeDay}
              setIsShowingCreateMeal={setIsShowingCreateMeal}
              setIsShowingCreateRecipe={setIsShowingCreateRecipe}
              setEditMealToDiary={setEditMealToDiary}
              setEditRecipeToDiary={setEditRecipeToDiary}
              type="recipe"
            />
          ) : editCustomFoodToDiary ? (
            <AddMealOrRecipeToDiary
              currentLevel={currentLevel}
              levelId={currentLevel?.levelId}
              setIsRefreshNeeded={setIsRefreshNeeded}
              activeDay={activeDay}
              setIsShowingCreateMeal={setIsShowingCreateMeal}
              setIsShowingCreateRecipe={setIsShowingCreateRecipe}
              setEditMealToDiary={setEditMealToDiary}
              setEditRecipeToDiary={setEditRecipeToDiary}
              customFoodFormData={customFoodFormData}
              setCustomFoodFormData={setCustomFoodFormData}
              setIsShowingCreateCustomFood={setIsShowingCreateCustomFood}
              setEditCustomFoodToDiary={setEditCustomFoodToDiary}
              type="custom food"
            />
          ) : isShowingCreateMeal ? (
            <AddMealForLevel3
              setIsShowingCreateMeal={setIsShowingCreateMeal}
              isAddingMealItem={isAddingMealItem}
              setIsShowingCreateRecipe={setIsShowingCreateRecipe}
              setIsAddingMealItem={setIsAddingMealItem}
              selectedOptionData={selectedOptionData}
              setSelectedOptionData={setSelectedOptionData}
              type="meal"
              levelId={currentLevel?.levelId}
            />
          ) : isShowingCreateRecipe ? (
            <AddMealForLevel3
              setIsShowingCreateMeal={setIsShowingCreateMeal}
              setIsShowingCreateRecipe={setIsShowingCreateRecipe}
              isAddingMealItem={isAddingMealItem}
              setIsAddingMealItem={setIsAddingMealItem}
              selectedOptionData={selectedOptionData}
              setSelectedOptionData={setSelectedOptionData}
              type="recipe"
              levelId={currentLevel?.levelId}
            />
          ) : isShowingCreateCustomFood ? (
            <Box>
              <AddMealForLevel3
                setIsShowingCreateCustomFood={setIsShowingCreateCustomFood}
                setIsShowingCreateRecipe={setIsShowingCreateRecipe}
                setIsShowingCreateMeal={setIsShowingCreateMeal}
                isAddingMealItem={isAddingMealItem}
                setIsAddingMealItem={setIsAddingMealItem}
                selectedOptionData={selectedOptionData}
                setSelectedOptionData={setSelectedOptionData}
                customFoodFormData={customFoodFormData}
                setCustomFoodFormData={setCustomFoodFormData}
                type={"customFood"}
                levelId={currentLevel?.levelId}
              />
            </Box>
          ) : (
            <>
              <WeekView
                allCompletedData={allCompletedData}
                activeDay={activeDay}
                setActiveDay={setActiveDay}
                startDay={startDay}
                visibleDays={visibleDays}
                visibleDaysCount={visibleDaysCount}
                days={days}
                setStartDay={setStartDay}
                diaryData={diaryData}
                handleDayClick={handleDayClick}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />{" "}
              <Box
                display="flex"
                flexDirection="column"
                gap={"20px"}
                sx={{
                  ...((!isMobile || !isTablet) && {
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 120px)"
                  })
                }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    color={styles.color}
                    fontWeight={600}
                    fontSize={"24px"}
                  >
                    {formatDate(activeDay, false)} (
                    {dayjs(activeDay).format(isMobile ? "ddd" : "dddd")})
                  </Typography>
                  <CopyYesterdayButton
                    diaryData={diaryData}
                    activeDay={activeDay}
                    selectedLevel={selectedLevel}
                    currentLevel={currentLevel}
                    currentActiveData={currentActiveData}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                    setIsRefreshNeeded={setIsRefreshNeeded}
                    dispatch={dispatch}
                    copyYesterdayData={copyYesterdayData}
                  />
                </Box>
                <Box
                  display="flex"
                  borderRadius={"16px"}
                  sx={{ background: styles.diaryTracker }}
                  justifyContent="center"
                >
                  <DiaryTracker
                    levelName={currentLevel?.levelName}
                    openWeightModal={openWeightModal}
                    setOpenWeightModal={setOpenWeightModal}
                    title={"#800gChallenge"}
                    levelId={currentLevel?.levelId}
                    diaryId={currentActiveData?._id || ""}
                    value={
                      selectedLevel === "level3"
                        ? Math.round(
                            summaryData("entry800g")?.metric_serving_amount
                          )
                        : getTotalWeight(currentActiveData?.entry800g) || 0
                    }
                    strokeColor={"#F6541C"}
                    maxValue={currentActiveData?.outOf800g}
                    setIsRefreshNeeded={setIsRefreshNeeded}
                    dayName={activeDay}
                    heightX={currentLevel?.levelName === "level2" ? 130 : 250}
                    widthX={currentLevel?.levelName === "level2" ? 190 : 350}
                  />
                  {currentLevel?.levelName !== "level1" && (
                    <DiaryTracker
                      levelName={currentLevel?.levelName}
                      openWeightModal={openWeightModal}
                      setOpenWeightModal={setOpenWeightModal}
                      title={"Protein"}
                      levelId={currentLevel?.levelId}
                      diaryId={currentActiveData?._id || ""}
                      value={
                        selectedLevel === "level3"
                          ? Math.round(
                              summaryData("entry800g")?.protein +
                                summaryData("protein")?.protein +
                                summaryData("otherFood")?.protein
                            )
                          : getTotalWeight(currentActiveData?.protein) || 0
                      }
                      strokeColor={"#368AD0"}
                      maxValue={currentActiveData?.outOfProtein}
                      setIsRefreshNeeded={setIsRefreshNeeded}
                      dayName={activeDay}
                      heightX={currentLevel?.levelName === "level2" ? 130 : 250}
                      widthX={currentLevel?.levelName === "level2" ? 190 : 350}
                    />
                  )}
                  {currentLevel?.levelName === "level3" && (
                    <DiaryTracker
                      isCalories
                      levelName={currentLevel?.levelName}
                      openWeightModal={openWeightModal}
                      setOpenWeightModal={setOpenWeightModal}
                      title={"Calories"}
                      levelId={currentLevel?.levelId}
                      diaryId={currentActiveData?._id || ""}
                      value={
                        Math.round(
                          summaryData("entry800g")?.calories +
                            summaryData("protein")?.calories +
                            summaryData("otherFood")?.calories
                        ) || 0
                      }
                      strokeColor={"#FF9431"}
                      maxValue={currentActiveData?.outOfCalories}
                      setIsRefreshNeeded={setIsRefreshNeeded}
                      dayName={activeDay}
                      heightX={currentLevel?.levelName === "level2" ? 210 : 250}
                      widthX={currentLevel?.levelName === "level2" ? 300 : 350}
                    />
                  )}
                </Box>
                {currentLevel?.levelName === "level3" && (
                  <Box display="flex" gap="40px" justifyContent="center">
                    <Button
                      onClick={handleClick}
                      sx={{
                        textTransform: "none",
                        background: "#368AD0",
                        color: "#fcfcfc",
                        height: "40px",
                        fontWeight: 600,
                        width: { lg: "40%", xs: "50%" },
                        padding: "12px 24px",
                        gap: "8px",
                        borderRadius: "4px"
                      }}
                    >
                      <Add /> Add
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      slotProps={{
                        paper: {
                          style: {
                            background: styles.background,
                            color: styles.color
                          }
                        }
                      }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button"
                      }}
                      sx={{
                        "& .MuiPaper-root": {
                          width: anchorEl ? anchorEl.offsetWidth : "auto",
                          background: "#fff",
                          color: "#000"
                          // margin: "0 20px"
                        }
                      }}
                    >
                      <MenuItem
                        sx={{
                          // justifyContent: "center",
                          gap: "10px"
                          // alignItems: "center"
                        }}
                        onClick={() => {
                          handleClose();
                          setEditIngredientData();
                          setIsAddingWeight("entry800g");
                        }}
                      >
                        <Add
                          style={{ marginRight: "4px" }}
                          height={17}
                          width={17}
                        />{" "}
                        <Typography fontSize={12.75}>Add 800g </Typography>
                      </MenuItem>
                      <MenuItem
                        sx={{
                          gap: "10px"
                          // alignItems: "center",
                          // justifyContent: "center"
                        }}
                        onClick={() => {
                          handleClose();
                          setEditIngredientData();
                          setIsAddingWeight("protein");
                        }}
                      >
                        {" "}
                        <Add
                          style={{ marginRight: "4px" }}
                          height={17}
                          width={17}
                        />
                        <Typography fontSize={12.75}>Add Protein</Typography>
                      </MenuItem>
                      <MenuItem
                        sx={{
                          gap: "10px"
                          // alignItems: "center",
                          // justifyContent: "center"
                        }}
                        onClick={() => {
                          handleClose();
                          setEditIngredientData();
                          setIsAddingWeight("otherFood");
                        }}
                      >
                        <Add
                          style={{ marginRight: "4px" }}
                          height={17}
                          width={17}
                        />{" "}
                        <Typography fontSize={12.75}>Add Other</Typography>
                      </MenuItem>
                      <MenuItem
                        sx={{
                          gap: "10px"
                          // alignItems: "center",
                          // justifyContent: "center"
                        }}
                        onClick={() => {
                          handleClose();
                          setIsShowingCreateCustomFood(false);
                          setEditCustomFoodToDiary(true);
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/custom.png"
                          alt="base"
                          sx={{
                            height: 20,
                            width: 20,
                            objectFit: "cover",
                            marginRight: "8px"
                          }}
                        />
                        <Typography fontSize={12.75}>
                          Add Custom Food
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        sx={{
                          gap: "10px"
                          // alignItems: "center"
                        }}
                        onClick={() => {
                          handleClose();
                          // Action for Add Meal
                          setIsShowingCreateMeal(false);
                          setEditMealToDiary(true);
                        }}
                      >
                        <MealIcon
                          style={{ marginRight: "8px" }}
                          height={20}
                          width={20}
                        />
                        <Typography fontSize={12.75}>Add Meal</Typography>
                      </MenuItem>
                      <MenuItem
                        sx={{
                          gap: "10px",
                          alignItems: "center"
                        }}
                        onClick={() => {
                          handleClose();
                          // Action for Add Recipe
                          setIsShowingCreateRecipe(false);
                          setEditRecipeToDiary(true);
                        }}
                      >
                        <RecipeIcon
                          style={{ marginRight: "8px" }}
                          height={17}
                          width={17}
                        />
                        <Typography fontSize={12.75}>Add Recipe</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                )}
                <Box
                  width={"100%"}
                  gap="40px"
                  display="flex"
                  justifyContent="center"
                >
                  {selectedLevel !== "level3" ? (
                    <Button
                      onClick={() => {
                        setEditIngredientData();
                        setIsAddingWeight("entry800g");
                      }}
                      sx={{
                        textTransform: "none",
                        background: "#368AD0",
                        color: "#fcfcfc",
                        height: "40px",
                        fontWeight: 600,
                        padding: "12px 24px",
                        gap: "8px",
                        borderRadius: "4px"
                      }}
                    >
                      <Add /> Add 800g entry
                    </Button>
                  ) : (
                    <Typography
                      // marginBottom={-4}
                      // marginTop={4}
                      fontWeight={600}
                      color="#f6521c"
                    >
                      800g{" "}
                    </Typography>
                  )}
                </Box>
                {currentActiveData?.entry800g?.length ? (
                  selectedLevel === "level3" ? (
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                      <Box
                        display={"flex"}
                        gap={0.4}
                        flexDirection="column"
                        mt={-2}
                        borderRadius="8px"
                        padding={"12px"}
                        width={"100%"}
                        sx={{ background: styles.lightGreyColor }}
                      >
                        <Typography fontWeight="bold" fontSize={14}>
                          {"800g"}
                        </Typography>
                        <Typography fontSize={13}>
                          {`${
                            Math.round(+summaryData("entry800g")?.calories) || 0
                          } Cals · ${
                            Math.round(+summaryData("entry800g")?.protein) || 0
                          }g Protein · ${
                            Math.round(
                              +summaryData("entry800g")?.carbohydrate
                            ) || 0
                          }g Carbs · ${
                            Math.round(+summaryData("entry800g")?.fiber) || 0
                          }g Fiber · ${
                            Math.round(+summaryData("entry800g")?.fat) || 0
                          }g Fat`}
                        </Typography>
                      </Box>
                      <Box
                        // height={"200px"}
                        gap="10px"
                        borderRadius="16px"
                        p="20px"
                        mb={2}
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        sx={{ background: styles.lightDiaryTracker }}
                        justifyContent="center"
                      >
                        {currentActiveData?.entry800g?.map((item) => (
                          <Box
                            width={"100%"}
                            display="flex"
                            justifyContent="space-between"
                            key={item.key}
                          >
                            <Box>
                              <Box
                                display={"flex"}
                                alignItems="center"
                                gap="10px"
                              >
                                <Tooltip
                                  title={
                                    options800gms?.find(
                                      (option) => option.value === item.name
                                    )?.label ?? item.name
                                  }
                                >
                                  <Typography
                                    fontSize={13}
                                    color={styles.color}
                                    textTransform={"capitalize"}
                                    sx={{
                                      cursor: "pointer",
                                      display: "block", // Ensures the container behaves as a block element
                                      maxWidth: isMobile ? "180px" : "250px", // Set your fixed width (adjust as needed)
                                      overflow: "hidden", // Hide overflow content
                                      textOverflow: "ellipsis", // Show ellipsis when text overflows
                                      whiteSpace: "nowrap" // Prevent text from wrapping
                                    }}
                                  >
                                    {options800gms?.find(
                                      (option) => option.value === item.name
                                    )?.label ?? item.name}
                                  </Typography>
                                </Tooltip>
                                {item?.type === "meal" ? (
                                  <MealIcon
                                    style={{
                                      width: 16,
                                      height: 16
                                    }}
                                  />
                                ) : item?.type === "recipe" ? (
                                  <RecipeIcon
                                    style={{
                                      width: 16,
                                      height: 16
                                    }}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Box>
                              <Typography
                                fontSize={10}
                                color={styles.disabledColor}
                              >
                                {item.time}
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems={"center"}>
                              <Typography fontSize={13} fontWeight={600}>
                                {convertItemQuantityToGrams(item)?.quantity}g
                              </Typography>
                              <LongMenu
                                onClickHandler={async (type) => {
                                  setEditIngredientData(item);
                                  if (type === "edit") {
                                    setIsAddingWeight("entry800g");
                                  } else {
                                    await deleteIngredients(
                                      currentLevel?.levelId,
                                      currentActiveData?._id,
                                      item?._id,
                                      activeDay
                                    );
                                    setIsRefreshNeeded(true);
                                    dispatch(
                                      getCurrentUserDiaries({
                                        levelId: currentLevel?.levelId
                                      })
                                    );
                                  }
                                }}
                              />
                            </Box>
                          </Box>
                        ))}
                        <Button
                          onClick={async () => {
                            await updateIngredients({
                              diaryId: currentActiveData?._id,
                              levelId: currentLevel?.levelId,
                              entry800g: [],
                              currentDayDate: activeDay
                            });
                            setIsRefreshNeeded(true);
                            dispatch(
                              getCurrentUserDiaries({
                                levelId: currentLevel?.levelId
                              })
                            );
                          }}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            p: "2px 40px",
                            color: styles.color,
                            border: `1px solid ${styles.color}`,
                            cursor: "pointer",
                            textTransform: "none"
                          }}
                        >
                          <IconButton
                            sx={{
                              color: styles.textColorDiary,
                              height: "17px",
                              width: "17px",
                              padding: 0,
                              ":disabled": {
                                color: styles.textColorDiary
                              }
                            }}
                            disabled
                          >
                            <Add
                              color={styles.textColorDiary}
                              fontSize="12px"
                            />
                          </IconButton>
                          <Typography
                            color={styles.textColorDiary}
                            fontSize="13px"
                          >
                            Clear all
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      // height={"200px"}
                      gap="10px"
                      borderRadius="16px"
                      p="20px"
                      alignItems="center"
                      display="flex"
                      flexDirection="column"
                      sx={{ background: styles.lightDiaryTracker }}
                      justifyContent="center"
                    >
                      {currentActiveData?.entry800g?.map((item) => (
                        <Box
                          width={"100%"}
                          display="flex"
                          justifyContent="space-between"
                          key={item.key}
                        >
                          <Box>
                            <Typography
                              fontSize={13}
                              color={styles.color}
                              textTransform={"capitalize"}
                            >
                              {options800gms?.find(
                                (option) => option.value === item.name
                              )?.label ?? item.name}
                            </Typography>
                            <Typography
                              fontSize={10}
                              color={styles.disabledColor}
                            >
                              {item.time}
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems={"center"}>
                            <Typography fontSize={13} fontWeight={600}>
                              {convertItemQuantityToGrams(item)?.quantity}g
                            </Typography>
                            <LongMenu
                              onClickHandler={async (type) => {
                                setEditIngredientData(item);
                                if (type === "edit") {
                                  setIsAddingWeight("entry800g");
                                } else {
                                  await deleteIngredients(
                                    currentLevel?.levelId,
                                    currentActiveData?._id,
                                    item?._id,
                                    activeDay
                                  );
                                  setIsRefreshNeeded(true);
                                  dispatch(
                                    getCurrentUserDiaries({
                                      levelId: currentLevel?.levelId
                                    })
                                  );
                                }
                              }}
                            />
                          </Box>
                        </Box>
                      ))}
                      <Button
                        onClick={async () => {
                          await updateIngredients({
                            diaryId: currentActiveData?._id,
                            levelId: currentLevel?.levelId,
                            entry800g: [],
                            currentDayDate: activeDay
                          });
                          setIsRefreshNeeded(true);
                          dispatch(
                            getCurrentUserDiaries({
                              levelId: currentLevel?.levelId
                            })
                          );
                        }}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          p: "2px 40px",
                          color: styles.color,
                          border: `1px solid ${styles.color}`,
                          cursor: "pointer",
                          textTransform: "none"
                        }}
                      >
                        <IconButton
                          sx={{
                            color: styles.textColorDiary,
                            height: "17px",
                            width: "17px",
                            padding: 0,
                            ":disabled": {
                              color: styles.textColorDiary
                            }
                          }}
                          disabled
                        >
                          <Add color={styles.textColorDiary} fontSize="12px" />
                        </IconButton>
                        <Typography
                          color={styles.textColorDiary}
                          fontSize="13px"
                        >
                          Clear all
                        </Typography>
                      </Button>
                    </Box>
                  )
                ) : (
                  <Box
                    height={"200px"}
                    gap="10px"
                    borderRadius="16px"
                    p="20px"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    sx={{ background: styles.lightDiaryTracker }}
                    justifyContent="center"
                  >
                    <NoEntry />
                    <Typography color="#6B7280">No entry</Typography>
                  </Box>
                )}
                {currentLevel?.levelName !== "level1" && (
                  <>
                    <Box
                      width={"100%"}
                      gap="40px"
                      display="flex"
                      justifyContent="center"
                    >
                      {selectedLevel !== "level3" ? (
                        <Button
                          onClick={() => {
                            setEditIngredientData();
                            setIsAddingWeight("protein");
                          }}
                          sx={{
                            textTransform: "none",
                            background: "#368AD0",
                            color: "#fcfcfc",
                            height: "40px",
                            fontWeight: 600,
                            padding: "12px 24px",
                            gap: "8px",
                            borderRadius: "4px"
                          }}
                        >
                          <Add /> Add Protein entry
                        </Button>
                      ) : (
                        <Typography
                          // marginBottom={-4}
                          // marginTop={4}
                          fontWeight={600}
                          color="#368AD0"
                        >
                          Protein{" "}
                        </Typography>
                      )}
                    </Box>
                    {currentActiveData?.protein?.length ? (
                      selectedLevel === "level3" ? (
                        <Box display={"flex"} flexDirection={"column"} gap={2}>
                          <Box
                            display={"flex"}
                            gap={0.4}
                            flexDirection="column"
                            mt={-2}
                            borderRadius="8px"
                            padding={"12px"}
                            width={"100%"}
                            sx={{ background: styles.lightGreyColor }}
                          >
                            <Typography fontWeight="bold" fontSize={14}>
                              {"Protein"}
                            </Typography>
                            <Typography fontSize={13}>
                              <Typography fontSize={13}>
                                {`${
                                  Math.round(
                                    +summaryData("protein")?.calories
                                  ) || 0
                                } Cals · ${
                                  Math.round(
                                    +summaryData("protein")?.protein
                                  ) || 0
                                }g Protein · ${
                                  Math.round(
                                    +summaryData("protein")?.carbohydrate
                                  ) || 0
                                }g Carbs · ${
                                  Math.round(+summaryData("protein")?.fiber) ||
                                  0
                                }g Fiber · ${
                                  Math.round(+summaryData("protein")?.fat) || 0
                                }g Fat`}
                              </Typography>
                            </Typography>
                          </Box>
                          <Box
                            gap="10px"
                            borderRadius="16px"
                            p="20px"
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            mb={2}
                            sx={{ background: styles.lightDiaryTracker }}
                            justifyContent="center"
                          >
                            {currentActiveData?.protein?.map((item) => (
                              <Box
                                width={"100%"}
                                display="flex"
                                justifyContent="space-between"
                                key={item.key}
                              >
                                <Box>
                                  <Box
                                    display={"flex"}
                                    alignItems="center"
                                    gap="10px"
                                  >
                                    <Tooltip
                                      title={
                                        optionsProteins?.find(
                                          (option) => option.value === item.name
                                        )?.label ?? item.name
                                      }
                                    >
                                      <Typography
                                        sx={{
                                          cursor: "pointer",
                                          display: "block", // Ensures the container behaves as a block element
                                          maxWidth: isMobile
                                            ? "210px"
                                            : "600px", // Set your fixed width (adjust as needed)
                                          overflow: "hidden", // Hide overflow content
                                          textOverflow: "ellipsis", // Show ellipsis when text overflows
                                          whiteSpace: "nowrap" // Prevent text from wrapping
                                        }}
                                        fontSize={13}
                                        color={styles.color}
                                      >
                                        {optionsProteins?.find(
                                          (option) => option.value === item.name
                                        )?.label ?? item.name}
                                      </Typography>
                                    </Tooltip>
                                    {item?.type === "meal" ? (
                                      <MealIcon
                                        style={{
                                          width: 16,
                                          height: 16
                                        }}
                                      />
                                    ) : item?.type === "recipe" ? (
                                      <RecipeIcon
                                        style={{
                                          width: 16,
                                          height: 16
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Box>
                                  <Typography
                                    fontSize={10}
                                    color={styles.disabledColor}
                                  >
                                    {item.time}
                                  </Typography>
                                </Box>
                                <Box display="flex" alignItems={"center"}>
                                  <Typography fontSize={13} fontWeight={600}>
                                    {convertItemQuantityToGrams(item)?.quantity}
                                    g
                                  </Typography>
                                  <LongMenu
                                    onClickHandler={async (type) => {
                                      setEditIngredientData(item);
                                      if (type === "edit") {
                                        setIsAddingWeight("protein");
                                      } else {
                                        await deleteIngredients(
                                          currentLevel?.levelId,
                                          currentActiveData?._id,
                                          item?._id,
                                          activeDay
                                        );
                                        setIsRefreshNeeded(true);
                                        dispatch(
                                          getCurrentUserDiaries({
                                            levelId: currentLevel?.levelId
                                          })
                                        );
                                      }
                                    }}
                                  />
                                </Box>
                              </Box>
                            ))}
                            <Button
                              onClick={async () => {
                                await updateIngredients({
                                  diaryId: currentActiveData?._id,
                                  levelId: currentLevel?.levelId,
                                  protein: [],
                                  currentDayDate: activeDay
                                });
                                setIsRefreshNeeded(true);
                                dispatch(
                                  getCurrentUserDiaries({
                                    levelId: currentLevel?.levelId
                                  })
                                );
                              }}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                p: "2px 40px",
                                color: styles.color,
                                border: `1px solid ${styles.color}`,
                                cursor: "pointer",
                                textTransform: "none"
                              }}
                            >
                              <IconButton
                                sx={{
                                  color: styles.textColorDiary,
                                  height: "17px",
                                  width: "17px",
                                  padding: 0,
                                  ":disabled": {
                                    color: styles.textColorDiary
                                  }
                                }}
                                disabled
                              >
                                <Add
                                  color={styles.textColorDiary}
                                  fontSize="12px"
                                />
                              </IconButton>
                              <Typography
                                color={styles.textColorDiary}
                                fontSize="13px"
                              >
                                Clear all
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          gap="10px"
                          borderRadius="16px"
                          p="20px"
                          alignItems="center"
                          display="flex"
                          flexDirection="column"
                          sx={{ background: styles.lightDiaryTracker }}
                          justifyContent="center"
                        >
                          {currentActiveData?.protein?.map((item) => (
                            <Box
                              width={"100%"}
                              display="flex"
                              justifyContent="space-between"
                              key={item.key}
                            >
                              <Box>
                                <Typography
                                  fontSize={13}
                                  color={styles.color}
                                  textTransform={"capitalize"}
                                >
                                  {optionsProteins?.find(
                                    (option) => option.value === item.name
                                  )?.label ?? item.name}
                                </Typography>
                                <Typography
                                  fontSize={10}
                                  color={styles.disabledColor}
                                >
                                  {item.time}
                                </Typography>
                              </Box>
                              <Box display="flex" alignItems={"center"}>
                                <Typography fontSize={13} fontWeight={600}>
                                  {convertItemQuantityToGrams(item)?.quantity}g
                                </Typography>
                                <LongMenu
                                  onClickHandler={async (type) => {
                                    setEditIngredientData(item);
                                    if (type === "edit") {
                                      setIsAddingWeight("protein");
                                    } else {
                                      await deleteIngredients(
                                        currentLevel?.levelId,
                                        currentActiveData?._id,
                                        item?._id,
                                        activeDay
                                      );
                                      setIsRefreshNeeded(true);
                                      dispatch(
                                        getCurrentUserDiaries({
                                          levelId: currentLevel?.levelId
                                        })
                                      );
                                    }
                                  }}
                                />
                              </Box>
                            </Box>
                          ))}
                          <Button
                            onClick={async () => {
                              await updateIngredients({
                                diaryId: currentActiveData?._id,
                                levelId: currentLevel?.levelId,
                                protein: [],
                                currentDayDate: activeDay
                              });
                              setIsRefreshNeeded(true);
                              dispatch(
                                getCurrentUserDiaries({
                                  levelId: currentLevel?.levelId
                                })
                              );
                            }}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              p: "2px 40px",
                              color: styles.color,
                              border: `1px solid ${styles.color}`,
                              cursor: "pointer",
                              textTransform: "none"
                            }}
                          >
                            <IconButton
                              sx={{
                                color: styles.textColorDiary,
                                height: "17px",
                                width: "17px",
                                padding: 0,
                                ":disabled": {
                                  color: styles.textColorDiary
                                }
                              }}
                              disabled
                            >
                              <Add
                                color={styles.textColorDiary}
                                fontSize="12px"
                              />
                            </IconButton>
                            <Typography
                              color={styles.textColorDiary}
                              fontSize="13px"
                            >
                              Clear all
                            </Typography>
                          </Button>
                        </Box>
                      )
                    ) : (
                      <Box
                        height={"200px"}
                        gap="10px"
                        borderRadius="16px"
                        p="20px"
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        sx={{ background: styles.lightDiaryTracker }}
                        justifyContent="center"
                      >
                        <NoEntry />
                        <Typography color="#6B7280">No entry</Typography>
                      </Box>
                    )}
                  </>
                )}
                {currentLevel?.levelName === "level3" && (
                  <>
                    <Box
                      width={"100%"}
                      gap="40px"
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography
                        // marginBottom={-4}
                        // marginTop={4}
                        fontWeight={600}
                        color={"#ff9431"}
                      >
                        Other{" "}
                      </Typography>
                    </Box>
                    {currentActiveData?.otherFood?.length ? (
                      selectedLevel === "level3" ? (
                        <Box display={"flex"} flexDirection={"column"} gap={2}>
                          <Box
                            display={"flex"}
                            gap={0.4}
                            flexDirection="column"
                            mt={-2}
                            borderRadius="8px"
                            padding={"12px"}
                            width={"100%"}
                            sx={{ background: styles.lightGreyColor }}
                          >
                            <Typography fontWeight="bold" fontSize={14}>
                              {"Other"}
                            </Typography>
                            <Typography fontSize={13}>
                              {`${
                                Math.round(
                                  +summaryData("otherFood")?.calories
                                ) || 0
                              } Cals · ${
                                Math.round(
                                  +summaryData("otherFood")?.protein
                                ) || 0
                              }g Protein · ${
                                Math.round(
                                  +summaryData("otherFood")?.carbohydrate
                                ) || 0
                              }g Carbs · ${
                                Math.round(+summaryData("otherFood")?.fiber) ||
                                0
                              }g Fiber · ${
                                Math.round(+summaryData("otherFood")?.fat) || 0
                              }g Fat`}
                            </Typography>
                          </Box>
                          <Box
                            gap="10px"
                            borderRadius="16px"
                            p="20px"
                            mb={2}
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            sx={{ background: styles.lightDiaryTracker }}
                            justifyContent="center"
                          >
                            {currentActiveData?.otherFood?.map((item) => (
                              <Box
                                width={"100%"}
                                display="flex"
                                justifyContent="space-between"
                                key={item.key}
                              >
                                <Box>
                                  <Box
                                    display={"flex"}
                                    alignItems="center"
                                    gap="10px"
                                  >
                                    <Tooltip title={item.name}>
                                      <Typography
                                        fontSize={13}
                                        color={styles.color}
                                        textTransform={"capitalize"}
                                        sx={{
                                          cursor: "pointer",
                                          display: "block", // Ensures the container behaves as a block element
                                          maxWidth: isMobile
                                            ? "160px"
                                            : "210px", // Set your fixed width (adjust as needed)
                                          overflow: "hidden", // Hide overflow content
                                          textOverflow: "ellipsis", // Show ellipsis when text overflows
                                          whiteSpace: "nowrap" // Prevent text from wrapping
                                        }}
                                      >
                                        {item.name}
                                      </Typography>
                                    </Tooltip>
                                    {item?.type === "meal" ? (
                                      <MealIcon
                                        style={{
                                          width: 16,
                                          height: 16
                                        }}
                                      />
                                    ) : item?.type === "recipe" ? (
                                      <RecipeIcon
                                        style={{
                                          width: 16,
                                          height: 16
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Box>
                                  <Typography
                                    fontSize={10}
                                    color={styles.disabledColor}
                                  >
                                    {item.time}
                                  </Typography>
                                </Box>
                                <Box display="flex" alignItems={"center"}>
                                  <Typography fontSize={13} fontWeight={600}>
                                    {convertItemQuantityToGrams(item)?.quantity}
                                    g
                                  </Typography>
                                  <LongMenu
                                    onClickHandler={async (type) => {
                                      setEditIngredientData(item);
                                      if (type === "edit") {
                                        setIsAddingWeight("otherFood");
                                      } else {
                                        await deleteIngredients(
                                          currentLevel?.levelId,
                                          currentActiveData?._id,
                                          item?._id,
                                          activeDay
                                        );
                                        setIsRefreshNeeded(true);
                                        dispatch(
                                          getCurrentUserDiaries({
                                            levelId: currentLevel?.levelId
                                          })
                                        );
                                      }
                                    }}
                                  />
                                </Box>
                              </Box>
                            ))}
                            <Button
                              onClick={async () => {
                                await updateIngredients({
                                  diaryId: currentActiveData?._id,
                                  levelId: currentLevel?.levelId,
                                  otherFood: [],
                                  currentDayDate: activeDay
                                });
                                setIsRefreshNeeded(true);
                                dispatch(
                                  getCurrentUserDiaries({
                                    levelId: currentLevel?.levelId
                                  })
                                );
                              }}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                p: "2px 40px",
                                color: styles.color,
                                border: `1px solid ${styles.color}`,
                                cursor: "pointer",
                                textTransform: "none"
                              }}
                            >
                              <IconButton
                                sx={{
                                  color: styles.textColorDiary,
                                  height: "17px",
                                  width: "17px",
                                  padding: 0,
                                  ":disabled": {
                                    color: styles.textColorDiary
                                  }
                                }}
                                disabled
                              >
                                <Add
                                  color={styles.textColorDiary}
                                  fontSize="12px"
                                />
                              </IconButton>
                              <Typography
                                color={styles.textColorDiary}
                                fontSize="13px"
                              >
                                Clear all
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          gap="10px"
                          borderRadius="16px"
                          p="20px"
                          alignItems="center"
                          display="flex"
                          flexDirection="column"
                          sx={{ background: styles.lightDiaryTracker }}
                          justifyContent="center"
                        >
                          {currentActiveData?.otherFood?.map((item) => (
                            <Box
                              width={"100%"}
                              display="flex"
                              justifyContent="space-between"
                              key={item.key}
                            >
                              <Box>
                                <Typography
                                  fontSize={13}
                                  color={styles.color}
                                  textTransform={"capitalize"}
                                >
                                  {optionsProteins?.find(
                                    (option) => option.value === item.name
                                  )?.label ?? item.name}
                                </Typography>
                                <Typography
                                  fontSize={10}
                                  color={styles.disabledColor}
                                >
                                  {item.time}
                                </Typography>
                              </Box>
                              <Box display="flex" alignItems={"center"}>
                                <Typography fontSize={13} fontWeight={600}>
                                  {convertItemQuantityToGrams(item)?.quantity}g
                                </Typography>
                                <LongMenu
                                  onClickHandler={async (type) => {
                                    setEditIngredientData(item);
                                    if (type === "edit") {
                                      setIsAddingWeight("otherFood");
                                    } else {
                                      await deleteIngredients(
                                        currentLevel?.levelId,
                                        currentActiveData?._id,
                                        item?._id,
                                        activeDay
                                      );
                                      setIsRefreshNeeded(true);
                                      dispatch(
                                        getCurrentUserDiaries({
                                          levelId: currentLevel?.levelId
                                        })
                                      );
                                    }
                                  }}
                                />
                              </Box>
                            </Box>
                          ))}
                          <Button
                            onClick={async () => {
                              await updateIngredients({
                                diaryId: currentActiveData?._id,
                                levelId: currentLevel?.levelId,
                                otherFood: [],
                                currentDayDate: activeDay
                              });
                              setIsRefreshNeeded(true);
                              dispatch(
                                getCurrentUserDiaries({
                                  levelId: currentLevel?.levelId
                                })
                              );
                            }}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              p: "2px 40px",
                              color: styles.color,
                              border: `1px solid ${styles.color}`,
                              cursor: "pointer",
                              textTransform: "none"
                            }}
                          >
                            <IconButton
                              sx={{
                                color: styles.textColorDiary,
                                height: "17px",
                                width: "17px",
                                padding: 0,
                                ":disabled": {
                                  color: styles.textColorDiary
                                }
                              }}
                              disabled
                            >
                              <Add
                                color={styles.textColorDiary}
                                fontSize="12px"
                              />
                            </IconButton>
                            <Typography
                              color={styles.textColorDiary}
                              fontSize="13px"
                            >
                              Clear all
                            </Typography>
                          </Button>
                        </Box>
                      )
                    ) : (
                      <Box
                        height={"200px"}
                        gap="10px"
                        borderRadius="16px"
                        p="20px"
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        sx={{ background: styles.lightDiaryTracker }}
                        justifyContent="center"
                      >
                        <NoEntry />
                        <Typography color="#6B7280">No entry</Typography>
                      </Box>
                    )}
                  </>
                )}
                {/* <Box display="flex" justifyContent="center">
                  <Button
                    onClick={() => {
                      dispatch(
                        completeDiaryDay({
                          levelId: currentLevel?.levelId,
                          diaryId: currentActiveData?._id
                        })
                      );
                      dispatch(
                        getCurrentUserDiaries({
                          levelId: currentLevel?.levelId
                        })
                      );

                      setIsRefreshNeeded(true);
                    }}
                    disabled={
                      currentActiveData?.completionDiaryDayDate
                        ? true
                        : !currentActiveData?.entry800g?.length &&
                          !currentActiveData?.protein?.length
                    }
                    sx={{
                      fontSize: "small",
                      p: "10px 20px",
                      color: "#368AD0",
                      border: "1px solid #368AD0",
                      cursor: "pointer",
                      textTransform: "none",
                      ":disabled": {
                        backgroundColor: "#A7C8E9",
                        border: "none",
                        color: "#64AEDD",
                        pointerEvents: "none"
                      }
                    }}
                  >
                    Complete the day
                  </Button>
                </Box> */}
              </Box>
            </>
          )}
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarMessage.includes("Success") ? "success" : "error"}
          variant="filled"
          sx={{
            width: "100%",
            alignItems: "center",
            padding: "0 16px",
            ".MuiAlert-action": {
              padding: "8px"
            }
          }}
        >
          <Box display="flex" gap="10px" alignItems={"center"}>
            <Typography fontSize="14px"> {snackbarMessage}</Typography>{" "}
            {!snackbarMessage?.includes("yesterday") &&
            !snackbarMessage?.includes("subscription") ? (
              <Button
                onClick={() => navigate("/programs")}
                sx={{
                  color: styles.color,
                  textTransform: "none",
                  background: "#F87171", // red-400
                  "&:hover": {
                    background: "#FCA5A5" // red-300
                  }
                }}
              >
                Go here
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Diary;
