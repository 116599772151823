import {
  Avatar,
  Box,
  Grid2,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import getInitials from "../../utils/getInitials";
import { useDispatch, useSelector } from "react-redux";
import { ChevronLeft, LinkOutlined } from "@mui/icons-material";
import { ReactComponent as CoachIcon } from "../../assets/coach.svg";
import { ReactComponent as ConstantIcon } from "../../assets/constant.svg";
import { ReactComponent as BicepIcon } from "../../assets/bicep.svg";
import { ReactComponent as ChefIcon } from "../../assets/chef.svg";
import { ReactComponent as Lvl1Icon } from "../../assets/lvl1.svg";
import { ReactComponent as Lvl2Icon } from "../../assets/lvl2.svg";
import { ReactComponent as Lvl3Icon } from "../../assets/lvl3.svg";
import { useTheme } from "@emotion/react";
import MealCard from "../Meals/MealCard";
import { getRecentMeals } from "../../features/meals/mealsSlice";
import dayjs from "dayjs";
import {
  selectLoading,
  // selectRecentMealData,
  selectUserRecentMealData
} from "../../features/meals/mealsSelectors";
import useSignedUrl from "../../utils/useSignedUrl";
import useThemeColor from "../../utils/useTheme";

function ViewUserMeal({
  isViewingUserMeals: currentMeal,
  setIsViewingUserMeals
}) {
  const dispatch = useDispatch();

  const [isRefresh, setIsRefresh] = React.useState(false);
  const theme = useTheme(); // Use theme hook to get the theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Correctly access breakpoints
  const allMeals = useSelector(selectUserRecentMealData);
  const loader = useSelector(selectLoading);
  const styles = useThemeColor();
  const userBadgeData = useMemo(
    () => allMeals?.[0]?.createdByUser || {},
    [allMeals]
  );
  const isTablet = useMediaQuery("(min-width: 600px) and (max-width: 900px)");

  useEffect(() => {
    dispatch(
      getRecentMeals({
        currentDate: dayjs().format("YYYY-MM-DD"),
        ...((currentMeal?.createdByUser?._id
          ? currentMeal?.createdByUser?._id
          : currentMeal?.createdByAdminUser?._id) && {
          userId: currentMeal?.createdByUser?._id
            ? currentMeal?.createdByUser?._id
            : currentMeal?.createdByAdminUser?._id
        })
      })
    );
  }, [dispatch, currentMeal, isRefresh]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const scrollableElement = document.getElementById("dashboard-main"); // or document.body
    scrollableElement.scrollTo(0, 0);
  }, []);

  const imgSrc = useSignedUrl(
    currentMeal?.createdByUser?.profilePic ||
      currentMeal?.createdByAdminUser?.profilePic,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );
  return (
    <Box
      sx={{
        padding: { xs: "0 0 100px 0", md: 0 }
      }}
    >
      <Box position={"relative"}>
        <IconButton
          sx={{
            position: "absolute",
            zIndex: 1,
            top: { sm: "20px" },
            left: { sm: "20px" }
          }}
          onClick={() => setIsViewingUserMeals(false)}
        >
          <ChevronLeft sx={{ height: "25px", width: "25px", fill: "white" }} />
        </IconButton>
        <Box
          width="100%"
          position={"relative"}
          component="img"
          src="/images/frame.png"
          alt="frame"
        />
      </Box>
      <Box
        p={{ sm: "0 24px", xs: "20px 10px 0 20px" }}
        display="flex"
        flexDirection="column"
        gap={6}
      >
        <Box
          position="relative"
          sx={{
            display: {
              sm: "block",
              md: "flex"
            },
            gap: {
              sm: "140px",
              md: "200px"
            }
          }}
        >
          <Box
            display="flex"
            gap="10px"
            alignItems="center"
            mt={isTablet ? 6.5 : 0}
          >
            {currentMeal?.createdByUser?.profilePic ||
            currentMeal?.createdByAdminUser?.profilePic ? (
              <Box
                component="img"
                height={isMobile ? 100 : 170}
                borderRadius="50%"
                border={`3px solid ${styles.background}`}
                width={isMobile ? 100 : 170}
                style={{
                  ...(isMobile && {
                    top: "-45px",
                    height: "100px",
                    width: "100px"
                  })
                }}
                position={"absolute"}
                src={imgSrc}
                alt="avatar"
                bottom="-10px"
              />
            ) : (
              <Avatar
                sx={{
                  ...(isMobile && {
                    top: "-45px",
                    height: "100px",
                    width: "100px"
                  }),
                  width: isMobile ? 100 : 170,
                  height: isMobile ? 100 : 170,
                  position: "absolute",
                  bottom: "-10px",
                  border: `3px solid ${styles.background}`
                }}
              >
                <Typography
                  color={styles.color}
                  fontSize="30px"
                  fontWeight={400}
                >
                  {getInitials(
                    currentMeal?.createdByAdminUser?.name
                      ? currentMeal?.createdByAdminUser?.name
                      : currentMeal?.createdByUser?.fullName
                  )}
                </Typography>
              </Avatar>
            )}{" "}
          </Box>
          <Box
            pl={isTablet ? 25 : 2}
            sx={{
              ...(isMobile && { marginTop: "60px" })
            }}
          >
            <Typography color={styles.color} fontSize="30px" fontWeight={500}>
              {currentMeal?.createdByAdminUser
                ? currentMeal?.createdByAdminUser?.name
                : currentMeal?.createdByUser?.fullName}
            </Typography>
            <Typography color="#9CA3AF" fontSize="16px" fontWeight={400}>
              {currentMeal?.createdByUser?.bio}
            </Typography>
            {currentMeal?.createdByUser?.url && (
              <Box
                display="flex"
                alignItems="center"
                gap="5px"
                sx={{
                  ":hover": {
                    cursor: "pointer"
                  }
                }}
                onClick={() => {
                  const fullUrl =
                    currentMeal?.createdByUser?.url.startsWith("http://") ||
                    currentMeal?.createdByUser?.url.startsWith("https://")
                      ? currentMeal?.createdByUser?.url
                      : `https://${currentMeal?.createdByUser?.url}`; // You can choose http or https based on your requirements

                  window.open(fullUrl, "_blank");
                }}
              >
                <LinkOutlined
                  color="#368AD0"
                  sx={{ path: { color: "#368AD0" } }}
                />
                <Typography color="#368AD0" fontSize="13px" fontWeight={500}>
                  {currentMeal?.createdByUser?.url}
                </Typography>
              </Box>
              // </a>
            )}
          </Box>
        </Box>
        {userBadgeData?.approvedMealsCount ||
        userBadgeData?.completedLevel?.length ||
        (allMeals?.[0]?.createdByUser?.isAdmin &&
          allMeals?.[0]?.createdByUser?.userType === "Coach") ||
        userBadgeData?.isConsistent ||
        userBadgeData?.maxStreak ? (
          <Box
            display="flex"
            mt={2}
            ml={2}
            gap={2}
            alignItems="center"
            overflow="auto"
            sx={{
              "::-webkit-scrollbar": {
                display: "none"
              }
            }}
          >
            {userBadgeData?.approvedMealsCount ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ cursor: "pointer" }}
                gap="10px"
                position="relative" // Make the Box a positioning context
              >
                <ChefIcon height={80} width={80} />
                {/* <Typography fontSize="0.7rem" variant="bold">
                                   Chef
                                 </Typography> */}
                <Typography
                  color="#F6541C"
                  borderRadius="50%"
                  fontSize="smaller"
                  sx={{
                    padding:
                      String(userBadgeData?.approvedMealsCount)?.length === 1
                        ? "1px 7px"
                        : "1px 4px",
                    background: styles.color,
                    position: "absolute", // Position the count absolutely
                    top: "45px", // Adjust as needed
                    right: "10px", // Adjust as needed
                    transform: "translate(50%, 50%)" // Center the count on the border
                  }}
                >
                  {userBadgeData?.approvedMealsCount}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
            {userBadgeData?.completedLevel?.at(-1) === "level1" ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="10px"
                position="relative" // Make the Box a positioning context
              >
                <Lvl1Icon height={80} width={80} />
              </Box>
            ) : (
              <></>
            )}
            {userBadgeData?.completedLevel?.at(-1) === "level2" ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="10px"
                position="relative" // Make the Box a positioning context
              >
                <Lvl2Icon height={80} width={80} />
              </Box>
            ) : (
              <></>
            )}
            {userBadgeData?.completedLevel?.at(-1) === "level3" ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="10px"
                position="relative" // Make the Box a positioning context
              >
                <Lvl3Icon height={80} width={80} />
              </Box>
            ) : (
              <></>
            )}
            {allMeals?.[0]?.createdByUser?.isAdmin &&
            allMeals?.[0]?.createdByUser?.userType === "Coach" ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                justifyContent="center"
                gap="10px"
                position="relative" // Make the Box a positioning context
              >
                <CoachIcon height={80} width={80} />
              </Box>
            ) : (
              <></>
            )}
            {userBadgeData?.isConsistent ? (
              <Box
                display="flex"
                flexDirection="column"
                sx={{ cursor: "pointer" }}
                alignItems="center"
                justifyContent="center"
                gap="10px"
                position="relative" // Make the Box a positioning context
              >
                <BicepIcon height={80} width={80} />
                {/* <Typography
                  color="#F6541C"
                  padding="0px 7px"
                  borderRadius="50%"
                  fontSize="smaller"
                  sx={{
                    background: styles.color,
                    padding:
                      String(userBadgeData?.isConsistent)?.length === 1
                        ? "1px 7px"
                        : "1px 4px",
                    position: "absolute", // Position the count absolutely
                    top: "45px", // Adjust as needed
                    right: "10px", // Adjust as needed
                    transform: "translate(50%, 50%)" // Center the count on the border
                  }}
                >
                  {userBadgeData?.isConsistent}
                </Typography> */}
              </Box>
            ) : (
              <></>
            )}
            {userBadgeData?.maxStreak ? (
              <Box
                display="flex"
                flexDirection="column"
                sx={{ cursor: "pointer" }}
                alignItems="center"
                justifyContent="center"
                gap="10px"
                position="relative" // Make the Box a positioning context
              >
                <ConstantIcon height={80} width={80} />
                <Typography
                  color="#F6541C"
                  padding="0px 7px"
                  borderRadius="50%"
                  fontSize="smaller"
                  sx={{
                    background: styles.color,
                    padding:
                      String(userBadgeData?.maxStreak)?.length === 1
                        ? "1px 7px"
                        : "1px 4px",
                    position: "absolute", // Position the count absolutely
                    top: "45px", // Adjust as needed
                    right: "10px", // Adjust as needed
                    transform: "translate(50%, 50%)" // Center the count on the border
                  }}
                >
                  {userBadgeData?.maxStreak}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        ) : (
          <></>
        )}
        {loader ? (
          <Skeleton sx={{ background: "gray" }} />
        ) : (
          <Grid2 container spacing={2}>
            {allMeals?.map((meal, index) => (
              <Grid2 item xs={2} key={index}>
                <MealCard
                  meal={meal}
                  setIsViewingUserMeals={setIsViewingUserMeals}
                  isFromViewingMeal={true}
                  currentTab={""}
                  setIsRefresh={setIsRefresh}
                />
              </Grid2>
            ))}
          </Grid2>
        )}
      </Box>
    </Box>
  );
}

export default ViewUserMeal;
