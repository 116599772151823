export const selectLevel3Loading = (state) => state.level3.loading;
export const selectSearchResults = (state) => state.level3.searchResults;
export const selectCustomFoodOptions = (state) =>
  state.level3.customFoodOptions;
export const selectSearchResultsLoading = (state) =>
  state.level3.searchResultsLoading;
export const selectAccessToken = (state) => state.level3.accessToken;
export const selectCurrentAddingMeal = (state) => state.level3.currentMealData;
export const selectCurrentAddingRecipe = (state) =>
  state.level3.currentRecipeData;
export const selectAllMealOrRecipeData = (state) =>
  state.level3.allMealOrRecipeData;
